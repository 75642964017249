<div class="row margin-top-11">
    <div class="col-md-4 padding-left-3 width-left-3">
        <div><span class="chart-title">Claim Aging</span><span class="margin-left-15"></span></div>
    </div>
</div>
<div class="row">
    <div class="col-md-2 "><span class="inprogress-hdr">Status</span></div>
    <div class="col-md-2 right-align"><span class="inprogress-hdr">Total</span></div>
    <div class="col-md-2 right-align"><span class="inprogress-hdr">0-6 Days</span></div>
    <div class="col-md-2 right-align"><span class="inprogress-hdr">7-14 Days</span></div>
    <div class="col-md-2 right-align"><span class="inprogress-hdr">15-30 Days</span></div>
    <div class="col-md-2 right-align"><span class="inprogress-hdr">30+ Days</span></div>

</div>
<div class="border-top-title"></div>
<div class="row linepadding" *ngFor="let inprogress of inprogressData; let index=index" >
    <div class="col-md-2 ">
        <span class="text">{{inprogress.status}}</span>
    </div>
    
    <div class="col-md-2 right-align" *ngIf="inprogress.totalCount!='' else lineBlock">
        <span class="text"><a href="#" [ngClass]="{'default-cursor':inprogress.totalCount == 0}" (click)="onInprogressClaimClick($event, inprogress.status,null,inprogress.totalCount)"><b>{{inprogress.totalCount}}</b></a></span>
    </div>       
    <div class="col-md-2 right-align" *ngIf="inprogress.catgOneCount!='' else lineBlock">
        <span class="text"><a href="#" [ngClass]="{'default-cursor':inprogress.catgOneCount == 0}" (click)="onInprogressClaimClick($event, inprogress.status, claimAges[0],inprogress.catgOneCount)"><b>{{inprogress.catgOneCount}}</b></a></span>
    </div>      
    <div class="col-md-2 right-align" *ngIf="inprogress.catgTwoCount!='' else lineBlock">
        <span class="text"><a href="#" [ngClass]="{'default-cursor':inprogress.catgTwoCount == 0}"  (click)="onInprogressClaimClick($event, inprogress.status, claimAges[1],inprogress.catgTwoCount)"><b>{{inprogress.catgTwoCount}}</b></a></span>
    </div>
    <div class="col-md-2 right-align" *ngIf="inprogress.catgThreeCount!='' else lineBlock">
        <span class="text"><a href="#" [ngClass]="{'default-cursor':inprogress.catgThreeCount == 0}"  (click)="onInprogressClaimClick($event, inprogress.status, claimAges[2],inprogress.catgThreeCount)"><b>{{inprogress.catgThreeCount}}</b></a></span>
    </div>
    <div class="col-md-2 right-align" *ngIf="inprogress.catgFourCount!='' else lineBlock">
        <span class="text"><a href="#" [ngClass]="{'default-cursor':inprogress.catgFourCount == 0}" (click)="onInprogressClaimClick($event, inprogress.status, claimAges[3],inprogress.catgFourCount)"><b>{{inprogress.catgFourCount}}</b></a></span>
    </div>
    <ng-template #lineBlock>
        <div  class="col-md-2 right-align lineTop">__</div>
    </ng-template>
</div>
