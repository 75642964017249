import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Constants } from '../data-models/constants.enum';

@Component({
  selector: 'app-load-more-records',
  templateUrl: './load-more-records.component.html',
  styleUrls: ['./load-more-records.component.css']
})
export class LoadMoreRecordsComponent{
  
@Input()
paginationDetails : any;

@Output()
triggerEvent = new EventEmitter<any>();

maxRowLimit: boolean;

customCloseDialogModal = { 
    show: false, 
}; 


  constructor() {
    // LoadMoreRecordsComponent constructor
   }


  onLoadMore(){
    this.isMaxRowLimitReached();
    this.customCloseDialogModal.show = true;
  }

  confirmLoadMoreRecords(){
  this.paginationDetails.segNo++;
  this.triggerEvent.emit({action:Constants.loadMore});
  this.customCloseDialogModal.show = false
  }

  exportResults(){
    this.customCloseDialogModal.show = false;
    this.triggerEvent.emit({action:Constants.export});
  }

  isMaxRowLimitReached(){
   this.maxRowLimit = false;
  if(((this.paginationDetails.segNo + 1) * 1000 ) + 1 > Number(Constants.maxRowLimit)){
    this.maxRowLimit = true;
  }
    return this.maxRowLimit;
  }
}
