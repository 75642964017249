<div class="terms-wrapper">
    <p class="terms-heading">Terms of Use</p>
    <p class="heading sub-heading">
        If you believe you are experiencing a medical emergency, please call 911.
    </p>
    <p>
        THIS DOCUMENT CONTAINS AN <a (click)="scroll(document,'heading1',$event)">ARBITRATION AGREEMENT AND CLASS ACTION
            WAIVER</a>, WHICH AFFECT YOUR LEGAL RIGHTS. YOU SHOULD REVIEW THIS DOCUMENT IN ITS ENTIRETY BEFORE ACCESSING
        OR USING THE ONLINE SERVICES.
    </p>
    <p class="margin-btm-0 heading">
        Agreement and Terms
    </p>
    <p>
        These Terms of Use (“Terms”) describe the rules for using Optum (also “‘we,” “us,” “our,” and “Company”) and
        Affiliates’ (an entity controlling, controlled by, or under common control with a named party) online and mobile
        websites, platforms, services and applications (“Online Services”).
    </p>
    <p>
        Our Online Services are intended for a United States audience. If you live outside the U.S., you may see Content
        on the Online Services about products or therapies that are not available or authorized in your country.
    </p>
    <p>
        By using our Online Services, you agree to these Terms and our <a target="_blank" href="/privacy">Privacy Policies</a>. We may change these Terms at
        any time, and such changes will be posted on the Online Services, with the date of the last revision listed as
        the “Effective Date” at the bottom of these Terms. Any modifications will be effective immediately upon such
        posting. By continuing to use the Online Services, you consent to any changes to our Terms.
    </p>
    <p>
        Your affirmative act of using the Online Services, or registering for membership, constitutes your electronic
        signature to these Terms and your consent to enter into this agreement electronically. You may print and retain
        a copy of these Terms. To print, you will need (i) a device (such as a computer or mobile phone) with a web
        browser and Internet access and (ii) either a printer or storage space on such device.
    </p>
    <p class="margin-btm-0 heading">
        Important Note Regarding Content of Digital Property
    </p>
    <p class="heading sub-heading">
        The information and content (collectively, “Content”) on the Online Services is for general educational
        information only. We are not a health care provider. We do not recommend any health care service, supply, or
        treatment for you. You should call your health care provider if you have questions, at any time, about your
        health.
    </p>
    <p>
        The Content may include information regarding therapeutic and generic alternatives for certain prescription
        drugs and may describe uses for products or therapies that have not been approved by the Food and Drug
        Administration.
    </p>
    <p>
        The Content should not be considered financial advices, legal advice or tax advice.
    </p>
    <p>
        You are responsible for protecting your username (e.g., the email address and password that you provide when
        registering for Online Services) or other activation codes, and if they are compromised, you agree to change
        your username and password and immediately Contact Us.
    </p>
    <p class="margin-btm-0 heading">
        Restrictions on Use of Online Services
    </p>
    <p class="margin-btm-0 heading sub-heading">
        You Will Not:
    </p>
    <ul>

        <li>Use our Online Services or Content in any way not expressly permitted by these Terms;</li>
        <li>Copy, modify or harvest data, Content, or materials from the Online Services;</li>
        <li>remove or alter, any copyright or other proprietary rights or notices on the Online Services;</li>
        <li>Misrepresent your identity or provide any false information;</li>
        <li>Interfere with the operation of our Online Services;</li>
        <li>Share any password with any third parties or use any third-party’s password;</li>
        <li>Engage in commercial, competitive, or viral messaging, or sending of unsolicited advertisements, or similar
            communications, including harmful computer code, viruses, or malware;</li>
        <li>Use the Online Services in any way that could, in our sole judgment, interfere with any other party's use or
            enjoyment of the Online Services, impair our networks or servers, or expose us or any third party to any
            claims or liability whatsoever, or use software or other means to access, "scrape," "crawl," or "spider,"
            any webpages or other services from the Online Services. If you are blocked from the Online Services
            (including by blocking your IP address), you agree not to implement any measures to circumvent such
            blocking;</li>
        <li>Directly or indirectly authorize anyone else to take actions prohibited in this section; or</li>
        <li>Attempt to reverse engineer any of the software used to provide the Online Services.</li>
    </ul>
    <p class="margin-btm-0 heading sub-heading">
        You Agree That:
    </p>
    <ul>
        <li>You will comply with all applicable laws and regulations;</li>
        <li>You represent and warrant that you are at least 13 years of age, and that, if you are between 13 and the age
            of majority in your state and otherwise not emancipated, a parent and/or guardian agrees to these Terms on
            your behalf; and</li>
        <li>We may take any measures we deem appropriate, in our sole discretion and permitted by law, to enforce these
            Terms.</li>
    </ul>
    <p class="margin-btm-0 heading">
        Posting Messages, Comments or Content
    </p>
    <p class="margin-btm-0 heading sub-heading">
        You Will Not:
    </p>
    <ul>
        <li>Post any improper or inappropriate content, including that which is obscene, libelous, harassing,
            threatening, harmful, inflammatory, invasive of privacy, or otherwise objectionable, constitutes advertising
            or solicitation, or violates applicable law.</li>
    </ul>
    <p class="margin-btm-0 heading sub-heading">
        You Agree That:
    </p>
    <ul>
        <li>Any content posted to the Online Services by third parties is only the opinion of the poster, is no
            substitute for your own research, and should not be relied upon for any purpose;</li>
        <li>You are solely responsible for the content of any postings that you submit. Any content you submit will be
            routed through the Internet and you understand and acknowledge that you have no expectation of privacy
            regarding that content. Never assume that you are anonymous and cannot be identified by your posts;</li>
        <li>If you do post content or submit material to the Online Services, and unless we indicate otherwise, you
            grant the Company a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to
            use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display
            such content throughout the world in any media;</li>
        <li>You grant the Company and any of its sublicensees the right to use the name that you submit in connection
            with such content, if they choose; and </li>
        <li>You represent and warrant that you own or otherwise control all of the rights to the content that you post;
            that the content is accurate; that use of the content you supply does not violate these Terms and will not
            cause injury to any person or entity; and that you will indemnify the Company or its affiliates for all
            claims resulting from any content that you supply.</li>
    </ul>
    <p class="margin-btm-0 heading">
        License to Use Online Services and Content Ownership
    </p>
    <p>
        Subject to these Terms, the Company grants you a personal, non-commercial, non-transferable, non-exclusive,
        revocable, limited license to view the Content on our Online Services for the sole purpose of obtaining
        information regarding our plans or products and related services. All rights, title, and interest in and to the
        Online Services, including the Content, and all intellectual property rights, including all copyright,
        trademark, patent, and trade secret rights will remain with the Company and our licensors and vendors. No
        ownership interest is transferred to you or any other entity by virtue of making the Content available on the
        Online Services, granting you a license to use Online Service, or your entering into this Agreement.
    </p>
    <p>
        We may terminate this license at any time for any reason. If you breach any of these Terms, your license to the
        Online Services and its Content terminates immediately. Upon the termination of this license, you must stop
        using the Online Services, including all Content, and return or destroy all copies, including electronic copies,
        of the Content in your possession or control.
    </p>
    <p class="margin-btm-0 heading">
        Copyright Infringement – DMCA Notice
    </p>
    <p>
        The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright owners who believe
        that material appearing on the Internet infringes their rights under US copyright law. If you believe, in good
        faith, that content or material on our Online Services infringes a copyright owned by you, you (or your agent)
        may send the Company a notice requesting that the material be removed or access to it blocked. Notices and
        counter-notices with respect to the Online Services should be sent to the applicable address below:
    </p>
    <div class="d-flex flex-row mb-18">
        <div class="d-flex flex-column">
            <span class="heading sub-heading"> Company</span>
            <span> Optum</span>
        </div>
        <div class="d-flex flex-column">
            <span class="heading sub-heading"> Contact Information</span>
            <span> Attn: DMCA Registered Agent</span>
            <span>9900 Bren Road East</span>
            <span>Minnetonka, MN 55343</span>
            <a href="mailto:DMCARegisteredAgent2@optum.com"> DMCARegisteredAgent2@optum.com</a>
        </div>
    </div>
    <p>
        The notice must include the following information: (a) a physical or electronic signature of a person authorized
        to act on behalf of the owner of an exclusive right that is allegedly infringed; (b) identification of the
        copyrighted work claimed to have been infringed; (c) identification of the material that is claimed to be
        infringing or the subject of infringing activity; (d) the name, address, telephone number, and email address of
        the complaining party; (e) a statement that the complaining party has a good-faith belief that use of the
        material in the manner complained of is not authorized by the copyright owner, its agent or the law; and (f) a
        statement that the information in the notification is accurate and, under penalty of perjury, that the
        complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
        If you believe in good faith that a notice of copyright infringement has been wrongly filed against you, the
        DMCA permits you to send us a counter-notice. Notices and counter-notices must meet the then-current statutory
        requirements imposed by the DMCA.
    </p>
    <p class="margin-btm-0 heading">
        Unsolicited Idea Submission Policy
    </p>
    <p>
        It is our policy not to accept or consider unsolicited ideas or offers to sell intellectual property. We ask
        that you please do not submit any such ideas or offers to us or our employees or contractors.
    </p>
    <p>
        This policy is intended to avoid potential misunderstandings if our technology, products, or services may seem
        similar to a submission made to the company. If you still choose to make any submission to us, you agree as
        follows, regardless of any statements made in your submission that:
    </p>
    <ul>

        <li>We owe you no compensation;</li>
        <li>There is no duty of confidentiality between us and you;</li>
        <li>Your submission automatically becomes our property, and we may use or redistribute the contents of that
            submission in any way for any purpose;</li>
        <li>We are free to acquire, develop, and sell services and products that may be competitive to those you offer
            or suggest; and</li>
        <li>It is your responsibility to protect your own intellectual property; you should not make a submission to us
            if you have concerns about intellectual property.</li>
    </ul>
    <p>
        If you are uncertain about the meaning of this policy or the legal ramifications of submitting materials to us,
        you should consult with your attorney before making a submission.
    </p>
    <p class="margin-btm-0 heading">
        Links
    </p>
    <p>
        While visiting our Online Services, you may go to a link to other online websites, mobile websites, platforms,
        services, and applications (“Weblinks”) and leave our Online Services. For your convenience, we provide Weblinks
        to other online content or sites that may contain information that may be useful or interesting to you. We do
        not endorse, nor are responsible for, the content, accuracy or accessibility of the content of Weblinks operated
        by third parties. You are solely responsible for your dealings with such third parties and should review the
        Terms and privacy policies of such third parties.
    </p>
    <p class="margin-btm-0 heading">
        No Warranty
    </p>
    <p class="heading sub-heading">
        Nothing within these Terms should be meant or implied to be a warranty. You use the Online Services at your own
        risk. We do not guarantee the accuracy, completeness, timeliness, correctness or reliability of any content
        available through the Online Services. Online Services are provided to you when available and are provided on an
        “as is” basis. We make no representation that use of our Online Services will be uninterrupted or error-free, or
        free of viruses or other harmful components.
    </p>
    <p class="heading sub-heading">
        Some states do not allow a Terms of Use to have a “no-warranties” provision, and even though these Terms make no
        warranty, if your state does not allow this type of limitation, this paragraph will not apply to you.
    </p>
    <p class="margin-btm-0 heading">
        Limitation of Liability
    </p>
    <p class="heading sub-heading">
        You agree that we have no liability for any loss arising out of, or relating to: these Online Services; any
        third-party site or program accessed through the Online Services; Any acts or omissions by us or any third
        party; and/or your access or use of the Online Services. This limitation of liability includes any claim based
        on warranty, contract, tort, strict liability, or any other legal theory.
    </p>
    <p class="heading sub-heading">
        This limitation of liability does not apply if you are a New Jersey resident. With respect to residents of New
        Jersey, any released parties are not liable for any damages unless such damages are the result of our negligent
        or reckless acts or omissions; and any released parties are not, in any case, liable for indirect, incidental,
        special, consequential or punitive damages.
    </p>
    <p class="heading sub-heading">
        Other states may also limit liability. If your state does not allow this type of limitation, one or more of the
        limitations above may not apply to you.
    </p>
    <p class="margin-btm-0 heading">
        Indemnification
    </p>
    <p>
        You agree to defend, indemnify, and hold harmless any released parties from any claim by third parties,
        including reasonable attorneys' fees for counsel of our own choosing, arising out of or related to your breach
        of the Terms or any copyright infringement, misappropriation, misuse, gross negligence, intentional misconduct,
        or violation of applicable law relating to your use of the Online Services or Content. You may not transfer or
        assign any rights or obligations under this Agreement. In any litigation, you will cooperate with us in
        asserting any available defenses.
    </p>
    <p class="margin-btm-0 heading">
        Export Controls
    </p>
    <p>
        You may not use, export, re-export, import, sell, transfer, or proxy our Online Services or Content unless such
        activity is permitted by these Terms and such activity is not prohibited by United States law, the laws of the
        jurisdiction in which you receive our Online Services, or any other applicable laws and regulations. In
        particular, but without limitation, the Online Services and/or Content may not be exported, re-exported or made
        available in any manner (a) into any U.S. embargoed countries or (b) to anyone on the U.S. Treasury Department's
        list of Specially Designated Nationals or the U.S. Department of Commerce Denied Person's List or Entity List.
    </p>
    <p>
        By using our Online Services, you represent and warrant that you and any ultimate beneficiary of our Online
        Services are not located in any such country or on any such list. You also agree that you will not use our
        Online Service for any purposes prohibited by U.S. law, including, without limitation, the development, design,
        manufacture or production of nuclear missiles or chemical or biological weapons.
    </p>
    <p class="margin-btm-0 heading" id="heading1">
        Resolving Disputes
    </p>
    <p  class="heading sub-heading">
        PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE
        A LAWSUIT IN COURT
    </p>
    <ul>
        <li><span class="heading sub-heading">Informal Resolution.</span> Before filing a claim in connection with these Terms or
            the Online Services, you agree to try to resolve the dispute informally. Contact <a herf="mailto:legalmail@uhg.com">legalmail@uhg.com</a> if the
            dispute is with Optum. If the dispute is not resolved within 30 days of contact, you or we may bring a
            formal arbitration proceeding as described below.</li>
        <li><span class="heading sub-heading">Agreement to Arbitrate.</span> You and we agree to resolve any claims relating to
            these Terms, or the Online Services, through final and binding arbitration, except as indicated below under
            “Exceptions to Agreement to Arbitrate” or where prohibited by law. The arbitration will be held in the state
            where you live. For residents outside the United States, arbitration will be initiated in Hennepin County,
            Minnesota, or a location agreed to by the parties. You and we agree to submit to the personal jurisdiction
            of any state or federal court in the state of Minnesota to compel arbitration, stay proceedings pending
            arbitration, or to confirm, modify, vacate, or enter judgment on the award entered by the arbitrator.</li>
        <li><span class="heading sub-heading">How to Opt Out of Agreement to Arbitrate.</span> If you don’t agree to arbitrate, you
            may opt out within 30 days of first agreeing to these terms by sending a letter to us. The letter must
            specify your first and last name, username, mailing address, and state that you are opting out of this
            arbitration provision. Send the letter to UnitedHealth Group, Attention: Legal Intake (MN-008-T502), 9900
            Bren Road East, Minnetonka, MN 55343.</li>
        <li><p><span class="heading sub-heading">Exceptions to Agreement to Arbitrate.</span> Either you (or we) may assert claims in
            the small claims court in Hennepin County, Minnesota or any other U.S. county in which we have a principal
            business address, provided that the informal dispute resolution described above was unsuccessful and the
            claim is eligible to be filed. Either party may bring a lawsuit solely for injunctive relief to stop
            unauthorized use or abuse of the Online Services or intellectual property infringement (for example,
            trademark, trade secret, copyright, or patent rights) without first engaging in arbitration or the
            informal-resolution process described above. The agreement to arbitrate does not apply to (a) disputes about
            the existence or extent of your health benefits or coverage or (b) claims that may not be arbitrated as a
            matter of law.</p>
            <p>
                Arbitration does apply to all other claims relating to these Terms or the Online Services including (1) the
                interpretation, formation, performance, or breach of the Terms, (2) whether any part of the Terms is void or
                voidable, (3) whether a claim is subject to arbitration, and/or (4) whether arbitration has been waived due to
                litigation conduct.
            </p>
        </li>
        <li>
            <span class="heading sub-heading">Arbitration Procedures.</span> The arbitration will be conducted by a single
            arbitrator, governed by the Consumer Arbitration Rules of the American Arbitration Association (“AAA”),
            (<a href="https://www.adr.org/Rules" target="_blank">www.adr.org/Rules</a>), unless otherwise agreed to by the parties. You may also obtain the Consumer Arbitration
            Rules by calling AAA at 1-800-778-7879, TTY 711.
        </li>
        <li>
            <span class="heading sub-heading">Arbitration and Attorney's Fees.</span> Payment of all filing, administration, and
            arbitrator costs and expenses imposed by AAA will be governed by the Consumer Arbitration Rules, provided
            that if you are initiating an arbitration against us and the value of the relief sought is $10,000 or less,
            then we will advance all filing, administrative and arbitration costs and expenses imposed by AAA (subject
            to reimbursement if the arbitrator finds the arbitration to be frivolous or asserted for an improper
            purpose). You are responsible for all other additional costs that you may incur in the arbitration,
            including attorney's fees and expert witness costs, unless we are otherwise specifically required to pay
            such fees under applicable law. The decision of the arbitrator will be in writing and binding and conclusive
            on us and you, and judgment to enforce the decision may be entered by any court of competent jurisdiction.
            You agree that dispositive motions, including without limitation, motions to dismiss and motions for summary
            judgment, will be allowed in the arbitration. The arbitrator must follow these rules and can award the same
            damages and relief as a court, including injunctive or other equitable relief and attorneys’ fees. You and
            we agree not to seek any attorneys’ fees and expert witness costs unless the arbitrator finds that a claim
            or defense was frivolous or asserted for an improper purpose. Applicable law may allow the arbitrator to
            award attorneys’ fees and costs to the prevailing party.
        </li>
        <li>
            Except as explicitly provided in these Terms, if any clause within these arbitration provisions is found to
            be illegal or unenforceable, that clause will be severed from these arbitration provisions, and the
            remainder of the arbitration provisions will be given full force and effect. The terms of these arbitration
            provisions will also apply to any claims asserted by you against any present or future parent or affiliated
            company of ours arising out of your use of the Online Services.
        </li>
    </ul>
    <p class="margin-btm-0 heading sub-heading">
        No Class Actions. PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS
    </p>
    <ul>
        <li>
            BY AGREEING TO THIS ARBITRATION AGREEMENT, YOU ARE GIVING UP YOUR RIGHT TO GO TO COURT, INCLUDING YOUR RIGHT
            TO A JURY TRIAL AND TO PARTICIPATE IN A CLASS ACTION. YOU UNDERSTAND THAT BY AGREEING TO THIS ARBITRATION
            AGREEMENT AND CLASS ACTION WAIVER, YOU MAY ONLY BRING CLAIMS AGAINST US AND OUR RELATED PARTIES IN AN
            INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE
            PROCEEDING. IF YOU DO NOT AGREE TO ARBITRATION, YOU SHOULD OPT OUT OF THIS ARBITRATION PROVISION PER THE
            TERMS. OTHERWISE, YOU ARE NOT AUTHORIZED TO USE THE ONLINE SERVICES IN ANY WAY.
        </li>
        <li>
            If any court or arbitrator determines that this “No Class Actions” section is void or unenforceable for any
            reason, or that an arbitration can proceed on a class basis, then the arbitration provisions set forth above
            will be deemed null and void in their entirety, and the parties will be deemed to have not agreed to
            arbitrate disputes.
        </li>
    </ul>
    <p class="margin-btm-0 heading">
        Changes to this Section
    </p>
    <p>
        We will provide 30 days’ notice of any changes to this section. Amendments will become effective thirty days
        after we provide such notice. If a court or arbitrator decides that this subsection on “Changes to this Section”
        is not enforceable or valid, then this subsection will be severed from the section entitled “and the court or
        arbitrator will apply the first “Resolving Disputes” section in existence after you began using the Online
        Services.
    </p>
    <p class="margin-btm-0 heading">
        Survival
    </p>
    <p>
        This “Resolving Disputes” section will survive any termination of your account, enrollment in any program,
        eligibility for coverage, or the Online Services.
    </p>
    <p class="margin-btm-0 heading">
        Governing Law and Statute of Limitations
    </p>
    <p>
        You agree that Minnesota law governs these Terms and any claim or dispute that you may have against us, and you
        agree to the jurisdiction and venue of the state and federal courts in Minnesota for any dispute involving the
        Company or its employees, officers, directors, agents and providers.
    </p>
    <p>
        Unless you are a resident of New Jersey, you must initiate any cause of action within one year after the claim
        has arisen, or you will be barred from pursuing any cause of action. The United Nations Convention on Contracts
        for the International Sale of Goods do not apply to these Terms.
    </p>
    <p class="margin-btm-0 heading">
        Termination
    </p>
    <p>
        We may cancel, suspend or block your use of the Online Services and/or registration at any time, without cause
        and/or without notice. <span class="heading sub-heading">You agree that we will not be liable to you or any other party for any termination of
            your access to the Online Services in accordance with these Terms.</span>
    </p>
    <p class="margin-btm-0 heading">
        Severability
    </p>
    <p>
        If any provision of these Terms is held to be unenforceable or invalid by an arbitrator or court of competent
        jurisdiction, the remaining portions of the Terms will be determined without the unenforceable or invalid
        provision. All other Terms will remain in full force and effect.
    </p>
    <p class="margin-btm-0 heading">
        Miscellaneous
    </p>
    <p>
        These Terms, and any supplemental terms, policies, rules and guidelines posted on the Online Services,
        constitute the entire agreement between you and us in connection with the Online Services and supersede all
        previous written or oral agreements. You acknowledge that we have the right to seek, at your expense, an
        injunction to stop or prevent a breach of your obligations. No waiver by us will have effect unless such waiver
        is set forth in writing and signed by us; nor will any such waiver of any breach or default constitute a waiver
        of any subsequent breach or default.
    </p>
    <p class="heading margin-btm-0">
        Mobile Applications
    </p>
    <p>
        The following additional terms apply to your access to or use of any mobile application in connection with the
        Online Services (together with all information and software associated therewith, the "Application" or
        "Applications") through any mobile device (such as tablets, mobile phones, etc.), and are "Additional Terms" as
        defined above.
    </p>
    <p>
        <span class="heading sub-heading">Rights Granted to You.</span> We grant you a limited, non-exclusive, revocable,
        non-transferrable license to download, install and use the Applications solely for your personal, non-commercial
        use on a mobile or tablet device owned or controlled by you and only in accordance with the Terms. Other than
        the limited rights granted in the immediately preceding sentence, no other rights are granted to you. This is
        only a license, and not a sale of, the Applications to you.
    </p>
    <p>
        <span class="heading sub-heading">Additional Restrictions and Notices.</span> You agree that you will not remove, disable,
        circumvent or otherwise create or implement any workaround to any copy protection, rights management, or
        security features in or protecting the Applications. You acknowledge that we may issue an upgraded version of
        the Applications and may automatically upgrade the Application that you are using. You consent to such automatic
        upgrading and agree that these Terms shall govern all such upgrades. Our Applications or the Online Services may
        include third-party code and other software, which is governed by the applicable open source or third-party end
        user license agreement, if any, that authorizes the use of such code.
    </p>
    <p>
        <span class="heading sub-heading">Third-Party Terms.</span> You agree to comply with all applicable terms, conditions and
        agreements between you and any third party that provides products or services that facilitate or enable your use
        of any Application, and you acknowledge and agree that your use of any Application may result in charges to you
        by those third parties in connection with the products and services they provide to you (such as data plan
        charges), and you will be solely responsible for any such charges.
    </p>
    <p>
        <span class="heading sub-heading">Termination of Your Rights.</span> Upon any termination of your rights hereunder, for any
        reason, you will immediately uninstall or delete the Applications and cease any further use of such
        Applications.
    </p>
    <p>
        <span class="heading sub-heading">Special Notice for International Use/Export Controls.</span> Any technology or software
        underlying the Applications or Online Services that is available in connection with the provision of the Online
        Services and the transmission of applicable data (collectively, the “Software”), if any, is subject to United
        States export controls. No Software may be downloaded from the Applications or Online Services or otherwise
        exported or re-exported in violation of U.S. export laws. Downloading or using the Software is at your sole
        risk. Recognizing the global nature of the Internet, you agree to comply with all local rules and laws regarding
        your use of the Applications and/or Online Services, including as it concerns online conduct and acceptable
        content.
    </p>
    <p>
        <span class="heading sub-heading">Apple iOS App.</span> If the Online Services that you use include an Application that you
        download, access and/or use and that runs on Apple's iOS operating system (an “iOS App”), you acknowledge and
        agree that:
    </p>
    <ul>

        <li>The iOS App may only be accessed and used on a device owned or controlled by you and using Apple's iOS
            operating system;</li>
        <li>These Terms are between you and us, and not with Apple;</li>
        <li>Apple has no obligation at all to provide any support or maintenance services in relation to the iOS App,
            and if you have any maintenance or support questions in relation to the iOS App, please contact Company, not
            Apple;</li>
        <li>Except as otherwise expressly set forth in these Terms, any claims relating to the possession or use of the
            iOS App are between you and us (and not between you, or anyone else, and Apple);</li>
        <li>In the event of any claim by a third party that your possession or use (in accordance with these Terms) of
            the iOS App infringes any intellectual property rights, Apple will not be responsible or liable to you in
            relation to that claim; and </li>
        <li>Although these Terms are entered into between you and Company (and not Apple), Apple, as a third-party
            beneficiary under these Terms, will have the right to enforce these terms against you.</li>
        <li>In addition, you represent and warrant that:
            <ul>
                <li>You are not, and will not be, located in any country that is the subject of a United States Government
                    embargo or that has been designated by the United States Government as a “terrorist supporting” country;
                </li>
                <li>You are not listed on any United States Government list of prohibited or restricted parties; and</li>
                <li>If the iOS App does not conform to any warranty applying to it, you may notify Apple, which will then refund
                    the purchase price of the iOS App (if any) to you. Subject to that, and to the maximum extent permitted by
                    law, Apple does not give or enter into any warranty, condition or other term in relation to the iOS App and
                    will not be liable to you for any claims, losses, costs or expenses of whatever nature in relation to the
                    iOS App or as a result of you or anyone else using the iOS App or relying on any of its content.</li>
            </ul>
        </li>
    </ul>
    <p>
        <span class="heading sub-heading">Google App.</span> If the Online Services that you use includes an Application that you
        download, access, and/or use from the Google Play Store (“Google-Sourced Software”): (i) you acknowledge that
        these Terms are between you and us only, and not with Google, Inc. (“Google”); (ii) your use of Google-Sourced
        Software must comply with Google’s then-current Google Play Store Terms of Service; (iii) Google is only a
        provider of the Google Play Store where you obtained the Google-Sourced Software; (iv) we, and not Google, are
        solely responsible for our Google-Sourced Software; (v) Google has no obligation or liability to you with
        respect to Google-Sourced Software or the Terms; and (vi) you acknowledge and agree that Google is a third-party
        beneficiary to the Terms as it relates to our Google-Sourced Software.
    </p>
    <p class="heading margin-btm-0">
        Survival and Assignment
    </p>
    <p>
        Your obligations under the following sections survive termination of this Agreement: Agreement and Terms;
        Important Note Regarding Content of Digital Property; License to Use the Online Services and Content Ownership;
        Restrictions on Use of Online Services; Posting Messages, Comments or Content; Copyright Infringement – DMCA
        Notice; Unsolicited Idea Submission Policy; No Warranty; Limitation of Liability; Indemnification; Export
        Controls; Resolving Disputes; Governing Law and Statute of Limitations; Termination; Severability; Miscellaneous
        and Mobile Applications. You may not transfer or assign any rights or obligations under this Agreement. The
        Company may transfer or assign its rights and obligations under this Agreement.
    </p>
    <p class="margin-btm-0 heading">
        Contact Us
    </p>
    <p>
        If you have questions regarding these Terms, contact us at <a href="mailto:william_c_marshall@optum.com">william_c_marshall@optum.com</a>.
    </p>
    <p class="margin-btm-0 heading">
        Effective Date:
    </p>
    <p>
        January 4, 2022
    </p>
</div>