import { Component, OnInit, Input, Inject } from '@angular/core';
import { ErrCrrUtility, inputTypeConfig } from '../../err-crr-utility';
import { ErrorCorrectionService } from 'src/app/services/errorCorrectionClm.service';
import { DOCUMENT, DatePipe } from '@angular/common';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { forkJoin } from 'rxjs';
import { Constants } from 'src/app/data-models/constants.enum';
import { MdeService } from 'src/app/services/tabs/mde.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Params } from '@angular/router';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';

@Component({
  selector: 'app-err-crr-member-catg',
  templateUrl: './err-crr-member-catg.component.html',
  styleUrls: ['./err-crr-member-catg.component.css']
})
export class ErrCrrMemberCatgComponent extends ErrCrrUtility implements OnInit {
  @Input() selectedRow: any;
  @Input() routeParam: Params;
  public clmDta;
  public open: boolean = true;
  changeBtnCls: string;
  memberClmInfoObj = {};
  public cmsContrctNoDtls ={};
  memberInfoFieldMapperObj= {};
  public showHideConfig = {};
  public invalidFieldStatus = {};
  inputTypeConfig = inputTypeConfig;
  public addressKeyMapping = {
    addinfo1:'loop2010BAddress.addressInformation1',
    addinfo2:'loop2010BAddress.addressInformation2',
    city:'loop2010BCityStateZipCode.cityName',
    postalCode:'loop2010BCityStateZipCode.postalCode',
    countryCode:'loop2010BCityStateZipCode.countryCode',
    stateorProvCode:'loop2010BCityStateZipCode.stateorProvCode'
};
private readonly section = 'Member Information';
private readonly memberInfoMapping = {
  memberName : 'Member Name',
  dob:'Date of Birth',
  gender:'Gender',
  memberAddress: 'Member Address',
  CMSContractNo: 'CMS Contract Number',
  
}
  public cancelParamObj = {};
  public saveParamObj = {};
  collapsiblePanel1: boolean = true;
  constructor(private errorCorrectionService: ErrorCorrectionService,
    sanitized: DomSanitizer,
    @Inject(DOCUMENT) private readonly document: Document,@Inject(SESSION_STORAGE)public sessionStorage:WebStorageService,public datePipe: DatePipe,  private readonly mdeService:MdeService) { 
      super(sanitized);
    }

  ngOnInit() {
    this.fetchErrCrrData();
    this.changeBtnCls = "changeBtn-" + this.getUuid();
  }

  getUuid() {
    return (this.selectedRow.medClmSk ? this.selectedRow.medClmSk : `${this.selectedRow.fileKey}_${this.selectedRow.clmId}`) + "" + this.selectedRow.rowIndex;  }

  toggleOpen() {
    this.open = !this.open;
  }

  fetchErrCrrData() {
    const viewingBy =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.viewingBy);
    forkJoin(
      {
        clmData: this.errorCorrectionService.getErrorCorrectionDetails(this.selectedRow.clmType, this.selectedRow.medClmSk, this.selectedRow.partySk),
        state: this.errorCorrectionService.getState(),
        gender : this.errorCorrectionService.getQualifierdropdown(Constants.member,this.selectedRow.clmType,Constants.memberUISec,Constants.memberRefCol,true),
        errorHighLight: this.errorCorrectionService.errorFieldHighlight(this.selectedRow,viewingBy.id)
      }
    ).subscribe(data => {
      this.clmDta = data.clmData;
      this.listConfig['state'] = data.state;
      this.listConfig['gender'] = data.gender;
      this.dataLoading = false;
      this.extractMemberClmInfo();
      this.errorHighlight = data.errorHighLight;
      this.mapErrorHighlightData();
    }, error => {
      this.dataLoading = false;
    });
  }
  mapErrorHighlightData() {
    for(const key in this.memberInfoMapping){
      this.updateMatchErrorHightlightObj(this.section,this.memberInfoMapping[key]);
    }
    this.updateUnmatchErrorHighlightObj(this.section);
  }
  
  extractMemberClmInfo(){
    const memberObj = this.clmDta.billingProvider[0].subscriber[0].loop2010BName;
     memberObj.forEach(membrItem => {
       if(membrItem.loop2010BNM1.entityIdCode === "IL"){
          this.memberClmInfoObj = membrItem;
       }
     });

     memberObj.forEach(memberItem => {
       memberItem.loop2010BReference.forEach(element => {
        if(element.referenceIdentQual === "2U"){
                this.cmsContrctNoDtls = memberItem;
              }
       });
     });

  }


  collapseEditor(event) {
    if (event.type === 'change') {
      if (event.isInvalid) {
        this.invalidFieldStatus[event.field] = true;
        return
      }
      this.isDataModified = true;
      this.updateClmData(event);
      if(event.inputType !== inputTypeConfig.address && event.inputType !== inputTypeConfig.entityName){
        this.updateClmAttribute(this.memberClmInfoObj, event.key, event.value);
      }
      
     }
    if (event.type === 'validation') {
      this.invalidFieldStatus[event.field] = event.isInvalid;
      return
    }
      
      this.invalidFieldStatus[event.field] = false;
      this.showHideConfig[event.field] = false;
  }

  updateClmData(event){
    
    if (event.field === "CmsContractNumber") {
       const  cmsContrctNoObj = this.getCmsContrcNo(false,this.cmsContrctNoDtls);
       if(cmsContrctNoObj){
        cmsContrctNoObj['loop2010BReference'][0].referenceIdent = event.value;
       }
    }
    else if(event.inputType === inputTypeConfig.date){
      event.value = this.encodeDateFormat(event.value);
    }
  }

  getCmsContrcNo(attrValidation: boolean = false,cmsCntrctObj){
    
    if(attrValidation){
        return cmsCntrctObj && cmsCntrctObj.loop2010BReference[0].referenceIdent;
    }else{
      return  cmsCntrctObj;
    }   
  }

  getGenderCode(){
    const gCode = this.memberClmInfoObj['loop2010BDemographicInformation'].genderCode;
    return gCode ? gCode : " ";
  }

  getDateOfBirth() {
    const  date = this.memberClmInfoObj['loop2010BDemographicInformation'].dateTimePeriod;
    return date ? this.decodeDateFormat(date) : " ";
  }

  getMemberAddr() {
    if(this.memberClmInfoObj['loop2010BAddress']){
    const addr1 = this.memberClmInfoObj['loop2010BAddress'].addressInformation1 ? this.memberClmInfoObj['loop2010BAddress'].addressInformation1 + "  " : "";
    const addr2 = this.memberClmInfoObj['loop2010BAddress'].addressInformation2 ? this.memberClmInfoObj['loop2010BAddress'].addressInformation2 + "  " : "";
    const city = this.memberClmInfoObj['loop2010BCityStateZipCode'].cityName ? this.memberClmInfoObj['loop2010BCityStateZipCode'].cityName + ", " : "";
    const postal = this.memberClmInfoObj['loop2010BCityStateZipCode'].postalCode ?  this.memberClmInfoObj['loop2010BCityStateZipCode'].postalCode : "";
    const state = this.getFieldLabel("state", this.memberClmInfoObj['loop2010BCityStateZipCode'].stateorProvCode);
    return  addr1 + addr2 + city + state + " " + postal;
    }
    
  }

  getMemberName(){
    if( this.memberClmInfoObj){
      const fName = this.memberClmInfoObj['loop2010BNM1'].nameFirst || "";
      const mName = this.memberClmInfoObj['loop2010BNM1'].nameMiddle || "";
      const lName = this.memberClmInfoObj['loop2010BNM1'].nameLastOrgName || "";
      const sName = this.memberClmInfoObj['loop2010BNM1'].nameSuffix || "";
      return fName + " " +mName+ " " + lName + " "+sName;
    }
  }
  cancelMember(){
    const accordionsEditorList = [this.showHideConfig];
    const invalidEntry = this.isEditorOpen(accordionsEditorList);
     this.cancelParamObj =  {
      "invalidEntry" : invalidEntry,
      ...this.getCollapsePanelParam()
    }
    this.cancel(this.cancelParamObj);
  }

  collapseMemberPanel(){
    this.collapsePanel(this.getCollapsePanelParam());
  }

  getCollapsePanelParam(){
    return {uuid:this.getUuid(),docObj:this.document,session:this.sessionStorage,errCrrService:this.errorCorrectionService};
  }
  closeMemberPopUp() {
    this.closePopUp(this.getCollapsePanelParam());
  }

  saveMemberClaim(isResubmitFlow :boolean =false){
    const accordionsEditorList = [this.showHideConfig];
    const {invalidEntry,isSaveEligible} = this.applyEditedChanges(accordionsEditorList,this.document,this.changeBtnCls);
    this.saveParamObj = {
        "isResubmitFlow": isResubmitFlow,
        "invalidEntry":invalidEntry,
        "isSaveEligible":isSaveEligible,
        "allClmData":this.clmDta,
        "session":this.sessionStorage,
        "errCrrService":this.errorCorrectionService,
        "mdeService":this.mdeService,
        "uuid":this.getUuid(),
        "docObj":this.document
       }
    this.saveClaim(this.saveParamObj);
   
  }

  colpseMemberResubmitPopUp(event){
    this.collapseResubmitPop(event,this.getCollapsePanelParam());
  }


}
