import { Component,Input } from '@angular/core';

@Component({
  selector: 'app-pageloader',
  templateUrl: './pageloader.component.html',
  styleUrls: ['./pageloader.component.css']
})
export class PageloaderComponent {

  visibleTextValue: string = 'Please wait...';
  @Input() pageloaderConfig: any;

  constructor() {
    //  blank Constructor
   }

}
