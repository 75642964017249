// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// TST  

export const environment = {
  production: false,
  showLogoutHeader: true,
  baseServerUrl: '',
  starGateUrl: 'https://gateway-stage.optum.com/api/tst/clm/submissions',
  backedBaseUrl:'https://sp-edps-tst.optum.com/api/submissions-portal/edps',
  maintenanceBaseUrl:"https://sp-edps-maintenance-tst.hcck8s-ctc-np1.optum.com/api/submissions-portal/edps/maintenance",
  accessTokenServerUrl: 'https://sub-auth-serv-stg-ctc.optum.com/api/submissions-auth-service',
  oauthEndpoint: '/security/oauth2/generateAccessToken',
  userEndPoint: '/v1/userprincipals/',

  logoutUrl: 'https://identity.nonprod.onehealthcareid.com/oidc/logout?id_token_hint=',
  pingfederateRedirectUrl: 'https://sp-test.optum.com/optumid',
  pingfederateLoginUrl: 'https://identity.nonprod.onehealthcareid.com/oidc/authorize?client_id=edps34525&response_type=code&scope=openid+profile+email+phone&redirect_uri=',
};
