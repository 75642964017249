export const single = [
    {
      "name": "ComplianceErrors",
      "value": 0
    },
    {
      "name": "ContentErrors",
      "value": 0
    },
    {
      "name": "999Errors",
      "value": 0
    },
      {
      "name": "277Errors",
      "value": 0
    },
    {
      "name": "MAOErrors",
      "value": 0
    }
  ];