import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ErrCrrUtility, patterns } from '../../err-crr-utility';

@Component({
  selector: 'app-error-crr-claim-ref',
  templateUrl: './error-crr-claim-ref.component.html',
  styleUrls: ['./error-crr-claim-ref.component.css']
})
export class ErrorCrrClaimRefComponent extends ErrCrrUtility implements OnInit,AfterViewInit {
  @Output() public collapseClaimRefInfoFields = new EventEmitter<any>();
  @Input() inputLabel;
  @Input() inputValue;
  @Input() claimReferenceDesMapping;
  @Input() changeBtnCls: string;
  @Input() isServiceLineCategory:boolean;
  @Input() listData: any;
  public claimRefInfoForm;
  public maxLength = "50";
  viewLoaded :boolean;
  public readonly maxLengthMapping = {
    "6R":"30",
    "EW":"6",
    "X4":"10",
    "F4":"10",
    "D9":"20",
    "1J":"10"
  }
  public readonly minLengthMapping = {
    "X4":"10",
    "F4":"10",
    "EW":"6"
  }
  public readonly ptternMapping = {
    "EW":"NUM",
    "X4":"AN3D",
    "F4":"AN3D",
    "1J":"AN11"

  }
  constructor(sanitized: DomSanitizer) { 
    super(sanitized)
  }

  ngOnInit() {
    this.initializeForm();
  }

  ngAfterViewInit(){
    this.viewLoaded = true;
  }

  cancelClick() {
    const event = {
      type: 'cancel',
      field: this.inputLabel
    }
    this.collapseClaimRefInfoFields.emit(event);
  }

  initializeForm() {
    let patternType = "AN";
    let minLength = "";
      this.maxLength = this.maxLengthMapping[this.inputValue.referenceIdentQual] || "50";
    patternType = this.ptternMapping[this.inputValue.referenceIdentQual] || "AN";
    minLength = this.minLengthMapping[this.inputValue.referenceIdentQual] || "";
    const formCtrls: any = {
    }
    if (this.isLUReferenceIdentQual()) {
      formCtrls["state"] = new UntypedFormControl('', [this.getValidators("", "", true, "")])
    } else {
      formCtrls["refIdentification"] = new UntypedFormControl('', [this.getValidators(this.maxLength, patterns[patternType], true, minLength)])
    }
    this.claimRefInfoForm = new UntypedFormGroup(formCtrls);
    if(this.isLUReferenceIdentQual()){
      this.listData['state'].forEach((element, index) => {
        if (this.inputValue.referenceIdent === element.value) {
         this.claimRefInfoForm.controls.state.setValue(this.listData['state'][index]);
        }
      })
    }else{
      this.claimRefInfoForm.controls.refIdentification.setValue(this.inputValue.referenceIdent);
    }
  }
  getValidators(maxLength, pattern, required?, minLength?) {
    const validators: ValidatorFn[] = [];
    if (required) {
      validators.push(Validators.required);
    }
    if (minLength !== undefined) {
      validators.push(Validators.minLength(minLength));
    }
    if (maxLength) {
      validators.push(Validators.maxLength(maxLength));
    }
    if (pattern) {
      validators.push(Validators.pattern(pattern));
    }
    return Validators.compose(validators);
  }
  validate() {
    const event = {
      type: 'validation',
      isInvalid: this.claimRefInfoForm.status === "INVALID",
      field: this.inputLabel
    }
    if(this.isLUReferenceIdentQual()){
      event['refIdentification'] = this.claimRefInfoForm.controls.state.status === 'INVALID';
    }else{
      event['refIdentification'] = this.claimRefInfoForm.controls.refIdentification.status === 'INVALID';
    }
    this.collapseClaimRefInfoFields.emit(event);
  }

  changeClick() {
    const event = {
      type: 'change',
      value: this.inputValue,
      field: this.inputLabel,
      isInvalid: false
    }
    event.isInvalid = this.claimRefInfoForm.status === "INVALID";
    if (!event.isInvalid) {
      this.inputValue.referenceIdent = this.isLUReferenceIdentQual() ? (this.claimRefInfoForm.get('state').value).value :this.claimRefInfoForm.get('refIdentification').value;
      event.value = this.inputValue;
    } else {
      if(this.isLUReferenceIdentQual()){
        event['refIdentification'] = this.claimRefInfoForm.controls.state.status === 'INVALID';
      } else{
        event['refIdentification'] = this.claimRefInfoForm.controls.refIdentification.status === 'INVALID';
      }
    }
    this.collapseClaimRefInfoFields.emit(event);
  }

  mousedownEvent(event) {
    event.preventDefault();
  }

  isLUReferenceIdentQual(){
    return this.inputValue.referenceIdentQual== 'LU'? true : false;
  }

  getFormControlValidity(frmControlNm) {
    let isInvalid: boolean = false;
    isInvalid = this.claimRefInfoForm.controls[frmControlNm].status === "INVALID";
    if (isInvalid && !this.viewLoaded) {
      this.validate();
    }
    return isInvalid;
  }
}
