import { Component,  } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from '../../services/auth/oauth.service';
import { ClientService } from '../../services/client.service';

@Component({
  selector: 'app-terms-conditions-popup',
  templateUrl: './terms-conditions-popup.component.html',
  styleUrls: ['./terms-conditions-popup.component.css']
})
export class TermsConditionsPopupComponent{
  public checkBoxItem = { label: '', val: 'AGREE', checked: false, disabled: false }; 
  isAgreeButtonChecked: boolean;
  constructor(private readonly clientService:ClientService,
    private readonly authenticationService: OAuthService,
    private readonly router:Router) { }


  onAgreeBtnClick(){
    const userDtls = JSON.parse(sessionStorage.getItem('userPrincipal'));
    this.clientService.acceptTermCondition(userDtls.preferred_username,userDtls.email).subscribe((res:any)=>{
      if(res.success === "true"){
        const userDtl = JSON.parse(sessionStorage.getItem('userPrincipal'));
        userDtl.isTNCAccepted = "Y";
        this.authenticationService.setUserDataStorage(userDtl);
        this.router.navigateByUrl("/home");
      }
    })
  }
  onCancelBtnClick(){
    this.router.navigateByUrl("/logout");
  }

}
