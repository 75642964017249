import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';
import { Constants } from 'src/app/data-models/constants.enum';

@Component({
  selector: 'app-session-timeout-dialog',
  templateUrl: './session-timeout-dialog.component.html',
  styleUrls:['./session-timeout-dialog.component.css']
})
export class SessionTimeoutDialogComponent implements OnInit, OnDestroy {
  
  sessionTimeOutDialogModal: any = { show: false }
  private sessionTimeoutId:any;
  public sessionTimeoutInMiliseconds:any = Number(Constants.sessionTimeoutInMiliseconds)
  private timerId:any;
  public timerInterval:any = Constants.timerInterval;
  timerSeconds:number;
  timerMinutes:number;
  private subscriptions = new Subscription();
  public timerStartTime;
  public timerCurrentTime;
  private timerStart;
  private timerCurrent; 
  constructor(private readonly router: Router) {
      console.log('SessionTimeoutDialogComponent is constructed');
  }
  ngOnInit(): void {
        this.subscriptions.add(fromEvent(document,'click').subscribe(()=> this.resetSessionTimeout()));
        this.subscriptions.add(fromEvent(document,'scroll').subscribe(()=>this.resetSessionTimeout()));
        this.subscriptions.add(fromEvent(document,'mousemove').subscribe(()=>this.resetSessionTimeout()));
        this.subscriptions.add(fromEvent(document,'mousedown').subscribe(()=>this.resetSessionTimeout()));
        this.subscriptions.add(fromEvent(document,'keypress').subscribe(()=>this.resetSessionTimeout()));
        this.subscriptions.add(fromEvent(document,'touchmove').subscribe(()=>this.resetSessionTimeout()));
        
        if(this.timerInterval>=0){ // this is to prevent running/starting timer after user has logged out.
          this.startSessionTimeout();
        }
  };
        
    public startSessionTimeout = ()=> {
        this.sessionTimeoutId = setTimeout(this.activateTimer, this.sessionTimeoutInMiliseconds)
    }
      
   public resetSessionTimeout = ()=> { 
        clearTimeout(this.sessionTimeoutId);
        this.startSessionTimeout();
    } 
    
    public activateTimer = ()=> {
      this.timerStartTime = new Date().getTime();
      this.timerStart = new Date();
      clearInterval(this.timerId);
      this.timerId = setInterval(() => {
        this.getTimeInterval();
      },1000);    
    }

    public staySignedIn(){
        this.sessionTimeOutDialogModal.show=false;  
        clearInterval(this.timerId);
        this.resetSessionTimeout;
        this.timerInterval=Constants.timerInterval ;     //Reset timer interaval after every click on staySignedIn.
    }
    public getTimeInterval () {
      this.timerCurrentTime = new Date().getTime();
      this.timerCurrent = new Date();
      if(this.timerInterval<0){//should not be negative value
        this.sessionTimeOutDialogModal.show=false;
        clearTimeout(this.sessionTimeoutId);
        clearInterval(this.timerId);
        this.router.navigate([`/session-timeout`]);
      } 
      //If browser tab is inactive, setInterval() will run only onetime for 1 min, instead of running for each second. To logout the user when browser/tab is inactive, timerStartTime is waiting for 1200000 milliseconds and then session timedout
      else if(this.timerCurrentTime - this.timerStartTime>Number(Constants.timerInterval)+5000){//5000 ms is to adjust milliseconds between timerCurrentTime & timerStartTime and setInterval counter
        console.log('User browser/tab is inactive for more than '+this.sessionTimeoutInMiliseconds+' milliseconds, so session timedout');
        console.log('Timer start time:'+this.timerStart+', Timer end time:'+this.timerCurrent+', Time differece:'+(this.timerCurrentTime-this.timerStartTime)+' milliseconds');
        this.sessionTimeOutDialogModal.show=false;
        clearTimeout(this.sessionTimeoutId);
        clearInterval(this.timerId);
        this.router.navigate([`/session-timeout`]);
      }
      console.log('timerInterval:'+this.timerInterval+', timerid:'+this.timerId+', sessionTimeoutId:'+this.sessionTimeoutId);
      this.allocateTimeUnits(this.timerInterval);
      this.sessionTimeOutDialogModal.show=true;
  }
  public allocateTimeUnits (timeInterval) {
      this.timerSeconds = Math.floor((timeInterval) / (Number(Constants.milliSecondsInASecond)) % Number(Constants.secondsInAMinute));
      this.timerMinutes = Math.floor((timeInterval) / (Number(Constants.milliSecondsInASecond) * Number(Constants.minutesInAnHour)) % Number(Constants.secondsInAMinute));
      this.timerInterval = this.timerInterval - Number(Constants.milliSecondsInASecond);
  }
     
    ngOnDestroy(){
      clearTimeout(this.sessionTimeoutId);
      clearInterval(this.timerId);
      this.subscriptions.unsubscribe();
    }
}
