import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { LineChartComponent } from '../../charts/lineCharts/lineCharts.component';
import { BarChartsComponent } from '../../charts/bar-charts/bar-charts.component';
import { SummaryService } from '../../services/tabs/summary.service';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { Constants } from '../../data-models/constants.enum';
import { Tab } from 'src/app/data-models/tab.stats';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';

const crrGraphSeriesMapping = {
  [Constants.cms999]: [14],
  [Constants.cms277]: [15],
  [Constants.cmsMAO_002]: [16]
}

@Component({
  selector: 'app-crr-claim-errors',
  templateUrl: './crr-claim-errors.component.html',
  styleUrls: ['./crr-claim-errors.component.css']
})
export class CrrClaimErrorsComponent implements OnInit {

  @Input()
  public data: any;
 
  @ViewChild(LineChartComponent, { static: false })
  public lineChart: LineChartComponent;

  @ViewChild(BarChartsComponent, { static: false })
  public barChart: BarChartsComponent;

  private graphSeriesId : number[];
  
  aggData:any;
  constructor(private  summaryService: SummaryService,
    @Inject(SESSION_STORAGE) private  sessionStorage: WebStorageService) { }

  ngOnInit() {
    const prtySk = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.clientName).value;
    const year_val = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod).value;    
    const isChildDataIncluded = this.sessionStorage.get(Constants.includeChildData);
    const clmDtCriteriaId = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.viewingBy).id;
    const timeCriteriaId = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod).id;
    const startDate = this.sessionStorage.get(Constants.fromDate);
    const endDate = this.sessionStorage.get(Constants.toDate);
    this.sessionStorage.set(Constants.yAxisLabel, JSON.stringify("Claims in Error"));
    this.sessionStorage.set(Constants.seriesName, JSON.stringify("CMS 999"));
    this.graphSeriesId = [14];
    this.sessionStorage.set(Constants.graphSeriesId, this.graphSeriesId);
    this.sessionStorage.set(Constants.tabsSelectedIndex, 6);
   
    this.summaryService.getCRRErrorTabStats(prtySk, isChildDataIncluded, year_val,startDate,endDate,6).subscribe((data:any) => {
      this.aggData = data;  
    });
    this.data["frequencyChange"] = this.reloadGraph.bind(this);
  }

  isBarChartVissible(){
    return false;
    /* const freqType = this.sessionStorage.get(Constants.graphViewByType);
    return this.data.timePeriod === Constants.today || 
    (this.data.timePeriod === Constants.customDateRange && 
    ((freqType === Constants.monthly && this.data[Constants.isSingleMonthDateRange]) 
    || this.data.duration === 1)); */
  }

  reloadGraph(seriesName) {
     this.sessionStorage.set(Constants.seriesName, JSON.stringify(seriesName));
    if(Constants.cms999===seriesName){
      this.graphSeriesId = [14];
    }else if(Constants.cms277===seriesName){
      this.graphSeriesId = [15];
    }else if(Constants.cmsMAO_002===seriesName){
      this.graphSeriesId = [16];
    }
    if(this.isBarChartVissible()){
      this.barChart.reloadGraph(this.graphSeriesId);
    }else{
      this.lineChart.reloadGraph(this.graphSeriesId);
    } 
    
  }
  
  exportData(event){
    event.preventDefault();
  }

}
