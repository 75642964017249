import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
@Component({
  selector: 'app-error-crr-diagnosis-code',
  templateUrl: './error-crr-diagnosis-code.component.html',
  styleUrls: ['./error-crr-diagnosis-code.component.css']
})
export class ErrorCrrDiagnosisCodeComponent implements OnInit {
  @Output() public collapsediagnosisCodeFields = new EventEmitter<any>();
  @Input() inputLabel;
  @Input() inputValue;
  @Input() listData: any;
  @Input() diagnosisQualMapping;
  @Input() diagnosisDesMapping;
  @Input() changeBtnCls:string;
  @Input() isInstitutional:boolean;
  public required : boolean =false;
  public diagnosisCodeForm;
  viewLoaded: boolean;
  constructor() { 
    //ErrorCrrDiagnosisCodeComponent 
  }

  ngOnInit() {
    this.initializeForm();
  }

  ngAfterViewInit(){
    this.viewLoaded = true;
  }
  initializeForm() {
    if (this.inputValue.codeListQualifierCode === 'ABJ' || this.inputValue.codeListQualifierCode === 'APR') {
      this.required = true
    }
    this.diagnosisCodeForm = new UntypedFormGroup({
      qualifier: new UntypedFormControl('', [this.getValidators('', '',  this.required, '')]),
      diagnosisCode: new UntypedFormControl('', [this.getValidators('30', '^[a-zA-Z0-9]+$', true, '1')]),
      poa: new UntypedFormControl('', []),
    });
    if (this.inputValue.codeListQualifierCode === 'ABJ' || this.inputValue.codeListQualifierCode === 'APR') {
      this.listData['diagnosisQualifierDropdown'].forEach((element, index) => {
        if (this.inputValue.codeListQualifierCode == element.value) {
          this.diagnosisCodeForm.controls.qualifier.setValue(this.listData['diagnosisQualifierDropdown'][index]);
        }
      });
    }
    this.diagnosisCodeForm.controls.diagnosisCode.setValue(this.inputValue.industryCode);
    if(this.isInstitutional){
      this.listData.POA.forEach((element, index) => {
        element.id= index;
        if (this.inputValue.responseCode === element.value) {
          this.diagnosisCodeForm.controls.poa.setValue(element);
        }
      });
    }
  }

  getFormControlValidity(frmControlNm){
    const isInvalid = this.diagnosisCodeForm.controls[frmControlNm].status === "INVALID" ;
    if(isInvalid && !this.viewLoaded){
      this.validate();
    }
    return isInvalid;
  }

  mousedownEvent(event){
    event.preventDefault();
  }

  validate(){
    const event = {
      type: 'validation',
      isInvalid: this.diagnosisCodeForm.status === "INVALID",
      field: this.inputLabel
    }
    if (this.inputValue.codeListQualifierCode === 'ABJ' || this.inputValue.codeListQualifierCode === 'APR'){
      event['qualifier'] = this.diagnosisCodeForm.controls.qualifier.status === 'INVALID';
    }
    event['diagnosisCode'] = this.diagnosisCodeForm.controls.diagnosisCode.status === 'INVALID';
    this.collapsediagnosisCodeFields.emit(event);
  }

  getValidators(maxLength, pattern, required?, minLength?) {
    const validators: ValidatorFn[] = [];
    if (required) {
      validators.push(Validators.required);
    }
    if (minLength !== undefined) {
      validators.push(Validators.minLength(minLength));
    }
    validators.push(Validators.maxLength(maxLength));
    validators.push(Validators.pattern(pattern));
    return Validators.compose(validators);
  }
  cancelClick() {
    const event = {
      type: 'cancel',
      field: this.inputLabel
    }
    this.collapsediagnosisCodeFields.emit(event);
  }

  changeClick() {
    const event = {
      type: 'change',
      value: this.inputValue,
      field: this.inputLabel,
      isInvalid: false
    }
    event.isInvalid = this.diagnosisCodeForm.status === "INVALID";
    if (!event.isInvalid) {
      if (this.inputValue.codeListQualifierCode === 'ABJ' || this.inputValue.codeListQualifierCode === 'APR') {
        this.inputValue.codeListQualifierCode = (this.diagnosisCodeForm.get('qualifier').value).value;
      }else if(this.isInstitutional){
        this.inputValue.responseCode = (this.diagnosisCodeForm.get('poa').value).value;
      }
      this.inputValue.industryCode = this.diagnosisCodeForm.get('diagnosisCode').value;
      event.value = this.inputValue;
    }else{
      if (this.inputValue.codeListQualifierCode === 'ABJ' || this.inputValue.codeListQualifierCode === 'APR'){
        event['qualifier'] = this.diagnosisCodeForm.controls.qualifier.status === 'INVALID';
      }
      event['diagnosisCode'] = this.diagnosisCodeForm.controls.diagnosisCode.status === 'INVALID';
    }
    this.collapsediagnosisCodeFields.emit(event);
  }
}
