import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-main',
  templateUrl: './app-main.component.html',
})
export class AppMainComponent {
  constructor(){
    //app main constructor 
  }
}
