import { Injectable } from '@angular/core';
import { Observable, timer, Subject} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { OauthResponse } from './auth/oauthResponse.model';
import { switchMap, tap, share, retry, takeUntil, filter } from 'rxjs/operators';
import { Constants } from '../data-models/constants.enum';
import { OAuthService } from './auth/oauth.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  private baseServerUrl = environment.baseServerUrl;
  private readonly stopFetchingFileCount = new Subject();
  private  filesCount;

  constructor(private readonly http: HttpClient, private readonly authService:OAuthService) {
  }
  getBaseServerUrl() {
    const resp: OauthResponse = JSON.parse(sessionStorage.getItem("oAuthResponse"));
    if (resp.stargateEnabled) {
      this.baseServerUrl = environment.starGateUrl+Constants.exportServiceBaseUrlSuffix;
    }
    else {
      this.baseServerUrl = environment.backedBaseUrl+Constants.exportServiceBaseUrlSuffix;
    }
  }
  getDownloadFilesList(userName) {
    this.getBaseServerUrl();
    return this.http.post(`${this.baseServerUrl}/v1/exportList`,userName);
  }

  deleteFile({bucketKey,fileName}) {
    this.getBaseServerUrl();
    return this.http.post(`${this.baseServerUrl}/v1/delete`,{bucketKey,fileName},{responseType:"text"});
  }

  downloadReport({bucketKey,fileName}) {
    this.getBaseServerUrl();
    return this.http.post(`${this.baseServerUrl}/v1/download`,{bucketKey,fileName},{observe: 'response', responseType: 'blob'});
  }


  getFilesCount(userName) {
     this.getBaseServerUrl();
    return this.http.post(`${this.baseServerUrl}/v1/exportCount`,userName);
  }

  getDownloadFilesCount(userName) {
    this.filesCount = timer(1, 10000).pipe(
      filter(value => !this.authService.isRefreshTokenTriggered()),
      switchMap(() => {
        return this.getFilesCount(userName);
      }
      ),
      retry(),
      share(),
      takeUntil(this.stopFetchingFileCount)
    );
    return this.filesCount;
  }
  exportData({preferred_username,email},segNo,claimDtlsCriteriaObj:any,filterSortSelection:any,availableRecords:number,filterLabels:string=""){
    const pageCriteria = {segNo};
    const claimDtlsCriteria = {...claimDtlsCriteriaObj,userName:preferred_username,availableRecords,email,filterLabels};
    const endPointUrl = (claimDtlsCriteriaObj.hasOwnProperty('claimRecords') && claimDtlsCriteriaObj["claimRecords"].length > 0) 
                        ? '/v1/exportSelectedFile'
                        : '/v1/exportFile'
    return this.http.post(`${this.baseServerUrl + endPointUrl}`, 
    { pageCriteria,claimDtlsCriteria,...filterSortSelection},
    {responseType:"text"}); 
  }

  stopExportCountPolling(){
    this.stopFetchingFileCount.next();
  }
  ngOnDestroy() {
    this.stopExportCountPolling();
  }
  
}
