import { ReportsCommonComponent } from "./reports.common.component";
import { IUITKColumnState, UITKSortDirection, UITKTableDataSource } from '@uitk/angular';

export class ReportsColumnsDialogComponent extends ReportsCommonComponent {
  public openShowHideDrawer: boolean = false;
  public columns: any[] = [];
  public visibleColumns = [];
  public selectedColumns = [];
  public isAllColumnChecked: boolean = false;
  public showHideDialogModal = {
    show: false,
  };
  public checkedRows: any = [];
  isAllCheckboxSelected: boolean = false;
  hideAll() {
    this.visibleColumns.forEach((column) => {
      if (!column.showAlways) {
        column.hideColumn = true;
      }
    });
    this.updateSelectedColumn();
  }

  updateSelectedColumn() {
    const isNested = !!(
      this.visibleColumns &&
      this.visibleColumns[0] &&
      this.visibleColumns[0].headerName
    );
    const dynamicTableColList = isNested
      ? this.getFlatColumnList()
      : this.columns;
    this.deepCopy(dynamicTableColList).forEach((column) => {
      if (!column.showAlways) {
        this.selectedColumns.push(column);
      }
    });
  }

  showAll() {
    this.visibleColumns.forEach((column) => {
      if (!column.showAlways) {
        column.hideColumn = false;
      }
    });
    this.updateSelectedColumn();
  }

  reset() {
    this.visibleColumns = this.deepCopy(this.columns);
  }

  showCustomDialog() {
    this.showHideDialogModal.show = true;
  }
  updateShowHideConfig(column, $event) {
    column = this.deepCopy(column);
    let exists = false;
    this.selectedColumns.forEach((icolumn) => {
      if (column.label === icolumn.label) {
        icolumn.hideColumn = $event;
        exists = true;
        return;
      }
    });
    if (!exists) {
      column.hideColumn = $event;
      this.selectedColumns.push(column);
    }
    this.visibleColumns.forEach((icolumn) => {
      if (icolumn.label === column.label) {
        icolumn.hideColumn = !$event;
        return;
      }
    });

    this.isAllColumnChecked = this.getSelectAllState();
  }
  cancelShowHideRow() {
    this.selectedColumns.forEach((column) => {
      this.visibleColumns.forEach((icolumn) => {
        if (icolumn.label === column.label) {
          icolumn.hideColumn = column.hideColumn;
          return;
        }
      });
    });
    this.selectedColumns = [];
    this.showHideDialogModal.show = false;
  }
  saveRecords() {
    const dynamicTableColList = this.getDynamicTableColList();
    this.visibleColumns.forEach((column) => {
      dynamicTableColList.forEach((icolumn) => {
        if (!column.showAlways && icolumn.label === column.label) {
         /*  if (this.dynamicTableComponent) {
            icolumn.hideColumn = !column.hideColumn;
            this.dynamicTableComponent.updateShowHideConfig(icolumn);
          } else {
          } */
          icolumn.hideColumn = column.hideColumn;
          return;
        }
      });
    });
    this.selectedColumns = [];
    this.isNestedColumn() && this.updateNestedColumnsDetails();
    this.showHideDialogModal.show = false;
  }
  updateNestedColumnsDetails() {
    this.columns.forEach((pcolumn) => {
      let colCount = 0;
      pcolumn.children.forEach((column) => {
        !column.hideColumn && colCount++;
      });
      pcolumn.count = colCount;
    });
  }

  getDynamicTableColList() {
    return this.isNestedColumn()
      ? this.getFlatColumnList()
      : this.columns;
  }
  isNestedColumn() {
    return !!this.columns[0].headerName;
  }

  getFlatColumnList() {
    const flatColumnList = [];
    this.columns.forEach((element) => {
      element.children.forEach((item) => {
        flatColumnList.push(item);
      });
    });
    return flatColumnList;
  }

  deepCopy(oldObj: any) {
    let newObj = oldObj;
    if (oldObj && typeof oldObj === "object") {
      newObj =
        Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
      for (const i in oldObj) {
        if (i) {
          newObj[i] = this.deepCopy(oldObj[i]);
        }
      }
    }
    return newObj;
  }
  getTableExportBtnLabel(checkedRows?) {
    return checkedRows && checkedRows.length > 0
      ? "Export Selected Records"
      : "Export Loaded Records";
  }

  getExportFilterLabel(filterList) {
    const filterLabelList = [];
    filterList.forEach((filter) => {
      const label =
        (filter.showLabel ? filter.label + ":" : "") +
        (filter.prefix ? " " + filter.prefix : "") +
        " " +
        filter.value +
        (filter.postfix ? " " + filter.postfix : "");
      filterLabelList.push(label);
    });
    return filterLabelList.join(",");
  }
  getExportFilterAPIInput(exportReporting, filterSelectionDetails) {
    const bubbleData = exportReporting
      ? filterSelectionDetails.bubbleData
      : filterSelectionDetails.confimrationBubbleData;
    const filterSelectionAPIInput = exportReporting
      ? filterSelectionDetails.trxCmpltApiInput
      : filterSelectionDetails.apiInput;
    return { bubbleData, filterSelectionAPIInput };
  }

  updateBubbleData(filterSelectionDetails) {
    filterSelectionDetails.bubbleData = this.deepCopy(
      filterSelectionDetails.confimrationBubbleData
    );
    filterSelectionDetails.trxCmpltApiInput = this.deepCopy(
      filterSelectionDetails.apiInput
    );
  }

  checkUncheckAll(event: any) {
    if (event.target.checked) {
      this.showAll();
    } else {
      this.hideAll();
    }
  }

  getSelectAllState() {
    const checkedColumns = this.visibleColumns.filter(function (column) {
      return !column.hideColumn;
    });
    return this.visibleColumns.length === checkedColumns.length;
  }

  selectAllEvent(event: any, dataSource,document) {
    this.selectAllByEvent(event.target.checked, dataSource,document);
    this.isAllCheckboxSelected =event.target.checked;
    
  }
  selectAllByEvent(isChecked: boolean, dataSource: any,document) {
    const currentPageInputElements = Array.from(document.querySelectorAll("td input.row-checkbox:not([disabled])"));
    const currentPageRecords = dataSource.data.filter(item => 
      currentPageInputElements.map((x:any) => x.id)
      .indexOf(String(item.rowIndex)) > -1);
    
    const rowsSelected = currentPageRecords.filter((item)=>{
      if(item.isChecked !== isChecked){
          item.isChecked = isChecked;
          return true;
      }
    })
    
    if(isChecked){
      this.checkedRows = [...this.checkedRows,...rowsSelected];
    }else{
      this.checkedRows = this.checkedRows.filter(item => item.isChecked);
    }
  }
  validateAllCheckboxStatus(document){
    setTimeout(()=>{
      const currentPageInputElements:any = Array.from(document.querySelectorAll("td input.row-checkbox:not([disabled])"));
      
      if(currentPageInputElements?.length > 0){
      let isAllChecked =true;
      for(const itr in currentPageInputElements){
        if(!currentPageInputElements[itr].checked){
          isAllChecked = false;
          break
        }
      }
      this.isAllCheckboxSelected =  isAllChecked;
    }
    })
  }
  selectRow(item: any, event: any) {
    item.isChecked = event.target.checked;
    if (event.target.checked) {
       this.checkedRows.push(item);
    } else {
      this.checkedRows = this.checkedRows.filter((value: any) => {
        return value.rowIndex !== item.rowIndex;
      });
    }
  }
  onSortChange(sortState: IUITKColumnState,dataSource,uitkTableSort,document?) {

    uitkTableSort.setDirection({ column: sortState.column, direction: sortState.direction });

    this.sortColumn(dataSource,sortState.column,sortState.direction);
    document && this.validateAllCheckboxStatus(document)

   }
    /**
     * Sort column data based on column - For example purpose
     *  column {string}
     *  direction {string}
     */
     sortColumn(dataSource:UITKTableDataSource<any>,column: string, direction: UITKSortDirection) {
      dataSource.data?.sort((a, b) => {
          let aValue = a[column];
          let bValue = b[column];
          aValue = aValue ? aValue : '';
          bValue = bValue ? bValue : '';

          if (direction === 0) {
              direction = 1;
          }

          return this.returnSortedOrder(aValue, bValue, direction);
      });
      return dataSource.data = [...dataSource.data];
  }

  /**
   * Returns the order the item should be sorted
   *  aValue
   *  bValue
   *  direction
   */
  returnSortedOrder(aValue, bValue, direction) {
      if (aValue < bValue) {
          return -1 * direction;
      } else if (aValue > bValue) {
          return 1 * direction;
      } else {
          return 0;
      }
  }

}
