import { Component, HostListener } from '@angular/core';
import { environment } from './../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { OAuthService } from './services/auth/oauth.service';
import { UserPrincipal } from './services/auth/userPrincipal.model';
import { MaintenanceService } from './services/maintenance.service';
import { DownloadService } from './services/download.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  {
  currentUser: UserPrincipal;
  title = 'app';
  isTermsAccepted:boolean = true;
  private readonly serviceConfig = { 
      subText: "Fetching Data....", // Text to display under the Page Loader 
  }; 
  url: string;
  isAppStatusPollingTriggered: boolean;

  constructor( 
    private readonly router: Router,
    private readonly authenticationService: OAuthService,
    private readonly maintenanceService: MaintenanceService,
    private readonly downloadService:DownloadService){
      this.authenticationService.currentUser.subscribe(x => {
        this.currentUser = x;
       
        if(x && !!x.role){
          this.isTermsAccepted = this.authenticationService.isTermsAccepeted();
          if(!this.isAppStatusPollingTriggered){
            this.maintenanceService.appStatusPolling().subscribe( res => {
            if(res.isMaintenance === "Y"){
              this.router.navigateByUrl(`/maintenance`);
            }
            })
            this.isAppStatusPollingTriggered = true;
          }
        }        
        if(this.currentUser && this.currentUser.email && !this.currentUser.role) {
          this.router.navigateByUrl("/unauthorized-user");
        }

        
      });
      
      console.log(environment.production);
      router.events.subscribe((val) => {
        if(val instanceof NavigationEnd){
          this.onNavigationChange(val);
        }
      });
    }
    
    onNavigationChange(val){
      this.url = val.url;
      if (this.isStaticPage()) {
        this.maintenanceService.stopAppStatusPolling();
        this.downloadService.stopExportCountPolling();
      }
    }

    isSessionTimeoutPage(){
      return this.url == "/session-timeout";
    }
    
    isStaticPage(){
      const staticPages =["/privacy","/terms","/logout","/maintenance","/unauthorized-user"];
      return staticPages.indexOf(this.url) !== -1;
    }
  
  // Fixed header solution for all table
    @HostListener("window:scroll", [])
    public onScrollBy(): any {
      const tableElement = document.querySelector('table');
      const top = tableElement !== null ? tableElement.getBoundingClientRect().top: 0;
      const bottom = tableElement !== null ? tableElement.getBoundingClientRect().bottom:0;
      let tableTop = 0;
      if( top <= 0 && bottom >= 0){
        tableTop  = Math.abs(top);
      }else{
        tableTop = 0
      }
      const  thElements=  document.getElementsByTagName('th');
      for (let index = 0; index < thElements.length; index++) {
        const element = thElements[index];
        element.style.top = tableTop + 'px';
        
        element.style.zIndex = '5';
        
      }
    }
}
