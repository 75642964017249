import { propertyMap } from '../propertyMap';

export class UserPrincipal{
    @propertyMap('sub')
    public sub:string;

    @propertyMap('email_verified')
    public email_verified:boolean;

    @propertyMap('birthdate')
    public birthdate:string;
    
    @propertyMap('gender')
    public gender:string;

    @propertyMap('name')
    public name:string;

    @propertyMap('nickname')
    public nickname:string;

    @propertyMap('preferred_username')
    public preferred_username:string;

    @propertyMap('given_name')
    public given_name:string;

    @propertyMap('token_type')
    public tokenType:string;

    @propertyMap('family_name')
    public family_name:string;

    @propertyMap('email')
    public email:string;

    @propertyMap('accessToken')
    public accessToken:string;

    @propertyMap('expires_in')
    public expires_in:string;

    @propertyMap('refreshToken')
    public refreshToken:string;
    
    @propertyMap('active')
    public active:boolean;

    @propertyMap('clientName')
    public clientName:string;

    @propertyMap('role')
    public role:string;

    @propertyMap('sessionIdGenerated')
    public sessionIdGenerated:string;

    @propertyMap('isTNCAccepted')
    public isTNCAccepted:string;

    @propertyMap('asmEnabled')
    public asmEnabled:String;

}
