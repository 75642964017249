import { Component, Input, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { OAuthService } from '../services/auth/oauth.service';
import { UserPrincipal } from '../services/auth/userPrincipal.model';
import { FileService } from '../services/file.service';
import { DownloadService } from '../services/download.service';
import { Subscription,interval } from 'rxjs';
import { Constants } from '../data-models/constants.enum';
@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css']
})
export class AppHeaderComponent implements OnInit {
  @Input() isStaticPage: boolean;
  isTermsAccepted:boolean = false;
  @Input() isSessionTimeoutPage: boolean;
  manageDownloadModal = {
    show: false,
  };
  public downloadFiles:any;
  public filesCount;
  public userPrincipal: UserPrincipal;
  appTitle = 'Encounter Submission Portal';
  userTitle = 'James Dean';
  isAuthorizedUser:boolean = false;
  public dataRefresh:any;
  private updateDataRefresh: Subscription;
   /**
     * Logo configuration
     */
  public logosConfig = {
      logo: { path: 'assets/images/optum-logos/Optum_logo_header.svg', alt: 'optum logo' },
  };
  constructor( private readonly authenticationService: OAuthService,
    private readonly fileService: FileService, private readonly downloadService: DownloadService) {
  }

  ngOnInit() {
    console.log("App Header component init called");
    this.userPrincipal = this.authenticationService.getUserPrincipal();
    this.authenticationService.currentUser.subscribe(user => {
      this.isTermsAccepted = this.authenticationService.isTermsAccepeted();
      this.isAuthorizedUser = user && !!user.role;
    });
    if (this.userPrincipal){
      this.userTitle = this.userPrincipal.name;
      this.updateDataRefresh = interval(1000).subscribe(
        (val) => {this.dataRefresh= this.fileService.getDatRefresh();
      });

      this.downloadService.getDownloadFilesCount(this.userPrincipal.preferred_username).subscribe(res => {
        this.filesCount=res;
      });
    }
  }

  openDownloadModal() {
    if (this.userPrincipal){
      this.downloadService.getDownloadFilesList(this.userPrincipal.preferred_username).subscribe(data => {
        this.manageDownloadModal.show = true;
        this.downloadFiles = data;
      });
    }
  }
  clickSpotlightBtn() { 
    let spoltUrl = sessionStorage.getItem(Constants.spoltUrl);
    if(spoltUrl && [`'`,`"`].includes(spoltUrl.charAt(0)) && [`'`,`"`].includes(spoltUrl.charAt(spoltUrl.length-1))){
      spoltUrl = spoltUrl.slice(1,-1);
    }
    if(spoltUrl.length==0){
      window.open("\\", '_blank');
    }
    else 
      window.open(spoltUrl,'_blank');
} 
}
