import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TermsPrivacyUtility } from '../terms-privacy-utility';
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent extends TermsPrivacyUtility {
  constructor( @Inject(DOCUMENT) public document: Document) { super();}
}
