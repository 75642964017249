import { Component, OnInit, Input, Inject } from '@angular/core';
import { MdeService } from '../../services/tabs/mde.service';
import { UserPrincipal } from '../../services/auth/userPrincipal.model';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { Constants } from '../../data-models/constants.enum';
import { DOCUMENT } from '@angular/common';
import { ErrorCorrectionService } from '../../services/errorCorrectionClm.service';
import { environment } from '../../../environments/environment';
import { ResubmitClmConfig } from '../error-crr-resubmit/error-crr-resubmit.component';
import { of } from 'rxjs';

@Component({
  selector: 'app-mde-expand',
  templateUrl: './mde-expand.component.html',
  styleUrls: ['./mde-expand.component.css']
})
export class MdeExpandComponent implements OnInit {

 
  public open:boolean = false;
  public mdeInfo:any;
  // To disable mde resubmit on PROD, add isProd = environment.production
  isProd: boolean = false;
  public componentLoading: boolean = true;

  @Input() selectedRow:any;
  resubmitKeys={
    [Constants.mdeResubmittedStatus]:true,
    [Constants.failedStatus]:true,
    [Constants.generatedStatus]:true,
    [Constants.errorSubmittedStatus]:true
  }

  public resubmitClmConfig: ResubmitClmConfig = {
    loading: false,
    showPopup: false,
    title: '',
    workFlow: '',
    description: ''
  };
  
  collapsiblePanel: boolean = true;
  openPanel: boolean = true;
  constructor( readonly mdeService: MdeService,
    private readonly errorCorrectionService: ErrorCorrectionService,
    @Inject(SESSION_STORAGE) public readonly sessionStorage: WebStorageService,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.getData();
  }

  toggleOpen(){
    this.open = !this.open;
  }

  getData() {
    this.componentLoading = false;
    const medClaimSk = this.selectedRow.medClmSk;
    this.mdeService.getMemberDetails(medClaimSk).subscribe((data: any) =>{
      if(data){
        this.mdeInfo = {};
        this.mdeInfo.clmErrs = data.mdeErrorDetails;
        this.mdeInfo.diagCodes = data.memberDetails;
        this.mdeInfo.memberInfo = data.memberDetails[0];
      }
    })    
  }

  resumbitClaims(){
    if(this.isProd){
      return;
    }
    const userPrincipal: UserPrincipal = this.sessionStorage.get(Constants.userPrincipal);
    const row = this.selectedRow;
    const mdeData = [{
      "clmType": row["clmType"],
      "medClmSk": Number(row["medClmSk"]),
      "fileKey": Number(row["fileKey"]),
      "partySk": Number(row["partySk"]),
      "fileName": row["fileName"],
      "fileReceivedDate": row["fileReceivedDate"],
      "clmId": row["clmId"],
      "dosFrom": row["dosFrom"],
      "dosThrough": row["dosThrough"],
      "mbi": row["mbi"],
      "billingPrvNpi": row["billingPrvNpi"],
      "status": "IN_PROGRESS",
      "userId": userPrincipal.preferred_username,
      "errType": row["errType"],
      "clmSvcMonth": row["clmSvcMonth"],
      "clmSvcDtJulian": row["clmSvcDtJulian"]
    }];
  
    this.errorCorrectionService.isClaimClosed(mdeData[0].medClmSk)
    .subscribe((data: any) => {
      if (data.success === 'true') {
        this.updateClosedClaimStatus(mdeData);
        }else{
          this.mdeService.submitMdeData(mdeData).subscribe((data : any) => {
            this.mdeService.emitUpdateRecord({data:mdeData,flow:Constants.mdeResubmittedFlow});
            this.cancel(true);
           });
        }
      },err => {
    });

   
  }

  collapsePopUp(event){
    this.componentLoading=false;
    this.resubmitClmConfig.showPopup=false;
    this.cancel(true);
  }

  updateClosedClaimStatus(mdeClm){
    const clmData =[{'medClmSk':mdeClm.medClmSk, 'orgFileKey':mdeClm.fileKey,'partySK': mdeClm.partySk}]
    this.errorCorrectionService.updateClaimStatus(clmData)
    .subscribe((data: any) => {
      this.resubmitClmConfig = {
        loading: false,
        showPopup: true,
        title: 'This claim was closed',
        workFlow: 'closed',
        description: this.errorCorrectionService.getClosedMdeClaims([this.selectedRow])
      };
    }, err => {
    });
    this.mdeService.emitUpdateRecord({ data: mdeClm, flow: Constants.mdeResubmittedFlow, status: Constants.claimClosedStatus ,hideAcknowledgment : true });
  }

  cancel(unlockClaimReq:boolean=false){

    const userDetails = this.sessionStorage.get(Constants.userPrincipal);
    const subscription = (this.selectedRow.selected && !unlockClaimReq) ? of({}):this.errorCorrectionService.lockUnlockClaim(this.selectedRow.medClmSk, this.selectedRow.partySk, userDetails.preferred_username,Constants.claimUnlocked, userDetails.sessionIdGenerated);

    subscription.subscribe( data => {
      this.expandCollapseMDERecord(data);
    })
  }
  expandCollapseMDERecord(data){
    const uuid = (this.selectedRow.medClmSk ? this.selectedRow.medClmSk :  `${this.selectedRow.fileKey}_${this.selectedRow.clmId}`) + "" + this.selectedRow.rowIndex ;
      const rowElement : any = this.document.getElementById(uuid);
      if(rowElement){
        const children = rowElement.parentElement.children;
        let mdeIcon;
        for(const el of children){
          if (el && el.innerHTML && el.innerHTML.includes("uitk-c-table__expandable-row-button")) {
            mdeIcon = el.getElementsByTagName("button");
            break;
          }
        }
        if (mdeIcon && mdeIcon.length > 0) {
          mdeIcon[0].click();
          this.selectedRow.expanded = false;
        }
      }
  }
  
  isMDEResubmittedRecord(){
    return this.isProd || this.resubmitKeys[this.selectedRow[Constants.mdeResubmittedKey]];
  }
}
