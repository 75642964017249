import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OauthResponse } from './auth/oauthResponse.model';
import { environment } from 'src/environments/environment';
import { Constants } from '../data-models/constants.enum';


@Injectable({
  providedIn: 'root'
})
export class MdeClaimCountService {

  private  baseServerUrl = environment.baseServerUrl;

  constructor(private readonly http: HttpClient) { }

  getBaseServerUrl(){
    const resp : OauthResponse = JSON.parse(sessionStorage.getItem("oAuthResponse"));
    if(resp.stargateEnabled){
        this.baseServerUrl = environment.starGateUrl+Constants.reportingServiceBaseUrlSuffix;
    }
    else{
        this.baseServerUrl = environment.backedBaseUrl+Constants.reportingServiceBaseUrlSuffix;
    }
  }
  getMdeClaimStats(partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate){
    console.log("inside MDE Claim stats ",environment.baseServerUrl, partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate);
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerUrl}/v1/mdeclaimcount`,{partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate});
  }

  
}
