import { Component, OnInit, Inject, Input, Output, EventEmitter} from '@angular/core';
import { ReportsColumnsDialogComponent } from '../reports/reports.columns.dialog.component';
import { WebStorageService, SESSION_STORAGE } from 'ngx-webstorage-service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-show-hide-pop-up',
  templateUrl: './show-hide-pop-up.component.html',
  styleUrls: ['./show-hide-pop-up.component.css']
})
export class ShowHidePopUpComponent extends ReportsColumnsDialogComponent implements OnInit {

  @Input() visibleColumns
  @Input() showHideDialogModal
  @Input() columns
  @Input() dynamicTableComponent : any

  constructor(@Inject(SESSION_STORAGE) public sessionStorage: WebStorageService,
  public dateFormat: DatePipe) {
    super(sessionStorage,dateFormat);
   }

  ngOnInit() {
    // ngonint blank
  }

}
