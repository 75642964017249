import { Component, Input } from '@angular/core';
import { ErrCrrUtility } from '../err-crr-utility';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-error-highlight',
  templateUrl: './error-highlight.component.html',
  styleUrls: ['./error-highlight.component.css']
})
export class ErrorHighlightComponent extends ErrCrrUtility{
  @Input() errorHighlightList;
  constructor(
    sanitized: DomSanitizer
  ) {
    super(sanitized);
  }


}
