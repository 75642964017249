<div class="row margin-top-11">
    <div class="col-md-4 padding-left-3">
        <div><span class="chart-title">Claims In Error By Type</span>
            <span class="margin-left-15">
                <a href="#" (click)="exportData($event)">Export Data <uitk-icon-font icon="uimf-icon-export" hiddenText="export"></uitk-icon-font></a>
            </span>
        </div>
    </div>
    <div class="col-md-8 radio-group-wrapper">
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="rd_1" name="rd" class="custom-control-input"
                (change)="reloadGraph('CMS 999')" checked="checked">
            <label class="custom-control-label red" for="rd_1">CMS 999</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="rd_2" name="rd" class="custom-control-input"
                (change)="reloadGraph('CMS 277')">
            <label class="custom-control-label red" for="rd_2">CMS 277</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="rd_3" name="rd" class="custom-control-input" 
                (change)="reloadGraph('CMS MAO-002')">
            <label class="custom-control-label red" for="rd_3">CMS MAO-002</label>
        </div>
    </div>
</div>
<div class="margin-bottom-35 graph-wrapper">
    <line-chart [hidden]="isBarChartVissible()" [isHidden]="isBarChartVissible()"></line-chart>
    <app-bar-charts [scopeData]="data" [hidden]="!isBarChartVissible()" [isHidden]="!isBarChartVissible()">
    </app-bar-charts>
</div>
<div class="xlabel-wrapper">Encounter Date of Service</div>
<!-- <app-graph-switch-frequency [scopeProps]="data"></app-graph-switch-frequency> -->
<div class="row error-tab-stats-wrapper">
    <div class="col-md-4  error-tab-stats padding-left-3" *ngFor="let agg of aggData">
        <div class="count-title">{{agg.clmErrType}}</div>
        <div class="count-sub-title-wraper"> 
            <div class="count-value">{{agg.clmErrCnt}}</div>
            <div class="count-label">Claims</div>
         </div>
         <div class="row padding-2 margin-top-5">
            <div class="col-md-8 code-txt bold">Top Codes</div>
            <div class="col-md-4 code-txt text-align-right bold">Qty</div>
        </div>
        <div class="border-top-title"></div>
        <div class="row padding-2" *ngFor="let recipient of agg.clmErrCdMap | keyvalue">
            <div class="col-md-8 code-txt">{{recipient.key}}</div>
            <div class="col-md-4 code-txt text-align-right">{{recipient.value}}</div>
        </div>
    </div>
</div>