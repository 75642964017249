import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-error-crr-claim-adjustments',
  templateUrl: './error-crr-claim-adjustments.component.html',
  styleUrls: ['./error-crr-claim-adjustments.component.css']
})
export class ErrorCrrClaimAdjustmentsComponent implements OnInit,AfterViewInit {

  @Output() public collapseClaimAdjFields = new EventEmitter<any>();
  @Input() inputLabel;
  @Input() inputValue;
  @Input() listData: any;
  @Input() changeBtnCls : string;
  @Input() isInstitutional:boolean;
  public claimAdjForm;
  public maxLengthInfo = {
    reasonCode:'5',
    dollar:'5',
    cent:'2',
    qty:'15'
  }
  viewLoaded: boolean;

  constructor() {
    //ErrorCrrClaimAdjustmentsComponent
   }

  ngOnInit() {
    this.maxLengthInfo.dollar = this.isInstitutional ?'8':'5' ;
    this.initializeClmAdjForm();
  }

  ngAfterViewInit(){
    this.viewLoaded = true;
  }
  
  initializeClmAdjForm() {

    const formGroup = {
      dropdown: new UntypedFormControl('', [this.getValidators("","",true)]),
      reasonCodeField : new UntypedFormControl('', [this.getValidators(this.maxLengthInfo.reasonCode, '^[a-zA-Z0-9]+$', true, '1')]),
      dollarField : new UntypedFormControl('', [this.getValidators("", '^-?[0-9]+', true, '1')]),
      centField : new UntypedFormControl('', [this.getValidators(this.maxLengthInfo.cent, '[0-9]+', false, '0')]),
      quantityField : new UntypedFormControl('', [this.getValidators(this.maxLengthInfo.qty, '[0-9]+', false, '1')]) 
    }
    
    this.claimAdjForm = new UntypedFormGroup(formGroup);
    this.listData['clmAdjGroupCode'].forEach((element, index) => {
      if (this.inputValue.claimAdjGroupCode == element.value) {
        this.claimAdjForm.controls.dropdown.setValue(this.listData['clmAdjGroupCode'][index]);
      }
    });
    
    this.claimAdjForm.controls.reasonCodeField.setValue(this.inputValue.claimAdjReasonCode);

    const splitDollar = this.inputValue.monetaryAmount.toString().split(".");
    const dollar = splitDollar[0];
    const cent = splitDollar.length > 1 ? splitDollar[1] : '';

    this.claimAdjForm.controls.dollarField.setValue(dollar);
    this.claimAdjForm.controls.centField.setValue(cent);
    let qty = this.inputValue.quantity;
    if(qty !== undefined || qty !== null){
       qty = qty.toString();
    }
    this.claimAdjForm.controls.quantityField.setValue(qty);
  }

  getFormControlValidity(frmControlNm){
    const isInvalid :boolean = this.claimAdjForm.controls[frmControlNm].status === "INVALID" ;
    if(isInvalid && !this.viewLoaded){
      this.validate();
    }
    return isInvalid;
  }

  getValidators(maxLength, pattern, required?, minLength?) {
    const validators: ValidatorFn[] = [];
    if (required) {
      validators.push(Validators.required);
    }

    if (minLength !== undefined) {
      validators.push(Validators.minLength(minLength));
    }
    if(maxLength){

    validators.push(Validators.maxLength(maxLength));
    }
    if(pattern){

    validators.push(Validators.pattern(pattern));
    }
    return Validators.compose(validators);
  }
  cancelClick() {
    const event = {
      type: 'cancel',
      field: this.inputLabel
    }
    this.collapseClaimAdjFields.emit(event);
  }

  validate() {
    const event = {
      type: 'validation',
      isInvalid: this.claimAdjForm.status === "INVALID",
      field: this.inputLabel
    }
  
    event['amount'] = event['amount'] ||  (this.claimAdjForm.controls.centField.status === 'INVALID') ||  (this.claimAdjForm.controls.dollarField.status === 'INVALID');
    event['quantity'] = event['quantity'] || this.claimAdjForm.controls.quantityField.status === 'INVALID';
    event['reasonCode'] = event['reasonCode'] || this.claimAdjForm.controls.reasonCodeField.status === 'INVALID';
    
    event['groupCode'] = this.claimAdjForm.controls.dropdown.status === 'INVALID';
    this.collapseClaimAdjFields.emit(event);
  }

  mousedownEvent(event){
    event.preventDefault();
  }

  changeClick() {
    const event = {
      type: 'change',
      value: this.inputValue,
      field: this.inputLabel,
      isInvalid: false
    }
    event.isInvalid = this.claimAdjForm.status === "INVALID";
    if (!event.isInvalid) {
        this.inputValue.claimAdjReasonCode = this.claimAdjForm.get('reasonCodeField').value;
        const cent = this.claimAdjForm.get('centField').value;
        this.inputValue.monetaryAmount = this.claimAdjForm.get('dollarField').value + (cent === "" ? "" : "." + cent);
        this.inputValue.quantity = this.claimAdjForm.get('quantityField').value;
        this.inputValue.claimAdjGroupCode = (this.claimAdjForm.get('dropdown').value).value;
        event.value = this.inputValue;
      } else {
      event['amount'] = event['amount'] ||  (this.claimAdjForm.controls.centField.status === 'INVALID') ||  (this.claimAdjForm.controls.dollarField.status === 'INVALID');
      event['quantity'] = event['quantity'] || this.claimAdjForm.controls.quantityField.status === 'INVALID';
      event['reasonCode'] = event['reasonCode'] || this.claimAdjForm.controls.reasonCodeField.status === 'INVALID';
      event['groupCode'] = this.claimAdjForm.controls.dropdown.status === 'INVALID';
    }
    event.isInvalid = this.claimAdjForm.status === "INVALID";
    this.collapseClaimAdjFields.emit(event);
  }
}
