import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

import {SelectItem} from '../data-models/select.item';

import { map } from 'rxjs/operators';
import { WebStorageService, SESSION_STORAGE } from 'ngx-webstorage-service';
import { OauthResponse } from './auth/oauthResponse.model';
import { Subject } from 'rxjs/internal/Subject';
import { Constants } from '../data-models/constants.enum';

@Injectable({ 
    providedIn: 'root' 
})

export class  FileService {

    dataRefreshTime:any;
    private readonly filterRecodAPIStatusSubject = new Subject();
    private  baseServerUrl = environment.baseServerUrl;

    constructor(private readonly http: HttpClient) {
        
    }
    
    getBaseServerUrl(){
        const resp : OauthResponse = JSON.parse(sessionStorage.getItem("oAuthResponse"));
        if(resp.stargateEnabled){
            this.baseServerUrl = environment.starGateUrl+Constants.reportingServiceBaseUrlSuffix;
        }
        else{
            this.baseServerUrl = environment.backedBaseUrl+Constants.reportingServiceBaseUrlSuffix;
        }
    }

    getLastFileReceivedDays(prtySk){
        this.getBaseServerUrl();
        return this.http.post<any>(`${this.baseServerUrl}/getNumDaysLastFileReceived`,{prtySk});
    }

    getLastRefreshTime(prtySk){
        this.getBaseServerUrl();
        return this.http.post<any>(`${this.baseServerUrl}/getDataRefreshTime`,{prtySk});
    }

    getLastRefreshTimeCRR(prtySk){
        this.getBaseServerUrl();
        return this.http.post<any>(`${this.baseServerUrl}/getDataRefreshTimeCRR`,{prtySk});
    }
    
    setDataReresh(dataRefreshTime){
        this.getBaseServerUrl();
        this.dataRefreshTime=dataRefreshTime;
    }
    getDatRefresh(){
        this.getBaseServerUrl();
        return this.dataRefreshTime;
    }

    emitFilterRecodAPIStatusSubject(data){
        this.filterRecodAPIStatusSubject.next(data);
    }

    subscribeFilterRecodAPIStatus(){
        return this.filterRecodAPIStatusSubject.asObservable()
    }
}
