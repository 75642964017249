import { Component, ViewChild, Input, Inject } from '@angular/core';
import { Constants } from '../../data-models/constants.enum';
import { CustomLinerChartService } from '../../services/custom.line.chart.service';
import { SummaryService } from '../../services/tabs/summary.service';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { BaseChartsComponent } from '../base-charts/base-charts.component';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';
@Component({
  selector: 'line-chart',
  templateUrl: './lineCharts.component.html',
  styleUrls: ['./lineCharts.component.css']
})
export class LineChartComponent extends BaseChartsComponent {
  @Input()
  private readonly isHidden: boolean;
  multi: any[];
  view: any;

  xAxisLabel: String = 'Month';
  yAxisLabel: String = 'Number of Files';
  timeline: boolean = true;

  @ViewChild('chart') chart: any;

  colorScheme = {
    domain: ['#e87722']
  };

  constructor(private readonly customLinerChartService: CustomLinerChartService,
    private readonly summaryService: SummaryService,
    @Inject(SESSION_STORAGE) private readonly sessionStorage: WebStorageService) {
    super();
  }

  ngOnInit() {
    const graphSeriesId: number[] = [SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.graphSeriesId)];
    this.yAxisLabel =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.yAxisLabel);
    !this.isHidden && this.populateDataBasedOnTimePeriod(graphSeriesId);
  }

  ngAfterViewChecked() {
    this.customLinerChartService.showDots(this.chart);
  }

  populateDataBasedOnTimePeriod(graphSeriesId): any {
    const prtySk =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.clientName)?.value;
    const isChildDataIncluded = this.sessionStorage.get(Constants.includeChildData);
    const clmDtCriteriaId =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.viewingBy)?.id;
    const timeCriteriaId =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod)?.id;
    const yearVal = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod)?.value;
    const startDate = this.sessionStorage.get(Constants.fromDate);
    const endDate = this.sessionStorage.get(Constants.toDate);
    const tabindx = this.sessionStorage.get(Constants.tabsSelectedIndex);
    const customDateObj = {
      duration: this.sessionStorage.get(Constants.duration),
      graphViewByType: this.sessionStorage.get(Constants.graphViewByType)
    }
    const recordType = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.recordType);
    if (tabindx === 0 && recordType.id === 0) {
      this.summaryService.getSummaryGraphStats(prtySk, isChildDataIncluded, clmDtCriteriaId, timeCriteriaId, startDate, endDate, Constants.claimsRecieved, customDateObj).subscribe(data => {
        this.view = this.calculateChartView(data);
        const clmData = {
          name: Constants.claimsRecieved,
          series: data
        }
        const chartDataInfo = new Array<any>();
        chartDataInfo.push(clmData);

        this.multi = chartDataInfo;

      })
    }
    else {
      this.summaryService.getChartStats(prtySk, isChildDataIncluded, clmDtCriteriaId, timeCriteriaId,yearVal, startDate, endDate, graphSeriesId, customDateObj).subscribe(data => {
        this.view = this.calculateChartView(data.series);
        const chartDataInfo = new Array<any>();
        chartDataInfo.push(data);
        this.multi = chartDataInfo;
      });
    }
  }

  reloadGraph(graphSeriesId) {
    this.populateDataBasedOnTimePeriod(graphSeriesId);
  }

}