<div [formGroup]="diagnosisCodeForm" class="editor-wrapper hdr-data-cls">
    <div *ngIf="inputValue.codeListQualifierCode === 'ABJ' || inputValue.codeListQualifierCode === 'APR'"
        class="diag-item qualf-col">
        <uitk-form-field>
        <uitk-select id="qualifier" formControlName="qualifier" class="qualifier-field"
            [itemList]="listData['diagnosisQualifierDropdown']" [required]='false' [defaultLabelFlag]=false
            [showError]="getFormControlValidity('qualifier')">
        </uitk-select>
    </uitk-form-field>
    </div>

    <div *ngIf="inputValue.codeListQualifierCode !== 'ABJ' && inputValue.codeListQualifierCode !== 'APR'"
        class="diag-item qualf-col text-ellipses">
        <span
            [title]="diagnosisQualMapping[inputValue.codeListQualifierCode] ? inputValue.codeListQualifierCode + '-' + diagnosisQualMapping[inputValue.codeListQualifierCode]:inputValue.codeListQualifierCode">{{inputValue.codeListQualifierCode}}{{
            diagnosisQualMapping[inputValue.codeListQualifierCode] ? '-' +
            diagnosisQualMapping[inputValue.codeListQualifierCode]:''}}</span>
    </div>

    <div class="diag-item diag-code">
        <uitk-form-field>
            <input  class="diagnosiscode-field" uitkInput formControlName="diagnosisCode" id="diagnosisDes"
                [required]="required" (blur)="validate()" [appInputRestrict]="diagnosisCodeForm"
                formCtrl="diagnosisCode" maxLength="30" />
            <uitk-form-field-error *ngIf="getFormControlValidity('diagnosisCode')">
            </uitk-form-field-error>
        </uitk-form-field>
    </div>
    <div class="diag-item desc-col text-ellipses">
        <span
            [title]="diagnosisDesMapping[inputValue.industryCode] || ''">{{diagnosisDesMapping[inputValue.industryCode]
            || ''}}</span>
    </div>
    <div class="diag-item poa-col">
        <ng-container
            *ngIf="inputValue.codeListQualifierCode !== 'ABJ' && inputValue.codeListQualifierCode !== 'APR' && isInstitutional">
            <uitk-form-field>
            <uitk-select id="poa" formControlName="poa" class="poa-field" [itemList]="listData['POA']" [required]='false'
                [defaultLabelFlag]=false>
            </uitk-select>
        </uitk-form-field>
        </ng-container>
    </div>
    <div class="action-btn-wrapper">
        <button [class]="'uitk-c-button uitk-c-button--default uitk-u-margin--xs   uitk-u-margin-left--xs '+changeBtnCls" (click)="changeClick()"
            (mousedown)="mousedownEvent($event)">Apply</button>
        <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--sm" (click)="cancelClick()">Cancel</button>
    </div>
</div>