<uitk-dialog [(showModal)]="sessionTimeOutDialogModal.show" tkDisableCloseOnEsc='true' tkShowHeader="false" uitkId='sessionTimeOutDialogModal' tkOwnContent="true" >
    <uitk-dialog-content>
        <div>
            <div class="dailog-img">
                <svg width="120" height="120" viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="120" cy="120" r="120" fill="#C25608"/>
                    <path d="M189.349 154.25L130.026 51.5C125.792 44.1667 115.208 44.1667 110.974 51.5L51.651 154.25C47.4171 161.583 52.7094 170.75 61.1773 170.75H179.823C188.291 170.75 193.583 161.583 189.349 154.25Z" stroke="white" stroke-width="10"/>
                    <path d="M126.911 128.093L128.525 83H112.57L114.184 128.093H126.911ZM120.547 133.752C115.799 133.752 112 137.403 112 141.967C112 146.349 115.799 150 120.547 150C125.201 150 129 146.349 129 141.967C129 137.403 125.201 133.752 120.547 133.752Z" fill="white"/>
                </svg>
            </div>
            <h3 class="timer-h3">Are you still there ?</h3>
            <p><span class="timer-span">Your session will end in 2 minutes due to inactivity.</span>
            <br>
            <span class="timer-span2">All unsaved work will be lost.</span></p>
            <div class="timer">
                <span id="minutes"> {{timerMinutes}} </span>:<span id="seconds"> {{timerSeconds | number:'2.0'}} </span>
            </div>
            <div class="buttonDiv">
                <button type="button" class="uitk-c-button uitk-c-button--default uitk-u-margin-bottom--xs" (click)="staySignedIn()">Stay Signed In</button>
            </div>
        </div>
    </uitk-dialog-content>
</uitk-dialog>