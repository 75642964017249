export const multi = [
  {
    name: "ComplianceErrors",
    series: [
      {
        name: "wk1",
        value: 2432,
      },
      {
        name: "wk2",
        value: 8756,
      },
      {
        name: "wk3",
        value: 1234,
      },
      {
        name: "wk4",
        value: 5456,
      },
    ],
  },

  {
    name: "ContentErrors",
    series: [
      {
        name: "wk1",
        value: 8756,
      },
      {
        name: "wk2",
        value: 4234,
      },
      {
        name: "wk3",
        value: 98756,
      },
      {
        name: "wk4",
        value: 6554,
      },
    ],
  },

  {
    name: "999Errors",
    series: [
      {
        name: "wk1",
        value: 12345,
      },
      {
        name: "wk2",
        value: 82675,
      },
      {
        name: "wk3",
        value: 2346,
      },
      {
        name: "wk4",
        value: 2645,
      },
    ],
  },
  {
    name: "277Errors",
    series: [
      {
        name: "wk1",
        value: 98762,
      },
      {
        name: "wk2",
        value: 73562,
      },
      {
        name: "wk3",
        value: 6732,
      },
      {
        name: "wk4",
        value: 3234,
      },
    ],
  },
  {
    name: "MAOErrors",
    series: [
      {
        name: "wk1",
        value: 3243,
      },
      {
        name: "wk2",
        value: 3463,
      },
      {
        name: "wk3",
        value: 6234,
      },
      {
        name: "wk4",
        value: 7300,
      },
    ],
  },
];
const count1 = "100,000,000";
const count2 = "50,000,000";
const count3 = "10,000,000";
const c4 = "Chart Review Records";
const c5 = "External Supplemental";
const c6 = "Optum Chart Review";
const c7 = "40,000,000";
const c8 = "Optum IOA";
const c9 = "150,000,000";
const c10 = "1,000";
const c11 = "250,000";
const c12 = "50,000";
const c13 = "500,000";
const c16 = "20,000,000";
const c14 = "25,000,000";
const c15 = "25,000,000";

export const CRRSummary = [
  {
    tabTitle: "Summary",
    headerText: "600,000,000",
    headerMsg: "Records",
    brkDownMsg: "0",
    aggregatedMsg: null,
  },
  {
    tabTitle: "Closed",
    headerText: count1,
    headerMsg: "Records",
    brkDownMsg: "",
    aggregatedMsg: null,
  },
  {
    tabTitle: "Record Errors",
    headerText: "200,000,000",
    headerMsg: "Records",
    brkDownMsg: "",
    aggregatedMsg: null,
  },
  {
    tabTitle: "In Progress",
    headerText: count2,
    headerMsg: "Records",
    brkDownMsg: "",
    aggregatedMsg: null,
  },
  {
    tabTitle: "Claim Creation",
    headerText: "250,000,000",
    headerMsg: "Records",
    brkDownMsg: count1,
    aggregatedMsg: null,
  },
  {
    tabTitle: "Claims In Progress",
    headerText: c16,
    headerMsg: "Records",
    brkDownMsg: "1,000,000",
    aggregatedMsg: null,
  },
  {
    tabTitle: "Claim Errors",
    headerText: c16,
    headerMsg: "Records",
    brkDownMsg: "1,000,000",
    aggregatedMsg: null,
  },
  {
    tabTitle: "CMS Accepted",
    headerText: "240,000,000",
    headerMsg: "Records",
    brkDownMsg: "98,000,000",
    aggregatedMsg: null,
  },
];

export const ClosedTabs = [
  {
    name: "Replaced",
    value: count3,
    label: c4,
    items: [
      {
        code: c5,
        count: c15,
      },
      {
        code: c6,
        count: "80,000,000",
      },
      {
        code: c8,
        count: "0",
      },
    ],
  },
  {
    name: "Duplicates",
    value: "90,000,000",
    label: c4,
    items: [
      {
        code: c5,
        count: c7,
      },
      {
        code: c6,
        count: count3,
      },
      {
        code: c8,
        count: c7,
      },
    ],
  },
  {
    name: "Other",
    value: "0",
    label: c4,
    items: [
      {
        code: c5,
        count: "0",
      },
      {
        code: c6,
        count: "0",
      },
      {
        code: c8,
        count: "0",
      },
    ],
  },
];

export const CreationTabs = [
  {
    name: "Linked Chart Review Records",
    value: c9,
    label: "Chart Review Records by Source",
    items: [
      {
        code: c5,
        count: count1,
      },
      {
        code: c6,
        count: c14,
      },
      {
        code: c8,
        count: c14,
      },
    ],
  },
  {
    name: "Unlinked Chart Review Records",
    value: count1,
    label: "Chart Review Records by Source",
    items: [
      {
        code: c5,
        count: count2,
      },
      {
        code: c6,
        count: c7,
      },
      {
        code: c8,
        count: count3,
      },
    ],
  },
  {
    name: "Linked Claims",
    value: "30,000,000",
    label: "Claim Records by Source",
    items: [
      {
        code: c5,
        count: count3,
      },
      {
        code: c6,
        count: c16,
      },
      {
        code: c8,
        count: "15,000,000",
      },
    ],
  },
  {
    name: "Unlinked Claims",
    value: "70,000,000",
    label: "Claim Records by Source",
    items: [
      {
        code: c5,
        count: count2,
      },
      {
        code: c6,
        count: count3,
      },
      {
        code: c8,
        count: count3,
      },
    ],
  },
];

export const CRRChartData = {
  "11": {
    name: c5,
    series: [
      {
        name: "Jan 2022",
        value: "100000",
        time: "1641016800000",
        partial: true,
        month: true,
        position: "Start From",
        toolTip: "January 1, 2022",
      },
      {
        name: "Feb 2022",
        value: "200000",
        time: "1643695200000",
        partial: false,
        month: true,
        position: null,
        toolTip: "February , 2022",
      },
      {
        name: "Mar 2022",
        value: "150000",
        time: "1646114400000",
        partial: false,
        month: true,
        position: null,
        toolTip: "March , 2022",
      },
      {
        name: "Apr 2022",
        value: "450000",
        time: "1648789200000",
        partial: false,
        month: true,
        position: null,
        toolTip: "April , 2022",
      },
      {
        name: "May 2022",
        value: "300000",
        time: "1651381200000",
        partial: false,
        month: true,
        position: null,
        toolTip: "May , 2022",
      },
      {
        name: "Jun 2022",
        value: "50000",
        time: "1654059600000",
        partial: false,
        month: true,
        position: null,
        toolTip: "June , 2022",
      },
      {
        name: "Jul 2022",
        value: "200000",
        time: "1656651600000",
        partial: false,
        month: true,
        position: null,
        toolTip: "July , 2022",
      },
      {
        name: "Aug 2022",
        value: "470000",
        time: "1659330000000",
        partial: false,
        month: true,
        position: null,
        toolTip: "August , 2022",
      },
      {
        name: "Sep 2022",
        value: "350000",
        time: "1662008400000",
        partial: false,
        month: true,
        position: null,
        toolTip: "September , 2022",
      },
      {
        name: "Oct 2022",
        value: "100000",
        time: "1664600400000",
        partial: false,
        month: true,
        position: null,
        toolTip: "October , 2022",
      },
      {
        name: "Nov 2022",
        value: "0",
        time: "1667278800000",
        partial: false,
        month: true,
        position: null,
        toolTip: "November , 2022",
      },
      {
        name: "Dec 2022",
        value: "300000",
        time: "1672466400000",
        partial: true,
        month: true,
        position: "End Thru",
        toolTip: "December 31, 2022",
      },
    ],
  },
};

export const CRRErrorStats = [
  {
    name: "CMS 999",
    value: "100,000",

    items: [
      {
        code: "I12",
        count: "587",
      },
      {
        code: "3",
        count: "231",
      },
      {
        code: "I6",
        count: "220",
      },
      {
        code: "5",
        count: "157",
      },
      {
        code: "6",
        count: "111",
      },
    ],
  },
  {
    name: "CMS 277",
    value: "400,000",

    items: [
      {
        code: "I12",
        count: "587",
      },
      {
        code: "3",
        count: "231",
      },
      {
        code: "I6",
        count: "220",
      },
      {
        code: "5",
        count: "157",
      },
      {
        code: "6",
        count: "111",
      },
    ],
  },
  {
    name: "CMS MAO-002",
    value: c13,

    items: [
      {
        code: "02240",
        count: "524",
      },
      {
        code: "01410",
        count: "514",
      },
      {
        code: "00760",
        count: "329",
      },
      {
        code: "20495",
        count: "313",
      },
      {
        code: "20835",
        count: "253",
      },
    ],
  },
];
const acc = "Acceptance Rate";
const acc_val = "80%";
export const CRRAcceptedTab = [
  {
    name: c5,
    value: count2,
    items: [
      {
        code: acc,
        count: acc_val,
      },
    ],
  },
  {
    name: c6,
    value: c15,
    items: [
      {
        code: acc,
        count: acc_val,
      },
    ],
  },
  {
    name: c8,
    value: "28,000,000",
    items: [
      {
        code: acc,
        count: acc_val,
      },
    ],
  },
];
export const CRR_Record_Error = {
  linking: {
    value: c9,
    items: [
      {
        code: "err24b",
        desc: "Blank/Invalid CPT Code format",
        count: c10,
      },
      {
        code: "err58",
        desc: "Invalid ICD Code for Bill Type DOS",
        count: c10,
      },
      {
        code: "err54",
        desc: "Provider Federal Tax ID Missing or Invalid",
        count: c10,
      },
      {
        code: "err67",
        desc: "Invalid Provider Name (EDS)",
        count: c10,
      },
      {
        code: "err59",
        desc: "Invalid Contract ID",
        count: c10,
      },
    ],
  },
  unlinking: {
    value: count2,
    items: [
      {
        code: "ASM64",
        desc:
          "The Contract Number resulting from the Subscriber Eligibility Service Call does not match the submitted Contract Number.",
        count: c10,
      },
      {
        code: "ASM118",
        desc:
          "The Diagnosis Code is an External Cause of Injury Code and cannot be submitted to CMS Unlinked.",
        count: c10,
      },
      {
        code: "ASM98",
        desc:
          "The ASM record cannot be submitted because the parent claim encounter was already submitted with an Admitting Diagnosis Code where in the Admitting Diagnosis Indicator on the ASM Record is '1'.",
        count: c10,
      },
      {
        code: "ASM65",
        desc:
          "The Client ID (Client GUID) does not match an existing 'ASM Processing' Client ID (Client GUID).",
        count: c10,
      },
      {
        code: "ASM97",
        desc:
          "The ASM record did not link to any existing claim that was accepted by CMS",
        count: c10,
      },
    ],
  },
  external: "100000",
  chart: "10000",
  ioa: "90000",
};

export const CRRInprogress = [
  {
    status: "Chart Review Records Ready to Link",
    totalCount: c14,
    catgOneCount: c14,
    catgTwoCount: "",
    catgThreeCount: "",
    catgFourCount: "",
  },
  {
    status: c5,
    totalCount: c16,
    catgOneCount: c16,
    catgTwoCount: "",
    catgThreeCount: "",
    catgFourCount: "",
  },
  {
    status: c6,
    totalCount: "5,000,000",
    catgOneCount: "5,000,000",
    catgTwoCount: "",
    catgThreeCount: "",
    catgFourCount: "",
  },
  {
    status: c8,
    totalCount: "",
    catgOneCount: "",
    catgTwoCount: "",
    catgThreeCount: "",
    catgFourCount: "",
  },
  {
    status: "Chart Review Records Pending Claim Creation",
    totalCount: c14,
    catgOneCount: "24,000,000",
    catgTwoCount: c13,
    catgThreeCount: c13,
    catgFourCount: "",
  },
  {
    status: c5,
    totalCount: count3,
    catgOneCount: count3,
    catgTwoCount: "",
    catgThreeCount: "",
    catgFourCount: "",
  },
  {
    status: c6,
    totalCount: "",
    catgOneCount: "",
    catgTwoCount: "",
    catgThreeCount: "",
    catgFourCount: "",
  },
  {
    status: c8,
    totalCount: "15,000,000",
    catgOneCount: "14,000,000",
    catgTwoCount: c13,
    catgThreeCount: c13,
    catgFourCount: "",
  },
 
];
export const CRRClaimInprogress = [
  {
    status: "Ready to Submit",
    totalCount: c13,
    catgOneCount: c13,
    catgTwoCount: "",
    catgThreeCount: "",
    catgFourCount: "",
  },
  {
    status: "Linked Records",
    totalCount: c11,
    catgOneCount: c11,
    catgTwoCount: "",
    catgThreeCount: "",
    catgFourCount: "",
  },
  {
    status: "Unlinked Records",
    totalCount: c11,
    catgOneCount: c11,
    catgTwoCount: "",
    catgThreeCount: "",
    catgFourCount: "",
  },
  {
    status: "Waiting on CMS",
    totalCount: c13,
    catgOneCount: c11,
    catgTwoCount: "100,000",
    catgThreeCount: "150,000",
    catgFourCount: "",
  },
  {
    status: "Linked Records",
    totalCount: c11,
    catgOneCount: "200,000",
    catgTwoCount: c12,
    catgThreeCount: "",
    catgFourCount: "",
  },
  {
    status: "Unlinked Records",
    totalCount: c11,
    catgOneCount: "150,000",
    catgTwoCount: c12,
    catgThreeCount: c12,
    catgFourCount: "",
  }
];

