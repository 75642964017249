import { Component } from '@angular/core';
import { UserPrincipal } from '../services/auth/userPrincipal.model';
import { Router } from '@angular/router';
import { OAuthService } from '../services/auth/oauth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent  {
  private readonly currentUser: UserPrincipal;
  constructor(private readonly router: Router, private readonly authenticationService: OAuthService) {
      console.log('LogoutComponent is constructed');
      this.authenticationService.logoutUser();
      this.authenticationService.logoutUserRedirectSignIn();
      //this.router.navigate(['/home']);
  }
}
