<div class="row margin-top-11">
    <div class="col-md-4 padding-left-3 width-left-3">
        <div><span class="chart-title">Claim Aging</span></div>
    </div>
</div>
<div class="row ">
    <div class="col-md-2 status-col "><span class="inprogress-hdr">Status</span></div>
    <div class="col-md-2 right-align"><span class="inprogress-hdr">Total</span></div>
    <div class="col-md-2 right-align"><span class="inprogress-hdr">0-6 Days</span></div>
    <div class="col-md-2 right-align"><span class="inprogress-hdr">7-14 Days</span></div>
    <div class="col-md-2 right-align"><span class="inprogress-hdr">15-30 Days</span></div>
    <div class="col-md-2 right-align"><span class="inprogress-hdr">30+ Days</span></div>
</div>
<div class="border-top-title"></div>
<div class="row linepadding " *ngFor="let inprogress of inprogressData;let i =index" 
[ngClass]="{'root-node': i%3 === 0}">
    <div *ngIf='inprogress?.status=="ReadyToSubmitTotalCount"' class="col-md-2 status-col">
        <span class="text">Ready to Submit</span>
    </div>
    <div *ngIf='inprogress?.status=="WaitingForCMSTotalCount"' class="col-md-2 status-col">
        <span class="text">Waiting on CMS</span>
    </div>
    <div *ngIf='inprogress?.status=="ReadyToSubmitLRCount" || inprogress?.status=="WaitingForCMSLRCount"' class="col-md-2 status-col">
        <span class="text">Linked Records</span>
    </div>
    <div *ngIf='inprogress?.status=="ReadyToSubmitULRCount" || inprogress?.status=="WaitingForCMSULRCount"' class="col-md-2 status-col">
        <span class="text">Unliked Records</span>
    </div>
    <div class="col-md-2 right-align">
        <span  class="text">{{inprogress.totalCount != '' ? inprogress.totalCount : "-"}}</span>
    </div>      
    <div class="col-md-2 right-align">
        <span class="text">{{inprogress.catgOneCount != '' ? inprogress.catgOneCount : "-"}}</span>
    </div>      
    <div class="col-md-2 right-align">
        <span class="text">{{inprogress.catgTwoCount != '' ? inprogress.catgTwoCount : "-"}}</span>
    </div>
    <div class="col-md-2 right-align">
        <span class="text">{{inprogress.catgThreeCount != '' ? inprogress.catgThreeCount : "-"}}</span>
    </div>
    <div class="col-md-2 right-align">
        <span class="text">{{inprogress.catgFourCount != '' ? inprogress.catgFourCount : "-"}}</span>
    </div>
</div>
