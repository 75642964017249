<div class="row margin-top-11">
    <div class="col-md-4 padding-left-3">
        <div><span class="chart-title">CMS Accepted Claims By Source</span>
            <span class="margin-left-15">
                <a href="#" (click)="exportData($event)">Export Data <uitk-icon-font icon="uimf-icon-export" hiddenText="export"></uitk-icon-font></a>
            </span>
        </div>
    </div>
    <div class="col-md-8 radio-group-wrapper">
        <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" id="chcbx_1"  [(ngModel)]="selection.isExt" name="chcbx_1" class="custom-control-input" (change)="reloadGraph('External Supplemental')" checked="checked">
            <label class="custom-control-label red" for="chcbx_1">External Supplemental</label>
        </div>
        <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" id="chcbx_2"  [(ngModel)]="selection.isOptChart" name="chcbx_2" class="custom-control-input" (change)="reloadGraph('Optum Chart Review')">
            <label class="custom-control-label red" for="chcbx_2">Optum Chart Review</label>
        </div>
        <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" id="chcbx_3"  [(ngModel)]="selection.isIOA" name="chcbx_2" class="custom-control-input" (change)="reloadGraph('Optum IOA')">
            <label class="custom-control-label red" for="chcbx_3">Optum IOA</label>
        </div>
    </div>
</div>
<div class="margin-bottom-35 graph-wrapper">
    <line-chart [hidden]="isBarChartVissible()" [isHidden]="isBarChartVissible()"></line-chart>
    <app-bar-charts [scopeData]="data" [hidden]="!isBarChartVissible()" [isHidden]="!isBarChartVissible()">
    </app-bar-charts>
</div>
<div class="xlabel-wrapper">Encounter Date of Service</div>
<!-- <app-graph-switch-frequency [scopeProps]="data"></app-graph-switch-frequency> -->
<div class="row error-tab-stats-wrapper">
    <div class="col-md-4  error-tab-stats padding-left-3" *ngFor="let agg of aggData">
        <div class="count-title">{{agg.name}}</div>
        <div class="count-sub-title-wraper"> 
            <div class="count-value">{{agg.value}}</div>
            <div class="count-label">Claims</div>
         </div>
        <div class="border-top-title"></div>
        <div class="row padding-2" *ngFor="let item of agg.items">
            <div class="col-md-8 code-txt">{{item.code}}</div>
            <div class="col-md-4 code-txt text-align-right">{{item.count}}</div>
        </div>
    </div>
</div>