<ngx-charts-line-chart #chart [view]="view" [scheme]="colorScheme" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
    [xAxis]="showXAxis" [yAxis]="showYAxis" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [timeline]="timeline" [results]="multi" [yAxisTickFormatting]="yAxisTickFormattingFn">
    <ng-template #tooltipTemplate let-model="model">
        <span *ngIf="model.value === null">Data not available</span>
        <div *ngIf="model.value !== null" class="pop-up-cls">
            <div *ngIf="!model.month"><strong>Date:</strong> &nbsp;&nbsp; <span>{{model.time | date:'MMMM d, yyyy'}}</span></div>
            <div *ngIf="model.month && model.partial"><strong>{{model.position}}:</strong> &nbsp;&nbsp; <span>{{model.time
                    | date:'MMMM d, yyyy'}}</span></div>
            <div *ngIf="model.month && !model.partial"><strong>Date:</strong> &nbsp;&nbsp; <span>{{model.time | date:'MMMM
                    , yyyy'}}</span></div>
            <div><strong>{{model.series}}:</strong> &nbsp;&nbsp;<span>{{model.value}}</span></div>
        </div>
    </ng-template>
    <ng-template #seriesTooltipTemplate let-model="model">
        <span *ngIf="model.value === null">Data not available</span>
        <div *ngIf="model.value !== null">
            <div *ngFor="let m of model" class="pop-up-cls">
                <div *ngIf="!m.month"><strong>Date:</strong> &nbsp;&nbsp; <span>{{m.time | date:'MMMM d, yyyy'}}</span></div>
                <div *ngIf="m.month && m.partial"><strong>{{m.position}}:</strong> &nbsp;&nbsp; <span>{{m.time | date:'MMMM
                        d, yyyy'}}</span></div>
                <div *ngIf="m.month && !m.partial"><strong>Date:</strong> &nbsp;&nbsp; <span>{{m.time | date:'MMMM , yyyy'}}</span></div>
                <div><strong>{{m.series}}: </strong> &nbsp;&nbsp;<span>{{m.value}}</span></div>
            </div>
        </div>
    </ng-template>
</ngx-charts-line-chart>