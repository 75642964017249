<div class="err-crr-provider-expand-wrapper">
    <div *ngIf="dataLoading" [ngStyle]="{ 'height': '370px', 'position': 'relative' }">
        <uitk-loading-indicator
        uitkId="errCrrProviderCatg"
        [centerSpinner]="true"
        visibleText="Please wait..."
        spinnerSize="medium"
        [displayOverlay]="false"
      >
      </uitk-loading-indicator>
    </div>
    <ng-container *ngIf="!dataLoading">
        <div class="info-wapper">
            <div class="flex-container">
                <span>
                    <span *ngIf="open" (click)="toggleOpen()" class="collapse-btn">Collapse All</span>
                    <span *ngIf="!open" (click)="toggleOpen()" class="collapse-btn">Expand All</span>
                </span>
                <a class="close-icon"(click)="cancelProvider()">
                    <i class="uitk-icon uitk-icon-close lg-icon"></i>
                </a>
            </div>
            <div>
                <div>
                    <uitk-panel class="claim-info-accordian" uitkId="claimInfo" [collapsible]="collapsiblePanel" [open]="open">
                        <uitk-panel-header-title [ngClass]="{'error-highlight-acc': getErrorHighlightCount(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode))}">
                        <strong>
                            {{ getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode) }}
                            <span *ngIf="getErrorHighlightCount(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode))" class="panel-header-detail">
                                <span class="error-highlight-count">{{getErrorHighlightCount(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode))}}</span>
                            </span>
                        </strong>
                        </uitk-panel-header-title>
                        <uitk-panel-content panelId="1" 
                            headerClass="tk-panl-with-underline"  [ngClass]="{'error-highlight-acc': getErrorHighlightCount(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode))}">
                           
                            <div *ngIf="getUnmatchErrorHighlight(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode))" class="info-unmatch-wrapper">
                                <app-error-highlight
                                    [errorHighlightList]="getUnmatchErrorHighlight(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode))">
                                </app-error-highlight>
                            </div>
                            <div class="claim-info-wrapper">
                                <div class="info-item entity-type-info-item error-highlight-section">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode),billProvFieldLabel.entityDetails)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode),billProvFieldLabel.providerAddress)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode),billProvFieldLabel.billingContactName)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item entity-type-info-item"  [ngClass]="{'error-highlight-field': getMatchErrorHighlight(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode),billProvFieldLabel.entityDetails)}">
                                    <ng-container *ngIf="isAttrApplicable(clmInfoProviderData,billingProKeyMapping.entityTypeCode)">
                                        <div *ngIf="invalidFieldStatus.entitydetails" class="invalid-field-msg" 
                                         [innerHTML]="getErrorHighlightMsg('Entity Details is invalid')">
                                        </div>
                                        <label [ngClass]="{'invalid-field': invalidFieldStatus.entitydetails}" class="info-label-item">Entity Details</label>
                                        <div class="icon-value-wrapper" *ngIf="!showHideConfig['entitydetails']">
                                             <i class="uitk-icon uitk-icon-edit md-icon"(click)="expandEditor('entitydetails',showHideConfig)"  hiddenText="edit"
                                               ></i>
                                            <span class="data-wrap">{{getFieldValue(clmInfoProviderData,billingProKeyMapping.entityTypeCode)}}
                                                - {{getName(clmInfoProviderData,'loop2010NM1')}}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <app-error-crr-editor 
                                    *ngIf="showHideConfig['entitydetails']" 
                                    [inputType]="inputTypeConfig.entityName"
                                        [inputValue]="clmInfoProviderData.loop2010NM1" 
                                        inputLabel="entitydetails" jsonKey="loop2010NM1" 
                                        [entityTypeList]="getEntityTypeList(entityId[clmInfoProviderData.loop2010NM1?.entityIdCode])"
                                        [isEntityTypeReq]="true"
                                        (collapseFields)="collapseEditor($event)" [changeBtnCls]="changeBtnCls">
                                    </app-error-crr-editor>
                                </div>
                                <div class="info-item" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode),billProvFieldLabel.providerAddress)}">
                                    <ng-container *ngIf="isAttrApplicable(clmInfoProviderData,billingProKeyMapping.addinfo1)">
                                        <div *ngIf="invalidFieldStatus.providerAddress" class="invalid-field-msg" 
                                         [innerHTML]="getErrorHighlightMsg('Provider Address is invalid')">
                                        </div>
                                        <label [ngClass]="{'invalid-field': invalidFieldStatus.providerAddress}" class="info-label-item">Provider Address
                                        </label>
                                        <div *ngIf="!showHideConfig['providerAddress']" class="icon-value-wrapper">
                                             <i class="uitk-icon uitk-icon-edit md-icon" (click)="expandEditor('providerAddress',showHideConfig)" hiddenText="edit">
                                           </i>
                                            <span class="data-wrap">{{getProviderAddr(clmInfoProviderData,billingProKeyMapping)}}</span>
                                        </div>
                                    </ng-container>
                                    <app-error-crr-editor *ngIf="showHideConfig['providerAddress']" [inputType]="inputTypeConfig.address"
                                        [inputValue]="clmInfoProviderData" instruction="Select one option"
                                        inputLabel="providerAddress" [jsonKey]="billingProKeyMapping"
                                        pattern='^[a-zA-Z0-9\s.\-]+$' [changeBtnCls]="changeBtnCls" maxLength='55' [listData]="listConfig"
                                        (collapseFields)="collapseEditor($event)">
                                    </app-error-crr-editor>
                                </div>
                                <div class="info-item" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode),billProvFieldLabel.billingContactName)}">
                                    <ng-container *ngIf="isAttrApplicable(clmInfoProviderData,billingProKeyMapping.billingContactName)">
                                        <div *ngIf="invalidFieldStatus['contactName_'+bilProv]"  class="invalid-field-msg" 
                                         [innerHTML]="getErrorHighlightMsg('Billing Contact Name is invalid')">
                                        </div>
                                        <label [ngClass]="{'invalid-field': invalidFieldStatus['contactName_'+bilProv]}" class="info-label-item">Billing Contact Name
                                        </label>

                                        <div class="icon-value-wrapper" *ngIf="!showHideConfig['contactName_'+bilProv]">
                                             <i class="uitk-icon uitk-icon-edit md-icon" hiddenText="edit"
                                            (click)="expandEditor('contactName_'+bilProv,showHideConfig)">
                                           </i>
                                            <span
                                                class="data-wrap">{{getFieldValue(clmInfoProviderData,billingProKeyMapping.billingContactName)}}</span>
                                        </div>
                                    </ng-container> 
                                    <app-error-crr-editor *ngIf="showHideConfig['contactName_'+bilProv]" [inputType]="inputTypeConfig.input"
                                    [inputValue]="getFieldValue(clmInfoProviderData,billingProKeyMapping.billingContactName)" instruction="Type to enter"
                                    [inputLabel]="'contactName_'+bilProv" [jsonKey]="billingProKeyMapping.billingContactName"
                                    [pattern]='patterns.NAME' [changeBtnCls]="changeBtnCls" maxLength='60' [listData]="listConfig"
                                    (collapseFields)="collapseEditor($event)">
                                </app-error-crr-editor>
                                </div>

                                <div class="info-item error-highlight-section">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode),billProvFieldLabel.billingContactInfo)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode),billProvFieldLabel.payToAddress)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode),billProvFieldLabel.NPI)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode),billProvFieldLabel.TIN)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode),billProvFieldLabel.billingContactInfo)}">
                                    <ng-container *ngIf="isAttrApplicable(clmInfoProviderData,billingProKeyMapping.billingContactInfo)">
                                        <div *ngIf="invalidFieldStatus.billingContact" class="invalid-field-msg" 
                                         [innerHTML]="getErrorHighlightMsg('Billing Contact Information is invalid')">
                                        </div>
                                        <label [ngClass]="{'invalid-field': invalidFieldStatus.billingContact}" class="info-label-item">Billing Contact Information
                                        </label>
                                        <div class="icon-value-wrapper" *ngIf="!showHideConfig['billingContact']">
                                             <i class="uitk-icon uitk-icon-edit md-icon"(click)="expandEditor('billingContact',showHideConfig)" 
                                                hiddenText="edit">
                                           </i>
                                            <div class="data-wrap">
                                                <div class="text-ellipses" [title]="data.commNumberQual === 'TE' || data.commNumberQual === 'FX'? formatIntoPhoneNo(data.commNumber) :
                                                data.commNumber" *ngFor="let data of clmInfoProviderData.loop2010PER">
                                                    {{data.commNumberQual === 'TE' || data.commNumberQual === 'FX'? formatIntoPhoneNo(data.commNumber) :
                                                    data.commNumber }}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <app-error-crr-editor *ngIf="showHideConfig['billingContact']" [inputType]="inputTypeConfig.contact"
                                        [inputValue]="clmInfoProviderData.loop2010PER" inputLabel="billingContact" [listData]="listConfig"
                                        (collapseFields)="collapseEditor($event)" [changeBtnCls]="changeBtnCls">
                                    </app-error-crr-editor>
                                </div>
                                <div class="info-item" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode),billProvFieldLabel.payToAddress)}">
                                    <ng-container  *ngIf="getPayToAddr(true)">
                                        <div *ngIf="invalidFieldStatus.payToAddress" class="invalid-field-msg" 
                                         [innerHTML]="getErrorHighlightMsg('Pay To Address is invalid')">
                                        </div>
                                        <label [ngClass]="{'invalid-field': invalidFieldStatus.payToAddress}" class="info-label-item">Pay To Address
                                        </label>
                                        <div *ngIf="!showHideConfig['payToAddress']"  class="icon-value-wrapper">
                                             <i class="uitk-icon uitk-icon-edit md-icon" hiddenText="edit"
                                            (click)="expandEditor('payToAddress',showHideConfig)">
                                           </i>
                                            <span class="data-wrap">{{getPayToAddr()}}</span>
                                        </div>
                                    </ng-container>
                                    <app-error-crr-editor *ngIf="showHideConfig['payToAddress']" [inputType]="inputTypeConfig.address"
                                        [inputValue]="getPayToAddr(false,true)" instruction="Select one option"
                                        inputLabel="payToAddress" [jsonKey]="billingProKeyMapping"
                                        pattern='^[a-zA-Z0-9\s.\-]+$' [changeBtnCls]="changeBtnCls" maxLength='55' [listData]="listConfig"
                                        (collapseFields)="collapseEditor($event)">
                                    </app-error-crr-editor>
                                </div>
                                <div class="info-item" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode),billProvFieldLabel.NPI)}">
                                    <ng-container *ngIf="isAttrApplicable(clmInfoProviderData,billingProKeyMapping.NPI)">
                                        <div *ngIf="invalidFieldStatus['npi_'+bilProv]" class="invalid-field-msg"
                                         [innerHTML]="getErrorHighlightMsg('NPI is invalid')">
                                        </div>
                                        <label [ngClass]="{'invalid-field': invalidFieldStatus['npi_'+bilProv]}" class="info-label-item">NPI
                                        </label>
                                        <div class="icon-value-wrapper"  *ngIf="!showHideConfig['npi_'+bilProv]">
                                             <i class="uitk-icon uitk-icon-edit md-icon" hiddenText="edit"
                                            (click)="expandEditor('npi_'+bilProv,showHideConfig)">
                                           </i>
                                            <span
                                                class="data-wrap">{{getFieldValue(clmInfoProviderData,billingProKeyMapping.NPI)}}</span>
                                        </div>
                                    </ng-container>
                                    <app-error-crr-editor *ngIf="showHideConfig['npi_'+bilProv]" [inputType]="inputTypeConfig.input"
                                    [inputValue]="getFieldValue(clmInfoProviderData,billingProKeyMapping.NPI)" instruction="Type to enter"
                                    [inputLabel]="'npi_'+bilProv" [jsonKey]="billingProKeyMapping.NPI"
                                    [pattern]='patterns.NUM_START_1' [changeBtnCls]="changeBtnCls" maxLength='10' minLength='10'
                                    (collapseFields)="collapseEditor($event)">
                                </app-error-crr-editor>
                                </div>
                                <div class="info-item" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode),billProvFieldLabel.TIN)}">
                                    <ng-container *ngIf="getTIN(true)">
                                        <div *ngIf="invalidFieldStatus.tin" class="invalid-field-msg"
                                         [innerHTML]="getErrorHighlightMsg('TIN is invalid')">
                                        </div>
                                        <label [ngClass]="{'invalid-field': invalidFieldStatus.tin}" class="info-label-item">TIN
                                        </label>
                                        <div *ngIf="!showHideConfig['tin']" class="icon-value-wrapper">
                                             <i class="uitk-icon uitk-icon-edit md-icon" (click)="expandEditor('tin',showHideConfig)" hiddenText="edit"
                                               >
                                           </i>
                                            <span class="data-wrap">{{getTIN()}}</span>
                                        </div>
                                    </ng-container>
                                    <app-error-crr-editor *ngIf="showHideConfig['tin']"
                                    [inputType]="inputTypeConfig.input" [inputValue]="getTIN()"
                                    instruction="Type to enter" inputLabel="tin"
                                    jsonKey="loop2010Reference.0.referenceIdent" pattern='^[a-zA-Z0-9]+$' maxLength='9' minLength='9'
                                    (collapseFields)="collapseEditor($event)"  [optional]="false"
                                    [changeBtnCls]="changeBtnCls">
                                </app-error-crr-editor>
                                </div>

                                <div class="info-item error-highlight-section">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode),billProvFieldLabel.taxonomy)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section"></div>
                                <div class="info-item error-highlight-section"></div>
                                <div class="info-item error-highlight-section"></div>
                                <div class="row-info-item"[ngClass]="{'error-highlight-field': getMatchErrorHighlight(getPanelTitle(clmInfoProviderData.loop2010NM1.entityIdCode),billProvFieldLabel.taxonomy)}">
                                    <ng-container *ngIf="getTaxonomy(billingProviderTax,true)">
                                        <div *ngIf="invalidFieldStatus['taxonomy_'+bilProv]" class="invalid-field-msg" 
                                         [innerHTML]="getErrorHighlightMsg('Taxonomy Code is invalid')">
                                        </div>
                                        <label [ngClass]="{'invalid-field': invalidFieldStatus['taxonomy_'+bilProv]}" class="info-label-item">Taxonomy Code
                                        </label>
                                        <div class="icon-value-wrapper" *ngIf="!showHideConfig['taxonomy_'+bilProv]">
                                             <i class="uitk-icon uitk-icon-edit md-icon" hiddenText="edit" 
                                            (click)="expandEditor('taxonomy_'+bilProv,showHideConfig)">
                                           </i>
                                            <span class="data-wrap">{{getTaxonomy(billingProviderTax)}}</span>
                                        </div>
                                    </ng-container>
                                    <app-error-crr-editor *ngIf="showHideConfig['taxonomy_'+bilProv]" [inputType]="inputTypeConfig.input"
                                    [inputValue]="getTaxonomy(billingProviderTax)" instruction="Type to enter"
                                    [inputLabel]="'taxonomy_'+bilProv" [jsonKey]="providerKeyMapping.taxonomy"
                                    [pattern]='patterns.AN' [changeBtnCls]="changeBtnCls" maxLength='10'
                                    minLength='10'
                                    (collapseFields)="collapseEditor($event)">
                                   </app-error-crr-editor>
                                </div>
                            </div>
                        </uitk-panel-content>
                    </uitk-panel>
                </div>
                <div
                    *ngFor="let providerData of clmDta?.billingProvider[0]?.subscriber[0]?.claim[0]?.loop2310Name; let index= index">
                    <uitk-panel class="service-line-accordian" uitkId="claimInfo" [collapsible]="collapsiblePanel" [open]="open">
                        <uitk-panel-header-title [ngClass]="{'error-highlight-acc': getErrorHighlightCount(getPanelTitle(providerData.loop2310NM1.entityIdCode))}">
                            <strong>{{ getPanelTitle(providerData.loop2310NM1.entityIdCode) }}
                            <span *ngIf="getErrorHighlightCount(getPanelTitle(providerData.loop2310NM1.entityIdCode))" class="panel-header-detail">
                                <span class="error-highlight-count">{{getErrorHighlightCount(getPanelTitle(providerData.loop2310NM1.entityIdCode))}}</span>
                            </span>
                            </strong>
                        </uitk-panel-header-title>
                        <uitk-panel-content panelId="1" 
                            headerClass="tk-panl-with-underline" [ngClass]="{'error-highlight-acc': getErrorHighlightCount(getPanelTitle(providerData.loop2310NM1.entityIdCode))}">
                           
                            <div *ngIf="getUnmatchErrorHighlight(getPanelTitle(providerData.loop2310NM1.entityIdCode))" class="info-unmatch-wrapper">
                                <app-error-highlight
                                    [errorHighlightList]="getUnmatchErrorHighlight(getPanelTitle(providerData.loop2310NM1.entityIdCode))">
                                </app-error-highlight>
                            </div>
                            <div  class="claim-info-wrapper">
                                <div class="info-item entity-type-info-item error-highlight-section">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(getPanelTitle(providerData.loop2310NM1.entityIdCode),providerFieldLabel.entityDetails)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(getPanelTitle(providerData.loop2310NM1.entityIdCode),providerFieldLabel.NPI)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section"  *ngIf="providerData.loop2310NM1.entityIdCode === '77'">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(getPanelTitle(providerData.loop2310NM1.entityIdCode),providerFieldLabel.serviceFacAddr)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section"  *ngIf="providerData.loop2310NM1.entityIdCode === '71' || providerData.loop2310NM1.entityIdCode === '82'">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(getPanelTitle(providerData.loop2310NM1.entityIdCode),providerFieldLabel.taxonomy)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section"  *ngIf="!(providerData.loop2310NM1.entityIdCode === '71' || providerData.loop2310NM1.entityIdCode === '82' || providerData.loop2310NM1.entityIdCode === '77')">
                                </div>
                                <div class="row-info-item entity-type-info-item" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(getPanelTitle(providerData.loop2310NM1.entityIdCode),providerFieldLabel.entityDetails)}">
                                    <ng-container *ngIf="isAttrApplicable(providerData,providerKeyMapping.entityTypeQua)">
                                        <div *ngIf="invalidFieldStatus['entitydetails_'+index]" class="invalid-field-msg"
                                            [innerHTML]="getErrorHighlightMsg('Entity Details is invalid')">
                                        </div>
                                    <label [ngClass]="{'invalid-field': invalidFieldStatus['entitydetails_'+index]}" class="info-label-item">Entity Details</label>
                                        <div class="icon-value-wrapper"  *ngIf="!showHideConfig['entitydetails_'+index]" >
                                             <i class="uitk-icon uitk-icon-edit md-icon" hiddenText="edit"
                                            (click)="expandEditor('entitydetails_'+index,showHideConfig)">
                                           </i>
                                            <span
                                                class="data-wrap">{{getFieldValue(providerData,providerKeyMapping.entityTypeQua)}}
                                                - {{getName(providerData,'loop2310NM1')}}</span>
                                        </div>
                                    </ng-container><app-error-crr-editor 
                                    *ngIf="showHideConfig['entitydetails_'+index]" 
                                    [inputType]="inputTypeConfig.entityName"
                                        [inputValue]="providerData.loop2310NM1" 
                                        [inputLabel]="'entitydetails_'+index" jsonKey="loop2310NM1" 
                                        [entityTypeList]="getEntityTypeList(entityId[providerData.loop2310NM1?.entityIdCode])"
                                        [isEntityTypeReq]="true"
                                        (collapseFields)="collapseEditor($event)" [changeBtnCls]="changeBtnCls">
                                    </app-error-crr-editor>
                                </div>
                                <div class="row-info-item"  [ngClass]="{'error-highlight-field':getMatchErrorHighlight(getPanelTitle(providerData.loop2310NM1.entityIdCode),providerFieldLabel.NPI)}">
                                    <ng-container *ngIf="isAttrApplicable(providerData,providerKeyMapping.NPI)">
                                        <div *ngIf="invalidFieldStatus['npi_'+index]" class="invalid-field-msg"
                                            [innerHTML]="getErrorHighlightMsg(providerFieldLabel.NPI + ' is invalid')">
                                        </div>
                                        <label [ngClass]="{'invalid-field': invalidFieldStatus['npi_'+index]}" class="info-label-item">{{providerFieldLabel.NPI}}
                                        </label>
                                        <div class="icon-value-wrapper" *ngIf="!showHideConfig['npi_'+index]">
                                             <i class="uitk-icon uitk-icon-edit md-icon" hiddenText="edit" 
                                            (click)="expandEditor('npi_'+index,showHideConfig)"
                                           >
                                           </i>
                                            <span
                                                class="data-wrap">{{getFieldValue(providerData,providerKeyMapping.NPI)}}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <app-error-crr-editor *ngIf="showHideConfig['npi_'+index]" [inputType]="inputTypeConfig.input"
                                    [inputValue]="getFieldValue(providerData,providerKeyMapping.NPI)" instruction="Type to enter"
                                    [inputLabel]="'npi_'+index" [jsonKey]="providerKeyMapping.NPI"
                                    [pattern]='patterns.NUM_START_1' [changeBtnCls]="changeBtnCls" maxLength='10' minLength='10'
                                    (collapseFields)="collapseEditor($event)">
                                   </app-error-crr-editor>
                                </div>
                                <div class="row-info-item"  *ngIf="providerData.loop2310NM1.entityIdCode === '77'" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(getPanelTitle(providerData.loop2310NM1.entityIdCode),providerFieldLabel.serviceFacAddr)}">
                                    <ng-container *ngIf="isAttrApplicable(providerData,providerKeyMapping.addinfo1)">
                                        <div *ngIf="invalidFieldStatus['facAddr_'+index]" class="invalid-field-msg"
                                            [innerHTML]="getErrorHighlightMsg('Service Facility Address is invalid')">
                                        </div>
                                        <label [ngClass]="{'invalid-field': invalidFieldStatus['facAddr_'+index]}" class="info-label-item">Service Facility Address
                                        </label>
                                        <div class="icon-value-wrapper" *ngIf="!showHideConfig['facAddr_'+index]">
                                             <i class="uitk-icon uitk-icon-edit md-icon" hiddenText="edit" 
                                            (click)="expandEditor('facAddr_'+index,showHideConfig)">
                                           </i>
                                            <span
                                                class="data-wrap">{{getProviderAddr(providerData,providerKeyMapping)}}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <app-error-crr-editor *ngIf="showHideConfig['facAddr_'+index]" [inputType]="inputTypeConfig.address"
                                        [inputValue]="providerData" instruction="Select one option"
                                        [inputLabel]="'facAddr_'+index" [jsonKey]="providerKeyMapping"
                                        pattern='^[a-zA-Z0-9\s.\-]+$' [changeBtnCls]="changeBtnCls" maxLength='55' [listData]="listConfig"
                                        (collapseFields)="collapseEditor($event)">
                                    </app-error-crr-editor>
                                </div>

                                <div class="row-info-item"  *ngIf="providerData.loop2310NM1.entityIdCode === '71' || providerData.loop2310NM1.entityIdCode === '82'" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(getPanelTitle(providerData.loop2310NM1.entityIdCode),providerFieldLabel.taxonomy)}">
                                    <ng-container *ngIf="isAttrApplicable(providerData,providerKeyMapping.taxonomy)">
                                        <div  *ngIf="invalidFieldStatus['taxonomy_'+index]" class="invalid-field-msg"
                                            [innerHTML]="getErrorHighlightMsg('Taxonomy Code is invalid')">
                                        </div>
                                        <label [ngClass]="{'invalid-field': invalidFieldStatus['taxonomy_'+index]}" class="info-label-item">Taxonomy Code
                                        </label>
                                        <div class="icon-value-wrapper" *ngIf="!showHideConfig['taxonomy_'+index]">
                                             <i class="uitk-icon uitk-icon-edit md-icon" hiddenText="edit" 
                                            (click)="expandEditor('taxonomy_'+index,showHideConfig)">
                                           </i>
                                            <span
                                                class="data-wrap">{{getFieldValue(providerData,providerKeyMapping.taxonomy)}}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <app-error-crr-editor *ngIf="showHideConfig['taxonomy_'+index]" [inputType]="inputTypeConfig.input"
                                    [inputValue]="getFieldValue(providerData,providerKeyMapping.taxonomy)" instruction="Type to enter"
                                    [inputLabel]="'taxonomy_'+index" [jsonKey]="providerKeyMapping.taxonomy"
                                    [pattern]='patterns.AN' [changeBtnCls]="changeBtnCls" maxLength='10'
                                    minLength='10'
                                    (collapseFields)="collapseEditor($event)">
                                   </app-error-crr-editor>
                                </div>
                            </div>
                        </uitk-panel-content>
                    </uitk-panel>
                </div>
            </div>
            <div class="row">
            </div>
        </div>
        <div class="btns-btm accrdn-btm">
            <div>
                <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="cancelProvider()">Cancel</button>
            </div>
            <div>
                <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="saveProvideClaim()">Save</button>
            </div>
            <div>
                <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="saveProvideClaim(true)">Resubmit</button>
            </div>
        </div>
    </ng-container>
</div>
<uitk-dialog [(showModal)]="showInvalidWarning"  uitkId='myDialog-4' class="warning-popup"
triggerElementId='myDialog_openBtn4' (onModalHidden)="closeProviderPopUp()" >
    <uitk-dialog-header>
        <div class="warning-msg-heading-wrap" *ngIf="warningFlow !=='cancel'" >
            <i  class="uitk-icon uitk-icon-warning_filled md-icon" hiddenText="warning"></i>
            <div class="warning-msg-heading">Warning</div>
        </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
        <div class="confirm-data" [ngClass]="{'cancel':warningFlow ==='cancel'}">
            <div class="warning-msg-wrapper">
                <div class="warning-msg-content">{{warningMsg}}</div> 
                <div class="warning-btns-btm" *ngIf="warningFlow==='cancel'">
                    <button class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="collapseProviderPanel()">Continue</button>
                    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="showInvalidWarning = false">Cancel</button>
                </div>
            </div>
        </div>
    </uitk-dialog-content>
</uitk-dialog>
<app-error-crr-resubmit [resubmitConfig]="resubmitClmConfig" [selectedRow]="selectedRow" [errorCategoryDetails]="errorCategoryDtls" (collapsePopUp)="colpseProviderResubmitPopUp($event)" ></app-error-crr-resubmit>