    <div class="editor-wrapper" id="editor">
            <div *ngIf="inputType === 'dropdown'" class="field-width">
                <form [formGroup]="claimErrorCrrForm">
                    <uitk-form-field>
                        <uitk-form-field-hint>{{instruction}}</uitk-form-field-hint>
                        <uitk-select
                            id="dropdownField"
                            formControlName="dropdownField"
                            [itemList]="listData[listKey]"
                            
                            [defaultLabelFlag]="false"
                            (onDropdownChange)="OnBlur()"
                            [showError]="getFormControlValidity('dropdownField')"
                        >
                        </uitk-select>
                    </uitk-form-field>
                </form>
            </div>
            <div *ngIf="inputType === 'dateField'" class="single-dateField field-width"
                [ngClass]="{'tk-form-element-container--error': getFormControlValidity('dateField')}">
                <form [formGroup]="claimErrorCrrForm">
                    <uitk-form-field-hint>{{instruction}} </uitk-form-field-hint>
                    <uitk-form-field>
                    
                        
                        <uitk-date-picker
                        uitkId="my-required-date-picker"
                            formControlName="dateField"
                            (ngModelChange)="OnBlur(cal0)"
                        ></uitk-date-picker>
                        <uitk-form-field-error
                            *ngIf="
                            claimErrorCrrForm?.controls?.dateField?.errors?.invalidFormat &&
                            claimErrorCrrForm?.controls?.dateField?.touched
                            "
                        >
                            {{ invalidText }}
                        </uitk-form-field-error>
                    </uitk-form-field>
               
                </form>
            </div>
            
            <div *ngIf="inputType === 'fromThroughField' && dateConfig.minYear" class="date-range-field field-width">
                <form [formGroup]="claimErrorCrrForm">
                    <div class="field-wrapper">
                    <div class="date-wrapper">
                        <uitk-form-field-hint>{{instruction}} </uitk-form-field-hint>
                        <uitk-form-field>
                            
                            <uitk-date-picker
                                uitkId ="fromField"
                                formControlName="fromField"
                                (ngModelChange)="OnBlur()"
                                [model]="dateConfig"
                                class="from-through-date"
                            ></uitk-date-picker>
                            <uitk-form-field-error
                                *ngIf="
                                claimErrorCrrForm?.controls?.fromField?.errors?.invalidFormat &&
                                claimErrorCrrForm?.controls?.fromField?.touched
                                "
                            >
                                {{ invalidText }}
                            </uitk-form-field-error>
                            <uitk-form-field-error *ngIf="getFormControlValidity('fromField')">
                            
                        </uitk-form-field-error>
                        </uitk-form-field>
                        </div>
                        <div class="date-wrapper">
                        <uitk-form-field-hint>{{instruction}} </uitk-form-field-hint>
                        <uitk-form-field>
                            
                            
                            <uitk-date-picker
                                uitkId ="throughField"
                                formControlName="throughField"
                                (ngModelChange)="OnBlur()"
                                class="from-through-date"
                                [model]="dateConfig"
                            ></uitk-date-picker>
                            <uitk-form-field-error
                                *ngIf="
                                claimErrorCrrForm?.controls?.throughField?.errors?.invalidFormat &&
                                claimErrorCrrForm?.controls?.throughField?.touched
                                "
                            >
                                {{ invalidText }}
                            </uitk-form-field-error>
                            <uitk-form-field-error
                            *ngIf="(isDateRangeInvalid() || getFormControlValidity('throughField'))"
                            >
                        </uitk-form-field-error>
                        </uitk-form-field>
                    </div>
                    </div>
                </form>
            </div>

            <div *ngIf="inputType === 'inputTextBox'" class="field-width">
                <form [formGroup]="claimErrorCrrForm">
                    <uitk-form-field>
                        <uitk-form-field-hint>{{instruction}} </uitk-form-field-hint>
                        <input id="my-basic-input-reactive" uitkInput formControlName="textbox" [required]="required" (blur)="OnBlur()" 
                        [appInputRestrict]="claimErrorCrrForm"  [maxLength]="maxLength"/>
                        <uitk-form-field-error
                                *ngIf="getFormControlValidity('textbox')">
                            </uitk-form-field-error>
                    </uitk-form-field>
                </form>
            </div>
            <div *ngIf="inputType === 'codeInfoTextBox'" class="field-width">
                <form [formGroup]="claimErrorCrrForm">
                    <uitk-form-field-hint>{{instruction}} </uitk-form-field-hint>
                    <div class="codeInfo-field-wrapper">
                     <uitk-form-field class="code-info-field">
                        
                        <input id="code1" uitkInput formControlName="code1"   [maxLength]="maxLength" [appInputRestrict]="claimErrorCrrForm"
                         (blur)="OnBlur()" />
                        <uitk-form-field-error
                                *ngIf="getFormControlValidity('code1')"
                            >
                            </uitk-form-field-error>
                    </uitk-form-field>
                    <uitk-form-field class="code-info-field">
                        <input id="code2" uitkInput formControlName="code2"  [maxLength]="maxLength"
                        [appInputRestrict]="claimErrorCrrForm"  (blur)="OnBlur()"/>
                        <uitk-form-field-error
                                *ngIf=" getFormControlValidity('code2')"
                            >  
                            </uitk-form-field-error>
                    </uitk-form-field>
                    <uitk-form-field class="code-info-field">
                        <input id="code3" uitkInput formControlName="code3"  [maxLength]="maxLength" 
                        (blur)="OnBlur()" [appInputRestrict]="claimErrorCrrForm"/>
                        <uitk-form-field-error
                                *ngIf="getFormControlValidity('code3')
                                "
                            >
                            </uitk-form-field-error>
                    </uitk-form-field>
                    <uitk-form-field class="code-info-field">
                        <input id="code4" uitkInput formControlName="code4"  [maxLength]="maxLength" 
                        (blur)="OnBlur()" [appInputRestrict]="claimErrorCrrForm"/>
                        <uitk-form-field-error
                                *ngIf="getFormControlValidity('code4')">
                                
                            </uitk-form-field-error>
                    </uitk-form-field>
                </div>
                </form>
            </div>

            <div *ngIf="inputType === 'dollarField'" class="field-width">
                <form [formGroup]="claimErrorCrrForm">
                    <uitk-form-field-hint ><span class="instruction-text">{{instruction}} </span></uitk-form-field-hint>
                    
                    <div class="dollar-field-wrapper">
                        <span class="dollar-symbol">$</span>
                        <uitk-form-field class="dollar-field">
                            <input id="dollar" uitkInput formControlName="dollar" [required]="required"  [maxLength]="maxLength" [appInputRestrict]="claimErrorCrrForm" 
                            (blur)="OnBlur()" />
                            <uitk-form-field-error
                                    *ngIf="getFormControlValidity('dollar')">
                                </uitk-form-field-error>
                        </uitk-form-field>
                        <span class="separator">.</span>
                        <uitk-form-field class="cent-field">
                            <input id="cent" uitkInput formControlName="cent" [required]="required"  [maxLength]="maxLength"
                             [appInputRestrict]="claimErrorCrrForm" (blur)="OnBlur()" />
                            <uitk-form-field-error
                                    *ngIf="getFormControlValidity('cent')">
                                </uitk-form-field-error>
                        </uitk-form-field>
                    </div>
                </form>
            </div>

            <div *ngIf="inputType === 'unitsField'" class="field-width">
                <form [formGroup]="claimErrorCrrForm">
                    <div class="field-wrapper">
                        <uitk-form-field class="units-quantity-field">
                            <label uitkLabel>Quantity</label>
                            <input id="unitsQuanity" uitkInput formControlName="unitsQuanity"   [required]="required" 
                            [appInputRestrict]="claimErrorCrrForm" [quantity]="quantityMaxLengthMapping" (blur)="OnBlur()" />
                            <uitk-form-field-error *ngIf="getFormControlValidity('unitsQuanity')">
                                   
                            </uitk-form-field-error>
                        </uitk-form-field>
                        <span class="separator"></span>
                        <uitk-form-field  class="units-field">
                            <label uitkLabel>Units of Measure</label>
                            
                            <uitk-select
                                id="units"
                                formControlName="units"
                                [itemList]="listData[jsonKey]"
                                (onDropdownChange)="OnBlur()"
                                [defaultLabelFlag]="false"
                                [showError]="getFormControlValidity('units')"
                            >
                            </uitk-select>
                        </uitk-form-field>
                    </div>
                </form>
            </div>                           
               

            <div *ngIf="inputType === 'addressField'" class="field-width">
                <form [formGroup]="claimErrorCrrForm">
                    <span class="instruction-text"><span class="asterisk">*</span>Required</span><br>
                    <uitk-form-field>
                    <label for="address1Field" class="tk-labl instruction-text" uitkLabel>Address Line 1<span
                            class="asterisk">*</span>
                    </label>
                    <input class="input-field-wrapper"uitkInput  id="address1Field"
                        formControlName="address1"  [required]="required" (blur)="OnBlur()" 
                        [appInputRestrict]="claimErrorCrrForm" formCtrl="address1" [maxLength]="maxLength">
                        <uitk-form-field-error
                        *ngIf="getFormControlValidity('address1')">
                        </uitk-form-field-error>
                    </uitk-form-field>
                    <uitk-form-field>
                    <label for="address2Field" class="tk-labl instruction-text" uitkLabel>Address Line 2 (optional)</label>
                    <input uitkInput class="input-field-wrapper" id="address2Field"
                        formControlName="address2" [required]="required" (blur)="OnBlur()"
                        [appInputRestrict]="claimErrorCrrForm" formCtrl="address2" [maxLength]="maxLength"/>
                    
                    <uitk-form-field-error
                    *ngIf="getFormControlValidity('address1')">
                    </uitk-form-field-error>
                    </uitk-form-field>
                    <uitk-form-field>
                    <label for="cityField" class="tk-labl instruction-text" uitkLabel>City<span
                            class="asterisk">*</span></label>
                    <input uitkInput class="input-field-wrapper" id="cityField" formControlName="city"
                        [required]="required" (blur)="OnBlur()" 
                        [appInputRestrict]="claimErrorCrrForm" formCtrl="city" [maxLength]="maxLengthInfo.city"/>
                        <uitk-form-field-error
                        *ngIf="getFormControlValidity('city')">
                        </uitk-form-field-error>
                    </uitk-form-field>
                    <div class="address-field-wrapper">
                        <span>
                            <uitk-form-field>
                            <label for="stateField" class="tk-labl instruction-text" uitkLabel>State<span
                                    class="asterisk">*</span></label>
                            <uitk-select class="address-field-select" formControlName="state" id="State"
                                [itemList]="listData['state']" [required]='false' [defaultLabelFlag]=false [showError]="getFormControlValidity('state')" >
                            </uitk-select>
                        </uitk-form-field>
                        </span>
                        <span>
                        <uitk-form-field>
                            <label for="zipcodefield" class="tk-labl instruction-text" uitkLabel>Zipcode<span
                                    class="asterisk">*</span></label>
                            <input uitkInput class="zipcode-field" id="zipcodefield"
                                formControlName="zipcode" [required]="required" (blur)="OnBlur()" 
                                [appInputRestrict]="claimErrorCrrForm" formCtrl="zipcode" [maxLength]="maxLengthInfo.zipcode">
                                <uitk-form-field-error
                                *ngIf="getFormControlValidity('zipcode')">
                                </uitk-form-field-error>
                        </uitk-form-field>
                        </span>
                        <span class="optional-zipcode-sep">
                            
                            <label class="separator">-</label>
                        </span>
                        <span>
                            <uitk-form-field>
                            <label class="tk-labl instruction-text" uitkLabel> &nbsp;</label>
                            <input uitkInput  id="zipcodeOptionalField"
                                formControlName="zipcodeoptional" [required]="required" placeholder="optional" (blur)="OnBlur()"
                                [appInputRestrict]="claimErrorCrrForm" formCtrl="zipcodeoptional" [maxLength]="maxLengthInfo.zipcodeoptional"/>
                            
                            </uitk-form-field>
                        </span>
                    </div>
                </form>
            </div>
            <div *ngIf="inputType === 'nameField'" class="field-width field-wrapper" [formGroup]="claimErrorCrrForm">
                <div *ngIf="isEntityTypeReq" class="provider-item entity-code-col text-ellipses select-field-wrapper">
                    <label class="form-label" [for]="ctrlMapping.entityCode">Type</label>
                    <uitk-form-field>
                    <uitk-select [formControlName]="ctrlMapping.entityCode" class="entity-select" [id]="ctrlMapping.entityCode" class="qualifier-field" [itemList]="entityTypeList"
                        [required]='false' [defaultLabelFlag]=false [showError]="getFormControlValidity(ctrlMapping.entityCode)"
                        (ngModelChange)='onEntityTypeFieldClick($event, ctrlMapping.entityCode)'>
                    </uitk-select>
                    </uitk-form-field>
                </div>
                <div class="name-col-wrap" [ngClass] ="{'person-name-col-wrap': !isEntityTypeReq}">
                    <div class="input-field fname" [hidden]="isOrgEntity">
                    <uitk-form-field>
                    <label uitkLabel class="form-label">First Name</label>
                        
                        <input  [id]="ctrlMapping.fName" uitkInput  [formControlName]="ctrlMapping.fName" [required]="required"
                            (blur)="OnBlur()" 
                            [appInputRestrict]="claimErrorCrrForm" [formCtrl]="ctrlMapping.fName"
                            [maxLength]="maxLengthInfo.fName"/>
                            <uitk-form-field-error
                            *ngIf="getFormControlValidity(ctrlMapping.fName)">
                        </uitk-form-field-error>
                    </uitk-form-field>
                    </div>
                    <div class="input-field mname" [hidden]="isOrgEntity">
                        <uitk-form-field>
                        <label class="form-label" [for]="ctrlMapping.mname" uitkLabel>MI</label>
                        <input uitkInput  [id]="ctrlMapping.mName" [formControlName]="ctrlMapping.mName" [required]="required"
                            (blur)="OnBlur()" 
                            [appInputRestrict]="claimErrorCrrForm" [formCtrl]="ctrlMapping.mName"
                            [maxLength]="maxLengthInfo.mName"/>
                            <uitk-form-field-error
                            *ngIf="getFormControlValidity(ctrlMapping.mName)">
                        </uitk-form-field-error>
                    </uitk-form-field>
                    </div>
                    <div class="input-field fname" [ngClass]="{'org-field':isOrgEntity}">
                        <uitk-form-field>
                        <label class="form-label" [for]="ctrlMapping.mname" uitkLabel>
                            {{isOrgEntity?"Organization Name":"Last Name"}}
                        </label>
                        <input uitkInput [id]="ctrlMapping.lName" [formControlName]="ctrlMapping.lName" [required]="required"
                            (blur)="OnBlur()" 
                            [appInputRestrict]="claimErrorCrrForm" [formCtrl]="ctrlMapping.lName"
                            [maxLength]="maxLengthInfo.lName">
                            <uitk-form-field-error
                            *ngIf="getFormControlValidity(ctrlMapping.lName)">
                        </uitk-form-field-error>
                        </uitk-form-field>
                    </div>
                    <div class="input-field suffix" [hidden]="isOrgEntity">
                        <uitk-form-field>
                        <label class="form-label" [for]="ctrlMapping.suffix" uitkLabel>Suffix</label>
                        <input uitkInput [id]="ctrlMapping.suffix" [formControlName]="ctrlMapping.suffix"
                            [required]="required" (blur)="OnBlur()"
                            [appInputRestrict]="claimErrorCrrForm" [formCtrl]="ctrlMapping.suffix"
                            [maxLength]="maxLengthInfo.sName"/>
                        
                        <uitk-form-field-error
                            *ngIf="getFormControlValidity(ctrlMapping.suffix)">
                        </uitk-form-field-error>
                    </uitk-form-field>
                    </div>
                </div>
            </div>
            <div *ngIf="inputType === 'contactField'" class="field-wrapper contact-wrapper" [formGroup]="claimErrorCrrForm">
                <div *ngFor="let contactType of contactInfoList;let idx = index" class="contact-item-field">
                    <div class="contact-type">
                        <uitk-form-field>
                        <label class="form-label" [for]="ctrlMapping.contact +idx" [hidden]=true uitkLabel>Type</label>
                        <uitk-select [formControlName]="ctrlMapping.contact +idx" [uitkId]="ctrlMapping.contact +idx " class="select-field-wrapper contact-field"  [itemList]="contactType.list" 
                             [defaultLabelFlag]=true [showError]="getFormControlValidity(ctrlMapping.contact+idx)"
                            (onDropdownChange)='onContactSelection($event, idx)'>
                        </uitk-select>
                    </uitk-form-field>
                    </div> 
                    <ng-container  *ngIf="contactType">
                        <div *ngIf="contactType.selected === 'TE' || contactType.selected === 'FX'" class="tel-wrapper">

                            <span class="tel-para">(</span>
                            <uitk-form-field>
                                <input class="input-field tel-field" uitkInput  [id]="ctrlMapping.telFax1 + idx"
                                [formControlName]="ctrlMapping.telFax1 + idx" (blur)="OnBlur()"
                                [appInputRestrict]="claimErrorCrrForm" [formCtrl]="ctrlMapping.telFax1 + idx" [maxLength]="maxLengthInfo.telFax">
                                <uitk-form-field-error
                                *ngIf="getFormControlValidity(ctrlMapping.telFax1 + idx)">
                                </uitk-form-field-error>
                            </uitk-form-field>
                            <span class="tel-para end">)</span>
                            <uitk-form-field>
                                <input class="input-field tel-field" uitkInput [id]="ctrlMapping.telFax2 + idx"
                                [formControlName]="ctrlMapping.telFax2 + idx" (blur)="OnBlur()" 
                                [appInputRestrict]="claimErrorCrrForm" [formCtrl]="ctrlMapping.telFax2 + idx" [maxLength]="maxLengthInfo.telFax">
                                <uitk-form-field-error
                                *ngIf="getFormControlValidity(ctrlMapping.telFax2 + idx)">
                                </uitk-form-field-error>
                            </uitk-form-field>
                            <span class="tel-hyphen">-</span>
                            <uitk-form-field>
                                <input class="input-field tel-field last-field" uitkInput [id]="ctrlMapping.telFax3 + idx"
                                [formControlName]="ctrlMapping.telFax3 + idx" (blur)="OnBlur()"
                                [appInputRestrict]="claimErrorCrrForm" [formCtrl]="ctrlMapping.telFax3 + idx" [maxLength]="maxLengthInfo.telFax3">
                                <uitk-form-field-error
                                *ngIf="getFormControlValidity(ctrlMapping.telFax3 + idx)">
                                </uitk-form-field-error>
                            </uitk-form-field>
                        </div>
                        <div *ngIf="contactType.selected === 'EM'" class="mail-wrapper">
                            <uitk-form-field>
                                <input class="input-field mail-field" uitkInput
                                [id]="ctrlMapping.email + idx"
                                [formControlName]="ctrlMapping.email + idx" (blur)="OnBlur()"
                                [appInputRestrict]="claimErrorCrrForm" [formCtrl]="ctrlMapping.email + idx" [maxLength]="maxLengthInfo.email"/> 
                                <uitk-form-field-error
                                *ngIf="getFormControlValidity(ctrlMapping.email + idx)">
                                </uitk-form-field-error>
                            </uitk-form-field>
                        </div>
                        <div *ngIf="contactType.selected === 'EX'" class="mail-wrapper tel-ext-wrapper">
                            <uitk-form-field>
                            <input class="input-field mail-field" uitkInput 
                            [id]="ctrlMapping.telExt + idx"
                            [formControlName]="ctrlMapping.telExt + idx" (blur)="OnBlur()"
                            [appInputRestrict]="claimErrorCrrForm" [formCtrl]="ctrlMapping.telExt + idx" [maxLength]="maxLengthInfo.email"> 
                            <uitk-form-field-error
                            *ngIf="getFormControlValidity(ctrlMapping.telExt + idx)">
                            </uitk-form-field-error>
                            </uitk-form-field>
                        </div>
                    </ng-container>
                </div>

            </div>

            <div class="btn-wrapper">
                <button [class]="'uitk-c-button uitk-c-button--sm uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs change-btn '+changeBtnCls" (click)="changeClick()">Apply</button>
                <button class="uitk-c-button uitk-c-button--sm uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs cancel-btn" (click)="cancelClick()">Cancel</button>
            </div>
    </div>
