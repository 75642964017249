<div *ngIf="componentLoading">
  <uitk-loading-indicator
    uitkId="fileloadLoadIndicator"
    [centerSpinner]="true"
    visibleText="Please wait..."
    spinnerSize="large"
    [displayOverlay]="true"
  >
  </uitk-loading-indicator>
</div>
<div *ngIf="componentLoading" class="empty-cnt"></div>
<div [hidden]="componentLoading" class="conainer-fluid reports-container">
  <app-claim-errors-header
    #headerComp
    [isFileLvlErrors]="isFileLvlErrors"
    [pageConfig]="pageConfig"
    [scopeData]="scopeData"
    (triggerEvent)="changeEventCallback($event)"
    [filterSelectionDetails]="filterSelectionDetails"
  ></app-claim-errors-header>
  <div class="row">
    <div class="col">
      <div
        [ngClass]="{
          'status-panel': true,
          'status-show': msgs.length > 0,
          'status-hide': msgs.length == 0
        }"
      >
        <p *ngFor="let msg of msgs">
          <i class="uitk-icon uitk-icon-checkmark_hollow sm-icon"></i>
          <span>{{ msg }}</span>
        </p>
      </div>
    </div>
  </div>
  <div class="records-count-wrap">
    <div class="text-wrap">
      <span class="records-count-text ax_default">
        {{ getPaginationDetails() }}
      </span>
      <span class="records-count-text" *ngIf="checkedRows?.length > 0">
        | {{ checkedRows?.length }} selected
      </span>
      <span class="txt-info"> Try adding filters to limit your results </span>
    </div>
  </div>
  <div class="row table-wrapper">
    <div class="col">
      <button
        [disabled]="records.length === 0"
        (click)="exportRecordsClick()"
        class="uitk-c-button uitk-c-button--sm uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
      >
        <i
          class="uitk-icon uitk-icon-document_download uitk-c-button-icon"
          hiddenText="document download"
        ></i>
        <span class="export-result-text"
          >{{ getTableExportBtnLabel(checkedRows) }}
        </span>
      </button>
      <button
        (click)="showCustomDialog()"
        class="uitk-c-button uitk-c-button--sm uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
      >
        <i
          class="uitk-icon uitk-icon-view uitk-c-button-icon"
          hiddenText="document download"
        ></i>
        <span class="show-hide-btns">Show/Hide Columns</span>
      </button>
      <uitk-pagination
        [paginationConfig]="paginationConfig"
        [entriesPerPage]="entriesPerPage"
        [enableGoToPage]="true"
        [totalRecordsCount]="dataSource?.data.length"
        (onPaginationChange)="validateAllCheckboxStatus(document)"
      >
        <div uitkTableContainer class="uitk-c-table--responsive">
          <table
            uitkTable
            [dataSource]="dataSource"
            id="basic-table"
            class="uitk-c-table"
            aria-describedby="patient-data"
          >
            <thead class="uitk-c-table__head">
              <tr
                uitkTableSort
                #sortTable="uitkTableSort"
                class="ng-tns-c99-25"
              >
                <th>
                  <input
                    id="checkbox-all"
                    name="checkbox-all"
                    type="checkbox"
                    uitkCheckbox
                    (click)="registerSelectAllCheckBoxSelectionEvent($event)"
                    [(ngModel)]="isAllCheckboxSelected"
                  />
                  <label uitkCheckboxLabel for="checkbox-all"></label>
                </th>
                <ng-container *ngFor="let column of columns; let i = index">
                  <th
                    *ngIf="!column.hideColumn"
                    uitkTableSortColumn
                    [column]="column.id"
                    (sortChange)="
                      onSortChange($event, dataSource, uitkTableSort,document)
                    "
                    [ngStyle]="{ minWidth: column?.minWidth}"
                    [ngClass]="{'hide-sort': 3 >= i}"
                  >
                    {{i > 3 ? column.label : "" }}
                  </th>
                </ng-container>
              </tr>
            </thead>
            <tbody id="patient-data" class="uitk-c-table__body">
              <ng-container *ngIf="dataSource?.data?.length === 0; else showTableData">
                <span class="text-record m-l-10">No records found.</span>
            </ng-container>
            <ng-template #showTableData>
              <ng-container *uitkTableRowBody="let rowData">
                <ng-container *uitkTableRowContext>
                  <tr
                    uitkTableExpandableRow
                    [expandableContent]="expandableContent"
                    expandableA11yText="test"
                  >
                    <td>
                      <input
                        [id]="rowData.rowIndex"
                        [name]="rowData.clmId"
                        type="checkbox"
                        uitkCheckbox
                        (click)="onRowCheckboxClick($event,rowData)"
                        [checked]="rowData.isChecked"
                        [disabled]="isRecordSubmitted(rowData) || isRecordLocked(rowData)"
                        class="row-checkbox"
                      />
                      <label
                        uitkCheckboxLabel
                        [for]="rowData.rowIndex"
                        class="row-checkox"
                      ></label>
                    </td>
                    <td [id]="getUuid(rowData)" [hidden]="true"></td>
                    <ng-container
                      *ngFor="let column of columns; let i = index"
                      scope="col"
                    >
                      <td *ngIf="!column.hideColumn" [hidden]="i === 0" [ngClass]="{'icon-td': 4 > i }">
                        
                        <span [ngClass]= "{
                          'errDescHide': column.id == 'errDescription' }" [title]=" (i==6 && (rowData[supplementalFileName]!=null)) ? rowData[supplementalFileName] :rowData[column.id]" *ngIf="i > 3">{{ rowData[column.id] }}</span>
                        <span *ngIf="i === 1">
                          <a
                            [ngClass]="{
                              'disable-edit': isIconDisable(rowData)
                            }"
                            *ngIf="
                              (rowData.isEditFlg || rowData.isMDEUIFlg) &&
                              isQuickResubmitClaim(rowData, column)
                            "
                            href=""
                            (click)="
                              onExpandIconClick($event, 'resubmit', rowData)
                            "
                            class="expand-icon-link"
                            title="Resubmit Claim"
                          >
                            <i class="uitk-icon uitk-icon-refresh sm-icon"></i>
                          </a>
                          <a
                            *ngIf="
                              (rowData.isEditFlg || rowData.isMDEUIFlg) &&
                              isRecordLocked(rowData)
                            "
                            href=""
                            (click)="onLockIconClick($event, rowData)"
                            class="expand-icon-link"
                            title="Claim is being edited by another user."
                          >
                            <i class="uitk-icon uitk-icon-lock sm-icon"></i>
                          </a>
                        </span>

                        <span *ngIf="i === 2">
                          <a
                            [ngClass]="{
                              'disable-edit': isIconDisable(rowData)
                            }"
                            *ngIf="rowData[column.id]"
                            href=""
                            (click)="onExpandIconClick($event, 'edit', rowData)"
                            class="expand-icon-link"
                          >
                            <i class="uitk-icon uitk-icon-edit sm-icon"></i>
                          </a>
                        </span>

                        <span *ngIf="i === 3">
                          <a
                            [ngClass]="{
                              'disable-edit': isIconDisable(rowData)
                            }"
                            *ngIf="rowData[column.id]"
                            href=""
                            (click)="onExpandIconClick($event, 'mde', rowData)"
                            class="expand-icon-link"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="19"
                              viewBox="0 0 41 25"
                            >
                              <g transform="translate(-4502.5 -5027.5)">
                                <path
                                  class="mde-svg"
                                  d="M2,24a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H38a2,2,0,0,1,2,2V22a2,2,0,0,1-2,2Zm0-2H38V2H2Zm24.274-6V7.411h6.369V8.863H28.009v1.9h4.312v1.447H28.009v2.338h4.8V16ZM17.53,16V7.411H20.7a6.181,6.181,0,0,1,1.635.164,2.869,2.869,0,0,1,1.294.791,3.739,3.739,0,0,1,.821,1.391,6.351,6.351,0,0,1,.281,2.03,5.631,5.631,0,0,1-.264,1.828,3.775,3.775,0,0,1-.92,1.517,3.036,3.036,0,0,1-1.219.686A5.232,5.232,0,0,1,20.794,16Zm1.734-1.447h1.3a4.764,4.764,0,0,0,1.049-.082,1.6,1.6,0,0,0,.7-.358,1.812,1.812,0,0,0,.455-.829,5.609,5.609,0,0,0,.176-1.573,5.137,5.137,0,0,0-.176-1.529,1.972,1.972,0,0,0-.492-.833,1.652,1.652,0,0,0-.8-.4,7.843,7.843,0,0,0-1.424-.082h-.779ZM14.13,16V9.239L12.425,16h-1.67l-1.7-6.762V16H7.445V7.411h2.6L11.6,13.27,13.14,7.411h2.6V16Z"
                                  transform="translate(4503 5028)"
                                  fill="#c25608"
                                />
                                <path
                                  d="M38,24.5H2A2.5,2.5,0,0,1-.5,22V2A2.5,2.5,0,0,1,2-.5H38A2.5,2.5,0,0,1,40.5,2V22A2.5,2.5,0,0,1,38,24.5ZM2,.5A1.5,1.5,0,0,0,.5,2V22A1.5,1.5,0,0,0,2,23.5H38A1.5,1.5,0,0,0,39.5,22V2A1.5,1.5,0,0,0,38,.5Zm36.5,22H1.5V1.5h37Zm-36-1h35V2.5H2.5Zm30.808-5H25.774V6.911h7.369V9.363H28.509v.9h4.312v2.447H28.509v1.338h4.8Zm-6.534-1h5.534v-.447h-4.8V11.715h4.312v-.447H27.509v-2.9h4.634V7.911H26.774Zm-5.98,1H17.03V6.911H20.7a6.579,6.579,0,0,1,1.776.184,3.377,3.377,0,0,1,1.517.927A4.252,4.252,0,0,1,24.924,9.6a6.869,6.869,0,0,1,.308,2.192,6.151,6.151,0,0,1-.291,1.991A4.281,4.281,0,0,1,23.9,15.492a3.54,3.54,0,0,1-1.414.8A5.654,5.654,0,0,1,20.794,16.5Zm-2.763-1h2.763a4.785,4.785,0,0,0,1.384-.158,2.561,2.561,0,0,0,1.021-.567,3.3,3.3,0,0,0,.8-1.322,5.169,5.169,0,0,0,.237-1.666,5.894,5.894,0,0,0-.254-1.869,3.262,3.262,0,0,0-.71-1.208,2.358,2.358,0,0,0-1.073-.656A5.766,5.766,0,0,0,20.7,7.911H18.03Zm-1.788,1H13.63V13.267L12.815,16.5h-2.45L9.556,13.28V16.5H6.945V6.911H8.556V5.2l.431,1.713h1.438l1.172,4.4,1.158-4.4H14.2l.429-1.7v1.7h1.612Zm-1.612-1h.612V7.911H14.63Zm-3.485,0h.891l1.913-7.59h-.424L11.6,15.225,9.656,7.911H9.238Zm-3.2,0h.611V7.911H7.945Zm12.615-.447h-1.8V8.363h1.279a7.984,7.984,0,0,1,1.534.094,2.144,2.144,0,0,1,1.036.528,2.471,2.471,0,0,1,.624,1.04,5.549,5.549,0,0,1,.2,1.685,6.023,6.023,0,0,1-.2,1.719,2.262,2.262,0,0,1-.6,1.054,2.1,2.1,0,0,1-.913.472A5.134,5.134,0,0,1,20.56,15.053Zm-.8-1h.8a4.166,4.166,0,0,0,.926-.067,1.106,1.106,0,0,0,.486-.244,1.32,1.32,0,0,0,.312-.6,5.166,5.166,0,0,0,.154-1.428,4.708,4.708,0,0,0-.15-1.373,1.494,1.494,0,0,0-.361-.626,1.15,1.15,0,0,0-.565-.278,7.049,7.049,0,0,0-1.318-.071h-.279Z"
                                  transform="translate(4503 5028)"
                                  fill="rgba(0,0,0,0)"
                                />
                              </g>
                            </svg>
                          </a>
                        </span>
                      </td>
                    </ng-container>
                  </tr>
                  <ng-template #expandableContent>
                    <tr
                    uitkExpandableContent
                    closeBtnText="Hide Row"
                    closeBtnA11yText="details for"
                >
                  
                    <app-clm-err-row-expand
                      [selectedRow]="rowData"
                    ></app-clm-err-row-expand>
                </tr>
                  </ng-template>
                </ng-container>
              </ng-container>
            </ng-template>
            </tbody>
          </table>
        </div>
      </uitk-pagination>
      <app-load-more-records
        *ngIf="!paginationDetails.isLastPage"
        [paginationDetails]="paginationDetails"
        (triggerEvent)="changeEventCallback($event)"
      ></app-load-more-records>
    </div>
  </div>
  <div class="mde-submit-all-wrap" *ngIf="isMDEScreen() && checkedRows.length > 0">
    <div class="mde-submit-btn-wrap">
      <div >
        <button class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base" (click)="onCancel()">Cancel</button>
        <button class="uitk-c-button uitk-c-button--default" (click)="onSubmit()">
          Resubmit MDE Claim
        </button>
      </div>
    </div>
  </div>
  <app-show-hide-pop-up
    *ngIf="showHideDialogModal.show"
    [visibleColumns]="visibleColumns"
    [dynamicTableComponent]="dynamicTableComponent"
    [showHideDialogModal]="showHideDialogModal"
    [columns]="columns"
  ></app-show-hide-pop-up>
  <app-error-crr-resubmit
    [resubmitConfig]="resubmitClmConfig"
    [selectedRow]="resubmitSelectedRow"
    [errorCategoryDetails]="errorCategoryDtls"
    (collapsePopUp)="collapsePopUp($event)"
  >
  </app-error-crr-resubmit>
</div>
