import { Component, Input, Inject, Output, EventEmitter } from '@angular/core';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';
import { Constants } from '../../data-models/constants.enum';
import { ErrorCorrectionService } from '../../services/errorCorrectionClm.service';
import { MdeService } from '../../services/tabs/mde.service';
export type ResubmitClmConfig = {
  loading: boolean,
  showPopup: boolean,
  title : string,
  workFlow:string,
  description?:string
}
@Component({
  selector: 'app-error-crr-resubmit',
  templateUrl: './error-crr-resubmit.component.html',
  styleUrls: ['./error-crr-resubmit.component.css']
})
export class ErrorCrrResubmitComponent {

  @Input() selectedRow: any;
  @Input() resubmitConfig:ResubmitClmConfig;
  @Input() errorCategoryDetails: any;
  @Output() collapsePopUp = new EventEmitter<any>();


  constructor(@Inject(SESSION_STORAGE) public sessionStorage: WebStorageService,
  private readonly errorCorrectionService : ErrorCorrectionService,
  private readonly mdeService : MdeService) { }

 
  resubmitClaim(){
    this.resubmitConfig.loading = true;
    const userDetails = this.sessionStorage.get(Constants.userPrincipal);
    const client =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.clientName);
    this.errorCorrectionService.submitCorrectedClaims(this.selectedRow, userDetails.preferred_username).subscribe((data:any) => {
      if(data.success ==="true"){
    this.mdeService.emitUpdateRecord({data:this.selectedRow,flow:Constants.errorSubmittedFlow,status:Constants.errorSubmittedStatus});
    }
    this.collapsePopUp.emit({type:"submitted",'flow': this.resubmitConfig.workFlow});
    this.resubmitConfig.showPopup = false;
    
   }, err => {
    this.resubmitConfig.showPopup = false;
    })
  }

  cancelResubmit(){
    this.collapsePopUp.emit({type:"cancel", 'flow': this.resubmitConfig.workFlow});
    this.resubmitConfig.showPopup = false;
  }

  closePopup(){
    const data = {type:"closed", flow: this.resubmitConfig.workFlow};
    this.collapsePopUp.emit(data);
    this.resubmitConfig.showPopup = false;
  }
  
  isSubmitFlow(){
    return (this.resubmitConfig.workFlow === 'submitted' || this.resubmitConfig.workFlow === 'resubmitted');
  }
 
}
