import { Component, OnInit } from '@angular/core';
import {Location} from "@angular/common"; 

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.css']
})
export class AppFooterComponent {
  externalLink = '/demos/footer/example'; 
  
  constructor(private readonly location: Location) { 
      this.externalLink = this.location.prepareExternalUrl(this.externalLink); 
  }

}
