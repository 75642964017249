
<footer role="contentinfo" class="uitk-c-footer">
    <div class="uitk-c-footer__wrapper uitk-c-footer__no-logo-wrapper">
        <!---->
        <div>
            <div class="uitk-c-footer__links-wrapper">
                <p class="uitk-c-footer__copy"> © 2023 Optum, Inc. All rights reserved. </p>
                <!---->
                <nav class="uitk-c-footer__nav">
                    <ul class="uitk-c-footer__list">
                        <li uitk-footer-item="" label="Privacy Policy" url="/privacy" targetwindow="_blank"
                            linkinfoforreader="Opens in a new window" footer-link=""><a href="javascript:void(0)"
                                class="uitk-c-footer__link"><span class="uitk-c--footer__item-label">Privacy Policy</span><span
                                    class="uitk-u-element--hidden">Opens in a new window</span>
                                <!----><sup aria-hidden="true"
                                    class="uitk-c-footer__icon--new-window uitk-icon uitk-icon-new_window"></sup>
                                <!---->
                            </a>
                            <!---->
                            <!---->
                        </li>
                        <li uitk-footer-item="" label="Terms of Use" url="/terms" targetwindow="_blank"
                            linkinfoforreader="Opens in a new window" footer-link=""><a href="javascript:void(0)"
                                class="uitk-c-footer__link"><span class="uitk-c--footer__item-label">Terms of Use</span><span
                                    class="uitk-u-element--hidden">Opens in a new window</span>
                                <!----><sup aria-hidden="true"
                                    class="uitk-c-footer__icon--new-window uitk-icon uitk-icon-new_window"></sup>
                                <!---->
                            </a>
                            <!---->
                            <!---->
                        </li>
                        
                    </ul>
                </nav>
            </div><small class="uitk-c-footer__text">
                <uitk-footer-disclaimer footer-text="">
                    <p>Your use of this product is governed by the terms of your company's
                        agreement. You may not use or disclose this product or allow others to use
                        it or disclose it, except as permitted by your agreement with
                        Optum.</p>
                </uitk-footer-disclaimer>
            </small>
        </div>
    </div>
</footer>