<div *ngIf="!!(currentUser || isSessionTimeoutPage())">
    <app-header [isSessionTimeoutPage]="isSessionTimeoutPage()" [isStaticPage]="isStaticPage()"></app-header>
</div>
<div *ngIf="!isSessionTimeoutPage()">
    <div *ngIf="!currentUser || (!isTermsAccepted && !isStaticPage())" class="empty-cnt"></div>
</div>
<div class="content main">
    <router-outlet></router-outlet>
</div>
<div *ngIf="isSessionTimeoutPage()" class="empty-cnt-50"></div>
<div *ngIf="currentUser">
    <app-terms-conditions-popup *ngIf="!isTermsAccepted && !isStaticPage()"></app-terms-conditions-popup>
</div>
<div class="app-footer-wrapper">
    <app-footer></app-footer>
</div>
<!-- <uitk-loading-indicator-container></uitk-loading-indicator-container> -->
<div *ngIf="currentUser">
    <app-session-timeout-dialog></app-session-timeout-dialog>
</div>