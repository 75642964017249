import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ErrCrrUtility, inputTypeConfig } from '../../err-crr-utility';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-service-line-info',
  templateUrl: './service-line-info.component.html',
  styleUrls: ['./service-line-info.component.css']
})
export class ServiceLineInfoComponent extends ErrCrrUtility implements OnInit {
  @Input() clmInfoData;
  @Input() selectedRow: any;
  @Input() listConfig: any;
  @Input() index :any;
  @Input() showHideConfig :any; 
  @Input() changeBtnCls :any;
  @Input() serviceLineNum:string;
  @Input() errorHighlight;
  @Output() isDataModifiedEvent = new EventEmitter<any>();
  inputTypeConfig = inputTypeConfig;
  constructor(
    public datePipe: DatePipe,
    sanitized: DomSanitizer
  ) {
    super(sanitized);
  }
  public serviceLineObj:any = {};
  public quantity;
  public quantityPattern;

  public ndcQuantity = {
    varChar: 7,
    decimal: 3
  }
  public invalidFieldStatus = {};
  public isDataModified :boolean = false;
  public readonly section='Service Line';
  public readonly labelMapping = {
    dateOfService:"Date Of Service",
    procedureCode:"Procedure Code",
    procedureCodeQual:"Procedure Code Qualifier",
    procedureCodeMod:"Procedure Code Modifier",
    serviceLineQty:"Service Line Quantity",
    ndcCode:"NDC Code",
    ndcQty:"NDC Quantity",
    serviceChgAmt:"Service Charge Amount",
    paidAmt:"Paid Amount",
    paidDate:"Paid Date"
  }
  public readonly labelMappingI={
    revenueCode:"Revenue code",
    
  }
  public readonly labelMappingP={
    placeOfService:"Place of Service",
    diagCodePointer:"Diagnosis Code Pointer",

  }

  public dateInputField;
  ngOnInit() {
    this.getClaimType();
    const claimKeyType = this.isProfessional ? 1 : 2;
    this.serviceLineObj= {
      revenueCode: 'sv2Loop2400ServiceLine.productorServiceID',
      facilityCode: 'sv1Loop2400ServiceLine.facilityCode',
      diagnosisCodePointer: 'sv1Loop2400ServiceLine.compDiagCodePoint',
      productQualifier: `sv${claimKeyType}Loop2400ServiceLine.compMedProcedID.productQualifier`,
      procedureCode : `sv${claimKeyType}Loop2400ServiceLine.compMedProcedID.productServiceId1`,
      procedureModifier: `sv${claimKeyType}Loop2400ServiceLine.compMedProcedID.procedureModifier1`,
      unitBassisMeasCode: `sv${claimKeyType}Loop2400ServiceLine.unitBassisMeasCode`,
      quantity: `sv${claimKeyType}Loop2400ServiceLine.quantity`,
      drugIdentification: 'linLoop2410DrugIdentification.loop2410DrugIdentification',
      drugQuantity: 'ctpLoop2410DrugQuantity.loop2410DrugQuantity',
      ndcUnit: 'ctpLoop2410DrugQuantity.compositeUnitofMea',
      monetaryAmount: `sv${claimKeyType}Loop2400ServiceLine.monetaryAmount`,
      paidAmount: 'loop2430LineAdjudicationInformation.0.svdLoop2430LineAdjudication.monetaryAmount',
      paidDate: 'loop2430LineAdjudicationInformation.0.dtpLoop2430DTP.dateTimePeriod'
    }
  
    if (this.isProfessional) {
      this.quantityPattern = {
        'UN': "^\\s*(?=.*[1-9])\\d{1,4}(?:\\.\\d{1})?\\s*$",
        'MJ': "^[1-9][0-9]{0,3}?$"
      };

      this.quantity = {
        "MJ": {
          varChar: 4,
          decimal: 0
        },
        "UN": {
          varChar: 4,
          decimal: 1
        }
      };

    } else if (this.isInstitutional) {
      this.quantity = {
        varChar: 6,
        decimal: 1
      };
    }
    this.mapSvcInfoErrorHighlightData();

  }

  
  collapseEditor(event) {
    if (event.type === 'change') {
      if (event.isInvalid) {
        this.invalidFieldStatus[event.field] = true;
        return
      }
      this.isDataModified = true;
      this.isDataModifiedEvent.emit(this.isDataModified);
      this.updateClmData(event);
    }
    if (event.type === 'validation') {
      this.invalidFieldStatus[event.field] = event.isInvalid;
      return
    }
    this.invalidFieldStatus[event.field] = false;
    this.showHideConfig[event.field] = false;
  }

  updateClmData(event){
    if (event.key === 'dateOfService') {
      this.collapseDateOfService(event);
    }
    else if (event.key === 'procedureModifier') {
     this.collapseModifierField(event);
    }
    else if (event.key === 'ndcUnits') {
      this.collapseUnitField(event);
    }
    else if(event.key ==='unitBassisMeasCode'){
      this.collapseServiceUnitField(event);
    }
    else {
      if (event.inputType === inputTypeConfig.date) {
        event.value = this.encodeDateFormat(event.value);
      }
      const isReqField = event.field === "adjPaymentDt";
      this.updateClmAttribute(this.clmInfoData, event.key, event.value, isReqField);
    }
  }

  collapseDateOfService(event){
    if (event.inputType === inputTypeConfig.date) {
      event.value = this.encodeDateFormat(event.value);
      const dateOfService = this.getDateOfService(true);
      dateOfService.dateTimePeriod = event.value;
    }else{
      const dateOfService = this.getDateOfService(true);
     const fromThroughDate = event.value.toString().split("-");
      if(fromThroughDate.length > 1){
       dateOfService.dateTimeFormatQual='RD8'; 
      }else{  
        dateOfService.dateTimeFormatQual='D8';
      }
      dateOfService.dateTimePeriod = event.value;
    }
  }
  collapseModifierField(event){
    const modifier = event.value.toString().split(",");
    const claimKeyType = this.isProfessional ? 1 : 2;
    if (this.findParentObjNode(this.clmInfoData, [`sv${claimKeyType}Loop2400ServiceLine`, 'compMedProcedID', ''])) {
      for (let i = 1; i <= 4; i++) {
        if (i <= modifier.length){
          this.updateClmAttribute(this.clmInfoData, `sv${claimKeyType}Loop2400ServiceLine.compMedProcedID.procedureModifier${i}`, modifier[i-1], false);
        } else if(this.clmInfoData[`sv${claimKeyType}Loop2400ServiceLine`].compMedProcedID.hasOwnProperty(`procedureModifier${i}`)){
          this.updateClmAttribute(this.clmInfoData, `sv${claimKeyType}Loop2400ServiceLine.compMedProcedID.procedureModifier${i}`, "", false);
        }
      }
    }
  }

  collapseUnitField(event){
    const unitData = event.value.toString().split(" ");
        const quantity = unitData[0];
        const unit = unitData.length > 1 ? unitData[1] : '';
        this.updateClmAttribute(this.clmInfoData, 'ctpLoop2410DrugQuantity.loop2410DrugQuantity', quantity, false);
        this.updateClmAttribute(this.clmInfoData, 'ctpLoop2410DrugQuantity.compositeUnitofMea', unit, false);
  }

  collapseServiceUnitField(event){
    const claimKeyType = this.isProfessional ? 1 : 2;
    const unitData = event.value.toString().split(" ");
        const quantity = unitData[0];
        const unit = unitData.length > 1 ? unitData[1] : '';
        this.updateClmAttribute(this.clmInfoData, `sv${claimKeyType}Loop2400ServiceLine.quantity`, quantity, false);
        this.updateClmAttribute(this.clmInfoData,  `sv${claimKeyType}Loop2400ServiceLine.unitBassisMeasCode`, unit, false);
  }

  isDateOfServiceValid() {
    const payerCtrlObj = this.getDateOfService(true);
    return payerCtrlObj && payerCtrlObj.hasOwnProperty("dateTimePeriod");
  }

  getDateOfService(attrValidation: boolean = false) {
    let payerContrlObj;
    if (this.clmInfoData && this.clmInfoData.dtpLoop2400DTP) {
      this.clmInfoData.dtpLoop2400DTP.forEach(element => {
        if (element['dateTimeQualifier'] === "472") {
          payerContrlObj = element;
        }
      });
      if (attrValidation) {
        return payerContrlObj;
      } else {
        if (payerContrlObj.dateTimeFormatQual === "RD8") {
          this.dateInputField = "fromThroughField";
          let date = this.getDate(payerContrlObj, false);
          date = date + ' - ' + this.getDate(payerContrlObj, true);
          return date;
        }
        if (payerContrlObj.dateTimeFormatQual === "D8") {
          this.dateInputField = "dateField";
          const date = this.getDate(payerContrlObj, false);
          return date;
        }
      }    
    }
  }

  getDate(claim, isThroughDate) {
    let date = "";
    const dateIndex = isThroughDate ? 1 : 0;
    if (claim.dateTimePeriod) {
      const dateList = claim.dateTimePeriod.split("-");
      date = dateList.length > dateIndex ? dateList[dateIndex] : "";
    }
    return this.decodeDateFormat(date);
  }

  getQtyUnitFieldData(quantity, unit) {
    const unitCode = this.getFieldValue(this.clmInfoData, unit);
    return this.getFieldValue(this.clmInfoData, quantity) + " " + unitCode;
  }


  procedureModifier(attrValidation: boolean = false) {
    const claimKeyType = this.isProfessional ? 1 : 2;
    let isApplicable: boolean = false;
    let procedureModifier = "";
    if (this.findParentObjNode(this.clmInfoData, [`sv${claimKeyType}Loop2400ServiceLine`, 'compMedProcedID', ''])) {
      for (let i = 1; i <= 4; i++) {
        if (this.clmInfoData[`sv${claimKeyType}Loop2400ServiceLine`].compMedProcedID.hasOwnProperty(`procedureModifier${i}`)) {
          const procedureModifierItem = this.clmInfoData[`sv${claimKeyType}Loop2400ServiceLine`].compMedProcedID[`procedureModifier${i}`];
          isApplicable = true;
          procedureModifier = procedureModifier + (procedureModifier !== "" && procedureModifierItem !=="" ? ':' : '') + procedureModifierItem
        }
      }
      if (attrValidation) {
        return isApplicable;
      } else {

        return procedureModifier;
      }
    }

  }

  getPaidDate(data, key) {
    const paidDate = this.getFieldValue(data, key);
    return this.decodeDateFormat(paidDate);
  }
  getDiagnosisCodePointer(data, key) {
    const diagCodPointer = this.getFieldValue(data, key);
    const diagCode=diagCodPointer.replace(/,/g, ':');
    return diagCode;
  }
  mapSvcInfoErrorHighlightData(){
    let fields; 
    if(this.isInstitutional){
      fields= {...this.labelMapping,...this.labelMappingI}
    }
    if(this.isProfessional){
      fields= {...this.labelMapping,...this.labelMappingP}
    }
    for(const key in fields){
      this.updateMatchErrorHightlightObj(this.section,fields[key]);
    }
    this.updateUnmatchErrorHighlightObj(this.section);
  }
 
}