import { AfterContentInit, Component, Inject, Input, OnInit } from '@angular/core';
import { Constants } from 'src/app/data-models/constants.enum';
import { SummaryService } from 'src/app/services/tabs/summary.service';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';


@Component({
  selector: 'app-crr-record-errors',
  templateUrl: './crr-record-errors.component.html',
  styleUrls: ['./crr-record-errors.component.css']
})
export class CrrRecordErrorsComponent implements OnInit {

  @Input()
  public data: any;
  
  agg:any;
  ColorBarTotal:any;
  ColorBarES_Count:any;
  ColorBarCR_Count:any;
  ColorBarIOA_Count:any;

  constructor(private summaryService: SummaryService,
    @Inject(SESSION_STORAGE) private sessionStorage: WebStorageService) { }

  ngOnInit() {
    const partySk = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.clientName).value;
    const isChildDataIncluded = this.sessionStorage.get(Constants.includeChildData);
    const year_val = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod).value;
    const startDate = this.sessionStorage.get(Constants.fromDate);
    const endDate = this.sessionStorage.get(Constants.toDate);
    
    this.summaryService.getCrrErrorRecordsTabStats(partySk,isChildDataIncluded,year_val,startDate,endDate).subscribe(data => {
      this.agg = data[0];
      this.initializeData(this.agg);
    });
   

  }
  exportData(event){
    event.preventDefault();
  }
  initializeData(agg:any){
    let sourceObject = (this.agg?.sourceInfo||[]).reduce((result,item)=>{let tempobj={};
    tempobj[item.sourceName]=item.errCodeSourceCnt;
    return {...result,...tempobj}},{})
    this.ColorBarTotal = (sourceObject["ES"]||0)+(sourceObject["CR"]||0)+(sourceObject["IOA"]||0); 
    this.ColorBarES_Count = (sourceObject["ES"]||0);
    this.ColorBarCR_Count = (sourceObject["CR"]||0);
    this.ColorBarIOA_Count = (sourceObject["IOA"]||0);
}
}
