<div class="row margin-top-11">
    <div class="col-md-6 padding-left-3"*ngIf="clmDtCriteriaIdClone==0">
        <div><span class="chart-title">File Load Activity</span><span class="margin-left-15"><a [routerLink]="['/reports/fileload']">View Details</a></span></div>
    </div>
    <div class="col-md-6 padding-left-3" *ngIf="clmDtCriteriaIdClone==1">
        <div><span class="chart-title">Claim Activity</span></div>
    </div>
    <div class="col-md-6 padding-left-19" *ngIf="clmDtCriteriaIdClone==0">
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="rd_1" name="rd" class="custom-control-input" disabled>
            <label class="custom-control-label red" for="rd_1">Files Received</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="rd_2" name="rd" class="custom-control-input" disabled>
            <label class="custom-control-label red" for="rd_2">Files Completed</label>
        </div>
    </div>
</div>
<div class="graph-wrapper clearfix">
    <ng-container *ngIf="clmDtCriteriaIdClone==1">
        <line-chart [hidden]="isBarChartVissible()" [isHidden]="isBarChartVissible()"></line-chart>
        <app-bar-charts [scopeData]="data" [hidden]="!isBarChartVissible()" [isHidden]="!isBarChartVissible()"></app-bar-charts>
    </ng-container>
    <app-stacked-bar-charts  *ngIf="clmDtCriteriaIdClone==0" [scopeData]="data"></app-stacked-bar-charts>
</div>
<app-graph-switch-frequency [scopeProps]="data"></app-graph-switch-frequency>
<div class="row" *ngIf="clmDtCriteriaIdClone==0">
    <div class="col-md-3 padding-left-3" *ngFor="let agg of aggData">
        <div><span>{{agg.name}}</span></div>
        <div class="count-title"> <span>{{agg.value}}</span></div>
        <div class="border-top-title"></div>
    </div>
</div>
<div class="row" *ngIf="clmDtCriteriaIdClone==1">
    <div class="col-md-3 padding-left-3" *ngFor="let agg of aggData">
        <div><span>{{agg.name}}</span></div>
        <div class="count-title"> <span>{{agg.value}}</span></div>
        <div class="border-top-title"></div>
    </div>
</div>