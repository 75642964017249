import {Component, NgModule, Inject } from '@angular/core';
import { single } from '../piechartData';
import { ClientService } from '../../services/client.service';
import { UserPrincipal } from '../../services/auth/userPrincipal.model'
import { SelectItem } from '../../data-models/select.item';
import { Constants } from '../../data-models/constants.enum';
import { HttpClient } from '@angular/common/http';
import { ReportsService } from 'src/app/services/reports.service';
import { ErrorSummaryCriteria } from 'src/app/data-models/error.summarycriteria';
import { Router } from '@angular/router';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';


@Component({
    selector:'pie-chart',
    templateUrl:'./pieCharts.component.html',
    styleUrls: ['./pieCharts.component.css']
})

export class PieChartComponent{

  public componentLoading: boolean = false;
  private readonly clientNames: any[] = [];
  private clientName: any;
  private readonly timePeriods: any[] =[];
  private timePeriod: any;
  private readonly dateCriteriaItems: any[] = [];
  private readonly monthCriteriaItems: any[] = [];
  private readonly yearCriteriaItems: any[]=[];
  private dateCriteria:any;
  startMonthCriteria:any;
  startYearCriteria:any;
  endMonthCriteria:any;
  endYearCriteria:any;
  single : any[];
  view: any[] = [700, 300];

  isDataFound: boolean = true;

  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = false;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';
  

    colorScheme = {
        domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
      };

      constructor(private readonly clientService: ClientService,private readonly reportService: ReportsService,
        private readonly router: Router, private readonly http: HttpClient,  @Inject(SESSION_STORAGE) public sessionStorage: WebStorageService) {
        Object.assign(this, { single });
        this.timePeriods = [
          { id: 1, label: Constants.lastMonthTillDate, value: Constants.lastMonthTillDate },
          { id: 2, label: Constants.last3MonthsTillDate, value: Constants.last3MonthsTillDate },
          { id: 3, label: Constants.last6MonthsTillDate, value: Constants.last6MonthsTillDate },
          { id: 4, label: Constants.last12MonthsTillDate, value: Constants.last12MonthsTillDate },
          { id: 5, label: Constants.OpenSubmissionPeriod, value: Constants.OpenSubmissionPeriod },
          { id: 6, label: Constants.dateRange, value: Constants.dateRange }
          ];
          
        this.dateCriteriaItems=[
          {
            label: Constants.claimLoadDateLabel,
            value: Constants.claimLoadDateValue,
            disabled: false,
            checked: true,
          },
          {
            label: Constants.claimServiceDateLabel,
            value: Constants.claimServiceDateValue,
            disabled: false,
            checked: false,
        }
        ];
        this.monthCriteriaItems = [
          { id: 1, label: 'Jan', value: 1 },
          { id: 2, label: 'Feb', value: 2 },
          { id: 3, label: 'Mar', value: 3 },
          { id: 4, label: 'Apr', value: 4 },
          { id: 5, label: 'May', value: 5 },
          { id: 6, label: 'Jun', value: 6 },
          { id: 7, label: 'Jul', value: 7 },
          { id: 8, label: 'Aug', value: 8 },
          { id: 9, label: 'Sep', value: 9 },
          { id: 10, label: 'Oct', value: 10 },
          { id: 11, label: 'Nov', value: 11 },
          { id: 12, label: 'Dec', value: 12 },
          ]; 
        this.yearCriteriaItems = [
          { id: 1, label: '2018', value: 2018 },
          { id: 2, label: '2019', value: 2019 },
          { id: 3, label: '2020', value: 2020 },
          { id: 4, label: '2021', value: 2021 },
          ]; 
      }

      ngOnInit() {
        const userPrincipal: UserPrincipal = JSON.parse(sessionStorage.getItem('userPrincipal'));
        console.log("**** Home user principal ****", userPrincipal);
        this.timePeriod = this.timePeriods[0];
        this.dateCriteria=Constants.claimLoadDateValue;
        if (userPrincipal != null && userPrincipal.active) {
          this.clientService.getClients(userPrincipal.preferred_username).subscribe(data => {
            for (let index = 0; index < data.length; index++) {
              const element = data[index];
              const selectItem: SelectItem = new SelectItem();
              selectItem.id = element["dClntSk"];
              selectItem.label = element["clntNm"];
              selectItem.value = element["prtySk"];
              this.clientNames.push(selectItem);
            }
            if (this.clientNames.length > 0) {
              this.clientName = this.clientNames[0];
              this.sessionStorage.set(Constants.errorClient, this.clientName.label);
              this.sessionStorage.set(Constants.partySk,this.clientName.value);
              } 
              this.gotoDetails();
          });
         
        }
        console.log("isDataFound == "+this.isDataFound)
      }

      onSelect(data): void {
        let respCtgCriteriaId = 0;
        if("Compliance Errors" === data.name){
          respCtgCriteriaId = 1;
        }else if ("Content Errors" === data.name){
          respCtgCriteriaId = 2;
        }else if ("999 Errors" === data.name){
          respCtgCriteriaId = 3;
        }else if ("277 Errors" === data.name){
          respCtgCriteriaId = 4;
        }else if ("MAO Errors" === data.name){
          respCtgCriteriaId = 5;
        }
        this.sessionStorage.set(Constants.partySk,this.clientName.value);
        this.sessionStorage.set(Constants.clmDtCriteriaId, Constants.claimLoadDateValue === this.dateCriteria ? 0 : 1);
        this.sessionStorage.set(Constants.timeCriteriaId, this.timePeriod.id);
        this.sessionStorage.set(Constants.errorClient, this.clientName.label);
        this.sessionStorage.set(Constants.respCtgCriteriaId, respCtgCriteriaId);
        this.sessionStorage.set(Constants.timePeriodValue, this.timePeriod.value);
        if(this.timePeriod.value === Constants.dateRange){
          this.sessionStorage.set(Constants.dtStartMonth, this.startMonthCriteria.value);
          this.sessionStorage.set(Constants.dtStartYear, this.startYearCriteria.value);
          this.sessionStorage.set(Constants.dtEndMonth, this.endMonthCriteria.value);
          this.sessionStorage.set(Constants.dtEndYear, this.endYearCriteria.value);
        }
        this.router.navigateByUrl('/home');
      }
    
      onActivate(data): void {
        console.log('Activate', JSON.parse(JSON.stringify(data)));
      }
    
      onDeactivate(data): void {
        console.log('Deactivate', JSON.parse(JSON.stringify(data)));
      }

      isInvalidValidClient() {
        return this.clientNames.length === 0;
      }

      isDateRangeDisabled() {
        return this.timePeriod.value!== Constants.dateRange;
      }

      isApplyButtonDisabled() {
        let isValid = false;
        if(this.timePeriod.value === Constants.dateRange){
          isValid = !(this.startMonthCriteria !== undefined && this.startMonthCriteria.id > 0 );
          if (!isValid){
            return isValid;
          }
          isValid = !(this.startYearCriteria !== undefined && this.startYearCriteria.id > 0 );
          if (!isValid){
            return isValid;
          }
          isValid = !(this.endMonthCriteria !== undefined && this.endMonthCriteria.id > 0 );
          if (!isValid){
            return isValid;
          }
          isValid = !(this.endYearCriteria !== undefined && this.endYearCriteria.id > 0);
        }
        return  isValid;
      }

      gotoDetails(){
        console.log("entered  into goto details",this.timePeriod.value,this.clientName.id,this.dateCriteria)
        if(this.timePeriod.value != null && this.clientName.id != null && this.dateCriteria != null){
          this.sessionStorage.set(Constants.errorClient, this.clientName.label);
          this.sessionStorage.set(Constants.partySk,this.clientName.value);

          const  errorCriteria :ErrorSummaryCriteria = new ErrorSummaryCriteria(); 
          errorCriteria.partySK = this.clientName.value;
          errorCriteria.clmDtCriteriaId = Constants.claimLoadDateValue === this.dateCriteria ? 0 : 1;
          errorCriteria.timeCriteriaId = this.timePeriod.id;
          this.sessionStorage.set('TimeCriteriaValue',this.timePeriod.value);
          if(this.timePeriod.value === Constants.dateRange){
            errorCriteria.startMonth = this.startMonthCriteria.value;
            errorCriteria.startYear = this.startYearCriteria.value;
            errorCriteria.endMonth = this.endMonthCriteria.value;
            errorCriteria.endYear = this.endYearCriteria.value;
            this.sessionStorage.set('startDate',this.startMonthCriteria.label+"/"+this.startYearCriteria.label);
            this.sessionStorage.set('endDate',this.endMonthCriteria.label+"/"+this.endYearCriteria.label);
          }
          console.log(errorCriteria)
          this.reportService.getErrorCountsByCategory(errorCriteria.partySK, errorCriteria.clmDtCriteriaId, errorCriteria.timeCriteriaId,
            errorCriteria.startMonth, errorCriteria.startYear, errorCriteria.endMonth, errorCriteria.endYear).subscribe( (data)=>{
            console.log("data from == "+data)
            if(data === null || data.length === 0){
              this.isDataFound = false;
              this.componentLoading = false;
            }else{
              this.single = data;
              this.isDataFound = true;
              this.componentLoading = false;
            }
            console.log("From Apply isDataFound == "+this.isDataFound)
          });
        }
      }

      changeTimePeriod(model) {
        this.timePeriod = model;
        console.log('time period selected is '+this.timePeriod.value);
        if(this.timePeriod.value!==Constants.dateRange){
        this.startMonthCriteria='';
        this.startYearCriteria='';
        this.endMonthCriteria='';
        this.endYearCriteria='';
        }
      }

      getvalidationFlag(validateFlag) {
        console.log(validateFlag);
      }

      onDtCriteriaChange(event) {
        console.log(event.value);
        this.dateCriteria = event.value;
        this.sessionStorage.set(Constants.dateCriteria, event.label);
      }

}