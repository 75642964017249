<div class="overlay" [hidden]="!pageloaderConfig.show">
    <div class="uitk-l-grid">
        <div class="uitk-l-grid__col">
            <div class="uitk-l-grid__row">
                <div class="uitk-l-grid__col--12  uitk-l-grid__col--m-12 uitk-l-grid__col--lg-12">
                    <div class="uitk-u-margin-left--sm">
                        <div [ngStyle]="{ width: '100%', height: '500px', position: 'relative' }">
                            <uitk-loading-indicator
                                uitkId="my-spinner-1"
                                [visibleText]="visibleTextValue"
                                spinnerSize="large"
                                [displayOverlay]="true"
                            >
                            </uitk-loading-indicator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>