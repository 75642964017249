import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ErrCrrUtility, patterns } from '../../err-crr-utility';

@Component({
  selector: 'app-err-crr-service-provider',
  templateUrl: './err-crr-service-provider.component.html',
  styleUrls: ['./err-crr-service-provider.component.css']
})
export class ErrCrrServiceProviderComponent extends ErrCrrUtility implements OnInit {
  @Output() public collapseServiceProviderFields = new EventEmitter<any>();
  @Input() inputLabel;
  @Input() inputValue;
  @Input() listData: any;
  @Input() changeBtnCls: string;
  @Input() isInstitutional: boolean;
  @Input() entityTypeList: any = [];
  @Input() entityId: any = {};
  public serviceProviderForm;
  private readonly entityTypeJsonKey = "loop2420NM1.entityIDCode";
  public readonly maxLengthInfo = {
    fName: '35',
    mName: '25',
    lName: '60',
    sName: '10',
    city: '30',
    zipcode: '5',
    zipcodeoptional: '4',
    taxonomy: "10",
    npi: "10"
  };
  maxLength = "55";
  pattern = "";

  public readonly ctrlMapping = {
    fName: "fName",
    lName: "lName",
    mName: "mName",
    suffix: "suffix",
    address1: "address1",
    city: "city",
    zipcode: "zipcode",
    address2: "address2",
    zipcodeOpt: "zipcodeoptional",
    state: "state",
    entityCode: "entityCode",
    npi: "npi",
    taxonomy: "taxonomy"
  }
  isOrgEntity: boolean;
  viewLoaded: boolean;

  constructor(sanitized: DomSanitizer) {
    super(sanitized)
  }

  ngOnInit() {
    this.isOrgEntity = (this.inputValue.loop2420NM1.entityTypeQualifier === "2");
    this.initializeForm();
  }

  ngAfterViewInit() {
    this.viewLoaded = true;
  }

  initializeForm() {
    const formCtrls: any = {
      [this.ctrlMapping.entityCode]: new UntypedFormControl('', [this.getValidators("", "", true)])
    }
    if (this.isNameNPIApplicable()) {
      formCtrls[this.ctrlMapping.npi] = new UntypedFormControl(this.inputValue.loop2420NM1.idCode, [this.getValidators(this.maxLengthInfo.npi,patterns.NUM_START_1, true,this.maxLengthInfo.npi)]);
      formCtrls["name"] = new UntypedFormGroup({
        [this.ctrlMapping.fName]: new UntypedFormControl(this.getFieldValue(this.inputValue, "loop2420NM1.nameFirst"), [this.getValidators(this.maxLengthInfo.fName, patterns.NAME, false)]),
        [this.ctrlMapping.mName]: new UntypedFormControl(this.getFieldValue(this.inputValue, "loop2420NM1.nameMiddle"), [this.getValidators(this.maxLengthInfo.mName, patterns.NAME, false)]),
        [this.ctrlMapping.lName]: new UntypedFormControl(this.getFieldValue(this.inputValue, "loop2420NM1.nameOrgName"), [this.getValidators(this.maxLengthInfo.lName, patterns.NAME, true)]),
        [this.ctrlMapping.suffix]: new UntypedFormControl(this.getFieldValue(this.inputValue, "loop2420NM1.nameSuffix"), [this.getValidators(this.maxLengthInfo.sName, patterns.AlPHA, false)])
      });
    }
    if (this.isTaxonomyApplicable()) {
      formCtrls[this.ctrlMapping.taxonomy] = new UntypedFormControl(this.inputValue.loop2420PRV.referenceIdent, [this.getValidators(this.maxLengthInfo.taxonomy, patterns.AN, true, this.maxLengthInfo.taxonomy)]);
    }
    if (this.isAddrApplicable()) {
      formCtrls["address"] = new UntypedFormGroup({
        [this.ctrlMapping.address1]: new UntypedFormControl(this.getFieldValue(this.inputValue, "loop2420ServiceFacilityddress.addressInformation1"),
          [this.getValidators(this.maxLength, patterns.ADD, true)]),

        [this.ctrlMapping.address2]: new UntypedFormControl(this.getFieldValue(this.inputValue, "loop2420ServiceFacilityddress.addressInformation2"),
          [this.getValidators(this.maxLength, patterns.ADD)]),
        [this.ctrlMapping.city]: new UntypedFormControl(this.getFieldValue(this.inputValue, "loop2420ServiceFacilityCityStateZipCode.cityName"),
          [this.getValidators(this.maxLengthInfo.city, patterns.ADD, true)]),
        [this.ctrlMapping.zipcode]: new UntypedFormControl("",
          [this.getValidators(this.maxLengthInfo.zipcode, '[0-9]+', true, '5')]),
        [this.ctrlMapping.zipcodeOpt]: new UntypedFormControl("",
          [this.getValidators(this.maxLengthInfo.zipcodeoptional, '[0-9]+', false, '4')]),
        [this.ctrlMapping.state]: new UntypedFormControl("", [this.getValidators("", "", true)])
      });
    }
    this.serviceProviderForm = new UntypedFormGroup(formCtrls);
    this.initFormData()
  }

  isTaxonomyApplicable() {
    return !this.isInstitutional && this.getFieldValue(this.inputValue, this.entityTypeJsonKey) === "82";
  }
  isNameNPIApplicable() {
    const entityID = this.getFieldValue(this.inputValue, this.entityTypeJsonKey)
    return !(entityID === "45" || entityID === "PW");
  }
  isAddrApplicable() {
    const entityIds = ["45", "PW", "DK", "77"];
    return !this.isInstitutional && entityIds.indexOf(this.getFieldValue(this.inputValue, this.entityTypeJsonKey)) !== -1;
  }

  getZipCode() {
    const zipValue = this.getFieldValue(this.inputValue, "loop2420ServiceFacilityCityStateZipCode.postalCode") || "";
    const splitZipcode = zipValue.match(/.{1,5}/g);
    return {
      zipcode: (splitZipcode && splitZipcode.length > 0) ? splitZipcode[0] : "",
      zipcodeOptional: (splitZipcode && splitZipcode.length > 1) ? splitZipcode[1] : ""
    }
  }
  initFormData() {
    this.entityTypeList.forEach((element, index) => {
      element.id = index;
      if (this.inputValue.loop2420NM1.entityTypeQualifier === element.value) {
        this.serviceProviderForm.controls.entityCode.setValue(element);
      }
    })
    if (this.isAddrApplicable()) {
      const { zipcode, zipcodeOptional } = this.getZipCode();
      this.serviceProviderForm.get("address").get("zipcode").setValue(zipcode);
      this.serviceProviderForm.get("address").get("zipcodeoptional").setValue(zipcodeOptional);
      const state = this.getFieldValue(this.inputValue, "loop2420ServiceFacilityCityStateZipCode.stateorProvCode");
      this.listData['state'].forEach((element, index) => {
        if (state === element.label) {
          this.serviceProviderForm.get("address").get("state").setValue(this.listData['state'][index]);
        }
      });
    }
  }

  getFormControlValidity(...frmControlNm) {
    let field;
    for (const ctrl in frmControlNm) {
      field = field ? field.get(frmControlNm[ctrl]) : this.serviceProviderForm.get(frmControlNm[ctrl]);
    }
    const isInvalid = field.status === "INVALID";
    if (isInvalid && !this.viewLoaded) {
      this.validate();
    }
    return isInvalid;
  }
  validate() {
    const event = {
      type: 'validation',
      isInvalid: this.serviceProviderForm.status === "INVALID",
      field: this.inputLabel
    }
    this.validateFields(event);
    this.collapseServiceProviderFields.emit(event);
  }

  cancelClick() {
    const event = {
      type: 'cancel',
      field: this.inputLabel
    }
    this.collapseServiceProviderFields.emit(event);
  }

  getValidators(maxLength, pattern, required?, minLength?) {
    const validators: ValidatorFn[] = [];
    if (required) {
      validators.push(Validators.required);
    }
    if (minLength !== undefined) {
      validators.push(Validators.minLength(minLength));
    }
    validators.push(Validators.maxLength(maxLength));
    validators.push(Validators.pattern(pattern));
    return Validators.compose(validators);
  }
  mousedownEvent(event) {
    event.preventDefault();
  }

  onDropdownChangeCallbck(event, formCtrl) {
    if ((this.serviceProviderForm.get(this.ctrlMapping.entityCode).value).value === "2") {
      this.serviceProviderForm.get("name").get(this.ctrlMapping.fName).setValue("");
      this.serviceProviderForm.get("name").get(this.ctrlMapping.mName).setValue("");
      this.serviceProviderForm.get("name").get(this.ctrlMapping.suffix).setValue("");
      this.isOrgEntity = true;
    } else {
      this.isOrgEntity = false;
    }
  }

  changeClick() {
    const event = {
      type: 'change',
      value: this.inputValue,
      field: this.inputLabel,
      isInvalid: false
    }
    event.isInvalid = this.serviceProviderForm.status === "INVALID";
    if (!event.isInvalid) {
      const entityType = (this.serviceProviderForm.get(this.ctrlMapping.entityCode).value).value;
      this.inputValue.loop2420NM1.entityTypeQualifier = entityType;
      if (this.isTaxonomyApplicable()) {
        this.inputValue.loop2420PRV.referenceIdent = this.serviceProviderForm.controls[this.ctrlMapping.taxonomy].value;
      }
      if (this.isNameNPIApplicable()) {
        this.inputValue.loop2420NM1.nameOrgName = this.serviceProviderForm.get("name").get(this.ctrlMapping.lName).value;
        this.inputValue.loop2420NM1.nameFirst = this.serviceProviderForm.get("name").get(this.ctrlMapping.fName).value;
        this.inputValue.loop2420NM1.nameMiddle = this.serviceProviderForm.get("name").get(this.ctrlMapping.mName).value;
        this.inputValue.loop2420NM1.nameSuffix = this.serviceProviderForm.get("name").get(this.ctrlMapping.suffix).value;
        this.inputValue.loop2420NM1.idCode = this.serviceProviderForm.controls[this.ctrlMapping.npi].value;
      }
      if (this.isAddrApplicable()) {
        this.inputValue.loop2420ServiceFacilityddress.addressInformation1 = this.serviceProviderForm.get("address").get('address1').value;
        this.inputValue.loop2420ServiceFacilityddress.addressInformation2 = this.serviceProviderForm.get("address").get('address2').value;
        this.inputValue.loop2420ServiceFacilityCityStateZipCode.cityName = this.serviceProviderForm.get("address").get('city').value;
        this.inputValue.loop2420ServiceFacilityCityStateZipCode.stateorProvCode = (this.serviceProviderForm.get("address").get('state').value).value;
        this.inputValue.loop2420ServiceFacilityCityStateZipCode.postalCode = (this.serviceProviderForm.get("address").get('zipcode').value +
          this.serviceProviderForm.get("address").get('zipcodeoptional').value);
      }
    } else {
      this.validateFields(event);
    }
    this.collapseServiceProviderFields.emit(event);
  }
  validateFields(event) {
    if (this.isAddrApplicable()) {
      event['address'] = this.serviceProviderForm.get("address").status === 'INVALID';
    }
    event[this.ctrlMapping.entityCode] = this.serviceProviderForm.controls[this.ctrlMapping.entityCode].status === 'INVALID';
    if (this.isTaxonomyApplicable()) {
      event[this.ctrlMapping.taxonomy] = this.serviceProviderForm.controls[this.ctrlMapping.taxonomy].status === 'INVALID';
    }
    if (this.isNameNPIApplicable()) {
      event[this.ctrlMapping.npi] = this.serviceProviderForm.controls[this.ctrlMapping.npi].status === 'INVALID';
      event['name'] = this.serviceProviderForm.get("name").status === 'INVALID';
    }
  }

}
