import { Component, OnInit, Input, ViewChild, AbstractType, Inject } from '@angular/core';
import { LineChartComponent } from '../../../charts/lineCharts/lineCharts.component';
import { BarChartsComponent } from '../../../charts/bar-charts/bar-charts.component';
import { SummaryService } from 'src/app/services/tabs/summary.service';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { Constants } from '../../../data-models/constants.enum';
import { Tab } from 'src/app/data-models/tab.stats';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';

@Component({
  selector: 'app-closed-tab',
  templateUrl: './closed.component.html',
  styleUrls: ['./closed.component.css']
})
export class ClosedComponent implements OnInit {

  @Input()
  public data: any;
 
  @ViewChild(LineChartComponent)
  public lineChart: LineChartComponent;

  @ViewChild(BarChartsComponent)
  public barChart: BarChartsComponent;

  private graphSeriesId : number[];

  tabs: Tab[] = [];
  
  private aggData:any;
  constructor(private readonly summaryService: SummaryService,
    @Inject(SESSION_STORAGE) private readonly sessionStorage: WebStorageService) { }

  ngOnInit() {
    const prtySk =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.clientName)?.value;
    const isChildDataIncluded = this.sessionStorage.get(Constants.includeChildData);
    const clmDtCriteriaId =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.viewingBy)?.id;
    const timeCriteriaId =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod)?.id;
    const startDate = this.sessionStorage.get(Constants.fromDate);
    const endDate = this.sessionStorage.get(Constants.toDate);
    this.sessionStorage.set(Constants.yAxisLabel, JSON.stringify("Number of Claims"));
    this.sessionStorage.set(Constants.seriesName, JSON.stringify("Replaced"));
    this.graphSeriesId = [9];
    this.sessionStorage.set(Constants.graphSeriesId, this.graphSeriesId);
    this.sessionStorage.set(Constants.tabsSelectedIndex, 5);
    const tabId = 6;
    this.summaryService.getClosedSummaryStats(prtySk, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,tabId).subscribe(data => {
      this.tabs = data;
       
    });
    this.data["frequencyChange"] = this.reloadGraph.bind(this);
  }

  isBarChartVissible(){
    const freqType = this.sessionStorage.get(Constants.graphViewByType);
    return this.data.timePeriod === Constants.today || 
    (this.data.timePeriod === Constants.customDateRange && 
    ((freqType === Constants.monthly && this.data[Constants.isSingleMonthDateRange]) 
    || this.data.duration === 1));
  }

  reloadGraph(seriesName) {
    this.sessionStorage.set(Constants.seriesName, JSON.stringify(seriesName));
    if(Constants.Replaced===seriesName){
      this.graphSeriesId = [9];
    }else if(Constants.PerClientRequest===seriesName){
      this.graphSeriesId = [10];
    }
    if(this.isBarChartVissible()){
      this.barChart.reloadGraph(this.graphSeriesId);
    }else{
      this.lineChart.reloadGraph(this.graphSeriesId);
    }
    
  }

}
