import { DomSanitizer } from '@angular/platform-browser';
import { Params } from '@angular/router';
import { of } from 'rxjs';
import { ResubmitClmConfig } from '../claim-errors/error-crr-resubmit/error-crr-resubmit.component';
import { Constants } from '../data-models/constants.enum';

export type collapsePanelParam = {
  uuid:string,
  docObj:any,
  session:any,
  errCrrService:any
}
export enum patterns{
  AN="^[a-zA-Z0-9]+$",
  ADD="^[a-zA-Z0-9\s. \-]+$",
  AlPHA="^[a-zA-Z]+$",
  NAME="^[a-zA-Z]{1}[a-zA-Z ]*$",
  NUM="[0-9]+",
  AN3D="^[a-zA-Z0-9]{2}[D]{1}[a-zA-Z0-9]+$",
  NUM_START_1 = "1[0-9]+",
  AN11="^1[a-zA-Z0-9]+$"
}
export enum inputTypeConfig {
  input='inputTextBox',
  select='dropdown',
  address='addressField',
  dollar='dollarField',
  date='dateField',
  entityName='nameField',
  unit='unitsField',
  decimal='decimalField',
  fromThrough='fromThroughField',
  codeInfo='codeInfoTextBox',
  contact='contactField'
}
export enum contactCode{
  tel="TE",
  fax="FX",
  mail="EM",
  telExt="EX"
}
export class ErrCrrUtility {
    
    public selectedRow;
    public routeParam: Params;
    isInstitutional: boolean = false;
    isProfessional: boolean = false;
    public listConfig = {};
    public datePipe;
    public warningMsg:string;
    warningFlow:string;
    showInvalidWarning: boolean = false;
    timeout: ReturnType<typeof setTimeout>;
    public resubmitClmConfig:ResubmitClmConfig = {
      loading: false,
      showPopup: false,
      title: '',
      workFlow: '',
      description: ''
    };
    public errorCategoryDtls :any = [];
    dataLoading: boolean = true;
    isDataModified: boolean = false;
    public readonly  alertIconSVG= `<svg xmlns="http://www.w3.org/2000/svg" class="alert-icon"   viewBox="0 0 17.001 17.001">
    <path data-name="Exclusion 1"
    d="M-5775.5,9094a8.51,8.51,0,0,1-8.5-8.5,8.511,8.511,0,0,1,8.5-8.5,8.51,8.51,0,0,1,8.5,8.5A8.509,8.509,0,0,1-5775.5,9094Zm0-5a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5A1.5,1.5,0,0,0-5775.5,9089Zm0-10a1.5,1.5,0,0,0-1.5,1.5v6a1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5v-6A1.5,1.5,0,0,0-5775.5,9079Z"
    transform="translate(5784.001 -9076.999)" fill="#fff" />
  </svg>`;
    public errorHighlight:any ={};
    public serviceLineNum:string;
    errorHighlightMatch: any = {};
    errorHighlightUnmatch: any = {};
    constructor(private sanitized: DomSanitizer) {}

    expandEditor(label, config) {
        config[label] = true;
    }

    isColumnInvalid(tableInvalidstatus,field,index?) {
        let isInvalid = false; 
        for(const label in tableInvalidstatus){
          const [labelIndex] = label.toString().split("_");                 
          if (tableInvalidstatus[label][field] && (index === undefined || index == labelIndex)) {
            isInvalid=true;
            break;
          }
        }
      return isInvalid;
      }

    getClaimType() {
        if (this.selectedRow.clmType === "I") {
            this.isInstitutional = true;
        } else if (this.selectedRow.clmType === "P") {
            this.isProfessional = true;
        }
    }

    isAttrApplicable(data, keys) {
        const jsonKeys = keys.split(".");
        const obj = this.findParentObjNode(data, jsonKeys);
        return obj && obj.hasOwnProperty(jsonKeys[jsonKeys.length - 1]);
    }


    findParentObjNode(data, jsonKeys, reqField?) {
        let iterate = 0;
        let obj = data;
        while (iterate < (jsonKeys.length - 1)) {
            if (reqField && !obj[jsonKeys[iterate]]) {
                obj[jsonKeys[iterate]] = {};
            }
            obj = obj[jsonKeys[iterate]];
            iterate++;
            if (!obj) {
                break;
            }
        }
        return obj
    }

    getFieldLabel(listKey, fieldValue,list?) {
        const listData = list || this.listConfig[listKey];
        let label = fieldValue;
        for (const item in listData) {
            if (fieldValue == listData[item].value) {
                label = listData[item].label;
                break;
            }
        }
        return label || "";
    }

    decodeDateFormat(date) {
        if (date && date.length === 8) {
            const parseDate = new Date(`${date.substr(0, 4)}/${date.substr(4, 2)}/${date.substr(6, 2)}`);
            if (!isNaN(parseDate.getTime())) {
                return this.datePipe.transform(new Date(parseDate), "MM-dd-yyyy");
            }
        }
        return "";
    }

    encodeDateFormat(date) {
        let encodedDate = "";
        const parseDate = new Date(date);
        if (!isNaN(parseDate.getTime())) {
            encodedDate = this.datePipe.transform(new Date(parseDate), "yyyyMMdd");
        }
        return encodedDate;
    }

    updateClmAttribute(data, keys, inputvalue, isReqField=false) {
        const jsonKeys = keys.split(".");
        const obj = this.findParentObjNode(data, jsonKeys, isReqField);
        if (obj) {
            obj[jsonKeys[jsonKeys.length - 1]] = inputvalue;
        }
    }

    getFieldValue(data, keys) {
        const jsonKeys = keys.split(".");
        const obj = this.findParentObjNode(data, jsonKeys);
        if (obj) {
            return obj[jsonKeys[jsonKeys.length - 1]];
        }
    }

    collapsePanel(param:collapsePanelParam,unlockClaimReq:boolean=false) {
      const userDetails = param.session.get(Constants.userPrincipal);
      const subscription = this.unlockClaimsApiCall(userDetails,unlockClaimReq,param);
      subscription.subscribe( data =>{
       const rowElement: any = param.docObj.getElementById(param.uuid);
        if (rowElement) {
          const children = rowElement.parentElement.children;
          let editIcon;
          for (const el of children) {
            if (el && el.innerHTML && el.innerHTML.includes("uitk-c-table__expandable-row-button")) {
              editIcon = el.getElementsByTagName("button");
              break;
            }
          }
          if (editIcon && editIcon.length > 0) {
            editIcon[0].click();
            this.selectedRow.expanded = false;
          }
        }
      })
    }

    unlockClaimsApiCall(userDetails,unlockClaimReq,param){
      return (this.isMDEScreen() && this.selectedRow.selected && !unlockClaimReq) ? of({}): param.errCrrService.lockUnlockClaim(this.selectedRow.medClmSk, this.selectedRow.partySk, userDetails.preferred_username,Constants.claimUnlocked,userDetails.sessionIdGenerated) ;
    }

    cancel(cancelParams :any){
        if(this.isDataModified || cancelParams.invalidEntry){
          this.warningMsg="Any unsaved changes will be lost. Would you like to continue?";
          this.warningFlow="cancel";
          this.showWarningPopup();
        }else{
          this.collapsePanel(cancelParams);
        }
      }

    updateWarningConfig(isSaveEligible,invalidEntry){
        if(invalidEntry){
          this.warningMsg  = "Unable to Save changes, as there are one or more invalid entries. Please Verify.";
          this.warningFlow = "invalidSaveData";
        }else if(!isSaveEligible){
          this.warningMsg = "There are no changes made to Save";
          this.warningFlow = "SaveSkip";
        }
      }
    
    showWarningPopup(){
        this.showInvalidWarning =  true;
       }

    isEditorOpen(accordionsEditorList){
        let invalidEntry = false;
        for(const accordion of accordionsEditorList){
            for(const field in accordion){
              if(accordion[field]){
                invalidEntry = true;
                break
              }
            }
          }
          return invalidEntry;
      }

    applyEditedChanges(accordianEditorList,docObj,changeBtnCls){
        let invalidEntry = false;
        const changeBtnList:any = docObj.getElementsByClassName(changeBtnCls);
        for(const btn of changeBtnList){
          btn.click();
        }
        if(changeBtnList.length > 0){
          invalidEntry= this.isEditorOpen(accordianEditorList);
        }
        return {invalidEntry,isSaveEligible : this.isDataModified};;
      }

     
    saveClaim(saveParams: any) {
        saveParams.errCrrService.isClaimClosed(this.selectedRow.medClmSk)
            .subscribe((res: any) => {
                if (res.success === 'true') {
                    this.updateClosedClaimStatus(saveParams);
                } else {
                    if (saveParams.invalidEntry || (!saveParams.isResubmitFlow && !saveParams.isSaveEligible)) {
                        this.showInvalidEntry(saveParams);
                        return
                    }
                    if (saveParams.isResubmitFlow) {
                        this.resubmitClmConfig = { 
                            showPopup: true,
                            loading: true,
                            title: 'Alert',
                            workFlow: 'submitted', description: '' 
                        }
                    } else {
                        this.dataLoading = true;
                    }
                    const userDetails = saveParams.session.get(Constants.userPrincipal);
                    const client = JSON.parse(saveParams.session.get(Constants.clientName));
                    saveParams.errCrrService.saveCorrectedClaims(this.selectedRow, userDetails.preferred_username, saveParams.allClmData).subscribe((data: any) => {
                    this.saveClaimsCallback(saveParams,data);
                    }, err => {
                        this.resubmitClmConfig.showPopup=false;
                        this.dataLoading = false;
                    })
                }
            }, err => {
            });
    }

    fetchErrorCategoryDetail(errCrrService, medClmSK, clmDtCriteriaId) {
        errCrrService.getErrorCategoryDetails(medClmSK, clmDtCriteriaId).subscribe(data => {
            this.errorCategoryDtls = data;
            this.resubmitClmConfig = { showPopup: true, loading: false, title: 'Alert', workFlow: 'submitted', description: '' };
        }, error => {

        });

    }

     collapseResubmitPop(event, collapseParam:collapsePanelParam){
        if(event.type === "submitted" || event.type === "closed" ){
            this.collapsePanel(collapseParam,true);
          }
     }

     closePopUp(collapseParam){
        this.showInvalidWarning = false;
        if (this.warningFlow === "SaveSkip" || this.warningFlow === "closedClaim") {
          clearTimeout(this.timeout);
          this.collapsePanel(collapseParam);
        }
     }

     updateClosedClaimStatus(saveParams){
       const clmData = [{ medClmSk:this.selectedRow.medClmSk, orgFileKey:this.selectedRow.fileKey, partySK:this.selectedRow.partySk }]
        saveParams.errCrrService.updateClaimStatus(clmData)
        .subscribe((data: any) => {
          this.resubmitClmConfig = {
            loading: false,
            showPopup: true,
            title: 'This claim was closed',
            workFlow: 'closed',
            description: saveParams.errCrrService.getClosedMdeClaims([this.selectedRow])
          };
        }, err => {
        });
        saveParams.mdeService.emitUpdateRecord({ data: this.selectedRow, flow: Constants.claimClosedFlow, status: Constants.claimClosedStatus });
     }
      
     showInvalidEntry(saveParams){
        this.updateWarningConfig(saveParams.isSaveEligible,saveParams.invalidEntry);
        this.showWarningPopup();
        this.timeout = setTimeout(() => {
            this.showInvalidWarning = false;
            if (!saveParams.isSaveEligible && !saveParams.invalidEntry) {
                this.collapsePanel(saveParams);
            }
        }, 3000);

        return
     }

     saveClaimsCallback(saveParams,data){
        const viewingBy = JSON.parse(saveParams.session.get(Constants.viewingBy));
        if (data.success === "true") {
            saveParams.mdeService.emitUpdateRecord({ data: this.selectedRow, flow: Constants.errorCorrectedFlow, status: Constants.errorCorrectedStatus });
        }
        if (saveParams.isResubmitFlow) {
            this.fetchErrorCategoryDetail(saveParams.errCrrService, this.selectedRow.medClmSk, viewingBy.id);
        } else {
            this.collapsePanel(saveParams);
        }
     }
     getEntityTypeList(entityID){
      const entityId = entityID && (entityID.replace(/ /g,"_").replace(/-/g,"")).toUpperCase();
      return this.listConfig["entityTypeCode"][entityId];
    }

    deepCopy(oldObj: any) {
      let newObj = oldObj;
      if (oldObj && typeof oldObj === "object") {
        newObj = Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
        for (const i in oldObj) {
          if (i) {
            newObj[i] = this.deepCopy(oldObj[i]);
          }
        }
      }
      return newObj;
    }

  getErrorHighlightMsg(errorMsg){
    return this.sanitized.bypassSecurityTrustHtml(`${this.alertIconSVG} ${errorMsg}`);
  }
  getUnmatchErrorHighlight(clmSection:string,serviceLineNum:string="-1") {
    const svcLineNum = this.serviceLineNum || serviceLineNum;
    return this.getFieldValue(this.errorHighlightUnmatch,`${svcLineNum}.${clmSection}`);
  }

  getMatchErrorHighlight(clmSection, clmField,qualifierCD?,idx?,serviceLineNum?) {
    const svcLineNum = this.serviceLineNum || serviceLineNum || "-1";
    const keys = !qualifierCD ? [svcLineNum,clmSection,clmField]:[svcLineNum,clmSection,idx,clmField];
    return this.getFieldValue(this.errorHighlightMatch,keys.join("."));
  }

  updateMatchErrorHightlightObj(clmSection, clmField,qualifierCD?,idx?,rptnPosNum?,serviceLineNum?) {
    const svcLineNum = this.serviceLineNum || serviceLineNum || "-1";
    const node = this.findParentObjNode(this.errorHighlight,["errorCorrectionMap",svcLineNum,clmSection,""]) || [];
    const obj=[];
    clmField = clmField || "";
    for (const item in node) {
      if(node[item].claimFiled && (node[item].claimFiled.toLowerCase() === clmField.toLowerCase())){
        if(qualifierCD){
          this.getListedItemErrorHighlight(node[item],clmSection,rptnPosNum,qualifierCD,obj)
        }else  {
          node[item]["match"] = true;
          obj.push(node[item].errorCode + '-' + node[item].errorDescription);
        }
      }
    }
    if(obj.length > 0){
      const keys = !qualifierCD ? [svcLineNum,clmSection,clmField]:[svcLineNum,clmSection,idx,clmField];
      this.updateClmAttribute(this.errorHighlightMatch,keys.join("."),obj,true)
    }
  }

  updateUnmatchErrorHighlightObj(clmSection,serviceLineNum = "-1") {
    const svcLineNum = this.serviceLineNum || serviceLineNum;
    const node = this.findParentObjNode(this.errorHighlight,["errorCorrectionMap",svcLineNum,clmSection,""]) || [];
    const obj=[];
    for (const item in node) {
      if(!node[item].match){
        obj.push(node[item].errorCode + '-' + node[item].errorDescription);
      }
    }
    if(obj.length > 0){
      this.updateClmAttribute(this.errorHighlightUnmatch,`${svcLineNum}.${clmSection}`,obj,true);
    }
  }


  getListedItemErrorHighlight(item,clmSection,rptnPosNum,qualifierCD,obj){
    let matchFound = false;
    if([Constants.headerAdjustmentSec,Constants.ServiceAdjustmentSec].indexOf(clmSection) !== -1){
      matchFound = (`${item.segRepeatationPositionNumber}${item.repeatationPositionNumber}` === rptnPosNum.toString());
    } else if (this.checkQualifierCDMatching(qualifierCD, item.qualifierCD)) {
      if([Constants.addHealthSec,Constants.diagCodeSec].indexOf(clmSection) !== -1){
        const errorRptn = rptnPosNum.toString().length === 1 ? Number(item.segRepeatationPositionNumber): Number(item.segRepeatationPositionNumber)*100 + 
        Number(item.repeatationPositionNumber) ;
        const skipRptnNumCheck = this.skipDiagCodeRptnCheck(qualifierCD);
        matchFound = skipRptnNumCheck || (!isNaN(errorRptn) && errorRptn === Number(rptnPosNum));
      }else{
        matchFound = true;
      }
    }
    if(matchFound){
      item["match"] = true;
      obj.push(item.errorCode + '-' + item.errorDescription);
    }
  }

  checkQualifierCDMatching(qualifierCD,errHghQualCD){
    if(errHghQualCD && errHghQualCD.indexOf(',') !== -1){
      return errHghQualCD.split(',').map(element => element.trim()).indexOf(qualifierCD) !== -1;
    }
    return qualifierCD === errHghQualCD;
  }

  skipDiagCodeRptnCheck(qualCD){
    const qualCDList = {
      "ABK":true,
      "ABJ":true,
      "BBR":true,
      "BK":true,
      "BJ":true,
      "BR":true
    }
    const errTypeList = {
      "MAO":true,
      "277CA":true,
      "999":true
    }

    return errTypeList[this.selectedRow.errType] && qualCDList[qualCD];
  }

  getErrorHighlightCount(clmSection,isServiceCat=false){
    const keyMapping= isServiceCat ? ["errorCorrectionMap",clmSection,""]:["errorCorrectionMap","-1",clmSection,""];
     const node = this.findParentObjNode(this.errorHighlight,keyMapping);
     if(node){
       if(isServiceCat){
         let count = 0;
         for(const itr in node){
           count += node[itr].length;
         }
         return count;
       }
       return node.length;
     }
   }

  isMDEScreen(){
    return this.routeParam.sourceScreen === Constants.mdeScreen;
  }

}
