import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReportsModule } from './reports/reports.module';
import { ClaimErrorsModule } from './claim-errors/claim-errors.module';
import { UITKLoadingIndicatorModule, UITKTableModule, UITKBreadcrumbModule, UITKTableFeaturesModule, UITKPaginationModule, UITKExpandRowService } from '@uitk/angular';

@NgModule({
  declarations: [],
  providers:[UITKExpandRowService],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReportsModule,
    ClaimErrorsModule,
    UITKLoadingIndicatorModule,
    UITKTableModule,
    UITKBreadcrumbModule,
    UITKTableFeaturesModule,
    UITKPaginationModule,
  ],
  exports: [
  ]
})
export class SharedModule { }
