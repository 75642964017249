<div [formGroup]="additionalHltCareForm" class="editor-wrapper hdr-data-cls">
    <div class="add-info-item add-info-qualf-col text-ellipses">
        <span [title]="additionalHltCareDesMapping[inputValue.codeListQualifierCode] ? inputValue.codeListQualifierCode + '-' +
        additionalHltCareDesMapping[inputValue.codeListQualifierCode]:inputValue.codeListQualifierCode">
            {{inputValue.codeListQualifierCode}}{{additionalHltCareDesMapping[inputValue.codeListQualifierCode] ? '-' +
            additionalHltCareDesMapping[inputValue.codeListQualifierCode]:''}}
        </span>
    </div>
    <div class="add-info-item add-info-code-col">
        <uitk-form-field>
            <input class="industryCode-field" uitkInput formControlName="industryCode" id="industryCode"
                (blur)="validate()" [appInputRestrict]="additionalHltCareForm" formCtrl="industryCode"
                [maxLength]="maxLengthInfo.industryCode">
            <uitk-form-field-error *ngIf="getFormControlValidity('industryCode')">
            </uitk-form-field-error>
        </uitk-form-field>
    </div>
    <div class="add-info-item add-info-code-col"
        [ngClass]="{'tk-form-element-container--error': getFormControlValidity('codeFromDate')}">
        <uitk-form-field>
            <uitk-date-picker *ngIf="displayCodeFrom()" class="code-from-field date-field" uitkId="codeFromDate"
                name="selectedDateDefault" formControlName="codeFromDate" (ngModelChange)="validate()">
            </uitk-date-picker>
            <uitk-form-field-error *ngIf="getFormControlValidity('codeFromDate')">
            </uitk-form-field-error>
        </uitk-form-field>
    </div>
    <div class="add-info-item add-info-code-col"
        [ngClass]="{'tk-form-element-container--error': isDateRangeInvalid() || getFormControlValidity('codeThroughDate')}">
        <uitk-form-field>
            <uitk-date-picker *ngIf="displayCodeThroughDate()" class="code-through-field date-field"
            uitkId="codeThroughDate" name="codeThrough" formControlName="codeThroughDate"
                (ngModelChange)="validate()">
            </uitk-date-picker>
            <uitk-form-field-error *ngIf="getFormControlValidity('codeFromDate')">
            </uitk-form-field-error>
        </uitk-form-field>
    </div>
    <div class="add-info-item amt-col"> 
        <div  *ngIf="displayAmountField()" class="dollar-field-wrapper" >
            <span class="dollar-symbol">$</span>
            <uitk-form-field>
            
            <input formControlName="dollarField" class="dollar-field" id="clmAdjDollar"
                (blur)="validate()" 
                [appInputRestrict]="additionalHltCareForm"  uitkInput formCtrl="dollarField" [maxLength]="maxLengthInfo.dollar"/>
                <uitk-form-field-error *ngIf="getFormControlValidity('dollarField')">
                </uitk-form-field-error>
            </uitk-form-field>
            <span class="separator uitk-u-margin-right--xxs uitk-u-margin-left--xxs">.</span>
            <uitk-form-field>
                <input formControlName="centField"  class="cent-field" id="clmAdjCent"
                (blur)="validate()" 
                [appInputRestrict]="additionalHltCareForm"  uitkInput formCtrl="centField" [maxLength]="maxLengthInfo.cent"/>
                <uitk-form-field-error *ngIf="getFormControlValidity('centField')">
                </uitk-form-field-error>
            </uitk-form-field>
        </div>
    </div>
    <div class="action-btn-wrapper">
        <button [class]="'uitk-c-button uitk-c-button--default  uitk-u-margin-right--sm   uitk-u-margin-left--xs  '+changeBtnCls" (mousedown)="mousedownEvent($event)"
            (click)="changeClick()">Apply</button>
        <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--sm " (click)="cancelClick()">Cancel</button>
    </div>
</div>