export class ErrorSummaryCriteria {
    partySK: number;
    clmDtCriteriaId: number;
    timeCriteriaId: number;
    respCtgCriteriaId: number;
    startMonth: number;
    startYear: number;
    endMonth: number;
    endYear: number;
    fromDate : string;
	toDate : string;
	errorLocation : string;
	errorCategory : string;
	errorCode : string;
}