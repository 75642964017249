export class BaseChartsComponent {

    //options
    public showGradient: boolean = false;
    public showLegend: boolean = false;
    public showLabels: boolean = true;
    public animations: boolean = false;
    public showXAxis: boolean = true;
    public showYAxis: boolean = true;
    public showYAxisLabel: boolean = true;
    public showXAxisLabel: boolean = false;
    public barPaddingConfig: { [key: string]: number } = {
        "1": 7000,
        "2": 130,
        "3": 400,
        "4": 250,
        "5": 175,
        "6": 130,
        "7": 90,
        "8": 60
    }
    public chartHeight:number = 250;

    public yAxisTickFormattingFn(data) {
        const tokens = (data + '').split('\.');
        return (tokens.length == 1) ? tokens[0] : '';
    }

    public xAxisTickFormattingFn(data) {
        return data.includes("dummy") ? "" : data;
    }
    public calculateChartView(chartData) {
        const chartView = [window.innerWidth > 1280 ? 1132 : window.innerWidth - 89, this.chartHeight];
        const maxXAxisData = 31;
        const yAxistWidth = 35;
        if (chartData.length > maxXAxisData) {
            chartView[0] = ((chartView[0] - yAxistWidth) / maxXAxisData) * chartData.length;
        }

        return chartView;
    }

    public modifyData(data) {
        const chartObj = { name: `dummy`, series: [], value: 0 };
        if (data.length <= 2) {
            data = [{ ...chartObj, name: `${chartObj.name}1` },
            { ...chartObj, name: `${chartObj.name}2` },
             ...data,
            { ...chartObj, name: `${chartObj.name}3` },
            { ...chartObj, name: `${chartObj.name}4` }];
        } else if (data.length <= 4) {
            data = [{ ...chartObj, name: `${chartObj.name}1` }, ...data,
             { ...chartObj, name: `${chartObj.name}2` }];
        }
        return data;
    }
}