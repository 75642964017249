<form [formGroup]="claimRefInfoForm" class="editor-wrapper hdr-data-cls"
    [ngClass]="{'service-catg':isServiceLineCategory}">
    <div class="claim-ref-item qualifier-col text-ellipses">
        <span
            [title]="claimReferenceDesMapping[inputValue.referenceIdentQual] ? inputValue.referenceIdentQual + '-' +
            claimReferenceDesMapping[inputValue.referenceIdentQual]:inputValue.referenceIdentQual">{{inputValue.referenceIdentQual}}{{
            claimReferenceDesMapping[inputValue.referenceIdentQual] ? '-' +
            claimReferenceDesMapping[inputValue.referenceIdentQual]:''}}</span>
    </div>
    <div *ngIf="!isLUReferenceIdentQual()" class="claim-ref-item ref-col">
        <uitk-form-field>
        <input class="refIdentification-field" uitkInput formControlName="refIdentification" id="refIdentification"
            (change)="validate()" 
             formCtrl="refIdentification" [maxLength]="maxLength" />
        <uitk-form-field-error *ngIf="
        claimRefInfoForm?.controls?.refIdentification?.touched &&
                                    getFormControlValidity('refIdentification')
                                ">
            
        </uitk-form-field-error>
        ></uitk-form-field>
    </div>

    <div *ngIf="isLUReferenceIdentQual()" class="claim-ref-item ref-col">
        <uitk-form-field>
        <uitk-select  id="state" [showError]="getFormControlValidity('state')" class="refIdentification-select-field"
            formControlName="state" [itemList]="listData['state']"  [defaultLabelFlag]=false>
        </uitk-select>
    </uitk-form-field>
    </div>
    <div class="action-btn-wrapper">
        <button [class]="'uitk-c-button uitk-c-button--default uitk-u-margin-right--sm   uitk-u-margin-left--xs '+changeBtnCls" (mousedown)="mousedownEvent($event)"
            (click)="changeClick()">Apply</button>
        <button class="uitk-c-button uitk-c-button--ghost" (click)="cancelClick()">Cancel</button>
    </div>
</form>