import { Component, OnInit, Input, Inject } from '@angular/core';
import { ErrCrrUtility } from '../../err-crr-utility';
import { forkJoin } from 'rxjs';
import { ErrorCorrectionService } from 'src/app/services/errorCorrectionClm.service';
import { Constants } from 'src/app/data-models/constants.enum';
import { DOCUMENT, DatePipe } from '@angular/common';
import { MdeService } from 'src/app/services/tabs/mde.service';
import { WebStorageService, SESSION_STORAGE } from 'ngx-webstorage-service';
import { DomSanitizer } from '@angular/platform-browser';
import { Params } from '@angular/router';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';

@Component({
  selector: 'app-err-crr-service-catg',
  templateUrl: './err-crr-service-catg.component.html',
  styleUrls: ['./err-crr-service-catg.component.css']
})
export class ErrCrrServiceCatgComponent extends ErrCrrUtility implements OnInit {
  @Input() selectedRow: any;
  @Input() routeParam: Params;
  public clmDta;
  invalidProvidersFieldStatus = {};
  showHideConfig = {};
  showHideProviderInfoConfig = {};
  public showHideclaimAdjConfig = {};
  public entityTypeCode;
  public entityId = {};
  public showHideClaimRefInfoConfig = {};
  public showHideAddClaimDatesConfig ={};
  public additionalClaimDateDesMapping = {};
  public additionalClaimDateTimeData = {};
  public claimReferenceDesMapping = {};
  changeBtnCls: string;
  invalidClaimAdjFieldStatus = {};
  public invalidClaimRefInfoFieldStatus = {};
  public open: boolean = false;
  dataLoading: boolean = true;
  getAddClaimDateProps: any;
  invalidAddClaimDatesFieldStatus: any = {};
  public serviceFieldsLabel = {
    providerSec: "Service Line Providers",
    providerFields:['Entity Type Code','Name','Entity ID','NPI',"Address","Taxonomy Code"],
    adjustmentSec: "Adjustment Records",
    adjustmentFields:['Group Code','Reason Code','Amount','Quantity'],
    clmRefInfoSec:'Service Line Reference Information',
    clmRefInfoFields:['Qualifier','Reference Identification'],
    AddClmDatesSec:'Additional Service Line Dates',
    AddClmDatesFields:['Date/Time Qualifier','Date/Time Format Qualifier','Date From','Date Through']
  }
  myCloseButtonText: string = 'Close';
  visibleTextValue: string = 'Please wait...';
  openAccordion = new Map<string,boolean>();
  constructor(private errorCorrectionService: ErrorCorrectionService, private mdeService:MdeService,
    @Inject(DOCUMENT) private readonly document: Document,public datePipe: DatePipe,@Inject(SESSION_STORAGE)public sessionStorage:WebStorageService,
    sanitized: DomSanitizer) {
    super(sanitized);
  }

  ngOnInit() {
    this.getClaimType();
    this.fetchErrCrrData();
    this.changeBtnCls = "changeBtn-" + this.getUuid();
    this.getAddClaimDateProps=this.getAddClaimDate.bind(this);
  }
  getUuid() {
    return (this.selectedRow.medClmSk ? this.selectedRow.medClmSk : `${this.selectedRow.fileKey}_${this.selectedRow.clmId}`) + "" + this.selectedRow.rowIndex;  
  }

  fetchErrCrrData() {
    const viewingBy =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.viewingBy);
    forkJoin(
      {
        clmData: this.errorCorrectionService.getErrorCorrectionDetails(this.selectedRow.clmType, this.selectedRow.medClmSk, this.selectedRow.partySk),
        state: this.errorCorrectionService.getState(),
        groupCode: this.errorCorrectionService.getQualifierdropdown(Constants.errorCatgService,this.selectedRow.clmType,Constants.claimAdjAccordion,Constants.claimAdjAccordionUIRef),
        entityTypeCode: this.errorCorrectionService.getEntityTypeCode(Constants.errorCatgService,this.selectedRow.clmType,Constants.entityTypeRefCol),
        ndcUnits: this.errorCorrectionService.getQualifierdropdown(Constants.errorCatgService,this.selectedRow.clmType,Constants.scvLineSec,Constants.ndcUnitMeasureRef),
        productQualifier: this.errorCorrectionService.getQualifierdropdown(Constants.errorCatgService,this.selectedRow.clmType,Constants.scvLineSec,Constants.procedureCodeQual),
        basisOfMeasurementCode: this.errorCorrectionService.getQualifierdropdown(Constants.errorCatgService,this.selectedRow.clmType,Constants.scvLineSec,Constants.unitMeasureRef),
        placeOfService: this.errorCorrectionService.getPlaceOfService(this.selectedRow.clmType),

        claimReferenceData: this.errorCorrectionService.getAllQualifierDes(Constants.errorCatgService, this.selectedRow.clmType, Constants.addlnSVCRefInfo,Constants.claimReferenceAccordionUIRef,true),
        additionalClaimDateData: 
        this.errorCorrectionService.getAllQualifierDes(Constants.errorCatgService, this.selectedRow.clmType, Constants.addlnSVCDatesCol,Constants.additionalClaimDatesAccordionUIRef,true),
        additionalClaimDateTimeData: 
        this.errorCorrectionService.getAllQualifierDes(Constants.errorCatgService, this.selectedRow.clmType, Constants.addlnSVCDatesCol,Constants.additionalClaimDateTimeUIRef,true),
        entityId: this.errorCorrectionService.getAllQualifierDes(Constants.errorCatgService,this.selectedRow.clmType,Constants.entityIdUISec,Constants.entityIdUIRef,true),
        errorHighLight: this.errorCorrectionService.errorFieldHighlight(this.selectedRow,viewingBy.id),
      }
    ).subscribe(data => {
      this.clmDta = data.clmData;
      this.setOpenAccordion();
      this.listConfig['state'] = data.state;
      this.listConfig['clmAdjGroupCode'] = data.groupCode;
      this.listConfig['entityTypeCode'] = data.entityTypeCode;
      this.listConfig['ndcUnits'] = data.ndcUnits;
      this.listConfig['productQualifier'] = data.productQualifier;
      this.listConfig['unitBassisMeasCode'] = data.basisOfMeasurementCode;
      this.listConfig['facilityCode'] = data.placeOfService;
      this.claimReferenceDesMapping = data.claimReferenceData;
      this.additionalClaimDateDesMapping = data.additionalClaimDateData;
      this.additionalClaimDateTimeData = data.additionalClaimDateTimeData;
      this.entityId = data.entityId;
      this.errorHighlight = data.errorHighLight;
      this.dataLoading = false;
      this.mapErrorHighlightData();
    }, error => {
      this.dataLoading = false;
      console.log(error);
    });
  }

  mapErrorHighlightData() {

    const svcLinesObj= this.getFieldValue(this.clmDta,["billingProvider","0","subscriber","0","claim","0","loop2400ServiceLine"].join("."));
    for(const serviceLineData of svcLinesObj){
      const lineNum = serviceLineData.loop2400ServiceLineNumber[0].assignedNumber;
      this.mapAdjSegErrorHighlightData(serviceLineData,lineNum)
      this.mapErrorHighlightDataWithQual(serviceLineData.loop2420Name,lineNum,this.serviceFieldsLabel.providerSec,
        "loop2420NM1.entityIDCode",this.serviceFieldsLabel.providerFields);
      this.mapErrorHighlightDataWithQual(serviceLineData.refLoop2400REF,lineNum,this.serviceFieldsLabel.clmRefInfoSec,
        "referenceIdentQual",this.serviceFieldsLabel.clmRefInfoFields);
      this.mapErrorHighlightDataWithQual(serviceLineData.dtpLoop2400DTP,lineNum,this.serviceFieldsLabel.AddClmDatesSec,
        "dateTimeQualifier",this.serviceFieldsLabel.AddClmDatesFields);

      this.updateUnmatchErrorHighlight(lineNum);
    }
    
    
  }
  mapErrorHighlightDataWithQual(data,lineNum,section,qualKeys,fields){
    for(const idx in data){
      const item = data[idx];
      for(const field of fields){
         this.updateMatchErrorHightlightObj(section,field,this.getFieldValue(item,qualKeys),idx,null,lineNum);
      }
    }

  }
  mapAdjSegErrorHighlightData(serviceLineData,lineNum){
    for(const claimAdj of serviceLineData.loop2430LineAdjudicationInformation){
      for(const clmAdjItem of claimAdj.loop2430CASLineAdjustment){
        for(const field of this.serviceFieldsLabel.adjustmentFields){
           this.updateMatchErrorHightlightObj(this.serviceFieldsLabel.adjustmentSec,field,clmAdjItem.claimAdjGroupCode,clmAdjItem.rptnPosNum,clmAdjItem.rptnPosNum,lineNum);
         }
      }
    }
  }

  updateUnmatchErrorHighlight(lineNum){
    const sectionList = [this.serviceFieldsLabel.adjustmentSec ,this.serviceFieldsLabel.providerSec,this.serviceFieldsLabel.clmRefInfoSec,this.serviceFieldsLabel.AddClmDatesSec];
    for(const section of sectionList){
      this.updateUnmatchErrorHighlightObj(section,lineNum);
    }
  }

  scroll(document, event) {
    const id = 'acc'+ this.selectedRow.srvcLnNo+ '-' + this.getUuid();;
    event.preventDefault();
    document.getElementById(id) && document.getElementById(id).scrollIntoView();
   const accHeader:any = this.document.querySelector("#"+id+" .tk-panl-closed .tk-panl-header");
   accHeader && accHeader.click();
   this.openAccordion.set(id , true);
  }

  setId(index) {
    const number = index + 1;
    return 'acc'+ number + '-' + this.getUuid();
  }
  getServiceLineNo(index) {
    const number = index + 1;
    return 'Service Line ' + number;
  }

  getName(providerObj){
    if( providerObj.loop2420NM1){
      const fName = providerObj.loop2420NM1.nameFirst || "";
      const mName = providerObj.loop2420NM1.nameMiddle || "";
      const oName = providerObj.loop2420NM1.nameOrgName || "";
      const sName = providerObj.loop2420NM1.nameSuffix || "";
      return fName + " " +mName+ " " + oName + " "+sName;
    }
  }
  getAddress(addrInfo) {
    if(addrInfo.loop2420ServiceFacilityddress && addrInfo.loop2420ServiceFacilityCityStateZipCode){
      const addr1 = addrInfo.loop2420ServiceFacilityddress.addressInformation1 ? addrInfo.loop2420ServiceFacilityddress.addressInformation1 + "  " : "";
      const addr2 = addrInfo.loop2420ServiceFacilityddress.addressInformation2 ? addrInfo.loop2420ServiceFacilityddress.addressInformation2 + "  " : "";
      const city = addrInfo.loop2420ServiceFacilityCityStateZipCode.cityName ? addrInfo.loop2420ServiceFacilityCityStateZipCode.cityName + ", " : "";
      const postal = addrInfo.loop2420ServiceFacilityCityStateZipCode.postalCode ? addrInfo.loop2420ServiceFacilityCityStateZipCode.postalCode : "";
      return addr1 + addr2 + city + this.getFieldLabel("state", addrInfo.loop2420ServiceFacilityCityStateZipCode.stateorProvCode) + " " + postal;
    }
  }
  toggleOpen(toggleValue:boolean) {
    this.open = !this.open;
    this.openAccordion.forEach((value:boolean,key:string)=>{
      this.openAccordion.set(key,toggleValue===value ? !value:value);
    })
    
  }
  

  collapseClaimAdjEditor(event) {
    if (event.type === 'change') {
      if (event.isInvalid) {
        this.invalidClaimAdjFieldStatus[event.field] = {
          'amount': event.amount,
          'reasonCode': event.reasonCode,
          'quantity': event.quantity,
          'groupCode': event.groupCode,
        }
        return
      }
      this.isDataModified = true;
    }
    if (event.type === 'validation') {
      this.invalidClaimAdjFieldStatus[event.field] = {
        'amount': event.amount,
        'reasonCode': event.reasonCode,
        'quantity': event.quantity,
        'groupCode': event.groupCode,

      }
      return
    }
    this.showHideclaimAdjConfig[event.field] = false;
    this.invalidClaimAdjFieldStatus[event.field] = {
      'amount': event.amount,
      'reasonCode': event.reasonCode,
      'quantity': event.quantity
    }

  }

  cancelService(){
    const accordionsEditorList = [this.showHideConfig,this.showHideclaimAdjConfig,this.showHideProviderInfoConfig,this.showHideAddClaimDatesConfig
      ,this.showHideClaimRefInfoConfig]
    const invalidEntry = this.isEditorOpen(accordionsEditorList);
    const cancelParamObj = {
      "invalidEntry" : invalidEntry,
      ...this.getCollapsePanelParam()
    }
    this.cancel(cancelParamObj);
  }

  emitIsDataModified(event){
    this.isDataModified = event;
  }

  collapseServiceProviderEditor(event){
    if (event.type === 'change') {
      if (event.isInvalid) {
        this.invalidProvidersFieldStatus[event.field] = {
          address: event.address,
          name:event.name,
          entityCode:event.entityCode,
          npi:event.npi,
          taxonomy:event.taxonomy
        }
        return
      }
      this.isDataModified = true;
    }
    if (event.type === 'validation') {
      this.invalidProvidersFieldStatus[event.field] = {
        address: event.address,
        name:event.name,
        entityCode:event.entityCode,
        npi:event.npi,
        taxonomy:event.taxonomy
      }
      return
    }
    this.showHideProviderInfoConfig[event.field] = false;
    this.invalidProvidersFieldStatus[event.field] = {}
  }

 getFromDate(clm) {
    return this.getAddClaimDate(clm, false);
 }

 getThroughDate(clm){
    return clm.dateTimeFormatQual === 'RD8' ? this.getAddClaimDate(clm,true) : "";
  }

  getAddClaimDate(claim, isThroughDate) {
    let date = "";
    const dateIndex = isThroughDate ? 1 : 0;
    if (claim.dateTimePeriod) {
      const dateList = claim.dateTimePeriod.split("-");
      date = dateList.length > dateIndex ? dateList[dateIndex] : "";
    }
    return this.decodeDateFormat(date);
  }
  collapseClaimRefInfoEditor(event){
    this.invalidClaimRefInfoFieldStatus[event.field] = {
      'refIdentification': event.refIdentification
    }
    if((event.type === 'change' && event.isInvalid) || event.type === 'validation') {
      return; 
    }
    if(event.type === 'change' && !event.isInvalid){
      this.isDataModified = true;
    }
    this.showHideClaimRefInfoConfig[event.field] = false;
  }

  collapseAddClaimDatesEditor(event) {
    this.invalidAddClaimDatesFieldStatus[event.field] = {
      'dateFrom': event.dateFrom,
      'dateThrough': event.dateThrough
    }
    if((event.type === 'change' && event.isInvalid) || event.type === 'validation') {
      return; 
    }
    if(event.type === 'change' && !event.isInvalid){
      this.isDataModified = true;
    }
    this.showHideAddClaimDatesConfig[event.field] = false;
  }


  saveServiceClaim(isResubmitFlow :boolean = false){
    const accordionsEditorList = [this.showHideConfig,this.showHideclaimAdjConfig,this.showHideProviderInfoConfig,this.showHideAddClaimDatesConfig
      ,this.showHideClaimRefInfoConfig]
    const {invalidEntry,isSaveEligible} = this.applyEditedChanges(accordionsEditorList,this.document,this.changeBtnCls);
    const saveParamObj = {
        "isResubmitFlow": isResubmitFlow,
        "invalidEntry":invalidEntry,
        "isSaveEligible":isSaveEligible,
        "dataLoading" : this.dataLoading,
        "allClmData":this.clmDta,
        "session":this.sessionStorage,
        "errCrrService":this.errorCorrectionService,
        "mdeService":this.mdeService,
        "uuid":this.getUuid(),
        "docObj":this.document
       }
    this.saveClaim(saveParamObj);
   
  }

  colpseSrvcResubmitPopUp(event){
    this.collapseResubmitPop(event,this.getCollapsePanelParam());
  }

  collapseServicePanel(){
    this.collapsePanel(this.getCollapsePanelParam());
  }

  closeServicePopUp() {
    this.closePopUp(this.getCollapsePanelParam());
  }
  getCollapsePanelParam(){
    return {uuid:this.getUuid(),docObj:this.document,session:this.sessionStorage,errCrrService:this.errorCorrectionService};
  }
 setOpenAccordion(){
  this.clmDta?.billingProvider[0]?.subscriber[0]?.claim[0]?.loop2400ServiceLine.forEach((data:any,index:number) => {
    this.openAccordion.set(this.setId(index),false);
  });
  
 }
 closeAccordion(id:string){
  this.openAccordion.set(id, !this.openAccordion.get(id))
 }
}
