<div class="filter-wrapper">
    <div class="filter-title-wrapper">
        <div class="filter-title"> Filters: </div>
        <app-applied-filter-bubble *ngIf="!filterExpandedView.show" [filterExpandedView]="filterExpandedView" [selectedFilters]="selectionDetails.bubbleData" [showEditFilter]="true"> </app-applied-filter-bubble>
        <div class="applied-filter-bubbles"></div>
    </div>
    
    <div class="filter-mandate-selection-wrapper" *ngIf="filterExpandedView.show">
        <div class="form-layout" *ngFor="let reqFilter of config.requiredFields">
            <ng-container *ngIf="reqFilter.type === 'select'">
                <label uitkLabel [for]="reqFilter.id" [ngClass]="{'label-disabled': isFieldDisabled(reqFilter.id)}">{{reqFilter.label}} <span class="asterisk"> *</span></label> 

                <uitk-select
                (onDropdownChange)='onDropdownChangeCallbck($event?.value,reqFilter)' 
                [itemList]="scopeData[reqFilter.list]"
                [name]="reqFilter.name"
                [id]="reqFilter.id"
                [(ngModel)]="scopeData[reqFilter.model]"
                [disabled]="isFieldDisabled(reqFilter.id)"
                [defaultLabelFlag]="false"
                >
                </uitk-select>
            </ng-container>
            <ng-container *ngIf="reqFilter.type === 'textfield'">
                <label uitkLabel [for]="reqFilter.id">{{reqFilter.label}}</label><span class="asterisk"> *</span>
                <input uitkInput [id]="reqFilter.id" [(ngModel)]='scopeData[reqFilter.model]' [name]="reqFilter.name"/>
            </ng-container>
           <!--  customClassName="input-field"  -->
            <ng-container *ngIf="reqFilter.type === 'datefield'">
                <div *ngIf="scopeData[reqFilter.model].isInvalid && filterExpandedView.isApplyClicked" class="invalid-field-msg">
                    <svg xmlns="http://www.w3.org/2000/svg" class="alert-icon" viewBox="0 0 17.001 17.001">
                  <path id="Exclusion_1" data-name="Exclusion 1" d="M-5775.5,9094a8.51,8.51,0,0,1-8.5-8.5,8.511,8.511,0,0,1,8.5-8.5,8.51,8.51,0,0,1,8.5,8.5A8.509,8.509,0,0,1-5775.5,9094Zm0-5a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5A1.5,1.5,0,0,0-5775.5,9089Zm0-10a1.5,1.5,0,0,0-1.5,1.5v6a1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5v-6A1.5,1.5,0,0,0-5775.5,9079Z" transform="translate(5784.001 -9076.999)" fill="#fff"/>
                </svg>
                    {{scopeData[reqFilter.model].validity.msg}}
                </div>
                <div class="date-wrapper" >
                    <div class="date-select" [ngClass]="{'date-wrapper-exp' : scopeData[reqFilter.model]['period']?.id !== 4}">
                        <label uitkLabel [for]="reqFilter.id" [ngClass]="{'label-disabled': isFieldDisabled(reqFilter.id)}">{{reqFilter.label}}<span class="asterisk"> *</span></label>
                        
                        <uitk-select
                        (onDropdownChange)='onDateChangeCallbck($event?.value,reqFilter)' 
                        [itemList]="scopeData[reqFilter.list]"
                        [name]="reqFilter.name"
                        [id]="reqFilter.id"
                        [(ngModel)]='scopeData[reqFilter.model]["period"]'
                        [disabled]="isFieldDisabled(reqFilter.id)"
                        [defaultLabelFlag]="false"
                        >
                        </uitk-select>
                    </div>
                   
                    <div class="date-input" *ngIf="scopeData[reqFilter.model]['period']?.id === 4" [ngClass]="{'date-invalid' : scopeData[reqFilter.model].validity.isFromInvalid && filterExpandedView.isApplyClicked}">
                        <label uitkLabel [for]="reqFilter.fromDate" [ngClass]="{'label-disabled': isFieldDisabled(reqFilter.id) || fromThroughDateValidity(reqFilter.model)}">From </label>
                        <div class="from-info">
                            <svg xmlns="http://www.w3.org/2000/svg" class="info-icon" viewBox="0 0 16 15.999">
                                <path id="Union_3" data-name="Union 3" d="M-5784,9085a8,8,0,0,1,8-8,8,8,0,0,1,8,8,8,8,0,0,1-8,8A8,8,0,0,1-5784,9085Zm.94,0a7.068,7.068,0,0,0,7.058,7.061,7.066,7.066,0,0,0,7.059-7.061,7.067,7.067,0,0,0-7.059-7.059A7.068,7.068,0,0,0-5783.059,9085Zm6.237,4.592v-6.224h1.646v6.224Zm0-7.067V9081h1.646v1.522Z" transform="translate(5784 -9077.001)" fill="#c25608"/>
                              </svg>
                            <span class="tooltiptext">{{dateInfoTooltipMsg}}</span>
                          </div> 
                        <uitk-date-picker [uitkId]="reqFilter.name+'From'" 
                        [model]="dateConfig"
                        [name]="reqFilter.fromDate" [(ngModel)]="scopeData[reqFilter.model].fromDate"
                        [disabled]="isFieldDisabled(reqFilter.id) || fromThroughDateValidity(reqFilter.model)"
                        (blur)="handleBlur($event,'fromDate',scopeData[reqFilter.model])">
                        </uitk-date-picker>
                    </div>
                    <div *ngIf="scopeData[reqFilter.model]['period']?.id === 4" class="date-input through-date"
                    [ngClass]="{'date-invalid' : scopeData[reqFilter.model].validity.isthroughInvalid && filterExpandedView.isApplyClicked}">
                        <label uitkLabel [for]="reqFilter.throughDate" [ngClass]="{'label-disabled': isFieldDisabled(reqFilter.id) || fromThroughDateValidity(reqFilter.model)}">Through </label>
                        <uitk-date-picker 
                            [uitkId]="reqFilter.name+'Through'"
                            [model]="dateConfig"
                            [name]="reqFilter.throughDate"
                            [(ngModel)]="scopeData[reqFilter.model].throughDate"
                            [disabled]="isFieldDisabled(reqFilter.id) || fromThroughDateValidity(reqFilter.model)"
                            (blur)="handleBlur($event,'throughDate',scopeData[reqFilter.model])" >
                        </uitk-date-picker>
                    </div> 
                </div>
            </ng-container>
        </div>
    </div>
    <div class="filter-selection-wrapper" *ngIf="filterExpandedView.show">
        <div class="filter-selection-category-wrapper" *ngFor="let row of rowArray">
        <div *ngFor="let col of colArray" class="form-layout" >
                <ng-container *ngIf="config.fields[col][row]; else elseBlock">
                     <ng-container *ngIf="config.fields[col][row].type === 'select'">
                        <label uitkLabel [for]="config.fields[col][row].id" [ngClass]="{'label-disabled': isFieldDisabled(config.fields[col][row].id)}">{{config.fields[col][row].label}} <span *ngIf="config.fields[col][row].required" class="asterisk"> *</span>
                        </label>
                       
                        <uitk-select
                               [disabled]="isFieldDisabled(config.fields[col][row].id)"
                                [(ngModel)]="scopeData[config.fields[col][row].model]"
                                [itemList]="scopeData[config.fields[col][row].list]"
                                [name]="config.fields[col][row].name"
                                [id]="config.fields[col][row].id"
                                defaultLabel="Select"
                                [defaultLabelFlag]="true"
                            >
                            </uitk-select>
                    </ng-container>
                    <ng-container *ngIf="config.fields[col][row].type === 'textfield'">
                        <label uitkLabel [for]="config.fields[col][row].id">{{config.fields[col][row].label}} <span class="mbi-npi-text" *ngIf="config.fields[col][row].operatorType === 'select'">(exact match)</span></label>
                        <input uitkInput [id]="config.fields[col][row].id"
                        [(ngModel)]="scopeData[config.fields[col][row].model]"  [name]="config.fields[col][row].name" />
                       <!--  tkType="text" customClassName="input-field" -->
                    </ng-container>
                    <ng-container *ngIf="config.fields[col][row].type === 'datefield'">
                        <div *ngIf="scopeData[config.fields[col][row].model].isInvalid && filterExpandedView.isApplyClicked" class="invalid-field-msg">
                            <svg xmlns="http://www.w3.org/2000/svg" class="alert-icon" viewBox="0 0 17.001 17.001">
                          <path id="Exclusion_1" data-name="Exclusion 1" d="M-5775.5,9094a8.51,8.51,0,0,1-8.5-8.5,8.511,8.511,0,0,1,8.5-8.5,8.51,8.51,0,0,1,8.5,8.5A8.509,8.509,0,0,1-5775.5,9094Zm0-5a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5A1.5,1.5,0,0,0-5775.5,9089Zm0-10a1.5,1.5,0,0,0-1.5,1.5v6a1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5v-6A1.5,1.5,0,0,0-5775.5,9079Z" transform="translate(5784.001 -9076.999)" fill="#fff"/>
                        </svg>
                            {{scopeData[config.fields[col][row].model].validity.msg}}
                        </div>
                        <div class="date-wrapper">
                            <div class="date-select" [ngClass]="{'date-wrapper-exp' : scopeData[config.fields[col][row].model].period?.id !== 4}">
                                <label uitkLabel [for]="config.fields[col][row].id">{{config.fields[col][row].label}}</label>
                               
                                <uitk-select
                                (onDropdownChange)='onDateChangeCallbck($event?.value,config.fields[col][row])' 
                               [name]="config.fields[col][row].name"
                                [id]="config.fields[col][row].id"
                                [(ngModel)]="scopeData[config.fields[col][row].model].period"
                                [itemList]="scopeData[config.fields[col][row].list]"
                                 defaultLabel="Select"
                                 [defaultLabelFlag]="true"
                             >
                             </uitk-select>
                            </div>
                            <div  *ngIf="scopeData[config.fields[col][row].model].period?.id === 4" class="date-input" [ngClass]="{'date-invalid' : scopeData[config.fields[col][row].model].validity.isFromInvalid && filterExpandedView.isApplyClicked}">
                                <label [for]="config.fields[col][row].fromDate"  [ngClass]="{'label-disabled': fromThroughDateValidity(config.fields[col][row].model)}" uitkLabel>From </label>
                                <div class="from-info">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="info-icon" viewBox="0 0 16 15.999">
                                        <path id="Union_3" data-name="Union 3" d="M-5784,9085a8,8,0,0,1,8-8,8,8,0,0,1,8,8,8,8,0,0,1-8,8A8,8,0,0,1-5784,9085Zm.94,0a7.068,7.068,0,0,0,7.058,7.061,7.066,7.066,0,0,0,7.059-7.061,7.067,7.067,0,0,0-7.059-7.059A7.068,7.068,0,0,0-5783.059,9085Zm6.237,4.592v-6.224h1.646v6.224Zm0-7.067V9081h1.646v1.522Z" transform="translate(5784 -9077.001)" fill="#c25608"/>
                                      </svg>
                                    <span class="tooltiptext">{{dateInfoTooltipMsg}}</span>
                                  </div>                              
                                <uitk-date-picker  [uitkId]="config.fields[col][row].name+'From'" [name]="config.fields[col][row].fromDate"
                                [(ngModel)]="scopeData[config.fields[col][row].model].fromDate" [model]="dateConfig"
                                [disabled]="fromThroughDateValidity(config.fields[col][row].model)"
                                (blur)="handleBlur($event,'fromDate',scopeData[config.fields[col][row].model])">
                            </uitk-date-picker>
                            </div>
                            <div *ngIf="scopeData[config.fields[col][row].model].period?.id === 4" class="date-input through-date" [ngClass]="{'date-invalid' : scopeData[config.fields[col][row].model].validity.isthroughInvalid && filterExpandedView.isApplyClicked}">
                                <label uitkLabel [for]="config.fields[col][row].throughDate" [ngClass]="{'label-disabled': fromThroughDateValidity(config.fields[col][row].model)}">Through </label>
                                <uitk-date-picker [uitkId]="config.fields[col][row].name+'Through'" [name]="config.fields[col][row].throughDate"
                                    [(ngModel)]="scopeData[config.fields[col][row].model].throughDate" [model]="dateConfig"
                                    [disabled]="fromThroughDateValidity(config.fields[col][row].model)" 
                                    placeholder="mmddyyyy" (blur)="handleBlur($event,'throughDate',scopeData[config.fields[col][row].model])">
                                </uitk-date-picker>
                            </div>
                        </div>
                    </ng-container>
                 </ng-container>
                 <ng-template #elseBlock>
                    <div class="form-layout">     
                    </div>
            </ng-template>
        </div>
        </div>
    </div> 
    <div class="action-btn-wrap" *ngIf="filterExpandedView.show">
        <button class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="onApplyBtnClick()" >Apply Filters</button>
        <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="onCancelBtnClick()" >Cancel</button>
    </div>

</div>