 <app-mde-expand *ngIf="selectedRow?.rowExpandType === 'mde'" [selectedRow]="selectedRow">
</app-mde-expand>
 <app-error-crr-header-catg *ngIf="selectedRow?.rowExpandType === 'edit' && selectedRow?.errCategory === 'Claim Header'" [selectedRow]="selectedRow"
 [routeParam]="routeParam">
</app-error-crr-header-catg>
<app-err-crr-service-catg *ngIf="selectedRow?.rowExpandType === 'edit' && selectedRow?.errCategory?.indexOf('Claim Service') !== -1" [selectedRow]="selectedRow"
[routeParam]="routeParam">
</app-err-crr-service-catg>
<app-error-crr-provider-catg *ngIf="selectedRow?.rowExpandType === 'edit' && selectedRow?.errCategory === 'Provider'" [selectedRow]="selectedRow"
[routeParam]="routeParam">
</app-error-crr-provider-catg>
<app-err-crr-member-catg *ngIf="selectedRow?.rowExpandType === 'edit' && selectedRow?.errCategory === 'Subscriber'" [selectedRow]="selectedRow"
[routeParam]="routeParam"></app-err-crr-member-catg>

