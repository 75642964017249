import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DOCUMENT, DatePipe } from '@angular/common';

@Component({
  selector: 'app-error-crr-add-claim-dates',
  templateUrl: './error-crr-add-claim-dates.component.html',
  styleUrls: ['./error-crr-add-claim-dates.component.css']
})
export class ErrorCrrAddClaimDatesComponent implements OnInit ,AfterViewInit {
  @Output() public collapseAddClaimDatesEditor = new EventEmitter<any>();
  @Input() inputLabel;
  @Input() inputValue;
  @Input() additionalClaimDateDesMapping;
  @Input() additionalClaimDateTimeData;
  @Input() changeBtnCls: string;
  @Input() getAddClaimDate;
  @Input() isInstitutional;
  @Input() encodeDateFormat;
  @Input() isServiceLineCategory:boolean;
  @Input() listData;

  public addClaimDateForm;
  public inputFieldQualifier = "096";
  public qualifier361 = "361";
  public codeThroughQulList: any = ['434','314','361'];
  viewLoaded :boolean;
  private dateFormat = 'yyyy-MM-dd';
  constructor(private datePipe: DatePipe) { }

  ngOnInit() {
    this.initializeForm();
  }

  ngAfterViewInit(){
    this.viewLoaded = true;
  }

  cancelClick() {
    const event = {
      type: 'cancel',
      field: this.inputLabel
    }
    this.collapseAddClaimDatesEditor.emit(event);
  }
  initializeForm() {
    const formControls:any= {
      dateFromField: new UntypedFormControl('', [this.getValidators('', '', this.displayCodeFrom(), '0')]),
      dateThroughField: new UntypedFormControl('', [this.getValidators('', '', this.displayCodeThroughDate(), '0')]),
      hoursField: new UntypedFormControl('', [this.getValidators('2', '^([0-1]?[0-9]|2[0-3])$', false, '0')]),
      minutesField: new UntypedFormControl('', [this.getValidators('2', '^([0-5]?[0-9]|[0-5][0-9])$', false, '0')]),
    }
    if(this.isDTTMFormatQualEditable()){
      let DTTM_FormatValue='';
      this.listData["dateTimeFormatCode"].forEach((element, index) => {
        if (this.inputValue.dateTimeFormatQual === element.value) {
          DTTM_FormatValue = element;
        }
      });
      formControls["dateTimeFormatQual"] = new UntypedFormControl(DTTM_FormatValue, [this.getValidators("", "", true,'0')])
    }
    this.addClaimDateForm = new UntypedFormGroup(formControls);
    if (this.displayInputField()) {
      const inputValues = this.isDTTMFormatQualEditable() ?  this.inputValue.dateTimePeriod.substr(8,4).match(/.{1,2}/g):this.inputValue.dateTimePeriod.match(/.{1,2}/g) ;
      if(inputValues){
        this.addClaimDateForm.controls.hoursField.setValue(inputValues[0]);
        this.addClaimDateForm.controls.minutesField.setValue(inputValues.length > 1 ? inputValues[1] : '');
      }
    }

    if (this.displayCodeFrom()) {
      this.addClaimDateForm.controls.dateFromField.setValue(this.datePipe.transform(this.getAddClaimDate(this.inputValue), this.dateFormat));
    }
    if (this.displayCodeThroughDate()) {
      this.addClaimDateForm.controls.dateThroughField.setValue(this.datePipe.transform((this.getAddClaimDate(this.inputValue, this.displayCodeFrom())),this.dateFormat));
    }
  }

  getValidators(maxLength, pattern, required?, minLength?) {
    const validators: ValidatorFn[] = [];
    if (required) {
      validators.push(Validators.required);
    }
    if (minLength !== undefined) {
      validators.push(Validators.minLength(minLength));
    }
    if (maxLength) {
      validators.push(Validators.maxLength(maxLength));
    }
    if (pattern) {
      validators.push(Validators.pattern(pattern));
    }
    return Validators.compose(validators);
  }

  onDropdownChangeCallbck(event) {
    this.addClaimDateForm.get("hoursField").setValue("00");
    this.addClaimDateForm.get("minutesField").setValue("00");
    this.validate();
  }

  validate() {
    const event = {
      type: 'validation',
      isInvalid: this.addClaimDateForm.status === "INVALID",
      field: this.inputLabel
    }
    this.validateFields(event);
    this.collapseAddClaimDatesEditor.emit(event);
  }

  isInvalidInput() {
    if (this.displayInputField()) {
      const hours = this.addClaimDateForm.get('hoursField').value;
      const minutes = this.addClaimDateForm.get('minutesField').value;
      return hours === "" && minutes === "";
    }
  }

  validateFields(event) {
    if (this.displayInputField()) {
      event['time'] = this.isInvalidInput() || this.addClaimDateForm.controls.hoursField.status === 'INVALID' 
      || this.addClaimDateForm.controls.minutesField.status === 'INVALID';
    }  
    if(this.displayCodeFrom()){
      event['dateFrom'] = this.addClaimDateForm.controls.dateFromField.status === 'INVALID';
    }
    if (this.displayCodeThroughDate()) {
      event['dateThrough'] = this.isDateRangeInvalid() || this.addClaimDateForm.controls.dateThroughField.status === 'INVALID';
    }
    if(this.isDTTMFormatQualEditable()){
      event['dateTimeFormatQual'] = this.addClaimDateForm.controls.dateTimeFormatQual.status === 'INVALID';
    }
  }

  changeClick() {
    const event = {
      type: 'change',
      value: this.inputValue,
      field: this.inputLabel,
      isInvalid: false
    }
    event.isInvalid = this.isDateRangeInvalid() || this.isInvalidInput() || this.addClaimDateForm.status === "INVALID";
    if (!event.isInvalid) {
      this.updateAddClaimDateData(event);
    } else {
      this.validateFields(event);
    }
    this.collapseAddClaimDatesEditor.emit(event);
  }

  updateAddClaimDateData(event){
    if (this.displayInputField() && !this.displayCodeFrom()) {
      this.inputValue.dateTimePeriod = this.setInputFieldValue();
    } 
    if(this.displayCodeFrom()){
      this.inputValue.dateTimePeriod = this.encodeDateFormat(this.addClaimDateForm.get('dateFromField').value);
      if(this.displayInputField()){
        this.inputValue.dateTimePeriod += this.setInputFieldValue();
      }
    }
    if (this.displayCodeThroughDate()) {
      const dateFrom = this.displayCodeFrom() ? this.inputValue.dateTimePeriod + '-' : ""; 
      this.inputValue.dateTimePeriod =  dateFrom+ this.encodeDateFormat(this.addClaimDateForm.get('dateThroughField').value);
    }
    if(this.isDTTMFormatQualEditable()){
      this.inputValue.dateTimeFormatQual = (this.addClaimDateForm.get("dateTimeFormatQual").value).value;
    }
    event.value = this.inputValue;
  }

  setInputFieldValue() {
    const hoursValue = this.addClaimDateForm.get('hoursField').value;
    const minutesValue = this.addClaimDateForm.get('minutesField').value;
    const hours = (hoursValue === "" ? "00" : hoursValue.length === 1 ? "0" : "") + hoursValue;
    const minutes = (minutesValue === "" ? "00" : minutesValue.length === 1 ? "0" : "") + minutesValue;
    return hours + "" + minutes;
  }
  displayCodeThroughDate() {
    return this.isServiceLineCategory ? this.inputValue.dateTimeFormatQual === 'RD8' : this.codeThroughQulList.indexOf(this.inputValue.dateTimeQualifier) !== -1;
  }

  displayInputField() {
    return this.isInstitutional && (this.inputValue.dateTimeQualifier === this.inputFieldQualifier || (this.inputValue.dateTimeQualifier === "435" && this.addClaimDateForm && this.addClaimDateForm.get("dateTimeFormatQual").value && (this.addClaimDateForm.get("dateTimeFormatQual").value).value === "DT" ));
  }

  displayCodeFrom(){
    return this.isServiceLineCategory || ( this.inputValue.dateTimeQualifier !== this.qualifier361 && 
      !(this.isInstitutional && this.inputValue.dateTimeQualifier === this.inputFieldQualifier) ) ;
  }
  isDTTMFormatQualEditable(){
    return this.inputValue.dateTimeQualifier === "435" && this.isInstitutional;
  }

  mousedownEvent(event) {
    event.preventDefault();
  }

  getFormControlValidity(frmControlNm) {
    let isInvalid: boolean = false;
    isInvalid = this.addClaimDateForm && this.addClaimDateForm.controls[frmControlNm].status === "INVALID";
    if (isInvalid && !this.viewLoaded) {
      this.validate();
    }
    return isInvalid;
  }

  isDateRangeInvalid() {
    if (this.displayCodeThroughDate() && this.displayCodeFrom()) {
      const fromDate = new Date(this.addClaimDateForm.get('dateFromField').value).getTime();
      const throughDate = new Date(this.addClaimDateForm.get('dateThroughField').value).getTime();
      return isNaN(throughDate) || isNaN(fromDate) || throughDate < fromDate;
    }
  }
}
