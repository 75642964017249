<div class="load-more-wrapper">
    <button  class="load-more uitk-c-button uitk-c-button--sm uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
    (click)="onLoadMore()">Load More</button>
</div>

<div>
<uitk-dialog [(showModal)]="customCloseDialogModal.show" tkDisableCloseOnEsc='true' tkShowHeader="false" uitkId='myDialog-4'
             tkTriggerElementId='myDialog_openBtn4' tkOwnContent="true">

    <uitk-dialog-content>
        <div>
            <div *ngIf="maxRowLimit">
                <h3>Well this is embarrassing...</h3>
            </div>
            <div *ngIf="!maxRowLimit">
                <p>
                    Loading more records will return you to the first page of results and reset table sorting
                </p>
            </div>

            <div *ngIf="maxRowLimit">
                <p>
                    We can’t load any more records into the application. Export or try adjusting your filters to limit results
                </p>
            </div>

            
        </div>
    </uitk-dialog-content>
    <uitk-dialog-actions>
        <div class="row" *ngIf="!maxRowLimit">
            <button class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="confirmLoadMoreRecords()">Confirm</button>
            <button  class="uitk-c-button uitk-c-button--sm uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
            (click)="customCloseDialogModal.show = false">Cancel</button>
          
        </div>
        <div class="row" *ngIf="maxRowLimit">
            <button class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="exportResults()" >Export Results</button>
            <button  class="uitk-c-button uitk-c-button--sm uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
            (click)="customCloseDialogModal.show = false">Cancel</button>
            
        </div>
    </uitk-dialog-actions>
</uitk-dialog>
</div>