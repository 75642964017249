import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { retry, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Constants } from '../data-models/constants.enum';
import { OauthResponse } from './auth/oauthResponse.model';

type AppStatusResponseModel = {
  isMaintenance :string
}

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  baseServerUrl: string;
  appStatusSubscription: Observable<AppStatusResponseModel>;
  private readonly stopFetchingAppStatus = new Subject();
  onloadAppStatusCalled: boolean = false;
  isAppDown: boolean = false;
  private readonly downTimeStatusUrl: string = "/v1/downTime/downTimeStatus";
  
  constructor(private readonly http: HttpClient) {
  }
 
  getBaseServerUrl() {
    const resp: OauthResponse = JSON.parse(sessionStorage.getItem("oAuthResponse"));
    if (resp.stargateEnabled) {
      this.baseServerUrl = environment.starGateUrl+Constants.maintenanceServiceBaseUrlSuffix;
    }
    else {
      this.baseServerUrl = environment.backedBaseUrl+Constants.maintenanceServiceBaseUrlSuffix;
    }
  }
  getNotificationData(){
    this.getBaseServerUrl();
    return this.http.get(`${this.baseServerUrl}/v1/notification/fetchNotifications`);
  }
  async getAppStatus() {
    this.getBaseServerUrl();
    await this.http.get<AppStatusResponseModel>(`${this.baseServerUrl}${this.downTimeStatusUrl}`).toPromise().then((res) => {
      this.isAppDown = res.isMaintenance === "Y";
    },
    ()=> { this.isAppDown = false;}
    )
    return this.isAppDown;

  }
  appStatusPolling() {
    this.getBaseServerUrl();
    this.appStatusSubscription = timer(120000,120000).pipe(
      switchMap(() => this.http.get<AppStatusResponseModel>(`${this.baseServerUrl}${this.downTimeStatusUrl}`)
      ),
      retry(10),
      takeUntil(this.stopFetchingAppStatus)
    );
    return this.appStatusSubscription;
  }

  stopAppStatusPolling(){
    this.stopFetchingAppStatus.next();
  }
  ngOnDestroy() {
    this.stopAppStatusPolling();
  }
}
