import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { OauthResponse } from './auth/oauthResponse.model';
import { Constants } from '../data-models/constants.enum';

@Injectable({ 
    providedIn: 'root' 
})

export class  ClientService {

    private baseUrl = "";
    constructor(private readonly http: HttpClient) {
      
    }

    getBaseServerUrl(){
        const resp : OauthResponse = JSON.parse(sessionStorage.getItem("oAuthResponse"));
        if(resp.stargateEnabled){
            this.baseUrl = environment.starGateUrl+Constants.reportingServiceBaseUrlSuffix;
        }
        else{
            this.baseUrl = environment.backedBaseUrl+Constants.reportingServiceBaseUrlSuffix;
        }
    }

    getClients(optumId){
        console.log("inside getClients",this.baseUrl);
        this.getBaseServerUrl();
        return this.http.get<any[]>(`${this.baseUrl}/v1/clients/${optumId}`);
    }

    getSubClients(optumId, partySK){
        this.getBaseServerUrl();
        return this.http.post<any[]>(`${this.baseUrl}/v1/subclients`, {partySK,optumId});
    }

    getStatusList(){
        this.getBaseServerUrl();
        return this.http.get<any[]>(`${this.baseUrl}/file-status`);
    }
    acceptTermCondition(optumID,email){
        this.getBaseServerUrl();
        return this.http.post<any>(`${this.baseUrl}/v1/userprincipals/acceptTnC`,{optumID,email});
    }
}