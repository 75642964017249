<ngx-charts-bar-vertical-stacked [view]="view" [scheme]="colorScheme" [results]="multi" [gradient]="showGradient" [xAxis]="showXAxis"
    [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel" [trimXAxisTicks]="trimXAxisTicks" [barPadding]="barPadding" [yAxisTickFormatting]="yAxisTickFormattingFn" [xAxisTickFormatting]="xAxisTickFormattingFn">
    <ng-template #tooltipTemplate let-model="model">
        <span *ngIf="model.value === null">Data not available</span>
        <div *ngIf="model.value !== null" class="pop-up-cls">
            <div *ngIf="!model.month"><strong>Date:</strong> &nbsp;&nbsp; <span>{{model.toolTip}}</span></div>
            <div *ngIf="model.month && model.partial && scopeData.isSingleMonthDateRange">
                <strong>Start From:</strong> &nbsp;&nbsp; <span>{{scopeData.submittedStartDate | date:'MMMM d, yyyy'}}</span>
            </div>
            <div *ngIf="model.month && model.partial">
                <strong>{{model.position}}:</strong> &nbsp;&nbsp; <span>{{model.toolTip}}</span>
            </div>
            <div *ngIf="model.month && !model.partial"><strong>Date:</strong> &nbsp;&nbsp; <span>{{model.toolTip}}</span></div>
            <div><strong>{{model.labelRcvd}}:</strong> &nbsp;&nbsp;<span>{{model.labelRcvdValue}}</span></div>
            <div><strong>{{model.labelCmpltd}}:</strong> &nbsp;&nbsp;<span>{{model.labelCmpltdValue}}</span></div>
        </div>
    </ng-template>
    <ng-template #seriesTooltipTemplate let-model="model">
        <span *ngIf="model.value === null">Data not available</span>
        <div *ngIf="model.value !== null">
            <div *ngFor="let m of model" class="pop-up-cls">
                <div><strong>Date:</strong> &nbsp;&nbsp; <span>{{m.toolTip}}</span></div>
                <div><strong>{{m.position}}:</strong> &nbsp;&nbsp;<span>{{m.value}}</span></div>
            </div>
        </div>
    </ng-template>
</ngx-charts-bar-vertical-stacked>