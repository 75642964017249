import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { MaintenanceService } from '../maintenance.service';
import { OAuthService } from './oauth.service';
import { UserPrincipal } from './userPrincipal.model';


@Injectable()
export class OAuthGuard implements CanActivate, CanActivateChild {
    constructor(private readonly myRoute: Router, private readonly oauthService: OAuthService, private readonly maintenanceService: MaintenanceService) {
        this.myRoute.routeReuseStrategy.shouldReuseRoute = () => false;
    }


    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.oauthService.isUserSessionValid()) {
            const isAppDown = await this.maintenanceService.getAppStatus();
            if (isAppDown) {
               this.navigateToMaintenance(route);
                return route.routeConfig.path === "maintenance";
            }
            return this.oauthService.isTermsAccepeted();
        } else {
            this.oauthService.clearUserDataStorage();
            localStorage.setItem('userInfo', 'fetching...');
            const authCode: string = this.fetchAuthCodeFromUrl(route);
            await this.oauthService.processAuth(authCode);
            const isAppDown = await this.maintenanceService.getAppStatus();
            if (isAppDown) {
                this.navigateToMaintenance(route);
                return route.routeConfig.path === "maintenance";
             }
            console.log('*****auth done*****');
            return this.oauthService.isTermsAccepeted();
        }

    }


    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(route, state);
    }

    navigateToMaintenance(route){
        if (route.routeConfig.path !== "maintenance") {
            this.myRoute.navigateByUrl(`/maintenance`);
        }
    }

    fetchAuthCodeFromUrl(route: ActivatedRouteSnapshot): string {
        let authCode = null;
        if (route === null || route === undefined || route.queryParams === undefined || route.queryParams['code'] === undefined) {
            const urlData: string[] = window.location.href.split('?');
            if (urlData.length > 1) {
                const queryParams: string[] = urlData[1].split('&');
                queryParams.forEach(function (queryParam) {
                    if (queryParam.split('=')[0].toLowerCase() === 'code') {
                        authCode = queryParam.split('=')[1];
                    }
                })
            }
        }
        else {
            authCode = route.queryParams['code'];
        }
        return authCode;
    }
}
