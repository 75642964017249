import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { BarChartsComponent } from '../../charts/bar-charts/bar-charts.component';
import { SummaryService } from '../../services/tabs/summary.service';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { Constants } from '../../data-models/constants.enum';
import { LineChartComponent } from '../../charts/lineCharts/lineCharts.component';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';

@Component({
  selector: 'app-crr-cms-accepted',
  templateUrl: './crr-cms-accepted.component.html',
  styleUrls: ['./crr-cms-accepted.component.css']
})

export class CrrCmsAcceptedComponent implements OnInit {

  @Input()
  public data: any;

  @ViewChild(LineChartComponent, { static: false })
  public lineChart: LineChartComponent;

  @ViewChild(BarChartsComponent, { static: false })
  public barChart: BarChartsComponent;

  private graphSeriesId: number[];

  aggData: any;

  selection = {
    isExt:true,
    isIOA:false,
    isOptChart:false
  };

  constructor(private summaryService: SummaryService,
    @Inject(SESSION_STORAGE) private sessionStorage: WebStorageService) { }

  ngOnInit() {
    const prtySk = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.clientName).value;
    const isChildDataIncluded = this.sessionStorage.get(Constants.includeChildData);
    const clmDtCriteriaId = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.viewingBy).id;
    const timeCriteriaId = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod).id;
    const startDate = this.sessionStorage.get(Constants.fromDate);
    const endDate = this.sessionStorage.get(Constants.toDate);
    this.sessionStorage.set(Constants.yAxisLabel, JSON.stringify("Accepted Claims"));
    this.sessionStorage.set(Constants.seriesName, JSON.stringify("External Supplemental"));
    this.graphSeriesId = [11];
    this.sessionStorage.set(Constants.graphSeriesId, this.graphSeriesId);
    this.sessionStorage.set(Constants.tabsSelectedIndex, 6);

    this.summaryService.getCrrAcceptedTabStats(prtySk, isChildDataIncluded, clmDtCriteriaId, timeCriteriaId, startDate, endDate, 0).subscribe((data: any) => {
      this.aggData = data;
    });
    this.data["frequencyChange"] = this.reloadGraph.bind(this);
  }

  isBarChartVissible() {
    return false;
  }

  reloadGraph(seriesName) {
   /*  this.sessionStorage.set(Constants.seriesName, JSON.stringify(seriesName));
    if (Constants.Replaced === seriesName) {
      this.graphSeriesId = [9];
    } else if (Constants.PerClientRequest === seriesName) {
      this.graphSeriesId = [10];
    }
    if (this.isBarChartVissible()) {
      this.barChart.reloadGraph(this.graphSeriesId);
    } else {
      this.lineChart.reloadGraph(this.graphSeriesId);
    } */

  }
  exportData(event){
    event.preventDefault();
  }

}

