import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Constants } from '../data-models/constants.enum';
import { FileService } from '../services/file.service';
import { Subscription } from 'rxjs';
import { ConfirmModelConfig } from './confirm.mdelconfig';


@Component({
  selector: 'app-filter-confirm',
  templateUrl: './filter-confirm.component.html',
  styleUrls: ['./filter-confirm.component.css']
})
export class FilterConfirmComponent implements OnInit {
  @Input()
  selectionDetails:any;
  @Input()
  config: ConfirmModelConfig;
  @Input()
  filterExpandedView :any;
  @Output()
  emitClosePopup  = new EventEmitter<any>();
  constants = Constants;
  filterRecodAPIStatusSubscription: Subscription;
  constructor(private readonly fileService: FileService) { }

  ngOnInit() {
    this.subscribeFilterRecodAPIStatus();
  }

  closeConfirmPopup(action){
    this.config.show = false;
    this.config.showSelectedFilters = false;
    this.config.isLoading = false;
    this.config.isLoadingSinceMoreThan8Secs = false;
    this.config.showExportPopup = false;
    this.filterExpandedView.isApplyClicked = false;
    if(action === Constants.refineFilters){
      this.filterExpandedView.show = true;
    }
  }

  displayResults(action){
    this.config.showSelectedFilters = false;
    this.config.isLoading = true;
    this.filterExpandedView.show = false;
    this.emitClosePopup.emit({action});
  }
  exportResultsClick(){
    this.config.isLoading = true;
    this.filterExpandedView.show = false;
    this.config.showSelectedFilters = false;
    this.emitClosePopup.emit({action:Constants.export});
  }

  subscribeFilterRecodAPIStatus(){
    this.filterRecodAPIStatusSubscription = this.fileService.subscribeFilterRecodAPIStatus().subscribe((data: any )=>{
      this.config.isLoading = data.loading;
      this.config.showExportPopup = data.export;
      this.config.selectedRecordsCount = data.selectedRecords;
      this.config.isLoadingSinceMoreThan8Secs = data.longerThan8Secs;
      this.config.show = !data.received;
      if(data.export){
        setTimeout(()=>{
          this.config.show = false;
          this.config.showExportPopup = false;
        },5000)
      }
    })
  }
  getExportHeadingMsg(){
    return this.config.selectedRecordsCount > 0 ? `Exporting ${this.config.selectedRecordsCount} Record${this.config.selectedRecordsCount>1?"s":""}` : "Export Process Initiated";
  }

  ngOnDestroy(){
    this.filterRecodAPIStatusSubscription.unsubscribe()
  }
}
