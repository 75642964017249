import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { SelectItem } from '../data-models/select.item';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ReportSummary } from '../data-models/report.summary';
import { ReportNotSubmittable } from '../data-models/report.notsubmit';
import { ReportCmsProcessed } from '../data-models/report.cmsprocessed';
import { ReportError } from '../data-models/report.error';
import { ReportDuplicatesClaims } from '../data-models/report.duplicatesclaims'
import { ReportClosedClaims } from '../data-models/report.closedclaims'
import { ErrorSummaryCriteria } from '../data-models/error.summarycriteria';
import { ErrorCodeSummary } from '../data-models/errorcodesummary';
import { ErrorCodeDetails } from '../data-models/errorcodedetails';
import { OauthResponse } from './auth/oauthResponse.model';
import { Constants } from '../data-models/constants.enum';

@Injectable({
    providedIn: 'root'
})

export class ReportsService {

    private baseUrl = "";
    constructor(private readonly http: HttpClient) {
       
    }
    
    getBaseServerUrl(){
        const resp : OauthResponse = JSON.parse(sessionStorage.getItem("oAuthResponse"));
        if(resp.stargateEnabled){
            return environment.starGateUrl+Constants.reportingServiceBaseUrlSuffix;
        }
        else{
            return environment.backedBaseUrl+Constants.reportingServiceBaseUrlSuffix;
        }
    }

    getReportData(clientSk, timeFrame, processStatus): Observable<ReportCmsProcessed[]> {
        this.baseUrl = this.getBaseServerUrl();
        return this.http.get<ReportCmsProcessed[]>(`assets/data/table-demo-data-cmsprocessed.json`);
    }
    
    getReportMockErrorCodeSummaryData(): Observable<ErrorCodeSummary[]> {
        this.baseUrl = this.getBaseServerUrl();
        return this.http.get<ErrorCodeSummary[]>(`assets/data/table-demo-data-error-code-summary.json`);
    }

    getReportMockErrorCodeDetails(): Observable<ErrorCodeDetails[]> {
        this.baseUrl = this.getBaseServerUrl();
        return this.http.get<ErrorCodeDetails[]>(`assets/data/table-demo-error_code_details.json`);
    }

    getReportMockErrorData(fileName,responseCategory): Observable<ReportError[]> {
        this.baseUrl = this.getBaseServerUrl();
        return this.http.get<ReportError[]>(`assets/data/table-demo-data-error.json`);
    }

    getReportClosedClaimsData(fileName, state, prtySk): Observable<ReportClosedClaims[]> {
        this.baseUrl = this.getBaseServerUrl();
        return this.http.post<ReportClosedClaims[]>(`${this.baseUrl}/getClosedDemographicDetails`,{ fileName, state, prtySk });
    }

    getReportErrorData(fileName,responseCategory, prtySk): Observable<ReportError[]> {
        this.baseUrl = this.getBaseServerUrl();
        return this.http.post<ReportError[]>(`${this.baseUrl}/getFileErrorDetails`, { fileName, responseCategory, prtySk });
    }

    getReportDuplicatesData(fileName,status,prtySk) : Observable<ReportDuplicatesClaims[]> {
        this.baseUrl = this.getBaseServerUrl();
        return this.http.post<ReportDuplicatesClaims[]>(`${this.baseUrl}/getFileDemographicDetails`,{ fileName, status, prtySk });
    }

    getReportNotSubmitData(clientSk, timeFrame, processStatus): Observable<ReportNotSubmittable[]> {
        this.baseUrl = this.getBaseServerUrl();
        return this.http.post<ReportNotSubmittable[]>(`${this.baseUrl}/getFileErrorStatus`, { timeFrame, clientSk, processStatus });
    }

    getFilesSummaryData(clientSk, timeFrame): Observable<ReportSummary[]> {
        this.baseUrl = this.getBaseServerUrl();
        return this.http.post<ReportSummary[]>(`${this.baseUrl}/getFileDetails`, { timeFrame, clientSk });
    }

    getReportCmsData(clientSk, timeFrame, processStatus): Observable<ReportCmsProcessed[]> {
        this.baseUrl = this.getBaseServerUrl();
        return this.http.post<ReportCmsProcessed[]>(`${this.baseUrl}/getCmsFileStats`, { timeFrame, clientSk, processStatus });
    }

    getErrorCountsByCategory(partySK, clmDtCriteriaId, timeCriteriaId, startMonth, startYear, endMonth, endYear) {
        this.baseUrl = this.getBaseServerUrl();
        return this.http.post<any[]>(`${this.baseUrl}/getErrCntsByCategory`, { partySK,clmDtCriteriaId,timeCriteriaId,startMonth,startYear,endMonth,endYear });
    }

    getErrorCategorySummaryResponse(partySK, clmDtCriteriaId, respCtgCriteriaId, timeCriteriaId, startMonth, startYear, endMonth, endYear) {
        this.baseUrl = this.getBaseServerUrl();
        return this.http.post<any[]>(`${this.baseUrl}/getErrCatgSmryResponse`, 
        { partySK, clmDtCriteriaId, respCtgCriteriaId, timeCriteriaId, startMonth, startYear, endMonth, endYear });
    }

    getErrorCodeDetails(partySK, clmDtCriteriaId, respCtgCriteriaId, fromDate, toDate, errorLocation, errorCategory, errorCode) {
        this.baseUrl = this.getBaseServerUrl();
        return this.http.post<any[]>(`${this.baseUrl}/getErrorCodeDetails`, 
        { partySK, clmDtCriteriaId, respCtgCriteriaId, fromDate, toDate, errorLocation, errorCategory, errorCode });
    }
}