import { Component, OnInit, Inject, Input } from '@angular/core';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { SummaryService } from 'src/app/services/tabs/summary.service';
import { Constants } from 'src/app/data-models/constants.enum';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';

@Component({
  selector: 'app-crr-claim-inprogress',
  templateUrl: './crr-claim-inprogress.component.html',
  styleUrls: ['./crr-claim-inprogress.component.css']
})
export class CrrClaimInprogressComponent implements OnInit {
  @Input()
  public data: any;

  inprogressData: any;

  constructor(@Inject(SESSION_STORAGE) 
  private readonly sessionStorage: WebStorageService,
  private readonly summaryService: SummaryService) {
    
   }

  ngOnInit() {
    const partySk = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.clientName)?.value;
    const isChildDataIncluded = this.sessionStorage.get(Constants.includeChildData);
    const year_val = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod)?.value;
    const startDate = this.sessionStorage.get(Constants.fromDate);
    const endDate = this.sessionStorage.get(Constants.toDate);
    this.summaryService.getCRRClaimInProgessDetails(partySk,isChildDataIncluded,year_val,startDate,endDate).subscribe(data => {
      this.inprogressData = data;
    });
  }

}
