<div class="row margin-top-11">
    <div class="col-md-4 padding-left-3 width-left-3">
        <div><span class="chart-title">Duplicate Claim Types</span><span class="margin-left-15"><a [routerLink]="['/duplicate-claims']">View Details</a></span></div>
    </div>
    <div class="col-md-8 duplicate-radio-wrapper">
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="rd_1" name="rd" class="custom-control-input" (change)="reloadGraph('Inbound Duplicate Claims')" checked="checked">
            <label class="custom-control-label red" for="rd_1">Inbound Duplicate Claims</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="rd_2" name="rd" class="custom-control-input" (change)="reloadGraph('CMS Duplicate Claims')">
            <label class="custom-control-label red" for="rd_2">CMS Duplicate Claims</label>
        </div>
      </div>
</div>
<div class="margin-bottom-35 graph-wrapper">
    <line-chart [hidden]="isBarChartVissible()" [isHidden]="isBarChartVissible()"></line-chart>
    <app-bar-charts [scopeData]="data" [hidden]="!isBarChartVissible()" [isHidden]="!isBarChartVissible()"></app-bar-charts>
</div>
<app-graph-switch-frequency [scopeProps]="data"></app-graph-switch-frequency>
<div class="row">
    <div class="col-md-4 padding-left-3" *ngFor="let tab of tabs">
        <div><span>{{tab.tabTitle}}</span></div>
        <div class="count-title"> <span>{{tab.headerText}}</span></div>
        <div class="border-top-title"></div>
        <div *ngFor="let claimCount of tab.aggregatedMsg"> 
        <div class="row"> 
            <div *ngIf="claimCount.name=='Inbound Duplicate Claims'" class="col-md-12 clms-txt-cls left-align">{{claimCount.value}} Claims</div>
            <div *ngIf="claimCount.name!='Inbound Duplicate Claims'" class="col-md-12 clms-txt-cls"> {{claimCount.value}} {{claimCount.name}} Claims
        </div>
        </div>
        </div>
    </div>
</div>