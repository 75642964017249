import { Injectable } from '@angular/core';
import { OauthResponse } from './auth/oauthResponse.model';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { errServicegroupCodeDta } from '../error-crr-expand/err-crr-service-catg-expand/err-crr-service-catg/err-crr-service-catg.component.mock';
import { map } from 'rxjs/operators';
import { Constants } from '../data-models/constants.enum';

@Injectable({
    providedIn: 'root'
  })
export class ErrorCorrectionService {

    private  baseServerUrl = environment.baseServerUrl;
    
    constructor(private readonly http: HttpClient) { }
    getBaseServerUrl(){
        const resp : OauthResponse = JSON.parse(sessionStorage.getItem("oAuthResponse"));
        if(resp.stargateEnabled){
            this.baseServerUrl = environment.starGateUrl+"/correction/v1"
        }
        else{
            this.baseServerUrl = environment.backedBaseUrl+"/correction/v1"
        }
    }

    getErrorCorrectionDetails(clmType,medClmSk,prtySk) {
        this.getBaseServerUrl();
        return this.http.post<any>(`${this.baseServerUrl}/ErrorCorrectionDetails`,{clmType,medClmSk,prtySk});
    }


    isClaimClosed(medClmSk ,isMDE :boolean =false){
      let medClmSkArr = [];
      if(isMDE){
        medClmSkArr = medClmSk;
      }else{
        medClmSkArr.push(medClmSk);
      }    
      this.getBaseServerUrl();
    return this.http.post<any>(`${this.baseServerUrl}/isClaimClosed`,medClmSkArr);
    }

  errorFieldHighlight(clmRec,clmDtCriteriaId) {
    this.getBaseServerUrl();
    let claimCategory = clmRec.errCategory;
    if(claimCategory.includes("Claim Service")){
      claimCategory = claimCategory.split(" (")[0];
    }
    return this.http.post<any>(`${this.baseServerUrl}/ErrorFieldHighlight`, { prtySk:clmRec.partySk,medClmSk:clmRec.medClmSk, responseCategory:clmRec.errType, clmType:clmRec.clmType,fileKey:clmRec.fileKey,julianNum:clmRec.julianNum, clmDtCriteriaId, claimCategory});
  }

  updateClaimStatus(clmList){
      this.getBaseServerUrl();
     return this.http.post<any>(`${this.baseServerUrl}/UpdateClaimStatus`,clmList); 
   
    }

    getfrequencyStatusCode(){
        this.getBaseServerUrl();
        return this.http.get(`${this.baseServerUrl}/frequencyStatusCode`);
      }

     getAdmissionTypeCode(){    
        this.getBaseServerUrl();
        return this.http.get<any[]>(`${this.baseServerUrl}/admissionTypeCode`);
     }
     
     getAdmissionStatusCode(){
        this.getBaseServerUrl();
        return this.http.get<any[]>(`${this.baseServerUrl}/admissionStatusCode`);
     }

     getDischargeStatus(){
        this.getBaseServerUrl();
        return this.http.get<any[]>(`${this.baseServerUrl}/dischargeStatus`);
     }

     getPlaceOfService(clmType){
        this.getBaseServerUrl();
        return this.http.get<any[]>(`${this.baseServerUrl}/plcOfSrvAndFacility/${clmType}`);
     }

 


  getEntityTypeCode(catagory,claimType,uiRefCol) {
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerUrl}/fetchEntityTypeCode`,{catagory,claimType,uiRefCol});
  }


     getState(){
        this.getBaseServerUrl();
        return this.http.get<any[]>(`${this.baseServerUrl}/state`);
     }
    
     getQualifierdropdown(catagory,claimType,uiSection,uiRefCol,isDropdown = false){
      this.getBaseServerUrl();
      return this.http.post<any[]>(`${this.baseServerUrl}/qualifierdropdown`,{catagory,claimType,uiSection,uiRefCol,isDropdown});
   }

   getAllQualifierDes(catagory,claimType,uiSection,uiRefCol,isDropdown = false){
     return this.getQualifierdropdown(catagory,claimType,uiSection,uiRefCol,isDropdown).pipe(
       map((data) => {
         return this.transformIntoKeyValuePair(data)
       })
     )
   }
   transformIntoKeyValuePair(data){
     const obj={}
     for(const key in data){
      obj[data[key]["value"]] = data[key]["label"]
     }
     return obj;
   }

   getGroupCode(catagory,claimType,uiSection){
     if(catagory === "SERVICE"){
       return of(errServicegroupCodeDta);
     }
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerUrl}/groupCodes`,{catagory,claimType,uiSection});
 }
 getdiagnosisDes(diagCD){
  this.getBaseServerUrl();
  return this.http.post<any[]>(`${this.baseServerUrl}/diagnosis`,{diagCD});
}

getQualifierDes(catagory,claimType,uiSection,uiRefCol,qualifierCd){
  this.getBaseServerUrl();
  return this.http.post<any[]>(`${this.baseServerUrl}/qualifiers`,{catagory,claimType,uiSection,uiRefCol,qualifierCd});
}

  saveCorrectedClaims(claimRec,userName,claimJson) {
    this.getBaseServerUrl();
    if(claimRec.errCategory.includes("Claim Service")){
    claimRec.errCategory = claimRec.errCategory.split(" (")[0];
  }
    const paramObj = {
      "medClmTypFlg": claimRec.clmType,
      "medClmSk": claimRec.medClmSk,
      "orgFileName": claimRec.fileName,
      "claimId": claimRec.clmId,
      "errorCategory": claimRec.errCategory,
      "claimCorrJson": JSON.stringify(claimJson),
      "clientGUID": "",
      "claimModId": "",
      "orgFileKey":claimRec.fileKey,
      "partySK":claimRec.partySk,
      userName
    }
    return this.http.post(`${this.baseServerUrl}/SaveEditedECDetails`,paramObj);
  }

  submitCorrectedClaims(claimRec,userName){
    this.getBaseServerUrl();
    const paramObj = {
      "medClmTypFlg": claimRec.clmType,
      "medClmSk": claimRec.medClmSk,
      "orgFileName": claimRec.fileName,
      "claimId": claimRec.clmId,
      "clientGUID": "",
      "claimModId": "",
      "orgFileKey":claimRec.fileKey,
      "partySK":claimRec.partySk,
      userName
    }
    return  this.http.post(`${this.baseServerUrl}/ReSubmitECDetails`,paramObj);
  }

  getErrorCategoryDetails(medClmSk,clmDtCriteriaId){
    this.getBaseServerUrl();
    return this.http.post(`${this.baseServerUrl}/errorDetails`,{medClmSk,clmDtCriteriaId});
  }

  lockUnlockClaim(medClmSk, partySk, updatedBy, isLock, sessionId) {
    this.getBaseServerUrl();
    this.updateRecordsInSessionStore([this.createRowsObject(partySk, medClmSk)], isLock);
    return this.http.post(`${this.baseServerUrl}/lockOrUnlockClaims`, [
      {
        medClmSks:[medClmSk],
        partySk,
        updatedBy,
        isLock,
        comment: "Edit action performed",
        sessionId,
      }
    ])
  }
  isClaimLocked(medClmSk, partySk, updatedBy) {
    this.getBaseServerUrl();
    return this.http.post(`${this.baseServerUrl}/isClaimsLockedByOtherUser`, [
      {
        medClmSks:[medClmSk],
        partySk,
        updatedBy
      }
    ]);
  }

  claimsLockedByOtherUser(medClmSksWithPartySk: any, updatedBy) {
    const claimLockStatusReq=Object.keys(medClmSksWithPartySk).map(partySk=>{
      return {
        partySk,
        medClmSks:medClmSksWithPartySk[partySk],
        updatedBy,
      };
     });
     this.getBaseServerUrl();
     return this.http.post(`${this.baseServerUrl}/isClaimsLockedByOtherUser`, claimLockStatusReq);
  }

  lockUnlockClaims(medClmSksWithPartySk: any, updatedBy, isLock, sessionId) {
    const lockUnlockClaimsReq=Object.keys(medClmSksWithPartySk).map(partySk=>{
      return {
        partySk,
        medClmSks:medClmSksWithPartySk[partySk],
        updatedBy,
        isLock,
        comment: "Edit action performed",
        sessionId,
      };
    })
    this.updateRecordsInSessionStore(this.createRowsArray(medClmSksWithPartySk), isLock);
    this.getBaseServerUrl();
    return this.http.post(`${this.baseServerUrl}/lockOrUnlockClaims`, lockUnlockClaimsReq);
  }

  isMedClmSkAvailable(item, data){
    return data[item.partySk] && data[item.partySk].indexOf(String(item.medClmSk))>-1;
  }
  
  errorCorrectionResubmitStatus(errorRecords){
    this.getBaseServerUrl();
    return this.http.post(`${this.baseServerUrl}/ErrorCorrectionResubmitStatus`,errorRecords)
  }

  getClosedMdeClaims(mdeClosedClmsList){
    const closedClaimIdList = mdeClosedClmsList.map(eachClosedRecord => eachClosedRecord['clmId']);
    const uniqueClosedClaimIdList = closedClaimIdList.filter((item,pos) => closedClaimIdList.indexOf(item) == pos);
    const closeClmIds = uniqueClosedClaimIdList.join(", ");
    const desription = uniqueClosedClaimIdList.length > 1 ?
                      `${closeClmIds} <br> These claims are no longer available for editing or resubmission. </br>` : 
                      `${closeClmIds} <br> ${Constants.closedClaimMsg} </br>`;
    return desription;
  }

  createRowsArray(medClmSksWithPartySk){
    let rowsArray = [];
    for( const eachPartySk in medClmSksWithPartySk){
      rowsArray = [...rowsArray,...medClmSksWithPartySk[eachPartySk].map(
                                    x =>  this.createRowsObject(eachPartySk, x))
                  ];
    }
    return rowsArray;
  }

  createRowsObject(partySk, medClmSk){
    return {"partySk": partySk, "medClmSk": medClmSk};
  }
  pushRecordsInSessionStore(rowsArray){
    const recordsInStore = JSON.parse(sessionStorage.getItem(Constants.sessionLockedRecords) || '[]');
    if(recordsInStore.length > 0){
      const recordsToBePushed = rowsArray.filter(eachRows => 
        !(recordsInStore.findIndex(eachRecord => 
          (Number(eachRecord['partySk']) === Number(eachRows['partySk']) && 
          Number(eachRecord['medClmSk']) === Number(eachRows['medClmSk']))) >= 0
         )
      );
      recordsInStore.push(...recordsToBePushed);
      sessionStorage.setItem(Constants.sessionLockedRecords, JSON.stringify(recordsInStore));
    }else{
      sessionStorage.setItem(Constants.sessionLockedRecords, JSON.stringify(rowsArray));
    }
  }

  popRecordsFromSessionStore(rowsArray){
    let recordsInStore = JSON.parse(sessionStorage.getItem(Constants.sessionLockedRecords) || '[]');
    if(recordsInStore.length > 0){
      const recordsToBePoped = rowsArray.filter(eachRows => 
        (recordsInStore.findIndex(eachRecord => 
          (Number(eachRecord['partySk']) === Number(eachRows['partySk']) && 
          Number(eachRecord['medClmSk']) === Number(eachRows['medClmSk']))) >= 0
        )
      );
      recordsInStore = recordsInStore.filter(eachRows => 
        !(recordsToBePoped.findIndex(eachRecord => 
          (Number(eachRecord['partySk']) === Number(eachRows['partySk']) && 
          Number(eachRecord['medClmSk']) === Number(eachRows['medClmSk']))) >= 0
        )
      );
    }
    sessionStorage.setItem(Constants.sessionLockedRecords, JSON.stringify(recordsInStore));
  }

  removeDuplicateRecords(rowsArray){
    return rowsArray.filter((eachRows,pos) => rowsArray.findIndex(eachRecord => 
      (Number(eachRecord['partySk']) === Number(eachRows['partySk']) && 
      Number(eachRecord['medClmSk']) === Number(eachRows['medClmSk']))) === pos);
  }
  updateRecordsInSessionStore(rowsArray, isLock){
    rowsArray = this.removeDuplicateRecords(rowsArray);
    isLock === Constants.claimLocked ? this.pushRecordsInSessionStore(rowsArray) : 
                                        this.popRecordsFromSessionStore(rowsArray);
  }
  
  
}