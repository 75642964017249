import { Component, Inject, Input, OnInit } from '@angular/core';
import { Constants } from 'src/app/data-models/constants.enum';
import { SummaryService } from 'src/app/services/tabs/summary.service';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';

@Component({
  selector: 'app-closed-crr-records',
  templateUrl: './closed-crr-records.component.html',
  styleUrls: ['./closed-crr-records.component.css']
})
export class ClosedCrrRecordsComponent implements OnInit {

  @Input()
  public data: any;
 
  aggData:any;

  constructor(private summaryService: SummaryService,
    @Inject(SESSION_STORAGE) private sessionStorage: WebStorageService) { }
    
  ngOnInit() {
    const partySk = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.clientName).value;
    const isChildDataIncluded = this.sessionStorage.get(Constants.includeChildData);
    const year_val = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod).value;
    const startDate = this.sessionStorage.get(Constants.fromDate);
    const endDate = this.sessionStorage.get(Constants.toDate);

    this.summaryService.getCrrClosedTabStats(partySk,isChildDataIncluded,year_val,startDate,endDate).subscribe((data:any) => {
      this.aggData = data;
    });
  }

}
