import { Component, OnInit, Input, Inject } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Constants } from '../../../data-models/constants.enum';
import { ErrorCorrectionService } from '../../../services/errorCorrectionClm.service';
import { DOCUMENT } from '@angular/common';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { MdeService } from '../../../services/tabs/mde.service';
import { ErrCrrUtility, inputTypeConfig, patterns } from '../../err-crr-utility';
import { DomSanitizer } from '@angular/platform-browser';
import { Params } from '@angular/router';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';

@Component({
  selector: 'app-error-crr-provider-catg',
  templateUrl: './error-crr-provider-catg.component.html',
  styleUrls: ['./error-crr-provider-catg.component.css']
})
export class ErrorCrrProviderCatgComponent extends ErrCrrUtility implements OnInit {
  @Input() selectedRow: any;
  @Input() routeParam: Params;
  private open: boolean = false;
  public billingProviderTax;
  private readonly bilProv:string = "BILPROV";
  public billingProKeyMapping = {
      entityTypeCode: 'loop2010NM1.entityTypeQualifier',
      firstName: 'loop2010NM1.nameFirst',
      middleName: 'loop2010NM1.nameMiddle',
      lastName: 'loop2010NM1.nameLastOrgName',
      suffix: 'loop2010NM1.nameSuffix',
      billingContactName:'loop2010PER.0.name',
      NPI:'loop2010NM1.idCode',
      TIN:'loop2010Reference.0.referenceIdent',
      billingContactInfo:'loop2010PER.0.commNumber',
      addinfo1:'loop2010Address.addressInformation1',
      addinfo2:'loop2010Address.addressInformation2',
      city:'loop2010CityStateZipCode.cityName',
      postalCode:'loop2010CityStateZipCode.postalCode',
      countryCode:'loop2010CityStateZipCode.countryCode',
      stateorProvCode:'loop2010CityStateZipCode.stateorProvCode'
  };

  public providerKeyMapping = {
    entityTypeQua: 'loop2310NM1.entityTypeQualifier',
    firstName: 'loop2310NM1.nameFirst',
    lastName: 'loop2310NM1.nameLastOrgName',
    NPI: 'loop2310NM1.idCode',
    taxonomy: 'loop2310PRV.0.referenceIdent',
    addinfo1: 'loop2310Address.addressInformation1',
    addinfo2: 'loop2310Address.addressInformation2',
    city: 'loop2310CityStateZipCode.cityName',
    postalCode: 'loop2310CityStateZipCode.postalCode',
    countryCode: 'loop2310CityStateZipCode.countryCode',
    stateorProvCode: 'loop2310CityStateZipCode.stateorProvCode'
  }

  private readonly billProvFieldLabel= {
    entityDetails : 'Entity Details',
    providerAddress:'Provider Address',
    billingContactName:'Billing Contact Name',
    billingContactInfo: 'Billing Contact Information',
    payToAddress: 'Pay To Address',
    NPI: 'NPI',
    TIN: 'TIN',
    taxonomy:'Taxonomy Code'
  }
  private readonly providerFieldLabel= {
    entityDetails : 'Entity Details',
    NPI: 'NPI',
    taxonomy:'Taxonomy Code',
    serviceFacAddr:"Service Facility Address"
  }
  public clmDta;
  public clmInfoProviderData;
  changeBtnCls: string;
  invalidFieldStatus:{[key:string]:boolean} ={};
  showHideConfig: {[key:string]:boolean} = {};
  entityId: {};
  public saveParamObj = {};
  public cancelParamObj = {};
  inputTypeConfig = inputTypeConfig;
  private readonly patterns = patterns;
  collapsiblePanel: boolean = true;
  constructor(private errorCorrectionService: ErrorCorrectionService,
     @Inject(DOCUMENT) private readonly document: Document,
    @Inject(SESSION_STORAGE) public sessionStorage: WebStorageService,
    sanitized: DomSanitizer,
    private readonly mdeService:MdeService) {
    super(sanitized);

  }

  ngOnInit() {
    this.changeBtnCls = "changeBtn-" + this.getUuid();
    this.getClaimType();
    this.fetchErrCrrData();
  }
  collapseEditor(event) {
    if (event.type === 'change') {
      if (event.isInvalid) {
        this.invalidFieldStatus[event.field] = true;
        return
      }
      this.isDataModified = true;
      if(event.inputType === inputTypeConfig.input){
        const [provField,proType]=event.field.split("_");
        const provData = (proType === this.bilProv ? this.clmInfoProviderData: this.clmDta.billingProvider[0].subscriber[0].claim[0].loop2310Name[proType]);
        if(provField === "taxonomy" && proType === this.bilProv){
          this.getTaxonomy(this.billingProviderTax,false,event.value)
        } else if(provField ==="tin"){
          this.getTIN(false,event.value)
        }else{
          this.updateClmAttribute(provData, event.key, event.value);
        }
      }
    }
    if (event.type === 'validation') {
      this.invalidFieldStatus[event.field] = event.isInvalid;
      return
    }
    this.invalidFieldStatus[event.field] = false;
    this.showHideConfig[event.field] = false;
  }

  toggleOpen() {
    this.open = !this.open;
  }

  fetchErrCrrData() {
    const viewingBy =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.viewingBy);
    forkJoin(
      {
        clmData: this.errorCorrectionService.getErrorCorrectionDetails(this.selectedRow.clmType, this.selectedRow.medClmSk, this.selectedRow.partySk),
        entityTypeCode: this.errorCorrectionService.getEntityTypeCode(Constants.errorCatgProvider,this.selectedRow.clmType,Constants.entityTypeRefCol),
        entityId: this.errorCorrectionService.getAllQualifierDes(Constants.errorCatgProvider,this.selectedRow.clmType,Constants.providerSec,Constants.entityIdUIRef,true),
        state: this.errorCorrectionService.getState(),
        contactType : this.errorCorrectionService.getQualifierdropdown(Constants.errorCatgProvider,this.selectedRow.clmType,Constants.billingContactUISec,Constants.billingContactUIRef,true),
        errorHighLight: this.errorCorrectionService.errorFieldHighlight(this.selectedRow,viewingBy.id)
      }
    ).subscribe(data => {
      this.dataLoading = false;
      this.listConfig['entityTypeCode'] = data.entityTypeCode;
      this.listConfig['billingContact'] = data.contactType;
      this.entityId = data.entityId;
      this.clmDta = data.clmData;
      this.clmInfoProviderData = this.clmDta.billingProvider[0].loop2010Name[0];
      this.billingProviderTax = this.clmDta.billingProvider[0].loop2000ABillingProviderSpecialtyInformation;
      this.dataLoading = false; 
      this.listConfig['state'] = data.state;
      this.errorHighlight = data.errorHighLight;
      this.mapErrorHighlightData();
    }, error => { 
      this.dataLoading = false;  
    });
  }
  mapErrorHighlightData() {
    const providerSections = this.getFieldValue(this.clmDta,"billingProvider.0.subscriber.0.claim.0.loop2310Name");
    const billingProviderSection = this.getPanelTitle(this.clmInfoProviderData.loop2010NM1.entityIdCode)
    for(const billingKey in this.billProvFieldLabel){
      this.updateMatchErrorHightlightObj(billingProviderSection,this.billProvFieldLabel[billingKey]);
    }
    this.updateUnmatchErrorHighlightObj(billingProviderSection);

    for(const providerObj of providerSections){
      const entityID = this.getFieldValue(providerObj,"loop2310NM1.entityIdCode")
      const sectionName = this.getPanelTitle(entityID);
      for(const fieldkey in this.providerFieldLabel){
        if((this.providerFieldLabel[fieldkey] === this.providerFieldLabel.serviceFacAddr && entityID !== '77') || 
        (this.providerFieldLabel[fieldkey] === this.providerFieldLabel.taxonomy && !(entityID === '71' || entityID === '82'))){
          continue;
        }
        this.updateMatchErrorHightlightObj(sectionName,this.providerFieldLabel[fieldkey]);
      }
      this.updateUnmatchErrorHighlightObj(sectionName);
    }  
  }
 
  getProviderAddr(providerObj, providerMapping) {
    const addr1 = this.getFieldValue(providerObj, providerMapping.addinfo1)
    const addr2 = this.getFieldValue(providerObj, providerMapping.addinfo2)
    const city = this.getFieldValue(providerObj, providerMapping.city)
    const postal = this.getFieldValue(providerObj, providerMapping.postalCode)
    return (addr1 ? addr1 + "  " : "") + (addr2 ? addr2  + "  " : "" ) + (city ? city + ", " : "") + this.getFieldLabel("state", this.getFieldValue(providerObj, providerMapping.stateorProvCode)) + " " + (postal ? postal : "");
  }

  getPanelTitle(id) {
    return id + '-' + this.entityId[id]
  }

  getName(providerKeyMapping,property){
    if( providerKeyMapping[property]){
      const fName = providerKeyMapping[property].nameFirst || "";
      const mName = providerKeyMapping[property].nameMiddle || "";
      const oName = providerKeyMapping[property].nameLastOrgName || "";
      const sName = providerKeyMapping[property].nameSuffix || "";
      return fName + " " +mName+ " " + oName + " "+sName;
    }
  }

  getTIN(attrValidation: boolean = false,updateValue: string = "",) {
    let tin;
    if (this.clmInfoProviderData && this.clmInfoProviderData.loop2010Reference) {
      this.clmInfoProviderData.loop2010Reference.forEach((record) => {
        if (record.referenceIdentQual === "EI") {
          tin = record.referenceIdent;
          if(updateValue){
            record.referenceIdent = updateValue;
          }
          if(attrValidation){
            return true;
          }
        }
      })
      return tin
    }
  }
  getPayToAddr(attrValidation: boolean = false,parentNodereq: boolean=false){
    let address;
    this.clmDta.billingProvider[0].loop2010Name.forEach((record)=>{
      if(record.loop2010NM1 && record.loop2010NM1.entityIdCode ===  "87"){
        address = this.getProviderAddr(record,this.billingProKeyMapping)
        if(parentNodereq){
          address= record;
        }
      }
      if (attrValidation) {
        return record && record.hasOwnProperty("addressInformation1");
      }
    })
    return address;
  }
  getUuid(){
    return (this.selectedRow.medClmSk ? this.selectedRow.medClmSk : `${this.selectedRow.fileKey}_${this.selectedRow.clmId}`) + "" + this.selectedRow.rowIndex;
  }

  cancelProvider(){
    const accordionsEditorList = [this.showHideConfig];
    const invalidEntry = this.isEditorOpen(accordionsEditorList);
     this.cancelParamObj =  {
      "invalidEntry" : invalidEntry,
      ...this.getCollapsePanelParam()
    }
    this.cancel(this.cancelParamObj);
  }

  collapseProviderPanel(){
    this.collapsePanel(this.getCollapsePanelParam());
  }

  getTaxonomy(data, attrValidation: boolean = false,updateValue:string="") {
    let taxonomyCode;
    data.forEach((record) => {
      if (record.referenceIdentQual === "PXC") {
        taxonomyCode = record.referenceIdent;
        if(updateValue){
          record.referenceIdent = updateValue;
        }
        if (attrValidation) {
          return true;
        }
      }
    })
    return taxonomyCode;
  }

  getCollapsePanelParam(){
    return {uuid:this.getUuid(),docObj:this.document,session:this.sessionStorage,errCrrService:this.errorCorrectionService};
  }

  closeProviderPopUp() {
    this.closePopUp(this.getCollapsePanelParam());
  }

  saveProvideClaim(isResubmitFlow :boolean =false){
    const accordionsEditorList = [this.showHideConfig];
    const {invalidEntry,isSaveEligible} = this.applyEditedChanges(accordionsEditorList,this.document,this.changeBtnCls);
    this.saveParamObj = {
        "isResubmitFlow": isResubmitFlow,
        "invalidEntry":invalidEntry,
        "isSaveEligible":isSaveEligible,
        "allClmData":this.clmDta,
        "session":this.sessionStorage,
        "errCrrService":this.errorCorrectionService,
        "mdeService":this.mdeService,
        "uuid":this.getUuid(),
        "docObj":this.document
       }
    this.saveClaim(this.saveParamObj);
   
  }

  colpseProviderResubmitPopUp(event){
    this.collapseResubmitPop(event,this.getCollapsePanelParam());
  }
  formatIntoPhoneNo = (str) => {
    const number = ('' + str).replace(/\D/g, '');
    const match = number.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };
    return null
  };
}