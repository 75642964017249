import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[appInputRestrict]'
})
export class InputRestrictDirective implements AfterViewInit {
  
  @Input('appInputRestrict') formGroup:UntypedFormGroup;
  @Input() formCtrl:string;
  @Input() maxLength:number;
  @Input() isNegAmtAllowed:boolean = false;
  @Input() quantity;
  
  constructor(private eleRef:ElementRef) {}
  ngAfterViewInit(){
    if(this.maxLength || this.quantity){
      const ele =  this.eleRef.nativeElement.querySelectorAll("input");
      ele.length > 0 && fromEvent(ele, 'input').subscribe((event:any)=>{
        const input:any =  event.target;
        const inputLength  = (this.isNegAmtAllowed && input.value.charAt(0) ==='-' ) ? input.value.length -1:
        input.value.length;
        this.updateQtyMaxLength(input);
        if (inputLength > this.maxLength){
          const maxLength = (this.isNegAmtAllowed && input.value.charAt(0) ==='-' ) ? Number(this.maxLength) + 1:
          this.maxLength;
          input.value = input.value.slice(0, maxLength);
          this.formGroup.controls[this.formCtrl].setValue(input.value);
        }
      });
    }
  }
updateQtyMaxLength(input){
  if(this.quantity){
    this.maxLength = Number(this.quantity.decimal) + Number(this.quantity.varChar) +1;
    const value = input.value && input.value.toString().split(".");
    const varChar = value[0] || "";
    const decimal = value.length > 1 ? value[1] : '';
    if (varChar.length > this.quantity.varChar) {
      this.maxLength = this.quantity.varChar;
    } else if(decimal.length > this.quantity.decimal){
      this.maxLength = Number(this.quantity.decimal) + varChar.length +1;
    }
   }
  }

}
