<div class="show-hide-dialog">
    <uitk-dialog [(showModal)]="showHideDialogModal.show"  uitkId='showHideDialog' tkShowHeader="false" tkOwnContent="true"
        tkTriggerElementId='showHideDialog_openBtn'>
        <uitk-dialog-content>
            <div class="dialog-header-content">
                <p> Show / Hide Columns</p> Select the columns you want to display in the results table.
            </div>
            <div class="dialog-content-wrapper">
                <div class="dialog-content">
                    <div class="select-all-wrapper" >
                        <input id="selectAllCheckbox" uitkCheckbox class="checkbox" type="checkbox"  [(ngModel)]="isAllColumnChecked"  (click) ="checkUncheckAll($event)"/>
                        <label uitkCheckboxLabel for="selectAllCheckbox"> &nbsp;Select All</label>
                    </div>
                    <div class="show-hide-column-list">
                        <div class="column-item" *ngFor="let column of visibleColumns;let index = index"  >
                            <input class="checkbox" type="checkbox" uitkCheckbox id="{{column+''+index+'_id'}}" [disabled] ="column.showAlways" [ngModel]="!column.hideColumn" (ngModelChange)="updateShowHideConfig(column, $event)"/>
                            <label uitkCheckboxLabel  [for]="column+''+index+'_id'" class="checkbox-label">{{column.label || column.columnName }} {{column.showAlways ? "(Always Visible)" : ""}} </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-grp">
                <button class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="saveRecords()">Save</button>
                <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="cancelShowHideRow()">Cancel</button>
            </div>
        </uitk-dialog-content>
    </uitk-dialog>
</div>
