import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TermsConditionsPopupComponent} from '../terms-conditions-popup/terms-conditions-popup.component';
import { UITKDialogModule } from '@uitk/angular';
import { UITKCheckboxModule, UITKFormFieldService, UITKInputService } from '@uitk/angular';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [TermsConditionsPopupComponent, PrivacyPolicyComponent],
  imports: [
    CommonModule,
    FormsModule,
    CommonModule,
    UITKDialogModule,
    UITKCheckboxModule



    ],
  exports: [TermsConditionsPopupComponent, PrivacyPolicyComponent],
  providers:[UITKFormFieldService, UITKInputService]
  
})
export class TermsConditionsPopupModule { }
