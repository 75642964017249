import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OauthResponse } from '../auth/oauthResponse.model';
import { environment } from '../../../environments/environment';
import { ClaimDetails } from '../../data-models/claimDetails';
import { of, Subject } from 'rxjs';
import { Constants } from '../../data-models/constants.enum';

@Injectable({
  providedIn: 'root'
})
export class MdeService {

  private baseServerUrl = environment.baseServerUrl;
  private readonly updateRecordSubject = new Subject();
  constructor(private readonly http: HttpClient) { }

  getBaseServerUrl() {
    const resp: OauthResponse = JSON.parse(sessionStorage.getItem("oAuthResponse"));
    if (resp.stargateEnabled) {
      this.baseServerUrl = environment.starGateUrl+Constants.reportingServiceBaseUrlSuffix;
    }
    else {
      this.baseServerUrl = environment.backedBaseUrl+Constants.reportingServiceBaseUrlSuffix;
    }
  }

  submitMdeData(mdeData) {
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerUrl}/v1/mdeclaimsubmit`, mdeData);
  }

  getClaimSearchResults(partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,segNo,claimDtlsCriteria:ClaimDetails,filterSortSelection:any){
    this.getBaseServerUrl();
    console.log("In Reports Service "+claimDtlsCriteria.clmId+" "+claimDtlsCriteria.mbi+" "+claimDtlsCriteria.billingPrvdrNpi);
    const pageCriteria = {segNo};
    return this.http.post<any[]>(`${this.baseServerUrl}/v1/ClaimResults`, 
    { partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId, pageCriteria,claimDtlsCriteria,...filterSortSelection});
}

getClaimSearchTotalCount(partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,segNo,claimDtlsCriteria:ClaimDetails,filterSortSelection:any){
    this.getBaseServerUrl();
    console.log("In Reports Service "+claimDtlsCriteria.clmId+" "+claimDtlsCriteria.mbi+" "+claimDtlsCriteria.billingPrvdrNpi);
    const pageCriteria = {segNo}
    return this.http.post<any[]>(`${this.baseServerUrl}/v1/ClaimResultCounts`, 
    { partySK, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId, pageCriteria,claimDtlsCriteria,...filterSortSelection});
 }

  getErrorCountSummaryDetails(scopeData,segNo,claimDtlsCriteria:ClaimDetails){
    this.getBaseServerUrl();
    
    return this.http.post<any[]>(`${this.baseServerUrl}/v1/errorTypeCounts`, 
    { partySK: scopeData.selectedClient.value, 
      isChildDataIncluded: scopeData.selectedClient.includeChildData,
      clmDtCriteriaId: scopeData.viewByDate.id, 
      timeCriteriaId: scopeData.timePeriod.period.id,
      startDate : scopeData.timePeriod.fromDate,
      endDate : scopeData.timePeriod.throughDate,
      pageCriteria: {segNo},
      claimDtlsCriteria
    });
  }
  fetchErrorCategory(){
    this.getBaseServerUrl();
    return this.http.get(`${this.baseServerUrl}/v1/ErrorCategory`);
  }
  fetchClaimType(){
    this.getBaseServerUrl();
    return this.http.get(`${this.baseServerUrl}/v1/ClaimType`);
  }
  fetchErrorType(screenType = Constants.defaultScreenType){
    this.getBaseServerUrl();
    return this.http.get(`${this.baseServerUrl}/v1/ErrorType/${screenType}`);
  }
  fetchDuplicateType(){
    this.getBaseServerUrl();

    return this.http.get(`${this.baseServerUrl}/v1/DuplicateClaims`);
  }
  getMemberDetails(medClaimSk){
    this.getBaseServerUrl();
    return this.http.post<any[]>(`${this.baseServerUrl}/v1/memberDetails`, 
    { medClaimSk});
  }

  emitUpdateRecord(submittedclmId){
    this.updateRecordSubject.next(submittedclmId);
  }

  subscribeUpdateRecord(){
    return this.updateRecordSubject.asObservable()
  }
}


