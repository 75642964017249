import { Constants } from "../data-models/constants.enum";

 export class SelectField {
  id?: number;
  value: string | Constants;
  label?: string | Constants;
  includeChildData: string | Constants;
}

export class TimePeriod{
  period:{
    id: number;
    value: string | Constants;
    label: string | Constants;
  }
}

export class DateValidity{
  msg: string = "Date Range is invalid";
  isFromInvalid: boolean = false;
  isthroughInvalid: boolean = false;
}

export type fileLoadScopeDataModel = {
  filename:string;
  selectedClient:SelectField;
  clientNames:Array<SelectField>;
  viewByDates:Array<SelectField>;
  viewByDate: SelectField;
  timePeriod: {
      period?: SelectField;
      fromDate?: any;
      throughDate?: any;
      validity?: DateValidity;
      isInvalid?: boolean;
  };
  timePeriods:Array<SelectField> ;
  sortOrder: SelectField;
  sortbylist:Array<SelectField>;
  subClient: SelectField;
  subClients:Array<SelectField>;
  statuslist:Array<SelectField>;
  status:SelectField;
  loadCompleteList:Array<SelectField>;
  loadComplete:{
    period?:SelectField,
    fromDate?:any,
    throughDate?:any,
    validity?: DateValidity;
    isInvalid?: boolean;
  }
}
export const scopeInitData: fileLoadScopeDataModel = {
    selectedClient:{value:"",includeChildData:""},
    clientNames:[],
    viewByDates:[],
    viewByDate: {value:"",includeChildData:""},
    timePeriod: {
      period: { value: "",includeChildData:""},
      fromDate: "",
      throughDate: "",
      validity: new DateValidity(),
      isInvalid: false
    },
    timePeriods:[],
    sortbylist:[],
    sortOrder:{value:"", includeChildData:""},
    subClients:[],
    subClient:null,
    statuslist:[],
    status:null,
    loadCompleteList:[],
    loadComplete:{
      period: null,
      fromDate:"",
      throughDate:"",
      validity: new DateValidity(),
      isInvalid: false
    },
    filename:""
  }