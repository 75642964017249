import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-clm-err-row-expand',
  templateUrl: './clm-err-row-expand.component.html',
  styleUrls: ['./clm-err-row-expand.component.css']
})
export class ClmErrRowExpandComponent implements OnInit {
  // public selectedRow:any;

  @Input() selectedRow;
  routeParam:Params

  constructor( private readonly route: ActivatedRoute) { }

  ngOnInit() {
    this.routeParam = this.route.snapshot.params;
  }

}
