import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { TermsPrivacyUtility } from '../terms-privacy-utility';
@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.css']
})
export class TermsOfUseComponent extends TermsPrivacyUtility {
  constructor(@Inject(DOCUMENT) public document: Document) {super() }
}
