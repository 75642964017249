import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'src/app/services/auth/oauth.service';
import { DownloadService } from 'src/app/services/download.service';

@Component({
  selector: 'app-unauthorized-user',
  templateUrl: './unauthorized-user.component.html',
  styleUrls: ['./unauthorized-user.component.css']
})
export class UnauthorizedUserComponent implements OnInit {
  public supportEmail;
  constructor(private readonly authenticationService: OAuthService,private readonly downloadService : DownloadService) { }

  ngOnInit() {
    this.authenticationService.getSupportEmail('ACCESS_REQUEST_SUPPORT').subscribe(data => {
      this.supportEmail = data[0] && data[0].emailId;
    });
    this.downloadService.stopExportCountPolling();
  }

}
