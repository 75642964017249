<form [formGroup]="serviceProviderForm" class="editor-wrapper" id="serviceProvider">
    <div class="provider-item entity-code-col select-field-wrapper input-field">
        <uitk-form-field>
            <label class="form-label visibility-hidden" for="entityCode" uitkLabel>Entity Type Code</label>
            <uitk-select formControlName="entityCode" id="entityCode" class="qualifier-field"
                [itemList]="entityTypeList" [required]='false' [defaultLabelFlag]=false
                [showError]="getFormControlValidity('entityCode')"
                (onDropdownChange)='onDropdownChangeCallbck($event, "entityCode")'>
            </uitk-select>
        </uitk-form-field>
    </div>
    <div class="provider-item name-col ">
        <ng-container *ngIf="isNameNPIApplicable()">
            <div class="name-col-wrap" formGroupName="name">
                <div class="input-field fname" [hidden]="isOrgEntity">
                    <uitk-form-field>
                        <label class="form-label" [for]="ctrlMapping.fName" uitkLabel>First Name</label>
                        <input uitkInput [id]="ctrlMapping.fName" [formControlName]="ctrlMapping.fName"
                            [required]="required" (blur)="validate()"
                            [appInputRestrict]="serviceProviderForm.get('name')" [formCtrl]="ctrlMapping.fName"
                            [maxLength]="maxLengthInfo.fName" />
                        <uitk-form-field-error *ngIf="getFormControlValidity('name',ctrlMapping.fName)">
                        </uitk-form-field-error>
                    </uitk-form-field>
                </div>
                <div class="input-field mname" [hidden]="isOrgEntity">
                    <uitk-form-field>
                        <label class="form-label" [for]="ctrlMapping.mname" uitkLabel>MI</label>
                        <input uitkInput [id]="ctrlMapping.mName" [formControlName]="ctrlMapping.mName"
                            [required]="required" (blur)="validate()"
                            [appInputRestrict]="serviceProviderForm.get('name')" [formCtrl]="ctrlMapping.mName"
                            [maxLength]="maxLengthInfo.mName" />
                        <uitk-form-field-error *ngIf="getFormControlValidity('name',ctrlMapping.mName)">
                        </uitk-form-field-error>
                    </uitk-form-field>
                </div>
                <div class="input-field fname" [ngClass]="{'org-field':isOrgEntity}">
                    <uitk-form-field>
                        <label class="form-label" [for]="ctrlMapping.mname" uitkLabel>{{isOrgEntity?"Organization Name":"Last Name"}}</label>
                        <input uitkInput [id]="ctrlMapping.lName" [formControlName]="ctrlMapping.lName"
                            [required]="required" (blur)="validate()"
                            [appInputRestrict]="serviceProviderForm.get('name')" [formCtrl]="ctrlMapping.lName"
                            [maxLength]="maxLengthInfo.lName" />
                        <uitk-form-field-error *ngIf="getFormControlValidity('name',ctrlMapping.lName)">
                        </uitk-form-field-error>
                    </uitk-form-field>
                </div>
                <div class="input-field suffix" [hidden]="isOrgEntity">
                    <uitk-form-field>
                    <label class="form-label" [for]="ctrlMapping.suffix" uitkLabel>Suffix</label>
                    <input uitkInput [id]="ctrlMapping.suffix" [formControlName]="ctrlMapping.suffix"
                        [required]="required" (blur)="validate()"
                        [appInputRestrict]="serviceProviderForm.get('name')" [formCtrl]="ctrlMapping.suffix"
                        [maxLength]="maxLengthInfo.sName"/>
                        <uitk-form-field-error *ngIf="getFormControlValidity('name',ctrlMapping.suffix)">
                        </uitk-form-field-error>
                    </uitk-form-field>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="provider-item entity-id text-ellipses">
        <label class="form-label visibility-hidden" for="entityCode" >Entity ID</label>
        <div>
            <span
                [title]="entityId[inputValue.loop2420NM1?.entityIDCode] ? inputValue.loop2420NM1?.entityIDCode + '-'+entityId[inputValue.loop2420NM1?.entityIDCode] : inputValue.loop2420NM1?.entityIDCode">
                {{entityId[inputValue.loop2420NM1?.entityIDCode] ? inputValue.loop2420NM1?.entityIDCode +
                '-'+entityId[inputValue.loop2420NM1?.entityIDCode] : inputValue.loop2420NM1?.entityIDCode}}
            </span>
        </div>
    </div>
    <div class="provider-item npi-col input-field">
        <ng-container *ngIf="isNameNPIApplicable()">
            <uitk-form-field>
            <label class="form-label visibility-hidden" [for]="ctrlMapping.npi" uitkLabel>NPI</label>
            <input class="input-field" uitkInput [id]="ctrlMapping.npi" [formControlName]="ctrlMapping.npi"
                (blur)="validate()"
                [appInputRestrict]="serviceProviderForm" [formCtrl]="ctrlMapping.npi" [maxLength]="maxLengthInfo.npi"/>
                <uitk-form-field-error *ngIf="getFormControlValidity(ctrlMapping.npi)">
                </uitk-form-field-error>
        </uitk-form-field>
        </ng-container>
    </div>
    <div class="provider-item address-col">
        <ng-container *ngIf="isAddrApplicable()">
            <div formGroupName="address" class="input-field">
                <uitk-form-field>
                    <label for="address1Field" class="form-label" uitkLabel>Address Line 1</label>
                    <input class="input-field" uitkInput id="address1Field" formControlName="address1"
                        [required]="required" (blur)="validate()"
                        [appInputRestrict]="serviceProviderForm.get('address')" formCtrl="address1" [maxLength]="maxLength">
                        <uitk-form-field-error *ngIf="getFormControlValidity('address','address1')">
                        </uitk-form-field-error>
                </uitk-form-field>
                <uitk-form-field>
                    <label for="address2Field" class="form-label" uitkLabel>Address Line 2 (optional)</label>
                    <input class="input-field" uitkInput id="address2Field" formControlName="address2"
                        [required]="required" (blur)="validate()"
                        [appInputRestrict]="serviceProviderForm.get('address')" formCtrl="address2" [maxLength]="maxLength"/>
                        <uitk-form-field-error *ngIf="getFormControlValidity('address','address2')">
                        </uitk-form-field-error>
                </uitk-form-field>
                <uitk-form-field>
                    <label for="cityField" class="form-label">City</label>
                    <input class="input-field" uitkInput id="cityField" formControlName="city"
                        [required]="required" (blur)="validate()"
                        [appInputRestrict]="serviceProviderForm.get('address')" formCtrl="city"
                        [maxLength]="maxLengthInfo.city"/>
                    <uitk-form-field-error *ngIf="getFormControlValidity('address','city')">
                        </uitk-form-field-error>
                </uitk-form-field>
                <div class="address-field-wrapper">
                    <span class="addr-state-zip select-field-wrapper">
                        <uitk-form-field>
                            <label for="stateField" class="form-label" uitkLabel>State</label>
                            <uitk-select class="address-field-select" id="state"  formControlName="state" [itemList]="listData['state']"
                                [required]='false' [defaultLabelFlag]=false
                                [showError]="getFormControlValidity('address','state')">
                            </uitk-select>
                        </uitk-form-field>
                    </span>
                    <span class="addr-state-zip">
                    <uitk-form-field>
                        <label for="zipcodefield" class="form-label" uitkLabel>Zipcode</label>
                        <input class="input-field" uitkInput id="zipcodefield" formControlName="zipcode"
                            [required]="required" (blur)="validate()"
                            [appInputRestrict]="serviceProviderForm.get('address')" formCtrl="zipcode"
                            [maxLength]="maxLengthInfo.zipcode">
                            <uitk-form-field-error *ngIf="getFormControlValidity('address','zipcode')">
                            </uitk-form-field-error>
                    </uitk-form-field>
                    </span>
                    <span class="optional-zipcode-sep">
                        <label class="separator" >-</label>
                    </span>
                    <span class="addr-state-zip">
                    <uitk-form-field>
                        <input class="input-field" uitkInput id="zipcodeOptionalField"
                            formControlName="zipcodeoptional" [required]="required" tkPlaceholder="optional"
                            (blur)="validate()" [appInputRestrict]="serviceProviderForm.get('address')"
                            formCtrl="zipcodeoptional" [maxLength]="maxLengthInfo.zipcodeoptional"/>
                        
                    </uitk-form-field>
                    </span>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="provider-item taxonomy-col input-field">
        <ng-container *ngIf="isTaxonomyApplicable()">
            <uitk-form-field>
            <label class="form-label visibility-hidden" [for]="ctrlMapping.taxonomy" uitkLabel>Taxonomy Code</label>
            <input class="input-field" uitkInput [id]="ctrlMapping.taxonomy"
                [formControlName]="ctrlMapping.taxonomy" (blur)="validate()"
                 [appInputRestrict]="serviceProviderForm"
                [formCtrl]="ctrlMapping.taxonomy" [maxLength]="maxLengthInfo.taxonomy"/>
                <uitk-form-field-error *ngIf="getFormControlValidity(ctrlMapping.taxonomy)">
                </uitk-form-field-error>
        </uitk-form-field>
        </ng-container>
    </div>
    <div class=" provider-item action-btn-wrapper">
        <label class="form-label visibility-hidden">button</label>
        <div class="btn-inner-wrapper">
            <button [class]="'uitk-c-button uitk-c-button--default uitk-c-button--sm margin-left-7 ' +changeBtnCls" (click)="changeClick()"
                (mousedown)="mousedownEvent($event)">Apply</button>
            <button class="uitk-c-button uitk-c-button--sm uitk-c-button--ghost margin-left-7" (click)="cancelClick()">Cancel</button>
        </div>
    </div>
</form>