<uitk-dialog 
 class="warning-popup" width="550px" 
 [(showModal)]="resubmitConfig.showPopup"
  tkDisableCloseOnEsc="true"
  tkShowHeader="false"
  uitkId="claim-resubmit"
  tkOwnContent="true"
  [ngClass]="{'loading':resubmitConfig.loading}" 
  >
  <uitk-dialog-header *ngIf="!resubmitConfig.loading">
    <div class="heading-wrap">
      <i class="uitk-icon uitk-icon-warning_filled md-icon"></i>
      <div >{{resubmitConfig.title}}</div>
    </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
        <div *ngIf="resubmitConfig.loading">
            <uitk-loading-indicator
              uitkId="claim-resubmit-loader"
              visibleText="Please wait..."
              spinnerSize="large"
              [displayOverlay]="false"
              [centerSpinner]='true'
            >
            </uitk-loading-indicator>
        </div>
        <div [hidden]="resubmitConfig.loading" cdkTrapFocus="true">
            <div class="confirm-data">
                <div class="warning-msg-wrapper">
                    <div *ngIf="!isSubmitFlow()" class="warning-msg-content" [innerHTML]='resubmitConfig.description'>
                        
                    </div>
                    <div *ngIf="isSubmitFlow()" class="warning-msg-content">
                        <div *ngIf="errorCategoryDetails.length > 0" class="err-txt">The Claim - {{selectedRow.clmId}}  still has other errors that need correction: 
                            <div class="err-desc" *ngFor = "let errordtl of errorCategoryDetails">
                                <span class="err-label">{{errordtl.label}} Category - </span> 
                                <span class="err-value"> {{errordtl.value}}</span>
                            </div>
                        </div>
                        <div class="err-txt-btm">Are you sure you want to resubmit the claim without any further changes ?</div>
                    </div> 
                </div>
            </div>
        </div>
    </uitk-dialog-content>
    <uitk-dialog-actions *ngIf="!resubmitConfig.loading">
        <button class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="resubmitClaim()">Continue</button>
        <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="cancelResubmit()">Cancel</button>               
        </uitk-dialog-actions>
</uitk-dialog>
