import { Component, OnInit, Inject, Input } from '@angular/core';
import { LineChart } from '../../data-models/line.chart';
import { LineChartData } from '../../data-models/line.chart.data';
import { DatePipe } from '@angular/common';
import { UserPrincipal } from '../../services/auth/userPrincipal.model';
import { SummaryService } from '../../services/tabs/summary.service';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { Constants } from '../../data-models/constants.enum';
import { BaseChartsComponent } from '../base-charts/base-charts.component';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';

@Component({
  selector: 'app-stacked-bar-charts',
  templateUrl: './stacked-bar-charts.component.html',
  styleUrls: ['./stacked-bar-charts.component.css']
})
export class StackedBarChartsComponent extends BaseChartsComponent implements OnInit {
  @Input()
  public scopeData: any;
  
  multi: any[];
  view: any;
  barPadding = 8;
  yAxisLabel = 'Number of Files';
  xAxisLabel = '';
  trimXAxisTicks = false;
  colorScheme = {
    domain: ['#C25608','#F1B273','#e9f3fc']
  };

  constructor(private readonly summaryService: SummaryService,
    @Inject(SESSION_STORAGE) private readonly sessionStorage: WebStorageService) { 
      super();
    }

  ngOnInit() {
    const userPrincipal: UserPrincipal = JSON.parse(sessionStorage.getItem('userPrincipal'));
    console.log("**** Home user principal ****", userPrincipal);
    this.populateDataBasedOnTimePeriod(Constants.filesRecieved);
  }

  populateDataBasedOnTimePeriod(seriesName): any {
    const prtySk =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.clientName)?.value;
    const isChildDataIncluded = this.sessionStorage.get(Constants.includeChildData);
    const clmDtCriteriaId =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.viewingBy)?.id;
    const timeCriteria =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod);
    const startDate = this.sessionStorage.get(Constants.fromDate);
    const endDate = this.sessionStorage.get(Constants.toDate);
    const graphView = this.sessionStorage.get(Constants.graphViewByType)
    const customDateObj= {
      duration : this.sessionStorage.get(Constants.duration),
      graphViewByType : graphView
    }
    this.summaryService.getSummaryGraphStats(prtySk, isChildDataIncluded, clmDtCriteriaId,timeCriteria.id,startDate,endDate,seriesName,customDateObj).subscribe(data => {
    const json = data;
    let finalData = [];
    const mxRcvd = 0;
    const rcvds = [];
    for(let i =0; i < json.length; i++){
        const row = json[i];
        const dataRow = {};
        const rcvd = row["recieved"];
        rcvds.push(rcvd);
        const cmpltd = row["completed"];
        dataRow["name"] = row["name"];
        const s1 = {};
        s1["name"] = Constants.filesRecieved;
        s1["value"] = rcvd - cmpltd;
        this.copyProps(s1, row, timeCriteria,graphView);
        const s2 = {};
        s2["name"] = Constants.filesCompleted;
        s2["value"] = row["completed"];
        this.copyProps(s2, row, timeCriteria,graphView);
        const series = [];
        series.push(s2);
        series.push(s1);
        if(rcvd === 0){
          const s3 = {};
          s3["name"] = "No Files";
          s3["value"] = 20;
          this.copyProps(s3,row,timeCriteria,graphView);
          series.push(s3);
        }
        dataRow["series"] = series;
        finalData.push(dataRow);
    }
    const maxVal = Math.max(...rcvds);
    if(maxVal > 0){
      finalData.forEach(d =>{
        if(d["series"][2]){
          d["series"][2]["value"]=maxVal;
        }
      });
    }
    finalData = this.modifyData(finalData);
    if(finalData.length <= 8){
      this.barPadding = this.barPaddingConfig[finalData.length.toString()];
    }else if(finalData.length <= 9){
      this.barPadding = 50;
    }else if(finalData.length <= 12){
      this.barPadding = 30;
    }else if(finalData.length > 12){
      this.barPadding = 8;
    }
    this.view = this.calculateChartView(json);
    this.multi = finalData;     
    });    
  }

  private copyProps(s: {}, row: any, timeCriteria,graphView) {
    s["time"] = row["time"];
    s["month"] = (timeCriteria.id === 3 || (timeCriteria.value === Constants.customDateRange && graphView === Constants.monthly)) ? true: false;
    s["partial"] = row["partial"];
    s["position"] = row["position"];
    s["labelCmpltd"] = Constants.filesCompleted;
    s["labelCmpltdValue"] = row["completed"];
    s["labelRcvd"] = Constants.filesRecieved;
    s["labelRcvdValue"] = row["recieved"];
    s["toolTip"] = row["toolTip"];
  }

  reloadGraph( seriesName) {
    this.populateDataBasedOnTimePeriod(seriesName);
  }

  checkXAxisLabel(data){
    return this.xAxisTickFormattingFn(data);
  }
  
}
