<div class="row margin-top-11">
    <div class="col-md-6 padding-left-3">
        <div><span class="chart-title">Closed Claim Reasons</span> <span class="margin-left-15"><a [routerLink]="['/closed-claims']">View Details</a></span></div>
    </div>
    <div class="col-md-6 padding-left-19">
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="rd_1" name="rd" class="custom-control-input" (change)="reloadGraph('Replaced')" checked="checked">
            <label class="custom-control-label red" for="rd_1">Replaced</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="rd_2" name="rd" class="custom-control-input" (change)="reloadGraph('Per Client Request')" >
            <label class="custom-control-label red" for="rd_2">Other</label>
        </div>
    </div>
</div>
<div class="margin-bottom-35 graph-wrapper">
    <line-chart [hidden]="isBarChartVissible()" [isHidden]="isBarChartVissible()"></line-chart>
    <app-bar-charts [scopeData]="data" [hidden]="!isBarChartVissible()" [isHidden]="!isBarChartVissible()"></app-bar-charts>
</div>
<app-graph-switch-frequency [scopeProps]="data"></app-graph-switch-frequency>
<div class="row">
    <div class="col-md-3 padding-left-3" *ngFor="let tab of tabs">
        <div><span>{{tab.tabTitle}}</span></div>
        <div class="count-title"> <span>{{tab.headerText}}</span></div>
        <div class="border-top-title"></div>
        <div><div class="clms-txt-cls">{{tab.brkDownMsg}} Claims</div></div>
    </div>
</div>