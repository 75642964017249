import { Component, OnInit, Input, ViewChild, AbstractType, Inject } from '@angular/core';
import { LineChartComponent } from '../../../charts/lineCharts/lineCharts.component';
import { BarChartsComponent } from '../../../charts/bar-charts/bar-charts.component';
import { SummaryService } from '../../../services/tabs/summary.service';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { Constants } from '../../../data-models/constants.enum';
import { MdeClaimCountService } from '../../../services/mdeclmcount.service'; 
import { Router } from '@angular/router';
import { MdeService } from '../../../services/tabs/mde.service';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';

//Mapping for graph series with the code
const graphSeriesMapping = {
  [Constants.inboundCompliance]: [2],
  [Constants.inboundContent]: [3],
  [Constants.cms999]: [4],
  [Constants.cms277]: [5],
  [Constants.cmsMAO_002]: [6]
}

@Component({
  selector: 'app-errors-tab',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.css']
})

export class ErrorsComponent implements OnInit {

  @Input()
  public data: any;
 
  @ViewChild(LineChartComponent)
  public lineChart: LineChartComponent;

  @ViewChild(BarChartsComponent)
  public barChart: BarChartsComponent;

  public mdeClaims : any ;
  public graphSeriesId : number[];
  public aggData:any;
  public isClmDtCriteriaDOS : boolean;
  errorTypeMapping: any;
  constructor(private readonly summaryService: SummaryService,
    private readonly mdeClaimCountService: MdeClaimCountService,
    @Inject(SESSION_STORAGE) private readonly sessionStorage: WebStorageService,
    private readonly router : Router,
    private readonly mdeService: MdeService
    ) { }

  ngOnInit() {
    const prtySk =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.clientName)?.value;
    const isChildDataIncluded = this.sessionStorage.get(Constants.includeChildData);
    const clmDtCriteriaId =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.viewingBy)?.id;
    this.isClmDtCriteriaDOS = (clmDtCriteriaId === 1);
    const timeCriteriaId =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod)?.id;
    const startDate = this.sessionStorage.get(Constants.fromDate);
    const endDate = this.sessionStorage.get(Constants.toDate);
    this.sessionStorage.set(Constants.yAxisLabel, JSON.stringify("Number of Errors"));
    this.sessionStorage.set(Constants.seriesName, JSON.stringify( this.isClmDtCriteriaDOS ? Constants.inboundContent : Constants.inboundCompliance));
    this.graphSeriesId = this.isClmDtCriteriaDOS ? graphSeriesMapping[Constants.inboundContent] : graphSeriesMapping[Constants.inboundCompliance];
    this.sessionStorage.set(Constants.graphSeriesId, this.graphSeriesId);
    this.sessionStorage.set(Constants.tabsSelectedIndex, 2);
    this.mdeClaimCountService.getMdeClaimStats(prtySk, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate).subscribe(data => {
      this.mdeClaims = data['mdeClaimCount'];
    });
    this.summaryService.getErrorsTabStats(prtySk, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate, 3).subscribe(data => {
      this.aggData = data;
    });
    this.mdeService.fetchErrorType().subscribe(data => {
    this.errorTypeMapping = data;
    });
    this.data["frequencyChange"] = this.reloadGraph.bind(this);
  }

  isBarChartVissible(){
    const freqType = this.sessionStorage.get(Constants.graphViewByType);
    return this.data.timePeriod === Constants.today || 
    (this.data.timePeriod === Constants.customDateRange && 
    ((freqType === Constants.monthly && this.data[Constants.isSingleMonthDateRange]) 
    || this.data.duration === 1));
  }

  reloadGraph(seriesName) {
    this.sessionStorage.set(Constants.seriesName, JSON.stringify(seriesName));
    this.graphSeriesId = graphSeriesMapping[seriesName];
    if(this.isBarChartVissible()){
      this.barChart.reloadGraph(this.graphSeriesId);
    }else{
      this.lineChart.reloadGraph(this.graphSeriesId);
    }
  }

  isClaimDOSInboundComplianceError(agg){
    return this.isClmDtCriteriaDOS && agg.name === Constants.inboundCompliance ;
  }
  

  getErrorTypeDetails(errorType){
    const selectedErrorType = this.errorTypeMapping.filter(item=>{
      return item.name === errorType || errorType.includes(item.name)
    }).map(item =>{
      item["label"]=item.name;
      return item;
    })
    if(selectedErrorType.length > 0){
      return selectedErrorType[0];
    }
    return false;
  }
  onViewDetailsClick(event,errorType){
    event.preventDefault();
    const errorTypeDetails = this.getErrorTypeDetails(errorType);
    this.sessionStorage.set(Constants.errorType,JSON.stringify(errorTypeDetails));
    this.router.navigateByUrl(`/error/type`);
    this.scrollToTop();
  }

  onErrorCodeClick(event,errorType,errorCode){
    event.preventDefault();
    const errorTypeDetails = this.getErrorTypeDetails(errorType);
    this.sessionStorage.set(Constants.errorType,JSON.stringify(errorTypeDetails));
    this.sessionStorage.set(Constants.errorCode,JSON.stringify(errorCode));
    this.sessionStorage.set(Constants.scopeDataSessionKeys,
      JSON.stringify([Constants.errorType,Constants.errorCode]));
    this.router.navigateByUrl(`/claim/error`);
    this.scrollToTop();
  }


  navigateToMDE(event){
    event.preventDefault();
    this.sessionStorage.set(Constants.mdeFilter,JSON.stringify({label:"Yes",value:"Yes",id:"0"}));
    this.sessionStorage.set(Constants.scopeDataSessionKeys,JSON.stringify([Constants.mdeFilter]));
    return this.router.navigateByUrl(`/claim/mde`);
  }

  scrollToTop(){
    window.scroll({
      top:0,
      left:0,
      behavior: "auto"
    }); 
  }

}
