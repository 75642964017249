import { Component, OnInit, Input, Inject } from '@angular/core';
import { DOCUMENT, DatePipe } from '@angular/common';
import { forkJoin } from 'rxjs';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { of } from 'rxjs';
import { ErrorCorrectionService } from 'src/app/services/errorCorrectionClm.service';
import { Constants } from 'src/app/data-models/constants.enum';
import { ErrCrrUtility, inputTypeConfig } from '../../err-crr-utility';
import { MdeService } from 'src/app/services/tabs/mde.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Params } from '@angular/router';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';

@Component({
  selector: 'app-error-crr-header-catg',
  templateUrl: './error-crr-header-catg.component.html',
  styleUrls: ['./error-crr-header-catg.component.css']
})
export class ErrorCrrHeaderCatgComponent extends ErrCrrUtility implements OnInit {

  @Input() selectedRow: any;
  @Input() routeParam: Params;
  clmDta: any ;
  allClmDta : any;
  open: boolean = false;
  payerControlNumber: any;
  diagnosisDesMapping = {};
  diagnosisQualMapping: any = {};
  changeBtnCls: string;
  additionalHltCareDesMapping: any = {};
  claimReferenceDesMapping: any = {};
  additionalClaimDateDesMapping: any = {};
  additionalClaimDateTimeData: any = {};
  public showHideConfig = {};
  public showHideclaimAdjConfig = {}
  public showHideDiagnosisCodeConfig = {}
  public showHideAdditionalHltCareConfig = {}
  public showHideClaimRefInfoConfig = {}
  public showHideAddClaimDatesConfig = {}

  public invalidFieldStatus = {};
  public invalidClaimAdjFieldStatus = {};
  public invalidDiagnosisCodeFieldStatus = {};
  public invalidadditionalHltcreFieldStatus = {};
  public invalidClaimRefInfoFieldStatus = {};
  public invalidAddClaimDatesFieldStatus = {};

public poaDropdown ={};
public getAddClaimDateProps;
public getAddHealthDateProps;
timeout: ReturnType<typeof setTimeout>;
public cancelParamObj = {};
public saveParamObj = {};
collapsiblePanel: boolean = true;
public addressMapping = {
  addinfo1: 'n3Loop2310Address.addressInformation1',
  addinfo2: 'n3Loop2310Address.addressInformation2',
  city: 'n4Loop2310Address.cityName',
  postalCode: 'n4Loop2310Address.cityName',
  countryCode: 'n4Loop2310Address.postalCode',
  stateorProvCode: 'n4Loop2310Address.stateorProvCode'
}
public claimInfoMapping = {
  section: 'Claim Information',
  facilityBillType : 'Facility/Bill Type',
  placeOfService:'Place of Service',
  frequencyCodeType:'Frequency Type Code',
  payerClmCtrlNo: 'Payer claim Control Number',
  claimTotalCharges: 'Claim Total Charges',
  admissionTypeCode: 'Admission Type Code',
  admissionSourceCode: 'Admission Source Code',
  dischargeStatus:'Discharge Status',
  paidAmount:'Paid Amount',
  adjDate:'Adjudication Date',
  AmbulancePickupAddr:'Ambulance Pick-up Address',
  AmbulanceDropoffpAddr:'Ambulance Drop-off Address',
  adjustmentSec: Constants.headerAdjustmentSec,
  adjustmentFields:['Group Code','Reason Code','Amount','Quantity'],
  diagnosisCodeSec: Constants.diagCodeSec,
  diagnosisCodeFields:['Qualifier','Diagnosis Code','POA'],
  addHealthSec:Constants.addHealthSec,
  addHltCareInfoFields:['Qualifier','Code','Code From','Code Through','Amount'],
  clmRefInfoSec:'Claim Reference Information',
  clmRefInfoFields:['Qualifier','Reference Identification'],
  AddClmDatesSec:'Additional Claim Dates',
  AddClmDatesFields:['Date/Time Qualifier','Date/Time Format Qualifier','Date From','Date Through','Time']
}
inputTypeConfig = inputTypeConfig;
  constructor(
    private errorCorrectionService: ErrorCorrectionService,
    @Inject(DOCUMENT) private readonly document: Document,
    public datePipe: DatePipe,
    @Inject(SESSION_STORAGE) public sessionStorage: WebStorageService,
    private readonly mdeService:MdeService,
    sanitized: DomSanitizer
    ) {
    super(sanitized);
  }

  ngOnInit() {
    this.getClaimType();
    this.fetchErrCrrData();
    this.changeBtnCls = "changeBtn-" + this.getUuid();
    this.getAddClaimDateProps=this.getAddClaimDate.bind(this);
    this.getAddHealthDateProps=this.getAddHealthDate.bind(this);

  }

  toggleOpen() {
    this.open = !this.open;
  }
  

  fetchErrCrrData() {
    const viewingBy =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.viewingBy);
    forkJoin(
      {
        frequencyCodeType: this.errorCorrectionService.getfrequencyStatusCode(),
        admissionType: this.errorCorrectionService.getAdmissionTypeCode(),
        admissionSource: this.errorCorrectionService.getAdmissionStatusCode(),
        dischargeStatus: this.errorCorrectionService.getDischargeStatus(),
        placeOfService: this.errorCorrectionService.getPlaceOfService(this.selectedRow.clmType),
        state: this.errorCorrectionService.getState(),
        clmData: this.errorCorrectionService.getErrorCorrectionDetails(this.selectedRow.clmType, this.selectedRow.medClmSk, this.selectedRow.partySk),
        diagnosisQualifierDropdown: this.errorCorrectionService.getQualifierdropdown(Constants.header,this.selectedRow.clmType,Constants.diagnosisCodeAccordion,Constants.diagnosisCodeAccordionUIRef),
        groupCode: this.errorCorrectionService.getQualifierdropdown(Constants.header,this.selectedRow.clmType,Constants.claimAdjAccordion,Constants.claimAdjAccordionUIRef),
        POA: this.errorCorrectionService.getQualifierdropdown(Constants.header,this.selectedRow.clmType,Constants.poaUISec,Constants.poaUIRef,true),
        dateTimeFormatCode: this.isInstitutional ? this.errorCorrectionService.getQualifierdropdown(Constants.header,this.selectedRow.clmType,Constants.additionalClaimDatesAccordion,Constants.additionalClaimDateTimeUIRef,false) : of([]),
        errorHighLight: this.errorCorrectionService.errorFieldHighlight(this.selectedRow,viewingBy.id),
      }
    ).subscribe(data => {
      this.listConfig['claimFrequencyTypeCode'] = data.frequencyCodeType;
      this.listConfig['admissionTypeCode'] = data.admissionType;
      this.listConfig['admissionSourceCode'] = data.admissionSource;
      this.listConfig['patientStatusCode'] = data.dischargeStatus;
      this.listConfig['facilityCodeValue'] = data.placeOfService;
      this.listConfig['state'] = data.state;
      this.listConfig['diagnosisQualifierDropdown'] = data.diagnosisQualifierDropdown;
      this.listConfig['clmAdjGroupCode'] = data.groupCode;
      this.listConfig['POA'] = data.POA;
      this.listConfig['dateTimeFormatCode'] = data.dateTimeFormatCode;
      this.poaDropdown = this.errorCorrectionService.transformIntoKeyValuePair(data.POA);
      delete this.poaDropdown[""];
      this.allClmDta = data.clmData;
      this.clmDta = this.allClmDta.billingProvider[0].subscriber[0].claim[0];
      this.qualifierCodeDesMapping();
      this.errorHighlight = data.errorHighLight;
      this.mapErrorHighlightData();
    }, error => {
      console.log(error);
    });

  }

  mapErrorHighlightData() {
    this.mapClaimInfoErrorHighlightData(); 
    for(const claimAdjGroup of this.clmDta.casLoop2320ClaimLevelAdjustments){
     for(const field of this.claimInfoMapping.adjustmentFields){
        this.updateMatchErrorHightlightObj(this.claimInfoMapping.adjustmentSec,field,claimAdjGroup.claimAdjGroupCode,claimAdjGroup.rptnPosNum,claimAdjGroup.rptnPosNum);
      }
    }
    for(const diagIdx in this.clmDta.hiLoop2300HealthcareDiagnosisCode){
      const item = this.clmDta.hiLoop2300HealthcareDiagnosisCode[diagIdx];
      for(const field of this.claimInfoMapping.diagnosisCodeFields){
         this.updateMatchErrorHightlightObj(this.claimInfoMapping.diagnosisCodeSec,field,item.codeListQualifierCode,diagIdx,item.rptnPosNum);
      }
    }
    for(const addnHltIdx in this.clmDta.addnlHiLoop2300HealthcareDiagnosisCode){
      const item = this.clmDta.addnlHiLoop2300HealthcareDiagnosisCode[addnHltIdx];
      for(const field of this.claimInfoMapping.addHltCareInfoFields){
         this.updateMatchErrorHightlightObj(this.claimInfoMapping.addHealthSec,field,item.codeListQualifierCode,addnHltIdx,item.rptnPosNum);
      }
    }
    for(const refIdx in this.clmDta.refLoop2300Reference){
      const item = this.clmDta.refLoop2300Reference[refIdx];
      for(const field of this.claimInfoMapping.clmRefInfoFields){
         this.updateMatchErrorHightlightObj(this.claimInfoMapping.clmRefInfoSec,field,item.referenceIdentQual,refIdx);
      }
    }
    for(const clmDateIdx in this.clmDta.dtpLoop2300DateTimePeriod){
      const item = this.clmDta.dtpLoop2300DateTimePeriod[clmDateIdx];
      for(const field of this.claimInfoMapping.AddClmDatesFields){
         this.updateMatchErrorHightlightObj(this.claimInfoMapping.AddClmDatesSec,field,item.dateTimeQualifier,clmDateIdx);
      }
    }
    this.mapUnmatchErrorData();
  }

  mapClaimInfoErrorHighlightData(){
    let fields = [this.claimInfoMapping.frequencyCodeType,this.claimInfoMapping.payerClmCtrlNo,
      this.claimInfoMapping.claimTotalCharges,this.claimInfoMapping.paidAmount,this.claimInfoMapping.adjDate];
    if(this.isInstitutional){
      fields= [...fields,this.claimInfoMapping.facilityBillType, this.claimInfoMapping.admissionTypeCode,
        this.claimInfoMapping.admissionSourceCode,this.claimInfoMapping.dischargeStatus]
    }
    if(this.isProfessional){
      fields= [...fields,this.claimInfoMapping.placeOfService,this.claimInfoMapping.AmbulancePickupAddr,this.claimInfoMapping.AmbulanceDropoffpAddr]
    }
    for(const field of fields){
      this.updateMatchErrorHightlightObj(this.claimInfoMapping.section,field);
    }
  }
  mapUnmatchErrorData(){
    const sectionList = [this.claimInfoMapping.section ,this.claimInfoMapping.adjustmentSec,this.claimInfoMapping.diagnosisCodeSec,
      this.claimInfoMapping.addHealthSec,this.claimInfoMapping.clmRefInfoSec,this.claimInfoMapping.AddClmDatesSec];
    for(const section of sectionList){
      this.updateUnmatchErrorHighlightObj(section);
    }
  }

  qualifierCodeDesMapping() {
    const diagnosisCodeList = [];
    const qualifierCodeList = [];
    const additionalHltCreQualList = [];
    const claimReferenceQualList = [];
    const additionalclaimDateQualList = [];
    const additionalclaimDateTimeList = [];

    if (this.findParentObjNode(this.clmDta,["hiLoop2300HealthcareDiagnosisCode",""])) {
      this.clmDta.hiLoop2300HealthcareDiagnosisCode.forEach((elementRef, idx) => {
        diagnosisCodeList.push(elementRef.industryCode);
        qualifierCodeList.push(elementRef.codeListQualifierCode);
      });
    }
    if (this.findParentObjNode(this.clmDta,["addnlHiLoop2300HealthcareDiagnosisCode",""])) {
      this.clmDta.addnlHiLoop2300HealthcareDiagnosisCode.forEach((elementRef, idx) => {
        additionalHltCreQualList.push(elementRef.codeListQualifierCode);
      });
    }

    if (this.findParentObjNode(this.clmDta,["refLoop2300Reference",""])) {
      this.clmDta.refLoop2300Reference.forEach((elementRef, idx) => {
        claimReferenceQualList.push(elementRef.referenceIdentQual);
      });
    }
    
    if (this.findParentObjNode(this.clmDta,["dtpLoop2300DateTimePeriod",""])) {
      this.clmDta.dtpLoop2300DateTimePeriod.forEach((elementRef, idx) => {
        additionalclaimDateQualList.push(elementRef.dateTimeQualifier); 
        additionalclaimDateTimeList.push(elementRef.dateTimeFormatQual);
      });
      this.listConfig['dateTimeFormatCode'].forEach(element => {
        additionalclaimDateTimeList.push(element.value);
      });
    }
    forkJoin(
      {
        diagnosisDescription: (diagnosisCodeList.length > 0) ? 
        this.errorCorrectionService.getdiagnosisDes(diagnosisCodeList) : of(null),
        qualifiercodeData: (qualifierCodeList.length > 0) ? 
        this.errorCorrectionService.getQualifierDes(Constants.header, this.selectedRow.clmType, Constants.diagnosisCodeAccordion,Constants.diagnosisCodeAccordionUIRef, qualifierCodeList) : of(null),
        additionalHltCareData: (additionalHltCreQualList.length > 0) ? 
        this.errorCorrectionService.getQualifierDes(Constants.header, this.selectedRow.clmType, Constants.additionalHltCareAccordion,Constants.diagnosisCodeAccordionUIRef, additionalHltCreQualList) 
        : of(null),
        claimReferenceData: (claimReferenceQualList.length > 0) ? 
        this.errorCorrectionService.getQualifierDes(Constants.header, this.selectedRow.clmType, Constants.claimReferenceAccordion,Constants.claimReferenceAccordionUIRef, claimReferenceQualList)
         : of(null),
        additionalClaimDateData: (additionalclaimDateQualList.length > 0) ? 
        this.errorCorrectionService.getQualifierDes(Constants.header, this.selectedRow.clmType, Constants.additionalClaimDatesAccordion,Constants.additionalClaimDatesAccordionUIRef, additionalclaimDateQualList)
         : of(null),
        additionalClaimDateTimeData: (additionalclaimDateTimeList.length > 0) ? 
        this.errorCorrectionService.getQualifierDes(Constants.header, this.selectedRow.clmType, Constants.additionalClaimDatesAccordion,Constants.additionalClaimDateTimeUIRef, additionalclaimDateTimeList)
         : of(null)
      }).subscribe(res => {
        res.diagnosisDescription && (this.diagnosisDesMapping = res.diagnosisDescription);
        res.qualifiercodeData && (this.diagnosisQualMapping = res.qualifiercodeData);
        res.additionalHltCareData && (this.additionalHltCareDesMapping = res.additionalHltCareData);
        this.claimReferenceDesMapping = res.claimReferenceData;
        this.additionalClaimDateDesMapping = res.additionalClaimDateData;
        this.additionalClaimDateTimeData = res.additionalClaimDateTimeData;
        this.dataLoading = false;
      }, error => {
        this.dataLoading = false;
        console.log(error);
      });
  }

  getPayerControlNumber(attrValidation: boolean = false) {
    let payerContrlObj;
    if (this.clmDta && this.clmDta.refLoop2300Reference) {
      this.clmDta.refLoop2300Reference.forEach(element => {
        if (element['referenceIdentQual'] === "F8") {
          payerContrlObj = element;
        }
      });
      if (attrValidation) {
        return payerContrlObj;
      } else {
        return payerContrlObj && payerContrlObj.referenceIdent;
      }
    }
  }

  getPaidAmount(attrValidation: boolean = false) {
    const keys = ["loop2320OtherSubscriber", "amtLoop2320Amount", "amountQualCode"];
    const paidAmtObj = this.findParentObjNode(this.clmDta,keys);
    if (paidAmtObj && paidAmtObj.amountQualCode === "D") {
        if (attrValidation) {
            return paidAmtObj;
        } else {
            return paidAmtObj && paidAmtObj.monetaryAmount;
        }
    }
}
  isPaidAmountApplicable() {
    const paidAmtObj = this.getPaidAmount(true);
    return paidAmtObj && paidAmtObj.hasOwnProperty("monetaryAmount");
  }
  isPayerCtrlNoApplicable() {
    const payerCtrlObj = this.getPayerControlNumber(true);
    return payerCtrlObj && payerCtrlObj.hasOwnProperty("referenceIdent");
  }


  collapseEditor(event) {
    if (event.type === 'change') {
      if (event.isInvalid) {
        this.invalidFieldStatus[event.field] = true;
        return
      }
      this.isDataModified = true;
      if (event.key === 'payerControlNumber') {
        const payerControlNumColObj = this.getPayerControlNumber(true);
        payerControlNumColObj.referenceIdent = event.value;
      } else if (event.key === 'paidAmount') {
        const paidAmountObj = this.getPaidAmount(true);
        paidAmountObj.monetaryAmount = event.value;
      } else {
        if(event.inputType === inputTypeConfig.date){
          event.value = this.encodeDateFormat(event.value);
        }
        const isReqField = event.field === "adjPaymentDt";
        if(event.inputType !== inputTypeConfig.address){
          this.updateClmAttribute(this.clmDta,event.key, event.value,isReqField);
        }
      }
    }
    if (event.type === 'validation') {
      this.invalidFieldStatus[event.field] = event.isInvalid;
      return
    }
    this.invalidFieldStatus[event.field] = false;
    this.showHideConfig[event.field] = false;
  }

  collapseClaimAdjEditor(event) {
    if (event.type === 'change') {
      if (event.isInvalid) {
        this.invalidClaimAdjFieldStatus[event.field] = {
          'amount': event.amount,
          'reasonCode': event.reasonCode,
          'quantity': event.quantity,
          'groupCode': event.groupCode,
        }
        return
      }
      this.isDataModified = true;
    }
    if (event.type === 'validation') {
      this.invalidClaimAdjFieldStatus[event.field] = {
        'amount': event.amount,
        'reasonCode': event.reasonCode,
        'quantity': event.quantity,
        'groupCode': event.groupCode,

      }
      return
    }
    this.showHideclaimAdjConfig[event.field] = false;
    this.invalidClaimAdjFieldStatus[event.field] = {
      'amount': event.amount,
      'reasonCode': event.reasonCode,
      'quantity': event.quantity
    }

  }


  getAmbulanceAddr(addrInfo) {
    const addr1 = addrInfo.n3Loop2310Address.addressInformation1 ? addrInfo.n3Loop2310Address.addressInformation1 + "  " : "";
    const addr2 = addrInfo.n3Loop2310Address.addressInformation2 ? addrInfo.n3Loop2310Address.addressInformation2 + "  " : "";
    const city = addrInfo.n4Loop2310Address.cityName ? addrInfo.n4Loop2310Address.cityName + ", " : "";
    const postal = addrInfo.n4Loop2310Address.postalCode ? addrInfo.n4Loop2310Address.postalCode : "";
    return addr1 + addr2 + city + this.getFieldLabel("state", addrInfo.n4Loop2310Address.stateorProvCode) + " " + postal;
  }

  getUuid(){
    return (this.selectedRow.medClmSk ? this.selectedRow.medClmSk : `${this.selectedRow.fileKey}_${this.selectedRow.clmId}`) + "" + this.selectedRow.rowIndex;
  }

  cancelHeader(){
    const accordionsEditorList = [this.showHideConfig,this.showHideclaimAdjConfig,this.showHideDiagnosisCodeConfig,this.showHideAddClaimDatesConfig
      ,this.showHideAdditionalHltCareConfig,this.showHideClaimRefInfoConfig];
    const invalidEntry = this.isEditorOpen(accordionsEditorList);
     this.cancelParamObj =  {
      "invalidEntry" : invalidEntry,
      ...this.getCollapsePanelParam()
    }
    this.cancel(this.cancelParamObj);
  }
  
   collapseHeaderPanel(){
    this.collapsePanel(this.getCollapsePanelParam());
  }
  getCollapsePanelParam(){
    return {uuid:this.getUuid(),docObj:this.document,session:this.sessionStorage,errCrrService:this.errorCorrectionService};
  }
  getAddHealthDate(hClaim, isThroughDate) {
    let date = "";
    const dateIndex = isThroughDate ? 1 : 0;
    if (hClaim.dTP) {
      const dateList = hClaim.dTP.split("-");
      date = dateList.length > dateIndex ? dateList[dateIndex] : "";
    }
    return this.decodeDateFormat(date);
  }

  getAddClaimDate(claim, isThroughDate) {
    let date = "";
    const dateIndex = isThroughDate ? 1 : 0;
    if (claim.dateTimePeriod) {
      const dateList = claim.dateTimePeriod.split("-");
      date = dateList.length > dateIndex ? dateList[dateIndex] : "";
      if(this.isAddClaimDTTMQual(claim)){
        date = date.substr(0,8);
      }
    }
    return this.decodeDateFormat(date);
  }


  saveHeaderClaim(isResubmitFlow :boolean =false){
    const accordionsEditorList = [this.showHideConfig,this.showHideclaimAdjConfig,this.showHideDiagnosisCodeConfig,this.showHideAddClaimDatesConfig
      ,this.showHideAdditionalHltCareConfig,this.showHideClaimRefInfoConfig];
    const {invalidEntry,isSaveEligible} = this.applyEditedChanges(accordionsEditorList,this.document,this.changeBtnCls);
    this.saveParamObj = {
        "isResubmitFlow": isResubmitFlow,
        "invalidEntry":invalidEntry,
        "isSaveEligible":isSaveEligible,
        "allClmData":this.allClmDta,
        "session":this.sessionStorage,
        "errCrrService":this.errorCorrectionService,
        "mdeService":this.mdeService,
        "uuid":this.getUuid(),
        "docObj":this.document
       }
    this.saveClaim(this.saveParamObj);
   
  }


   colpseHdrResubmitPopUp(event){
     this.collapseResubmitPop(event,this.getCollapsePanelParam());
   }

      
  collapseDiagnosisCodeEditor(event){
    if (event.type === 'change') {
      if (event.isInvalid) {
        this.invalidDiagnosisCodeFieldStatus[event.field] = {
          'diagnosisCode': event.diagnosisCode,
          'qualifier': event.qualifier
          
        }
        return
      }
      this.isDataModified = true;
    }
    if (event.type === 'validation') {
      this.invalidDiagnosisCodeFieldStatus[event.field] = {
        'diagnosisCode': event.diagnosisCode,
        'qualifier': event.qualifier
      }
      return
    }
    this.showHideDiagnosisCodeConfig[event.field] = false;
    this.invalidDiagnosisCodeFieldStatus[event.field] = {
      'diagnosisCode': event.diagnosisCode,
      'qualifier': event.qualifier
    }
  }


  collapseAdditionalHltcreEditor(event){
    if (event.type === 'change') {
      if (event.isInvalid) {
        this.invalidadditionalHltcreFieldStatus[event.field] = {
          'industryCode': event.industryCode,
          'codeFromDate': event.codeFromDate,
          'codeThroughDate': event.codeThroughDate,
          'amount': event.amount,
        }
        return
      }
      this.isDataModified = true;
    }
    if (event.type === 'validation') {
      this.invalidadditionalHltcreFieldStatus[event.field] = {
        'industryCode': event.industryCode,
        'codeFromDate': event.codeFromDate,
        'codeThroughDate': event.codeThroughDate,
        'amount': event.amount,

      }
      return
    }
    this.showHideAdditionalHltCareConfig[event.field] = false;
    this.invalidadditionalHltcreFieldStatus[event.field] = {
      'industryCode': event.industryCode,
      'codeFromDate': event.codeFromDate,
      'codeThroughDate': event.codeThroughDate,
      'amount': event.amount,
    }

  }

  collapseClaimRefInfoEditor(event){
    if (event.type === 'change') {
      if (event.isInvalid) {
        this.invalidClaimRefInfoFieldStatus[event.field] = {
          'refIdentification': event.refIdentification
        }
        return
      }
      this.isDataModified = true;
    }
    if (event.type === 'validation') {
      this.invalidClaimRefInfoFieldStatus[event.field] = {
        'refIdentification': event.refIdentification
      }
      return
    }
    this.showHideClaimRefInfoConfig[event.field] = false;
    this.invalidClaimRefInfoFieldStatus[event.field] = {
      'refIdentification': event.refIdentification
    }
  }

  collapseAddClaimDatesEditor(event) {
    const invalidStatus = {
      'dateFrom': event.dateFrom,
      'dateThrough': event.dateThrough,
      'time': event.time,
      'dateTimeFormatQual': event.dateTimeFormatQual,
    }
    if (event.type === 'change') {
      if (event.isInvalid) {
        this.invalidAddClaimDatesFieldStatus[event.field] = invalidStatus;
        return
      }
      this.isDataModified = true;
    }
    if (event.type === 'validation') {
      this.invalidAddClaimDatesFieldStatus[event.field] = invalidStatus;
      return
    }
    this.showHideAddClaimDatesConfig[event.field] = false;
    this.invalidAddClaimDatesFieldStatus[event.field] = invalidStatus
  }

  getFromDate(clm){
    return clm.dateTimeQualifier === '361'? "" :
      this.getAddClaimDate(clm,false);
  }


  getTime(clm){
    return this.isInstitutional && clm.dateTimeQualifier === '096' ? clm.dateTimePeriod: this.isAddClaimDTTMQual(clm) ? clm.dateTimePeriod.substr(8,4):  "";
  }

  isAddClaimDTTMQual(claim){
    return this.isInstitutional && claim.dateTimeQualifier === "435" && claim.dateTimeFormatQual === "DT"
  }

  getThroughDate(clm){
    const flag = clm.dateTimeQualifier !== '361';
    return this.getAddClaimDate(clm,flag);
  }

  closeHeaderPopUp() {
    this.closePopUp(this.getCollapsePanelParam());
  }
}
