<div [formGroup]="claimAdjForm" class="editor-wrapper scroll-field">
    <div class="clm-adjst-wrapper hdr-data-cls">
        <div class="group-code clm-adjst-item">
            <uitk-form-field>
                <uitk-select formControlName="dropdown" id="dropdown" class="select-field-wrapper"
                    [itemList]="listData['clmAdjGroupCode']" [defaultLabelFlag]=false
                    [showError]="getFormControlValidity('dropdown')">
                </uitk-select>
            </uitk-form-field>
        </div>
        <div class="res-code-col clm-adjst-item">
            <uitk-form-field >
                <input class="reasoncode-field" uitkInput formControlName="reasonCodeField" id="clmAdjReasonCode"
                    (change)="validate()" formCtrl="reasonCodeField" [maxLength]="maxLengthInfo.reasonCode" />
                <uitk-form-field-error *ngIf="claimAdjForm?.controls?.reasonCodeField?.touched &&
                    getFormControlValidity('reasonCodeField')">
                    Reason Code Invalid
                </uitk-form-field-error>
            </uitk-form-field>
        </div>
        <div class="clm-adjst-item">
            <div class="dollar-field-wrapper">
                <span class="dollar-symbol">$</span>
                <uitk-form-field >
                    <input uitkInput formControlName="dollarField"  id="clmAdjDollar"
                        (change)="validate()" formCtrl="dollarField" [maxLength]="maxLengthInfo.dollar" />
                    <uitk-form-field-error *ngIf="claimAdjForm?.controls?.dollarField?.touched &&
                    getFormControlValidity('dollarField')">
                        Amount Invalid
                    </uitk-form-field-error>
                </uitk-form-field>
                <span class="separator">.</span>
                <uitk-form-field>
                    <input uitkInput formControlName="centField" class="cent-field" id="clmAdjCent"
                        (change)="validate()" formCtrl="centField" [maxLength]="maxLengthInfo.cent" />
                    <uitk-form-field-error *ngIf="claimAdjForm?.controls?.dollarField?.touched &&
                    getFormControlValidity('dollarField')">
                        Amount Invalid
                    </uitk-form-field-error>
                </uitk-form-field>
            </div>
        </div>
        <div class="qunatity-col clm-adjst-item">
            <uitk-form-field class="quantity-field">
                <input uitkInput class="quantity-field" formControlName="quantityField" id="clmAdjQuantity"
                    (change)="validate()" formCtrl="quantityField" [maxLength]="maxLengthInfo.qty" />
                <uitk-form-field-error *ngIf="claimAdjForm?.controls?.quantityField?.touched &&
                    getFormControlValidity('quantityField')">
                    Quantity Invalid
                </uitk-form-field-error>
            </uitk-form-field>
        </div>
        <div class="action-btn-wrapper">
            <button [class]="'uitk-c-button uitk-c-button--sm uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs '+changeBtnCls" (click)="changeClick()"
                (mousedown)="mousedownEvent($event)">Apply</button>
            <button class="uitk-c-button uitk-c-button--sm uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="cancelClick()">Cancel</button>
        </div>
    </div>
</div>