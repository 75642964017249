import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import {  UITKDialogModule } from '@uitk/angular';
import { FormsModule } from '@angular/forms';
import { SessionTimeoutDialogComponent } from './session-timeout-dialog.component';
import { SessionTimeoutComponent } from './session-timeout.component';



@NgModule({
  declarations: [SessionTimeoutComponent,SessionTimeoutDialogComponent],
  imports: [
    CommonModule,
    UITKDialogModule,
  ],
  exports:[SessionTimeoutComponent,SessionTimeoutDialogComponent]
})
export class SessionTimeoutModule { }