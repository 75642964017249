import { propertyMap } from '../propertyMap';

export class OauthResponse{
    @propertyMap('access_token')
    public access_token:string;

    @propertyMap('refresh_token')
    public refresh_token:string;

    @propertyMap('id_token')
    public id_token:string;

    @propertyMap('token_type')
    public token_type:string;

    @propertyMap('expires_in')
    public expires_in:string;

    @propertyMap('stargateEnabled')
    public stargateEnabled:boolean;

}
