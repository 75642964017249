import { Inject } from '@angular/core';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { DatePipe } from '@angular/common';
import { Constants } from '../data-models/constants.enum';
import { SessionStorageUtility } from './utility/sessionstorage.utility';

export class ReportsCommonComponent {

  public clientName: string = '';
  public startDate: string = '';
  public endDate: string = '';

  constructor(
    @Inject(SESSION_STORAGE) public sessionStorage: WebStorageService,
    public dateFormat: DatePipe) {
  }

  public setTimePeriod() {
    const timePeriod =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod);
    let timePeriodValue = '';
    if (timePeriod) {
      timePeriodValue = timePeriod['value'];
    }
    const currentDate = new Date();
    this.endDate = this.dateFormat.transform(currentDate, Constants.mmddyyyy);
    if (Constants.lastYear === timePeriodValue) {
      const week = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 365);
      this.startDate = this.dateFormat.transform(week, Constants.mmddyyyy);
    } else if (Constants.lastMonth === timePeriodValue) {
      const week = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 30);
      this.startDate = this.dateFormat.transform(week, Constants.mmddyyyy);
    } else if (Constants.lastWeek == timePeriodValue) {
      const week = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7);
      this.startDate = this.dateFormat.transform(week, Constants.mmddyyyy);
    } else {
      this.startDate = this.dateFormat.transform(currentDate, Constants.mmddyyyy);
    }
  }
}