
import { UITKTableModule, UITKPaginationModule, UITKPanelModule, UITKSelectModule, UITKDatePickerModule, UITKDialogModule, UITKInputModule, UITKLoadingIndicatorModule, UITKBreadcrumbModule, UITKCheckboxModule, UITKTableFeaturesModule } from '@uitk/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { MdeExpandComponent } from './mde-expand/mde-expand.component';
import { ErrCrrServiceCatgComponent } from '../error-crr-expand/err-crr-service-catg-expand/err-crr-service-catg/err-crr-service-catg.component';
import { ServiceLineInfoComponent } from '../error-crr-expand/err-crr-service-catg-expand/service-line-info/service-line-info.component';
import { ErrorCrrEditorComponent } from '../error-crr-expand/error-crr-editor/error-crr-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorHighlightComponent } from '../error-crr-expand/error-highlight/error-highlight.component';
import { ErrCrrServiceProviderComponent } from '../error-crr-expand/err-crr-service-catg-expand/err-crr-service-provider/err-crr-service-provider.component';
import { ErrorCrrClaimAdjustmentsComponent } from '../error-crr-expand/error-crr-header-catg-expand/error-crr-claim-adjustments/error-crr-claim-adjustments.component';

import { ReportsModule } from '../reports/reports.module';
import { ErrorCrrClaimRefComponent } from '../error-crr-expand/error-crr-header-catg-expand/error-crr-claim-ref/error-crr-claim-ref.component';
import { ErrorCrrAddClaimDatesComponent } from '../error-crr-expand/error-crr-header-catg-expand/error-crr-add-claim-dates/error-crr-add-claim-dates.component';
import { ErrorCrrHeaderCatgComponent } from '../error-crr-expand/error-crr-header-catg-expand/error-crr-header-catg/error-crr-header-catg.component';
import { ErrorCrrDiagnosisCodeComponent } from '../error-crr-expand/error-crr-header-catg-expand/error-crr-diagnosis-code/error-crr-diagnosis-code.component';
import { InputRestrictDirective } from './input-restrict.directive';
import { ErrorCrrAdditionalHltcreComponent } from '../error-crr-expand/error-crr-header-catg-expand/error-crr-additional-hltcre/error-crr-additional-hltcre.component';
import { ErrCrrMemberCatgComponent } from '../error-crr-expand/err-crr-member-catg-expand/err-crr-member-catg/err-crr-member-catg.component';
import { ErrorCrrProviderCatgComponent } from '../error-crr-expand/err-crr-provider-catg-expand/error-crr-provider-catg/error-crr-provider-catg.component';
import { ClmErrRowExpandComponent } from './clm-err-row-expand/clm-err-row-expand.component';
import { ClaimErrorsDetailsComponent } from './claim-errors-details/claim-errors-details.component';
import { ClaimErrorsHeaderComponent } from './claim-errors-header/claim-errors-header.component';
const routes: Routes = [
  { path: '', component: ClaimErrorsDetailsComponent}
];

@NgModule({
  declarations: [
    MdeExpandComponent,
    ErrCrrServiceCatgComponent,
    ServiceLineInfoComponent,
    ErrorCrrEditorComponent,
    ErrorHighlightComponent,
    ErrCrrServiceProviderComponent,
    ErrorCrrClaimAdjustmentsComponent,
    ErrorCrrClaimRefComponent,
    ErrorCrrAddClaimDatesComponent,
    ErrorCrrHeaderCatgComponent,
    ErrorCrrDiagnosisCodeComponent,
    InputRestrictDirective,
    ErrorCrrAdditionalHltcreComponent,
    ErrCrrMemberCatgComponent,
    ErrorCrrProviderCatgComponent,
    ClaimErrorsDetailsComponent,
    ClaimErrorsHeaderComponent,
    ClmErrRowExpandComponent 
  ],
  imports: [
    CommonModule,
    UITKTableModule,
    UITKPaginationModule,
    UITKPanelModule,
    UITKSelectModule,
    ReactiveFormsModule,
    FormsModule,
    UITKDatePickerModule,
    UITKDialogModule,
    UITKInputModule,
    ReportsModule,
    UITKLoadingIndicatorModule,
    UITKTableModule,
    UITKBreadcrumbModule,
    UITKCheckboxModule,
    UITKTableFeaturesModule,
    UITKPaginationModule,
    RouterModule.forChild(routes)
    
  ],
 })
export class ClaimErrorsModule { }
