
<uitk-dialog [(showModal)]="manageDownloadModal.show" tkDisableCloseOnEsc='true' tkShowHeader="false"
uitkId='myDialog-4' tkTriggerElementId='myDialog_openBtn4' tkOwnContent="true" tkWidth="640px" class="download-popup">
    <uitk-dialog-content>
        <div class="download-title">Manage & Download Exports</div>
        <div class="downdoad-des-text">Remember to save your files to a secure location. Exported files will be
            available for 7 days.</div>
            <div class="list-wrapper">
                <div class="download-file-list col-md-12" *ngFor="let item of downloadFilesList; let i = index">
                    <div class="col-md-8">
                        <span class="file-name">{{item.fileName}}</span><br>
                       <span class="timestamp record-count">Record Count: {{item.totalRecords}}</span><span class="timestamp">Created: {{item.crtDttm}}</span>
                    </div>
                    <button class="delete-btn" (click)="deleteFile(i,item)">Delete</button>
                    <button class="download-modal-btn" [ngClass]="{
                            'download-btn' : !item.downloadStatus,
                            'downloading-btn' : item.downloadStatus == downloadingText,
                            'complete-btn' : item.downloadStatus == completeText}" (click)="downloadClick(item)">
                        
                        <i  *ngIf="item.downloadStatus === completeText"  class="uitk-icon uitk-icon-checkmark_hollow sm-icon"></i>
                        {{item.downloadStatus ? item.downloadStatus : downloadText}}
                    </button>
                </div>
            </div>        
        <div *ngIf="downloadFilesList && downloadFilesList.length == 0" class="ax_default no-downloads-text">No downloads available</div>
    </uitk-dialog-content>
</uitk-dialog>


<uitk-dialog [(showModal)]="deleteConfirmModal.show" uitkId='myDialog-4'
     width="500px" class="delete-popup">
     <uitk-dialog-header  *ngIf="!isLoading">
        <div class="delete-confirm-title">Delete your export?</div>
     </uitk-dialog-header>
    <uitk-dialog-content>
        <div  *ngIf="!isLoading">
            
            <div >This process cannot be undone.</div>
            
        </div>
        <div *ngIf="isLoading">
            <div class="loader-wrapper">
                <uitk-loading-indicator
                uitkId="download-loading"
                visibleText="The page will be available shortly"
                spinnerSize="medium"
                [centerSpinner]='true'
                >
                </uitk-loading-indicator>
            </div>
        </div>
    </uitk-dialog-content>
    <uitk-dialog-actions *ngIf="!isLoading">
        <button class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--x" (click)="confirmDelete()">Delete Export</button>
            <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="closeDeletePopup()">Cancel</button>
    </uitk-dialog-actions>
</uitk-dialog>


























<!-- <ng-content *ngIf=\"!content\"></ng-content> -->