<a class="uitk-c-header-skip-to-content-link" href="#main-content"
  >skip to content</a
>

<div class="data-refresh">Data Current As Of {{ dataRefresh }}</div>
<uitk-header>
  <uitk-header-logo>
      <img class="uitk-c-header__logo"   [src]="logosConfig?.logo.path"
      [alt]="logosConfig?.logo.alt" />
      <h4 class="app-title">{{appTitle}}</h4>
  </uitk-header-logo>
  <uitk-navigation-global [ngClass]="{
    'hide-content': isSessionTimeoutPage || isStaticPage || !isTermsAccepted
  }">
      <label class="uitk-c-global-nav__menu-label">Menu Label</label>
      <uitk-navigation-panel>
          <uitk-navigation-panel-item>
            <div uitkNavRoute class="header-text"> Welcome, {{ userTitle }} </div>
          </uitk-navigation-panel-item>
          <uitk-navigation-panel-item>
            <a uitkNavRoute (click)="openDownloadModal()"> Downloads <span *ngIf="filesCount > 0" class="notification">{{ filesCount }}</span> </a>
          </uitk-navigation-panel-item>
          <uitk-navigation-panel-item>
            <a uitkNavRoute routerLink="/home"> <i class="uitk-icon uitk-icon-home sm-icon"></i>Home </a>
          </uitk-navigation-panel-item>
          <uitk-navigation-panel-item>
            <a uitkNavRoute (click)="clickSpotlightBtn()">  <i class="uitk-icon uitk-icon-new_window sm-icon"></i> Spotlight</a>
          </uitk-navigation-panel-item>
          <uitk-navigation-panel-item>
            <a uitkNavRoute routerLink="/logout"> Sign Out </a>
          </uitk-navigation-panel-item>
      </uitk-navigation-panel>
  </uitk-navigation-global>
</uitk-header>
<app-user-notification-msg
  *ngIf="!isSessionTimeoutPage && !isStaticPage && isAuthorizedUser"
></app-user-notification-msg>
<app-download
  [manageDownloadModal]="manageDownloadModal"
  [downloadFilesList]="downloadFiles"
></app-download>
