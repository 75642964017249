<div class="err-crr-service-expand-wrapper">
    <div *ngIf="dataLoading" [ngStyle]="{ 'height': '370px', 'position': 'relative' }">
        <uitk-loading-indicator
                uitkId="errCrrServiceCatg"
                [visibleText]="visibleTextValue"
                spinnerSize="medium"
                [centerSpinner]='true'
            >
            </uitk-loading-indicator>
    </div>
    <ng-container *ngIf="!dataLoading">
        <div class="info-wapper">
            <div class="flex-container">
                <span>
                    <span *ngIf="open" class="collapse-btn" (click)="toggleOpen(true)">Collapse All</span>
                    <span *ngIf="!open" class="collapse-btn" (click)="toggleOpen(false)">Expand All</span>
                    <span *ngIf="selectedRow?.srvcLnNo" class="view-error-btn" (click)="scroll(document,$event)"> View
                        Error</span>
                </span>
                <a class="close-icon" (click)="cancelService()">
                    <i class="uitk-icon uitk-icon-close lg-icon"></i>
                </a>
            </div>
            <div>
                <div
                    *ngFor="let serviceLineData of clmDta?.billingProvider[0]?.subscriber[0]?.claim[0]?.loop2400ServiceLine; let index= index">
                    <uitk-panels [isAccordion]="false">
                        <uitk-panel [uitkId]="setId(index)" [open]="openAccordion.get(setId(index))" (openChange)="closeAccordion(setId(index))" headerClass="tk-panl-with-underline">
                            <uitk-panel-header-title
                                [ngClass]="{'error-highlight-acc': getErrorHighlightCount(serviceLineData.loop2400ServiceLineNumber[0].assignedNumber,true)}">
                                <strong>{{getServiceLineNo(index)}}
                                        <span *ngIf="getErrorHighlightCount(serviceLineData.loop2400ServiceLineNumber[0].assignedNumber,true)"
                                            class="error-highlight-count">{{getErrorHighlightCount(serviceLineData.loop2400ServiceLineNumber[0].assignedNumber,true)}}</span>
                                   
                                </strong>
                            </uitk-panel-header-title>
                            <uitk-panel-content>
                                <!-- <div *ngIf="getErrorHighlightCount(serviceLineData.loop2400ServiceLineNumber[0].assignedNumber,true)" class="panel-header-detail">
                                <span class="error-highlight-count">{{getErrorHighlightCount(serviceLineData.loop2400ServiceLineNumber[0].assignedNumber,true)}}</span>
                            </div> -->
                                <app-service-line-info [clmInfoData]="serviceLineData" [selectedRow]="selectedRow"
                                    [listConfig]="listConfig" [index]="index" [showHideConfig]="showHideConfig"
                                    [changeBtnCls]="changeBtnCls" (isDataModifiedEvent)="emitIsDataModified($event)"
                                    [errorHighlight]="errorHighlight"
                                    [serviceLineNum]="serviceLineData.loop2400ServiceLineNumber[0].assignedNumber">
                                </app-service-line-info>
                                <!-- service line Done -->
                                <div class="service-provider-section">
                                    <div class="section-heading">
                                        Service Line Providers
                                    </div>
                                    <div *ngIf="getUnmatchErrorHighlight(serviceFieldsLabel.providerSec,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)"
                                        class="unmatch-error-wrapper">
                                        <app-error-highlight
                                            [errorHighlightList]="getUnmatchErrorHighlight(serviceFieldsLabel.providerSec,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)">
                                        </app-error-highlight>
                                    </div>
                                    <div class="section-inner-wrapper hdr-btm-cls">
                                        <div class="provider-label entity-code-col">
                                            <div *ngIf="isColumnInvalid(invalidProvidersFieldStatus,'entityCode',index)"
                                                class="invalid-col-field-msg"
                                                [innerHTML]="getErrorHighlightMsg('Entity Type Code is invalid')">
                                            </div>
                                            <span
                                                [ngClass]="{'invalid-field': isColumnInvalid(invalidProvidersFieldStatus,'entityCode',index)}">Entity
                                                Type Code</span>
                                        </div>
                                        <div class="provider-label name-col">
                                            <div *ngIf="isColumnInvalid(invalidProvidersFieldStatus,'name',index)"
                                                class="invalid-col-field-msg"
                                                [innerHTML]="getErrorHighlightMsg('Name is invalid')">
                                            </div>
                                            <span
                                                [ngClass]="{'invalid-field': isColumnInvalid(invalidProvidersFieldStatus,'name',index)}">Name</span>
                                        </div>
                                        <div class="provider-label entity-id">
                                            Entity ID
                                        </div>
                                        <div class="provider-label npi-col">
                                            <div *ngIf="isColumnInvalid(invalidProvidersFieldStatus,'npi',index)"
                                                class="invalid-col-field-msg"
                                                [innerHTML]="getErrorHighlightMsg('NPI is invalid')">
                                            </div>
                                            <span
                                                [ngClass]="{'invalid-field': isColumnInvalid(invalidProvidersFieldStatus,'npi',index)}">NPI</span>
                                        </div>
                                        <div class="provider-label address-col">
                                            <div *ngIf="isColumnInvalid(invalidProvidersFieldStatus,'address',index)"
                                                class="invalid-col-field-msg"
                                                [innerHTML]="getErrorHighlightMsg('Address is invalid')">
                                            </div>
                                            <span
                                                [ngClass]="{'invalid-field': isColumnInvalid(invalidProvidersFieldStatus,'address',index)}">Address</span>
                                        </div>
                                        <div class="provider-label taxonomy-col">
                                            <div *ngIf="isColumnInvalid(invalidProvidersFieldStatus,'taxonomy',index)"
                                                class="invalid-col-field-msg"
                                                [innerHTML]="getErrorHighlightMsg('Taxonomy Code is invalid')">
                                            </div>
                                            <span
                                                [ngClass]="{'invalid-field': isColumnInvalid(invalidProvidersFieldStatus,'taxonomy',index)}">Taxonomy
                                                Code</span>
                                        </div>
                                    </div>
                                    <div class="section-inner-wrapper provider-data hdr-data-cls"
                                        *ngFor="let providerObj of serviceLineData?.loop2420Name; let providerIdx = index">
                                        <div *ngFor="let clmField of serviceFieldsLabel.providerFields"
                                            class="match-error-wrapper">
                                            <app-error-highlight [errorHighlightList]="getMatchErrorHighlight(serviceFieldsLabel.providerSec,clmField,providerObj.loop2420NM1?.entityIDCode,providerIdx
                                            ,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)">
                                            </app-error-highlight>
                                        </div>
                                        <div *ngIf="!showHideProviderInfoConfig[index+'_'+providerIdx]"
                                            class="data-wrapper">
                                            <div class="provider-item entity-code-col text-ellipses" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(serviceFieldsLabel.providerSec,serviceFieldsLabel.providerFields[0],providerObj.loop2420NM1?.entityIDCode,
                                    providerIdx,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)}">
                                                <span class="icon-clm-edit">
                                                    <i
                                                        (click)="expandEditor(index+'_'+providerIdx,showHideProviderInfoConfig)"
                                                        class="uitk-icon uitk-icon-edit md-icon" hiddenText="edit"></i>
                                                </span>
                                                <span
                                                    [title]="getFieldLabel('entityTypeCode',providerObj?.loop2420NM1?.entityTypeQualifier)">
                                                    {{getFieldLabel('',providerObj?.loop2420NM1?.entityTypeQualifier,getEntityTypeList(entityId[providerObj.loop2420NM1?.entityIDCode]))}}
                                                </span>
                                            </div>
                                            <div class="provider-item name-col text-ellipses" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(serviceFieldsLabel.providerSec,serviceFieldsLabel.providerFields[1],providerObj.loop2420NM1?.entityIDCode,providerIdx
                                    ,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)}">
                                                <span [title]="getName(providerObj)">{{getName(providerObj)}}</span>
                                            </div>
                                            <div class="provider-item entity-id text-ellipses" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(serviceFieldsLabel.providerSec,serviceFieldsLabel.providerFields[2],providerObj.loop2420NM1?.entityIDCode,providerIdx
                                    ,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)}">
                                                <span
                                                    [title]="entityId[providerObj.loop2420NM1?.entityIDCode] ? providerObj.loop2420NM1?.entityIDCode + '-'+entityId[providerObj.loop2420NM1?.entityIDCode] : providerObj.loop2420NM1?.entityIDCode">
                                                    {{entityId[providerObj.loop2420NM1?.entityIDCode] ?
                                                    providerObj.loop2420NM1?.entityIDCode +
                                                    '-'+entityId[providerObj.loop2420NM1?.entityIDCode] :
                                                    providerObj.loop2420NM1?.entityIDCode}}</span>
                                            </div>
                                            <div class="provider-item npi-col text-ellipses" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(serviceFieldsLabel.providerSec,serviceFieldsLabel.providerFields[3],providerObj.loop2420NM1?.entityIDCode,providerIdx
                                    ,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)}">
                                                <span [title]="providerObj.loop2420NM1?.idCode">
                                                    {{providerObj.loop2420NM1?.idCode}}
                                                </span>
                                            </div>
                                            <div class="provider-item address-col" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(serviceFieldsLabel.providerSec,serviceFieldsLabel.providerFields[4],providerObj.loop2420NM1?.entityIDCode,providerIdx
                                    ,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)}">
                                                <span [title]="getAddress(providerObj)">
                                                    {{getAddress(providerObj)}}
                                                </span>
                                            </div>
                                            <div class="provider-item taxonomy-col text-ellipses" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(serviceFieldsLabel.providerSec,serviceFieldsLabel.providerFields[5],providerObj.loop2420NM1?.entityIDCode,providerIdx
                                    ,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)}">
                                                <span [title]="providerObj.loop2420PRV?.referenceIdent">
                                                    {{providerObj.loop2420PRV?.referenceIdent }}
                                                </span>
                                            </div>
                                        </div>
                                        
                                        <app-err-crr-service-provider class="component-wrapper"
                                            *ngIf="showHideProviderInfoConfig[index+'_'+providerIdx]"
                                            [inputLabel]="index+'_'+providerIdx" [inputValue]="providerObj"
                                            [listData]="listConfig"
                                            [entityTypeList]="getEntityTypeList(entityId[providerObj.loop2420NM1?.entityIDCode])"
                                            [changeBtnCls]="changeBtnCls" [isInstitutional]="isInstitutional"
                                            [entityId]="entityId"
                                            (collapseServiceProviderFields)="collapseServiceProviderEditor($event)">
                                        </app-err-crr-service-provider>
                                    </div>
                                </div>
                                <div class="clm-adjst-section">
                                    <div class="section-heading">
                                        Adjustment Records
                                    </div>
                                    <div *ngIf="getUnmatchErrorHighlight(serviceFieldsLabel.adjustmentSec,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)"
                                        class="unmatch-error-wrapper">
                                        <app-error-highlight
                                            [errorHighlightList]="getUnmatchErrorHighlight(serviceFieldsLabel.adjustmentSec,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)">
                                        </app-error-highlight>
                                    </div>
                                    <div class="section-inner-wrapper hdr-btm-cls">
                                        <div class="grp-code clm-adjst-label">
                                            <div *ngIf="isColumnInvalid(invalidClaimAdjFieldStatus,'groupCode',index)"
                                                class="invalid-col-field-msg"
                                                [innerHTML]="getErrorHighlightMsg('Group Code is invalid')"></div>
                                            <span class="field-label"
                                                [ngClass]="{'invalid-field': isColumnInvalid(invalidClaimAdjFieldStatus,'groupCode',index)}">Group
                                                Code</span>
                                        </div>


                                        <div class="res-code-col clm-adjst-label">
                                            <div *ngIf="isColumnInvalid(invalidClaimAdjFieldStatus,'reasonCode',index)"
                                                class="invalid-col-field-msg"
                                                [innerHTML]="getErrorHighlightMsg('Reason Code is invalid')"></div>
                                            <span class="field-label"
                                                [ngClass]="{'invalid-field': isColumnInvalid(invalidClaimAdjFieldStatus,'reasonCode',index)}">Reason
                                                Code</span>
                                        </div>

                                        <div class="clm-adjst-label">
                                            <div *ngIf="isColumnInvalid(invalidClaimAdjFieldStatus,'amount',index)"
                                                class="invalid-col-field-msg"
                                                [innerHTML]="getErrorHighlightMsg('Amount is invalid')"></div>
                                            <span class="field-label"
                                                [ngClass]="{'invalid-field': isColumnInvalid(invalidClaimAdjFieldStatus,'amount',index)}">Amount</span>
                                        </div>
                                        <div class="clm-adjst-label">
                                            <div *ngIf="isColumnInvalid(invalidClaimAdjFieldStatus,'quantity',index)"
                                                class="invalid-col-field-msg"
                                                [innerHTML]="getErrorHighlightMsg('Quantity is invalid')"></div>
                                            <span class="field-label"
                                                [ngClass]="{'invalid-field': isColumnInvalid(invalidClaimAdjFieldStatus,'quantity',index)}">Quantity</span>
                                        </div>
                                    </div>

                                    <div
                                        *ngFor="let claimAdj of serviceLineData.loop2430LineAdjudicationInformation; let claimAdjGroupCode = index">
                                        <div class="section-inner-wrapper hdr-data-cls"
                                            *ngFor="let clmAdjItem of claimAdj.loop2430CASLineAdjustment;let clmAdjItemIndex = index">
                                            <div *ngFor="let clmField of serviceFieldsLabel.adjustmentFields"
                                                class="match-error-wrapper">
                                                <app-error-highlight
                                                    [errorHighlightList]="getMatchErrorHighlight(serviceFieldsLabel.adjustmentSec,clmField,clmAdjItem.claimAdjGroupCode,
                                                    clmAdjItem.rptnPosNum,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)">
                                                </app-error-highlight>
                                            </div>
                                            <div *ngIf="!showHideclaimAdjConfig[index+'_'+claimAdjGroupCode+'_'+clmAdjItemIndex]"
                                                class="data-wrapper">
                                                <div class="group-code clm-adjst-item text-ellipses"
                                                    [ngClass]="{'error-highlight-field': getMatchErrorHighlight(serviceFieldsLabel.adjustmentSec,
                                            serviceFieldsLabel.adjustmentFields[0],clmAdjItem.claimAdjGroupCode,
                                            clmAdjItem.rptnPosNum,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)}">
                                                    <ng-container>
                                                        <span class="icon-clm-edit">
                                                            <i class="uitk-icon uitk-icon-edit md-icon"
                                                                (click)="expandEditor(index+'_'+claimAdjGroupCode+'_'+clmAdjItemIndex,showHideclaimAdjConfig)">
                                                        </i>
                                                        </span>
                                                        {{getFieldLabel('clmAdjGroupCode',clmAdjItem.claimAdjGroupCode)}}
                                                    </ng-container>
                                                </div>
                                                <div class="res-code-col clm-adjst-item"
                                                    [ngClass]="{'error-highlight-field': getMatchErrorHighlight(serviceFieldsLabel.adjustmentSec,
                                            serviceFieldsLabel.adjustmentFields[1],clmAdjItem.claimAdjGroupCode,
                                            clmAdjItem.rptnPosNum,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)}">
                                                    {{clmAdjItem.claimAdjReasonCode}}</div>
                                                <div class="clm-adjst-item"
                                                    [ngClass]="{'error-highlight-field': getMatchErrorHighlight(serviceFieldsLabel.adjustmentSec,
                                            serviceFieldsLabel.adjustmentFields[2],clmAdjItem.claimAdjGroupCode,
                                            clmAdjItem.rptnPosNum,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)}">
                                                    {{clmAdjItem.monetaryAmount | currency}}</div>
                                                <div class="clm-adjst-item"
                                                    [ngClass]="{'error-highlight-field': getMatchErrorHighlight(serviceFieldsLabel.adjustmentSec,
                                            serviceFieldsLabel.adjustmentFields[3],clmAdjItem.claimAdjGroupCode,
                                            clmAdjItem.rptnPosNum,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)}">
                                                    {{clmAdjItem.quantity}}</div>
                                            </div>
                                            <app-error-crr-claim-adjustments
                                                *ngIf="showHideclaimAdjConfig[index+'_'+claimAdjGroupCode+'_'+clmAdjItemIndex]"
                                                [inputValue]="clmAdjItem" [listData]="listConfig"
                                                [inputLabel]="index+'_'+claimAdjGroupCode+'_'+clmAdjItemIndex"
                                                [changeBtnCls]="changeBtnCls"
                                                (collapseClaimAdjFields)="collapseClaimAdjEditor($event)"
                                                [isInstitutional]="isInstitutional">
                                            </app-error-crr-claim-adjustments>
                                        </div>
                                    </div>
                                </div>

                                <div class="service-line-ref-info-section">
                                    <div class="section-heading">
                                        Service Line Reference Information
                                    </div>
                                    <div *ngIf="getUnmatchErrorHighlight(serviceFieldsLabel.clmRefInfoSec,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)"
                                        class="unmatch-error-wrapper">
                                        <app-error-highlight
                                            [errorHighlightList]="getUnmatchErrorHighlight(serviceFieldsLabel.clmRefInfoSec,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)">
                                        </app-error-highlight>
                                    </div>
                                    <div class="section-inner-wrapper hdr-btm-cls">
                                        <div class="claim-ref-label qualf-col">Qualifier</div>
                                        <div class="claim-ref-label ref-col">
                                            <div *ngIf="isColumnInvalid(invalidClaimRefInfoFieldStatus,'refIdentification',index)"
                                                class="invalid-col-field-msg error-msg"
                                                [innerHTML]="getErrorHighlightMsg('Reference Identification is invalid')">
                                            </div>
                                            <span
                                                [ngClass]="{'invalid-field': isColumnInvalid(invalidClaimRefInfoFieldStatus,'refIdentification',index)}">
                                                Reference Identification</span>
                                        </div>
                                    </div>
                                    <div class=" section-inner-wrapper hdr-data-cls"
                                        *ngFor="let serviceLineRefInfo of serviceLineData?.refLoop2400REF; let serviceLineRefIdx = index">
                                        <div *ngFor="let clmField of serviceFieldsLabel.clmRefInfoFields"
                                            class="match-error-wrapper">
                                            <app-error-highlight [errorHighlightList]="getMatchErrorHighlight(serviceFieldsLabel.clmRefInfoSec,clmField,serviceLineRefInfo.referenceIdentQual,serviceLineRefIdx
                                            ,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)">
                                            </app-error-highlight>
                                        </div>
                                        <div *ngIf="!showHideClaimRefInfoConfig[index+'_'+serviceLineRefIdx]"
                                            class="data-wrapper">
                                            <div class="claim-ref-item text-ellipses" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(serviceFieldsLabel.clmRefInfoSec,serviceFieldsLabel.clmRefInfoFields[0],serviceLineRefInfo.referenceIdentQual,serviceLineRefIdx
                                        ,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)}">
                                                <span class="icon-clm-edit">
                                                    <i class="uitk-icon uitk-icon-edit md-icon"hiddenText="edit"
                                                        (click)="expandEditor(index+'_'+serviceLineRefIdx,showHideClaimRefInfoConfig)">
                                                </i>
                                                </span>
                                                <span
                                                    [title]="claimReferenceDesMapping[serviceLineRefInfo.referenceIdentQual] ? serviceLineRefInfo.referenceIdentQual + '-' + claimReferenceDesMapping[serviceLineRefInfo.referenceIdentQual]:serviceLineRefInfo.referenceIdentQual">{{serviceLineRefInfo.referenceIdentQual}}{{
                                                    claimReferenceDesMapping[serviceLineRefInfo.referenceIdentQual] ?
                                                    '-' +
                                                    claimReferenceDesMapping[serviceLineRefInfo.referenceIdentQual]:''}}</span>
                                            </div>
                                            <div class="claim-ref-item ref-col text-ellipses" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(serviceFieldsLabel.clmRefInfoSec,serviceFieldsLabel.clmRefInfoFields[1],serviceLineRefInfo.referenceIdentQual,serviceLineRefIdx
                                        ,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)}">
                                                <span [title]="serviceLineRefInfo.referenceIdent">
                                                    {{serviceLineRefInfo.referenceIdent}}
                                                </span>
                                            </div>
                                        </div>
                                        <app-error-crr-claim-ref class="component-wrapper"
                                            *ngIf="showHideClaimRefInfoConfig[index+'_'+serviceLineRefIdx]"
                                            [inputLabel]="index+'_'+serviceLineRefIdx" [inputValue]="serviceLineRefInfo"
                                            [claimReferenceDesMapping]="claimReferenceDesMapping"
                                            [changeBtnCls]="changeBtnCls" [isServiceLineCategory]="true"
                                            (collapseClaimRefInfoFields)="collapseClaimRefInfoEditor($event)">
                                        </app-error-crr-claim-ref>
                                    </div>
                                </div>
                                <div class="add-service-line-dts-section addition-clm-dts" *ngIf="!isInstitutional">
                                    <div class="section-heading">
                                        Additional Service Line Dates
                                    </div>
                                    <div *ngIf="getUnmatchErrorHighlight(serviceFieldsLabel.AddClmDatesSec,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)"
                                        class="unmatch-error-wrapper">
                                        <app-error-highlight
                                            [errorHighlightList]="getUnmatchErrorHighlight(serviceFieldsLabel.AddClmDatesSec,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)">
                                        </app-error-highlight>
                                    </div>
                                    <div class="section-inner-wrapper hdr-btm-cls">
                                        <div class="clm-dts-label qualf-col">Date/Time Qualifier</div>
                                        <div class="clm-dts-label add-dt-frm-col add-dttm-fmt-col">Date/Time Format
                                            Qualifier</div>
                                        <div class="clm-dts-label add-dt-frm-col clm-add-date-col">
                                            <div *ngIf="isColumnInvalid(invalidAddClaimDatesFieldStatus,'dateFrom',index)"
                                                class="invalid-col-field-msg error-msg"
                                                [innerHTML]="getErrorHighlightMsg('Date From is invalid')"></div>
                                            <span
                                                [ngClass]="{'invalid-field': isColumnInvalid(invalidAddClaimDatesFieldStatus,'dateFrom',index)}">Date
                                                From</span>
                                        </div>

                                        <div class="clm-dts-label add-dt-frm-col clm-add-date-col">
                                            <div *ngIf="isColumnInvalid(invalidAddClaimDatesFieldStatus,'dateThrough',index)"
                                                class="invalid-col-field-msg"
                                                [innerHTML]="getErrorHighlightMsg('Date Through is invalid')"></div>
                                            <span
                                                [ngClass]="{'invalid-field': isColumnInvalid(invalidAddClaimDatesFieldStatus,'dateThrough',index)}">Date
                                                Through</span>
                                        </div>
                                        <div class="clm-dts-label add-dt-frm-col">
                                            <span>Time</span>
                                        </div>
                                    </div>
                                    <div class="section-inner-wrapper hdr-data-cls"
                                        *ngFor="let addSvcLineDates of serviceLineData.dtpLoop2400DTP; let addClaimDates = index"
                                        [hidden]="addSvcLineDates.dateTimeQualifier === '472'">
                                        <div *ngFor="let clmField of serviceFieldsLabel.clmRefInfoFields"
                                            class="match-error-wrapper">
                                            <app-error-highlight [errorHighlightList]="getMatchErrorHighlight(serviceFieldsLabel.AddClmDatesSec,clmField,addSvcLineDates.dateTimeQualifier,addClaimDates
                                            ,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)">
                                            </app-error-highlight>
                                        </div>
                                        <div *ngIf="!showHideAddClaimDatesConfig[index+'_'+addClaimDates]"
                                            class="data-wrapper">
                                            <div class="clm-dts-item qualf-col text-ellipses" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(serviceFieldsLabel.AddClmDatesSec,serviceFieldsLabel.clmRefInfoFields[0],addSvcLineDates.dateTimeQualifier,addClaimDates
                                        ,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)}">
                                                <span class="icon-clm-edit">
                                                    <i class="uitk-icon uitk-icon-edit md-icon" hiddenText="edit"
                                                        (click)="expandEditor(index+'_'+addClaimDates,showHideAddClaimDatesConfig)">
                                                </i>
                                                </span>
                                                <span
                                                    [title]="additionalClaimDateDesMapping[addSvcLineDates.dateTimeQualifier] ? addSvcLineDates.dateTimeQualifier +'-' + additionalClaimDateDesMapping[addSvcLineDates.dateTimeQualifier]:addSvcLineDates.dateTimeQualifier">

                                                    {{addSvcLineDates.dateTimeQualifier}}{{additionalClaimDateDesMapping[addSvcLineDates.dateTimeQualifier]
                                                    ? '-' + additionalClaimDateDesMapping
                                                    [addSvcLineDates.dateTimeQualifier]:''}}
                                                </span>
                                            </div>
                                            <div class="clm-dts-item add-dt-frm-col add-dttm-fmt-col" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(serviceFieldsLabel.AddClmDatesSec,serviceFieldsLabel.clmRefInfoFields[1],addSvcLineDates.dateTimeQualifier,addClaimDates
                                        ,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)}">
                                                <span
                                                    [title]="additionalClaimDateTimeData[addSvcLineDates.dateTimeFormatQual] ? addSvcLineDates.dateTimeFormatQual +'-' + additionalClaimDateTimeData[addSvcLineDates.dateTimeFormatQual]:addSvcLineDates.dateTimeFormatQual">

                                                    {{addSvcLineDates.dateTimeFormatQual}}{{additionalClaimDateTimeData[addSvcLineDates.dateTimeFormatQual]
                                                    ? '-' + additionalClaimDateTimeData
                                                    [addSvcLineDates.dateTimeFormatQual]:''}}
                                                </span>

                                            </div>
                                            <div class="clm-dts-item add-dt-frm-col clm-add-date-col" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(serviceFieldsLabel.AddClmDatesSec,serviceFieldsLabel.clmRefInfoFields[2],addSvcLineDates.dateTimeQualifier,addClaimDates
                                        ,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)}">
                                                {{getFromDate(addSvcLineDates)}}
                                            </div>
                                            <div class="clm-dts-item add-dt-thr-col clm-add-date-col" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(serviceFieldsLabel.AddClmDatesSec,serviceFieldsLabel.clmRefInfoFields[3],addSvcLineDates.dateTimeQualifier,addClaimDates
                                        ,serviceLineData.loop2400ServiceLineNumber[0].assignedNumber)}">
                                                {{getThroughDate(addSvcLineDates)}}
                                            </div>
                                            <div class="clm-dts-item add-dt-tm-col">

                                            </div>
                                        </div>
                                        <app-error-crr-add-claim-dates class="component-wrapper"
                                            *ngIf="showHideAddClaimDatesConfig[index+'_'+addClaimDates]"
                                            [inputLabel]="index+'_'+addClaimDates" [inputValue]="addSvcLineDates"
                                            [additionalClaimDateDesMapping]="additionalClaimDateDesMapping"
                                            [additionalClaimDateTimeData]="additionalClaimDateTimeData"
                                            [changeBtnCls]="changeBtnCls" [getAddClaimDate]="getAddClaimDateProps"
                                            [encodeDateFormat]="encodeDateFormat" [isInstitutional]="isInstitutional"
                                            [isServiceLineCategory]="true"
                                            (collapseAddClaimDatesEditor)="collapseAddClaimDatesEditor($event)">
                                        </app-error-crr-add-claim-dates>
                                    </div>
                                </div>
                            </uitk-panel-content>
                        </uitk-panel>
                    </uitk-panels>
                </div>
            </div>
            <div class="row">
                <div class="col accrdn-btm"></div>
            </div>
        </div>
        <div class="btns-btm">
            <div>
                <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="cancelService()">Cancel</button>
            </div>
            <div>
                <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="saveServiceClaim()">Save</button>
            </div>
            <div>
                <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="saveServiceClaim(true)">Resubmit</button>
            </div>
        </div>
    </ng-container>
</div>

<uitk-dialog [(showModal)]="showInvalidWarning"  uitkId='myDialog-4'
    class="warning-popup" triggerElementId='myDialog_openBtn4'(onModalHidden)="closeServicePopUp()"
    [closeButtonText]="myCloseButtonText">
    <uitk-dialog-header>
        <div class="warning-msg-heading-wrap" *ngIf="warningFlow !=='cancel'">
        <i class="uitk-icon uitk-icon-warning_filled md-icon" hiddenText="warning"></i>
        <div class="warning-msg-heading">Warning</div>
    </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
        <div class="confirm-data" [ngClass]="{'cancel':warningFlow ==='cancel'}">
            <div class="warning-msg-wrapper">
                <div class="warning-msg-content">{{warningMsg}}</div>
                <div class="warning-btns-btm" *ngIf="warningFlow==='cancel'">
                    <button class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="collapseServicePanel()">Continue</button>
                    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
                        (click)="showInvalidWarning = false">Cancel</button>
                </div>
            </div>
        </div>
    </uitk-dialog-content>
</uitk-dialog>
<app-error-crr-resubmit [resubmitConfig]="resubmitClmConfig" [selectedRow]="selectedRow"
    [errorCategoryDetails]="errorCategoryDtls" (collapsePopUp)="colpseSrvcResubmitPopUp($event)">
</app-error-crr-resubmit>