<div class="row margin-top-11">
    <div class="col-md-12">
        <div id="u1493_text" class="u1153_div">
            <p class="margin-paragraph"><strong class="ax_default mde-claims-count">{{mdeClaims}}</strong><span class="claims-mde-msg">claims are eligible for Minimum Data Element (MDE) Submission</span><span class="margin-left-15"><a href="#" (click)="navigateToMDE($event)">View Details</a></span></p>
        </div>       
    </div>
</div>
<div class="row margin-top-11">
    <div class="col-md-4 padding-left-3 width-left-3">
        <div><span class="chart-title">Claim Errors By Type</span></div>
    </div>
    <div class="col-md-8 custom-control-wrap">
        <div class="custom-control custom-radio custom-control-inline" *ngIf="!isClmDtCriteriaDOS">
            <input type="radio" id="rd_1" name="rd" class="custom-control-input" (change)="reloadGraph('Inbound Compliance')" [checked]="!isClmDtCriteriaDOS">
            <label class="custom-control-label red" for="rd_1">Inbound Compliance</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="rd_2" name="rd" class="custom-control-input" (change)="reloadGraph('Inbound Content')" [checked]="isClmDtCriteriaDOS">
            <label class="custom-control-label red" for="rd_2">Inbound Content</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="rd_3" name="rd" class="custom-control-input" (change)="reloadGraph('CMS 999')">
            <label class="custom-control-label red" for="rd_3">CMS 999</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="rd_4" name="rd" class="custom-control-input" (change)="reloadGraph('CMS 277')">
            <label class="custom-control-label red" for="rd_4">CMS 277</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="rd_5" name="rd" class="custom-control-input" (change)="reloadGraph('CMS MAO-002')">
            <label class="custom-control-label red" for="rd_5">CMS MAO-002</label>
        </div>
    </div>
</div>
<div class="graph-wrapper margin-bottom-35">
    <line-chart [hidden]="isBarChartVissible()" [isHidden]="isBarChartVissible()"></line-chart>
    <app-bar-charts [scopeData]="data" [hidden]="!isBarChartVissible()" [isHidden]="!isBarChartVissible()"></app-bar-charts>
</div>
<app-graph-switch-frequency [scopeProps]="data"></app-graph-switch-frequency>
<div class="row error-top-code-wrapper">
    <div class="col-md-2 error-top-code padding-left-3" *ngFor="let agg of aggData">
        <div><span>{{agg.name}}</span></div>
        <div class="count-title"> <span>{{ isClaimDOSInboundComplianceError(agg) ? "0" : agg.value}}</span> </div>
        <div class="row">
            <div class="col-md-6"><span class="err-hdr err-txt">Top Codes</span></div>
            <div class="col-md-6 text-align-right"><span class="err-hdr err-txt">Claims</span></div>
        </div>
        <div class="row border-top-title"></div>
        <ng-container *ngIf="!isClaimDOSInboundComplianceError(agg) ; else claimDOSInboundComp">
        <div class="row padding-2" *ngFor="let err of agg.errors;let i=index">
            <div class="col-md-6 err-txt">
                <uitk-tooltip [tooltipPlacement]="'top'" 
                [tooltipContent]="err.description"
                uitkId="{{i}}"
                tooltipPlacement="top">
                    <a href="#" (click)="onErrorCodeClick($event,agg.name,err.code)">{{err.code}}</a>
                    <!-- <span uitkTooltipContent>{{err.description}}</span> -->
                </uitk-tooltip>
            </div>
            <div class="col-md-6 err-txt text-align-right">{{err.clmsCount}}</div>
        </div>
        <div class="row padding-2" *ngIf="agg.errors?.length>0">
            <div class="col-md-6 err-txt"><a href="#" (click)="onViewDetailsClick($event,agg.name)"><b>View Details</b></a></div>
            <div class="col-md-6 err-txt text-align-right"></div>
        </div>
        </ng-container>
        <ng-template #claimDOSInboundComp>
            <div class="claim-DOS-Inbound-text padding-2">
                <span>Data not available when viewing by </span>
                <span class="text-italic">“Claim Date of Service”</span>
            </div>
        </ng-template>

    </div>
</div>
