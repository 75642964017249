import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ConfirmModelConfig } from '../../filter-confirm/confirm.mdelconfig';
import { Constants } from '../../data-models/constants.enum';
import { SummaryService } from '../../services/tabs/summary.service';
import { DatePipe } from '@angular/common';

enum OperatorMapping{
  select="EQUAL",
  datefield = "BETWEEN",
  textfield = "LIKE"
}
type SelectedFilter = {
  type?: string | Constants;
  label?: string | Constants;
  showLabel?: boolean;
  prefix?: string| Constants;
  postfix?: string| Constants;
  value: string| Constants;
}
type APIInput = {
  sortCriteria:{sortCriteriaID?:string | number},
  filterCriteria?:Array<APIFieldData>,
  [key:string]:any
}

type APIFieldData = {
  "operater":string,
  "key": string,
  "value": string | number,
  "tableInfo": string,
  "label"?:string
}

export type SelectionDetails = {
  apiInput?:APIInput,
  trxCmpltApiInput?:APIInput,
  bubbleData:Array<SelectedFilter>,
  confimrationBubbleData?:Array<SelectedFilter>
}
  
@Component({
  selector: 'app-filter-selection',
  templateUrl: './filter-selection.component.html',
  styleUrls: ['./filter-selection.component.css']
})
export class FilterSelectionComponent implements OnInit {
  @Input() config: any;
  @Input() scopeData: any;
  @Input() pageConfig: any;
  @Input() confirmPopupConfig : ConfirmModelConfig;
  @Input() selectionDetails: SelectionDetails;
  @Input() filterExpandedView :any; 
  @Output() scopeDataChanged: EventEmitter<any> = new EventEmitter<any>();
  submissionYear: any;
  private readonly dateInfoTooltipMsg = "A Custom Date Range must be within the open CMS submission period and cannot exceed a 13 month time span.";
  public readonly dateConfig: any = {
    enableValidation: false,
    iconCalendar: true 
  };
  rowArray = [];
  colArray = [0,1,2,3];

  constructor(private summaryService: SummaryService,private datePipe:DatePipe) { }

  ngOnInit() {
    this.getSubmissionYear();
    this.getNumberOfRwos();
  }

  getNumberOfRwos(){
    let maxNumOfRows = 0;
    for(const col of this.config.fields){
      if(maxNumOfRows < col.length){
        maxNumOfRows = col.length;
      }
    }
    for(let i = 0; i < maxNumOfRows; i++){
      this.rowArray.push(i);
    }
  }

  fromThroughDateValidity(dateKey){
    return !this.isCustomDateRangeSelection(this.scopeData[dateKey].period);
  }

  isFieldDisabled(fieldId){
    return this.pageConfig.disabledFields && this.pageConfig.disabledFields.indexOf(fieldId) !== -1;
  }

  onApplyBtnClick(){
    this.filterExpandedView.isApplyClicked = true;
    if(!this.getIsDatesValid()){
      return;
    }
    this.prepareSearchInputList();
    this.showConfirmationPopup();
  }

  onCancelBtnClick(){
    this.scopeDataChanged.emit({action:Constants.resetFilter});
    this.filterExpandedView.show = false;
    this.filterExpandedView.isApplyClicked = false;
  }

  prepareSearchInputList(){
    const apiInput:APIInput = {
      filterCriteria:[],
      sortCriteria:{}
    }
    const confimrationBubbleData : Array<SelectedFilter> = [];
    for(const reqField of this.config.requiredFields){
      if(reqField.isSortByField){
        apiInput.sortCriteria = {
          "sortCriteriaID": this.scopeData[reqField.model].value
        }
        confimrationBubbleData.push({value:this.scopeData[reqField.model].label});
      }else{
        apiInput[reqField.columnKey] = this.getFieldValue(reqField);
        if(reqField.type === "datefield" && this.isCustomDateRangeSelection(this.scopeData[reqField.model].period)){
          apiInput["startDate"] = this.transformDate(this.scopeData[reqField.model].fromDate);
          apiInput["endDate"] = this.transformDate(this.scopeData[reqField.model].throughDate);
        }
        if( apiInput[reqField.columnKey] !== undefined){
          this.updateBubbleConfig(confimrationBubbleData,reqField);
        }
      }
      
    }
     
    for(const categoryIdx in this.config.fields){
       for(const fieldConfig of this.config.fields[categoryIdx]){
        this.prepareSearchInputForField(fieldConfig,apiInput,confimrationBubbleData);
      }
    }
    this.selectionDetails.apiInput = apiInput;
    this.selectionDetails.confimrationBubbleData = confimrationBubbleData;
  }
  
  prepareSearchInputForField(fieldConfig,apiInput,confimrationBubbleData){
    if(this.scopeData[fieldConfig.model]){
      const apiInputField = this.getInputFieldQueryObj(fieldConfig);
      if(apiInputField.value !== undefined){
        apiInputField.label =  this.getFieldlabel(fieldConfig);
        if(fieldConfig.parentLevel){
          apiInput[fieldConfig.columnKey] = this.getFieldValue(fieldConfig);
        } else{
        apiInput.filterCriteria.push(apiInputField);
        }
        this.updateBubbleConfig(confimrationBubbleData,fieldConfig);
      }
    }
  }

  getInputFieldQueryObj(fieldConfig){
    let tableInfo;
    const columnKey = fieldConfig.columnKey;
    if (this.config.columnTableMapping && this.config.columnTableMapping.hasOwnProperty(columnKey) && fieldConfig.apiInputDependantField) {
      const valueKey = fieldConfig.apiInputDependantField.valueFieldKey ? fieldConfig.apiInputDependantField.valueFieldKey : "value";
      const dependentFieldValue = this.scopeData[fieldConfig.apiInputDependantField.model][valueKey];
      tableInfo = this.config.columnTableMapping[columnKey][dependentFieldValue];
    }
    else {
      tableInfo = fieldConfig.tableInfo;
    }
 
    const apiInputField:APIFieldData = {
      "operater":OperatorMapping[fieldConfig.operatorType || fieldConfig.type],
      "key": fieldConfig.columnKey,
      "value": this.getFieldValue(fieldConfig),
      "tableInfo": tableInfo
    }
    if(fieldConfig.type === "datefield" && this.isCustomDateRangeSelection(this.scopeData[fieldConfig.model].period)){
      apiInputField["startDate"] = this.transformDate(this.scopeData[fieldConfig.model].fromDate);
      apiInputField["endDate"] = this.transformDate(this.scopeData[fieldConfig.model].throughDate);
    }
    return apiInputField;
  }
  getFieldlabel(inputField){
    return inputField.type === "datefield" ? 
    this.scopeData[inputField.model].period.label : inputField.type === "textfield" ? this.scopeData[inputField.model] : this.scopeData[inputField.model].label;
  }



  getFieldValue(inputField){
    const valueKey = inputField.valueFieldKey ? inputField.valueFieldKey : "value";
    return inputField.type === "textfield" ? 
    this.scopeData[inputField.model]:inputField.type === "datefield" ? 
    this.getDateFieldValue(inputField,valueKey)
    : this.scopeData[inputField.model][valueKey];
  }

  getDateFieldValue(inputField,valueKey){
    if(this.scopeData[inputField.model].period){
      return this.scopeData[inputField.model].period[valueKey]; 
    }
  }
  getFieldName(inputField){
    if(inputField.id === "mdeFilter"){
      return  this.scopeData[inputField.model].label === "Yes"? 
      "Eligible for MDE":"Not Eligible for MDE";
    }
    if(inputField.id === "canEdit"){
      return  this.scopeData[inputField.model].label === "Yes"? 
      "Editable Errors":"Non-Editable Errors";
    }
    if(inputField.type === "datefield"){
      return  this.isCustomDateRangeSelection(this.scopeData[inputField.model].period) ?
      `${this.scopeData[inputField.model]["fromDate"]} - ${this.scopeData[inputField.model]["throughDate"]}` : this.scopeData[inputField.model].period.label;
    }
    return inputField.type === "textfield" ? this.scopeData[inputField.model]:inputField.type === "datefield" ? 
    this.scopeData[inputField.model].period.label : this.scopeData[inputField.model].label;
  }


  updateBubbleConfig(bubbleData,inputField){

    bubbleData.push({
      value:this.getFieldName(inputField),
      showLabel:inputField.showLabel,
      type:inputField.type,
      label:inputField.label,
      prefix:inputField.prefix || "",
      postfix:inputField.postfix || ""
    })
  }

  showConfirmationPopup() {
    this.confirmPopupConfig.show = true;
    this.confirmPopupConfig.showSelectedFilters=true;
    this.confirmPopupConfig.isLoading = false;
    this.confirmPopupConfig.isLoadingSinceMoreThan8Secs = false;
  }

  onDropdownChangeCallbck(model, field) {
    
    if (field.dependentFields) {
      const fields = [].concat.apply([], this.config.fields);
      const dateTypeFields = [...this.config.requiredFields, ...fields].filter(obj => {
        return field.dependentFields.indexOf(obj.id) !== -1 && obj.type === "datefield"
      });
      for (const dateField of dateTypeFields) {
        this.resetCustomDateFilterFlag(dateField);
      }
      this.scopeDataChanged.emit({ action: field.id });
    }
  }

  onDateChangeCallbck(model, field){
    if(!model || !this.isCustomDateRangeSelection(model)){
      this.resetCustomDateFilterFlag(field);
    }
  }

  isCustomDateRangeSelection(dateField) {
    return dateField && dateField.value === Constants.customDateRange;
  }

  resetCustomDateFilterFlag(field) {
    this.scopeData[field.model].validity = { msg: "Date Range is invalid", isFromInvalid: false, isthroughInvalid: false };
    this.scopeData[field.model].isInvalid = false;
    this.scopeData[field.model].fromDate = "";
    this.scopeData[field.model].throughDate = "";
  }

  getIsDatesValid(){
    const fields = [].concat.apply([],this.config.fields);
    const dateTypeFields = [...this.config.requiredFields, ...fields].filter(obj =>{
      return obj.type === "datefield"
    });
    for(const field of dateTypeFields){
      const timeObj = this.scopeData[field.model];
      if( this.isCustomDateRangeSelection(timeObj.period) && this.getIsSingleDateValid(timeObj)){
        return false;
      }
    }
    return true;    
  }

  getIsSingleDateValid(dateObj){
    this.onDateChanged(dateObj);
    return dateObj.isInvalid;
  }
  handleBlur($event,model,dateObj){
    dateObj[model] = $event;
    this.onDateChanged(dateObj);
  }

  onDateChanged(dateObj){
    const fromDate = new Date(dateObj.fromDate).getTime();
    const throughDate = new Date(dateObj.throughDate).getTime();
    dateObj.validity.isFromInvalid = isNaN(fromDate) || new Date(dateObj.fromDate).getFullYear() < this.submissionYear ;
    dateObj.validity.isthroughInvalid = isNaN(throughDate) || new Date(dateObj.throughDate).getFullYear() < this.submissionYear || throughDate < fromDate;
    if(!dateObj.validity.isFromInvalid && !dateObj.validity.isthroughInvalid && this.getIs13MonthsRange(fromDate,throughDate)){
      dateObj.validity.isFromInvalid = true;
      dateObj.validity.isthroughInvalid = true;
    }
    dateObj.isInvalid = dateObj.validity.isFromInvalid || dateObj.validity.isthroughInvalid;
  }

   getIs13MonthsRange(fromDate,throughDate) {
    const date = new Date(fromDate);
    const d = date.getDate();
    date.setMonth(date.getMonth() + 13);
    if (date.getDate() !== d) {
      date.setDate(0);
    }
    return date.getTime() < throughDate;
  }
   
   getSubmissionYear(){
     const claimDOSViewByID = 1;
    this.summaryService.getTimePeriod(claimDOSViewByID).subscribe((data) =>{
      this.submissionYear = data &&  data[0] && data[0].value;
      if(this.submissionYear){
        this.dateConfig.minYear =this.submissionYear;
      }
    })
   }
   syncDropdownSelection(){
    const fields = [].concat.apply([], this.config.fields);
    const dropDownFields = [...this.config.requiredFields, ...fields].filter(obj => {
      return obj.type === "select" || obj.type === 'datefield';
    });
    for (const field of dropDownFields) {
      const selectFieldObj =  field.type === 'datefield' ? this.scopeData[field.model].period : this.scopeData[field.model];
      const valueKey = field.valueFieldKey || "value";
      selectFieldObj && this.scopeData[field.list].forEach((item:any) => {
        item.selected = (item[valueKey] === selectFieldObj[valueKey] && ( field.columnKey !== "partySK" || item.includeChildData === selectFieldObj.includeChildData ));
        item.selected && (selectFieldObj.id = item.id);
      })
    }
   }
  

  transformDate(datValue){
    const dateFormat= "MM-dd-yyyy";
    if(datValue){
      return this.datePipe.transform(datValue, dateFormat );
    }
  }
}
