import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-applied-filter-bubble',
  templateUrl: './applied-filter-bubble.component.html',
  styleUrls: ['./applied-filter-bubble.component.css']
})
export class AppliedFilterBubbleComponent {
  @Input()
  selectedFilters : Array<any>;
  @Input()
  showEditFilter : boolean = false;


  @Input()
  filterExpandedView : any;

  constructor() { 
    // AppliedFilterBubbleComponent constructor
  }

  getBubbleTitle(filter){
    return  `${filter.showLabel ? filter.label + ':': ''}${filter.prefix ? ' '+filter.prefix : ''} ${filter.value}
    ${filter.postfix ? ' ' + filter.postfix : ''}` 
  }

  onEditFilter(){
    this.filterExpandedView.show = true;
    this.filterExpandedView.isApplyClicked = false;
  }
}
