import { Component, OnInit, Inject } from '@angular/core';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { Constants } from '../../../data-models/constants.enum';
import { HttpClient } from '@angular/common/http';
import { SummaryService } from 'src/app/services/tabs/summary.service';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';

@Component({
  selector: 'app-inprogress',
  templateUrl: './inprogress.component.html',
  styleUrls: ['./inprogress.component.css']
})
export class InprogressComponent implements OnInit {
  
  inprogressData: any;
  claimAges = [];
  claimStatuses!: any;

  constructor(@Inject(SESSION_STORAGE) 
  private readonly sessionStorage: WebStorageService,
  private readonly http: HttpClient,
  private readonly summaryService: SummaryService,
  private readonly router : Router) { }

  ngOnInit() {
    this.getData();
  }
  
  getData(){
    const clmDtCriteriaId =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.viewingBy)?.id;
    this.sessionStorage.set(Constants.tabsSelectedIndex, 4);
    const prtySk =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.clientName)?.value;
    const isChildDataIncluded = this.sessionStorage.get(Constants.includeChildData);
    const timeCriteriaId =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod)?.id;
    const startDate = this.sessionStorage.get(Constants.fromDate);
    const endDate = this.sessionStorage.get(Constants.toDate);
    const tabId = 5;
    forkJoin(
      {
        claimAge: this.summaryService.getClaimAge(),
        claimStatus: this.summaryService.getClaimStatus(),
        inProgressData: this.summaryService.getInProgessDetails(prtySk, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate, tabId)
      }
    ).subscribe((data) => {
      this.inprogressData = data.inProgressData;
      this.claimAges = data.claimAge;
      this.claimStatuses = data.claimStatus;
    });
  }

  getItemType(item: any, itemArray: any){
    const selectedItem = itemArray.filter(element=>{
      return element.name === item || item.includes(element.name)
    }).map(element =>{
      element["label"]=element.name;
      return element;
    })
    if(selectedItem.length > 0){
      return selectedItem[0];
    }
    return undefined;
  }

  onInprogressClaimClick(event,inProgressStatus,inProgressClaimAge,count){
      event.preventDefault();
      if(count == 0){
        return;
      }
      this.sessionStorage.set(Constants.screenType, Constants.inprogressScreenType);
      const claimStatus = this.getItemType(inProgressStatus, this.claimStatuses);
      if (inProgressClaimAge){
        const claimAge = this.getItemType(inProgressClaimAge.name, this.claimAges);
        this.sessionStorage.set(Constants.claimAge, claimAge);
      } else {
        this.sessionStorage.set(Constants.claimAge, null);
      }
      this.sessionStorage.set(Constants.inprogressClaimStatus, claimStatus);
      this.router.navigateByUrl(`/inprogress-claims`);
      this.scrollToTop();
  }

  scrollToTop(){
    window.scroll({
      top:0,
      left:0,
      behavior: "auto"
    }); 
  }

  

}
