import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UntypedFormControl,ValidatorFn, UntypedFormGroup, Validators } from '@angular/forms';
import { SummaryService } from '../../services/tabs/summary.service';
import { contactCode, ErrCrrUtility, inputTypeConfig, patterns } from '../err-crr-utility';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-error-crr-editor',
  templateUrl: './error-crr-editor.component.html',
  styleUrls: ['./error-crr-editor.component.css']
})
export class ErrorCrrEditorComponent extends ErrCrrUtility implements OnInit, AfterViewInit {
  @Input() inputType: string;
  @Input() inputValue: any;
  @Input() instruction: string;
  @Input() pattern: any;
  @Input() maxLength: any;
  @Input() inputLabel: string;
  @Input() jsonKey: any;
  @Output() public collapseFields = new EventEmitter<any>();
  @Input() listData: any;
  @Input() changeBtnCls: string;
  @Input() optional: boolean;
  @Input() quantity;
  @Input() minLength;
  @Input() isConditional;   
  @Input() quantityPattern;
  @Input() entityTypeList: any;
  @Input() isEntityTypeReq :boolean;
  public claimErrorCrrForm;
  viewLoaded: boolean;
  listKey :string = "";
  submissionYear: any;
  public readonly dateConfig: any = {
    enableValidation: false,
    iconCalendar: true
  };
  public readonly maxLengthInfo = {
    cent: '2',
    city: '30',
    zipcode: '5',
    zipcodeoptional: '4',
    fName: '35',
    mName: '25',
    lName: '60',
    sName: '10',
    telFax:'3',
    telFax3:"4",
    email:"256"
  }
  public quantityMaxLengthMapping;
  isOrgEntity: boolean;
  public readonly ctrlMapping = {
    fName: "fName",
    lName: "lName",
    mName: "mName",
    suffix: "suffix",
    entityCode: "entityCode",
    contact:"contactDropDown",
    email:"email",
    telFax1:"telFax1",
    telFax2:"telFax2",
    telFax3:"telFax3",
    telExt:"telExt"
  }
  contactInfoList:any = [{},{},{},{},{},{}];
  invalidText = 'Date format is invalid';
  requiredText = 'Input is required';
  private dateFormat = 'yyyy-MM-dd';
  constructor(public datePipe: DatePipe,private readonly summaryService: SummaryService,
    sanitized: DomSanitizer) {
    super(sanitized);
   }
  ngOnInit() {
    this.getSubmissionYear();
    this.initializeForm();
    
  }

  ngAfterViewInit() {
    this.viewLoaded = true;
  }

  initializeForm() {
    if (this.inputType === inputTypeConfig.input) {
      this.claimErrorCrrForm = new UntypedFormGroup({
        textbox: new UntypedFormControl('', [this.getValidators(this.maxLength, this.pattern, 
          !this.optional,this.minLength)]),
      });
      this.claimErrorCrrForm.controls.textbox.setValue(this.inputValue);
    }
    else if(this.inputType===inputTypeConfig.codeInfo){
      this.initializeCodeInfoBox();
    }

    else if (this.inputType === inputTypeConfig.dollar) {
      this.initializeDollarField();
    }
    else if (this.inputType === inputTypeConfig.date) {
      this.claimErrorCrrForm = new UntypedFormGroup({
        dateField: new UntypedFormControl(this.datePipe.transform(this.inputValue, this.dateFormat), [this.getValidators("", "", true)])
      })
    }
    else if (this.inputType === inputTypeConfig.fromThrough) {
      this.initializeFromThroughField();
    }
    else if (this.inputType === inputTypeConfig.address) {
      this.initializeAddressField();
    }
    else if (this.inputType === inputTypeConfig.select) {
      this.claimErrorCrrForm = new UntypedFormGroup({
        dropdownField: new UntypedFormControl('', [this.getValidators("", "", !this.optional, "0")]),
      });

      const [inpLabel] = this.inputLabel.split("_");
      this.listKey = inpLabel;
      this.listData[inpLabel].forEach((element, index) => {
        element.id = index;
        if (this.inputValue === element.value) {
          this.claimErrorCrrForm.controls.dropdownField.setValue(element);
        }
      }
      );
    }
    else if (this.inputType === inputTypeConfig.unit) {
      this.initializeUnitsField();
    }else if(this.inputType === inputTypeConfig.entityName){
      this.initializeNameFields()
    }else if(this.inputType === inputTypeConfig.contact){
      this.initializeContactFields()
    }
  }

  initializeContactFields(){
    const telFaxArr:any = [contactCode.tel,contactCode.fax]
    const formControl ={};
    for(const idx in this.contactInfoList){
      this.contactInfoList[idx]["list"] =  this.deepCopy(this.listData[this.inputLabel].filter( (item) => {
        return idx !== "0" || item.value !== contactCode.telExt;
      }));
      this.contactInfoList[idx]["selected"]= this.inputValue.length > idx && this.inputValue[idx].commNumberQual;
      formControl[this.ctrlMapping.contact+idx] = new UntypedFormControl(null, [this.getValidators("", "", idx === "0",""),this.conditionalValidator(
      ()=>this.checkDependentFields(this.claimErrorCrrForm,this.ctrlMapping.contact,idx),
      Validators.required,'customRequired')]);
      formControl[this.ctrlMapping.email+idx]= new UntypedFormControl('', 
      [this.getValidators(this.maxLengthInfo.email),this.conditionalValidator(() => this.contactInfoList[idx].selected === contactCode.mail,
      Validators.required, 'customRequired')]);

      formControl[this.ctrlMapping.telFax1+idx]= new UntypedFormControl('', 
      [this.getValidators(this.maxLengthInfo.telFax,patterns.NUM, false,this.maxLengthInfo.telFax),this.conditionalValidator(() => telFaxArr.indexOf(this.contactInfoList[idx].selected) !== -1,
      Validators.required,'customRequired')]);
      formControl[this.ctrlMapping.telFax2+idx]= new UntypedFormControl('', 
      [this.getValidators(this.maxLengthInfo.telFax,patterns.NUM ,false,this.maxLengthInfo.telFax),this.conditionalValidator(() => telFaxArr.indexOf(this.contactInfoList[idx].selected) !== -1,
      Validators.required,'customRequired')]);
      formControl[this.ctrlMapping.telFax3+idx]= new UntypedFormControl('', [this.getValidators(this.maxLengthInfo.telFax3, patterns.NUM,false,this.maxLengthInfo.telFax3),this.conditionalValidator(() => telFaxArr.indexOf(this.contactInfoList[idx].selected) !== -1,
      Validators.required,'customRequired')]);
      formControl[this.ctrlMapping.telExt+idx]= new UntypedFormControl('', [this.getValidators(this.maxLengthInfo.email, patterns.NUM),this.conditionalValidator(() => this.contactInfoList[idx].selected === contactCode.telExt,
      Validators.required,'customRequired')]);
    }
    this.claimErrorCrrForm = new UntypedFormGroup(formControl);
    this.setAndRevalidateContact(telFaxArr)
  }
  checkDependentFields(form,ctrl,idx){
    let dependentFieldsSelected = false;
    if(form){
      for(let itr=Number(idx)+1; itr < 6; itr++){
        if(form.get(ctrl+itr).value){
          dependentFieldsSelected = true;
          break;
        }
      }
    }
    return dependentFieldsSelected;
  }
  onContactChangeSelection(idx){
    this.claimErrorCrrForm.get(this.ctrlMapping.contact + idx).valueChanges
    .subscribe(value => {
      this.claimErrorCrrForm.get(this.ctrlMapping.email+idx).updateValueAndValidity();
      this.claimErrorCrrForm.get(this.ctrlMapping.telFax1+idx).updateValueAndValidity();
      this.claimErrorCrrForm.get(this.ctrlMapping.telFax2+idx).updateValueAndValidity();
      this.claimErrorCrrForm.get(this.ctrlMapping.telFax3+idx).updateValueAndValidity();
      this.claimErrorCrrForm.get(this.ctrlMapping.telExt+idx).updateValueAndValidity();
      for(let itr=Number(idx)-1;itr >= 0;itr--){
        this.claimErrorCrrForm.get(this.ctrlMapping.contact+itr).updateValueAndValidity();
      }
    });
  }
  setAndRevalidateContact(telFaxArr){
    for(const idx in this.contactInfoList){
      this.onContactChangeSelection(idx);
      if(this.inputValue.length > idx){
        if(telFaxArr.indexOf(this.inputValue[idx].commNumberQual) !== -1){
        this.inputValue[idx].commNumber = this.inputValue[idx].commNumber || "";
        const tel1 = this.inputValue[idx].commNumber.substring(0,3);
        const tel2 = this.inputValue[idx].commNumber.substring(3,6);
        const tel3 = this.inputValue[idx].commNumber.substring(6);
        this.claimErrorCrrForm.controls[this.ctrlMapping.telFax1+idx].setValue(tel1);
        this.claimErrorCrrForm.controls[this.ctrlMapping.telFax2+idx].setValue(tel2);
        this.claimErrorCrrForm.controls[this.ctrlMapping.telFax3+idx].setValue(tel3);
        }
        if(this.inputValue[idx].commNumberQual === contactCode.mail){
          this.claimErrorCrrForm.controls[this.ctrlMapping.email+idx].setValue(this.inputValue[idx].commNumber);
        }else if(this.inputValue[idx].commNumberQual === contactCode.telExt){
          this.claimErrorCrrForm.controls[this.ctrlMapping.telExt+idx].setValue(this.inputValue[idx].commNumber); 
        }
        this.listData[this.inputLabel].forEach((element, index) => {
          if (this.inputValue[idx].commNumberQual === element.value) {
            this.claimErrorCrrForm.controls[this.ctrlMapping.contact+idx].setValue(element);
          }
        })
      }
    }
  }

  initializeNameFields(){
    this.isOrgEntity = this.isEntityTypeReq && this.inputValue.entityTypeQualifier === "2";
    const formcontrol = {
      [this.ctrlMapping.fName]: new UntypedFormControl(this.inputValue.nameFirst, [this.getValidators(this.maxLengthInfo.fName, patterns.NAME, false)]),
      [this.ctrlMapping.mName]: new UntypedFormControl(this.inputValue.nameMiddle, [this.getValidators(this.maxLengthInfo.mName, patterns.NAME, false)]),
      [this.ctrlMapping.lName]: new UntypedFormControl(this.inputValue.nameLastOrgName, [this.getValidators(this.maxLengthInfo.lName, patterns.NAME, true)]),
      [this.ctrlMapping.suffix]: new UntypedFormControl(this.inputValue.nameSuffix, [this.getValidators(this.maxLengthInfo.sName, patterns.AlPHA, false)])
    }
    if(this.isEntityTypeReq){
      formcontrol[this.ctrlMapping.entityCode] = new UntypedFormControl('', [this.getValidators("", "", true)]);
    }
    this.claimErrorCrrForm = new UntypedFormGroup(formcontrol)
    if(this.isEntityTypeReq){
    this.entityTypeList.forEach((element, index) => {
      if (this.inputValue.entityTypeQualifier === element.value) {
        this.claimErrorCrrForm.controls.entityCode.setValue(element);
      }
    })
  }
  }
  initializeCodeInfoBox(){
    this.claimErrorCrrForm = new UntypedFormGroup({
      code1: new UntypedFormControl('', [this.getValidators(this.maxLength, this.pattern),this.conditionalValidator(
        () => this.claimErrorCrrForm &&
         (this.claimErrorCrrForm.get('code2').value !== "" ||
          this.claimErrorCrrForm.get('code3').value !== "" || 
          this.claimErrorCrrForm.get('code4').value !== ""),
      Validators.required,
      'customRequired')]),
      code2: new UntypedFormControl('', [this.getValidators(this.maxLength, this.pattern),this.conditionalValidator(
        () => this.claimErrorCrrForm && 
        (this.claimErrorCrrForm.get('code3').value !== "" ||
         this.claimErrorCrrForm.get('code4').value !== ""),
      Validators.required,
      'customRequired')]),
      code3: new UntypedFormControl('', [this.getValidators(this.maxLength, this.pattern),this.conditionalValidator(
        () => this.claimErrorCrrForm && 
        ( this.claimErrorCrrForm.get('code4').value !== ""),
      Validators.required,
      'customRequired')]),
      code4: new UntypedFormControl('', [this.getValidators(this.maxLength, this.pattern)]),
    });
    
    const splitCode = this.inputValue.toString().split(/[,:]/);
    const codeFirst = splitCode[0];
    const codeSecond = splitCode.length > 1 ? splitCode[1] : '';
    const codeThird =  splitCode.length > 2 ? splitCode[2] : '';
    const codeFour =  splitCode.length > 3 ? splitCode[3] : '';
    this.claimErrorCrrForm.get('code2').valueChanges
      .subscribe(value => {
        this.claimErrorCrrForm.get('code1').updateValueAndValidity();
      });
    this.claimErrorCrrForm.get('code3').valueChanges
      .subscribe(value => {
        this.claimErrorCrrForm.get('code1').updateValueAndValidity();
        this.claimErrorCrrForm.get('code2').updateValueAndValidity();
      });
    this.claimErrorCrrForm.get('code4').valueChanges
      .subscribe(value => {
        this.claimErrorCrrForm.get('code1').updateValueAndValidity();
        this.claimErrorCrrForm.get('code2').updateValueAndValidity();
        this.claimErrorCrrForm.get('code3').updateValueAndValidity();
      });
    this.claimErrorCrrForm.controls.code1.setValue(codeFirst);
    this.claimErrorCrrForm.controls.code2.setValue(codeSecond);
    this.claimErrorCrrForm.controls.code3.setValue(codeThird);
    this.claimErrorCrrForm.controls.code4.setValue(codeFour); 
  }
  
  initializeDollarField() {
    this.claimErrorCrrForm = new UntypedFormGroup({
      dollar: new UntypedFormControl('', [this.getValidators(this.maxLength, this.pattern, true)]),
      cent: new UntypedFormControl('', [this.getValidators(this.maxLengthInfo.cent, this.pattern, false)]),
    });
    const splitDollar = this.inputValue.toString().split(".");
    const dollar = splitDollar[0];
    const cent = splitDollar.length > 1 ? splitDollar[1] : '';
    this.claimErrorCrrForm.controls.dollar.setValue(dollar);
    this.claimErrorCrrForm.controls.cent.setValue(cent);
  }

  initializeFromThroughField() {
    this.claimErrorCrrForm = new UntypedFormGroup({
      fromField: new UntypedFormControl('', [this.getValidators("", "", true,"")]),
      throughField: new UntypedFormControl('', [this.getValidators("", "", false,"")])
    })
    const date = this.inputValue.toString().split(" - ");
    const from = date[0];
    const through = date.length > 1 ? date[1] : '';
    this.claimErrorCrrForm.controls.fromField.setValue(this.datePipe.transform(from, this.dateFormat));
    this.claimErrorCrrForm.controls.throughField.setValue(this.datePipe.transform(through, this.dateFormat));
  }

  initializeUnitsField() {
    const unitsData = this.inputValue.toString().split(" ");
    const quantity = unitsData[0];
    const unit = unitsData.length > 1 ? unitsData[1] : '';
    const formControl = {
      units: new UntypedFormControl('', [this.getValidators("", "", true)])
    }
    if (this.isConditional) {
      this.quantityMaxLengthMapping = this.quantity[unit];
      formControl['unitsQuanity'] = new UntypedFormControl('', [Validators.required,
        this.conditionalValidator(() => this.claimErrorCrrForm && this.claimErrorCrrForm.get('units').value,
          Validators.pattern,
          'illuminatiError')])
    } else {
      this.quantityMaxLengthMapping = this.quantity;
      formControl['unitsQuanity'] = new UntypedFormControl('', [this.getValidators("", this.pattern, true)])
    }

    this.claimErrorCrrForm = new UntypedFormGroup(formControl)
    if(this.isConditional){
      this.claimErrorCrrForm.get('units').valueChanges
      .subscribe(value => {
        this.quantityMaxLengthMapping = this.quantity[(this.claimErrorCrrForm.get('units').value).value];
        this.claimErrorCrrForm.get('unitsQuanity').updateValueAndValidity();
      });
    }
  
    this.claimErrorCrrForm.controls.unitsQuanity.setValue(quantity);
    this.listData[this.jsonKey].forEach((element, index) => {
      if (unit === element.value) {
        this.claimErrorCrrForm.controls.units.setValue(element);
      }
    })
    this.listData[this.jsonKey].forEach((element, index) => {
      let object=this.listData[this.jsonKey];
      object[index].id=index;
    });
  }

  initializeAddressField() {
    this.claimErrorCrrForm = new UntypedFormGroup({
      address1: new UntypedFormControl('', [this.getValidators(this.maxLength, this.pattern, true)]),
      city: new UntypedFormControl('', [this.getValidators(this.maxLengthInfo.city, this.pattern, true)]),
      zipcode: new UntypedFormControl('', [this.getValidators(this.maxLengthInfo.zipcode, '[0-9]+', true, '5')]),
      address2: new UntypedFormControl('', [this.getValidators(this.maxLength, this.pattern)]),
      zipcodeoptional: new UntypedFormControl('', [this.getValidators(this.maxLengthInfo.zipcodeoptional, '[0-9]+', false, '4')]),
      state: new UntypedFormControl('', [this.getValidators("", "", true)])
    });
    const { zipcode, zipcodeOptional } = this.getZipCode();
    this.claimErrorCrrForm.controls.zipcode.setValue(zipcode);
    this.claimErrorCrrForm.controls.zipcodeoptional.setValue(zipcodeOptional);
    this.claimErrorCrrForm.controls.address1.setValue( 
      this.getFieldValue(this.inputValue, this.jsonKey.addinfo1)
      );
    this.claimErrorCrrForm.controls.address2.setValue(
      this.getFieldValue(this.inputValue, this.jsonKey.addinfo2));
    this.claimErrorCrrForm.controls.city.setValue(
       this.getFieldValue(this.inputValue, this.jsonKey.city));
    this.listData['state'].forEach((element, index) => {
      if ( (this.getFieldValue(this.inputValue, this.jsonKey.stateorProvCode)) === element.value) {
        this.claimErrorCrrForm.controls.state.setValue(this.listData['state'][index]);
      }
    });
    this.listData['state'].forEach((element, index) => {
      let object=this.listData['state'];
      object[index].id=index;
    });
  }

  conditionalValidator(predicate: any,
    validator: any,
    errorNamespace?: string): ValidatorFn {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      let error = null;
      const unitFieldValue = predicate();
      if (unitFieldValue) {
        if(errorNamespace==='customRequired')
        {
        error = validator(formControl);
        }
        else{
        error = validator(this.quantityPattern[unitFieldValue.value])(formControl);
        }
      }
      if (errorNamespace && error) {
        const customError = {};
        customError[errorNamespace] = error;
        error = customError
  
      }
      return error;
    })
  }
  getZipCode() {
  const splitZipcode = (this.getFieldValue(this.inputValue, this.jsonKey.postalCode)).match(/.{1,5}/g) 
    return {
      zipcode: (splitZipcode && splitZipcode.length > 0) ? splitZipcode[0] : "",
      zipcodeOptional: (splitZipcode && splitZipcode.length > 1) ? splitZipcode[1] : ""
    }
  }

  getFormControlValidity(frmControlNm) {
    let isInvalid: boolean = false;
    isInvalid = this.claimErrorCrrForm.controls[frmControlNm].status === "INVALID";

    if (isInvalid && !this.viewLoaded) {
      this.OnBlur();
    }
    return isInvalid;
  }

  getValidators(maxLength?, pattern?, required?, minLength?) {
    const validators: ValidatorFn[] = [];
    if (required) {
      validators.push(Validators.required);
    }
    if (minLength !== undefined) {
      validators.push(Validators.minLength(minLength));
    }
    if (maxLength !== "") {
      validators.push(Validators.maxLength(maxLength));
    }
    if (pattern !== "") {
      validators.push(Validators.pattern(pattern));
    }
    return Validators.compose(validators);
  }

  cancelClick() {
    const event = {
      type: 'cancel',
      field: this.inputLabel
    }
    this.collapseFields.emit(event);
  }

  onEntityTypeFieldClick(event, formCtrl) {
    if ((this.claimErrorCrrForm.get(this.ctrlMapping.entityCode).value).value === "2") {
      this.claimErrorCrrForm.get(this.ctrlMapping.fName).setValue("");
      this.claimErrorCrrForm.get(this.ctrlMapping.mName).setValue("");
      this.claimErrorCrrForm.get(this.ctrlMapping.suffix).setValue("");
      this.isOrgEntity = true;
    } else {
      this.isOrgEntity = false;
    }
  }

  onContactSelection(selectedValue,idx) {
    this.contactInfoList[idx]["selected"]= selectedValue ? selectedValue.value :"";
    this.claimErrorCrrForm.controls[this.ctrlMapping.telFax1+idx].setValue("");
    this.claimErrorCrrForm.controls[this.ctrlMapping.telFax2+idx].setValue("");
    this.claimErrorCrrForm.controls[this.ctrlMapping.telFax3+idx].setValue("");
    this.claimErrorCrrForm.controls[this.ctrlMapping.email+idx].setValue("");
    this.claimErrorCrrForm.controls[this.ctrlMapping.telExt+idx].setValue("");
  }


  OnBlur() {
    const event = {
      type: 'validation',
      isInvalid: this.claimErrorCrrForm.status === "INVALID",
      field: this.inputLabel
    }
    this.collapseFields.emit(event);
  }

  changeClick() {
    const event = {
      type: 'change',
      value: this.inputValue,
      key: this.jsonKey,
      field: this.inputLabel,
      isInvalid: false,
      inputType: this.inputType
    }
    event.isInvalid = this.isDateRangeInvalid() || this.claimErrorCrrForm.status === "INVALID";

    if (!event.isInvalid) {
      if (this.inputType === inputTypeConfig.select) {
        event.value = (this.claimErrorCrrForm.get('dropdownField').value).value;
      }
      else if (this.inputType === inputTypeConfig.input) {
        event.value = this.claimErrorCrrForm.get('textbox').value;
      }
      else if (this.inputType === inputTypeConfig.codeInfo) {
        this.updateCodeInfoTextBox(event);
      }
      else if (this.inputType === inputTypeConfig.dollar) {
        this.updateDollarField(event);
      }
      else if (this.inputType === inputTypeConfig.fromThrough) {
        this.updateFromThroughDate(event);
      }
      else if (this.inputType === inputTypeConfig.date) {
        event.value = this.claimErrorCrrForm.get(inputTypeConfig.date).value;
      }
      else if (this.inputType === inputTypeConfig.unit) {
        event.value = this.claimErrorCrrForm.get('unitsQuanity').value + " " + (this.claimErrorCrrForm.get('units').value).value;
      }
      else if (this.inputType === inputTypeConfig.address) {
       this.updateAddressField(event);
      } else if(this.inputType === inputTypeConfig.entityName){
        this.updateNameField(event)
      } else if(this.inputType === inputTypeConfig.contact){
        this.updateContactField(event)
      }
    }
    this.collapseFields.emit(event);
  }
  updateContactField(event){
    const telFaxArr = [contactCode.tel,contactCode.fax];
    for(const idx in this.contactInfoList){
      if(!this.inputValue[idx]){
        this.inputValue[idx]={}
      }
      const contactType = this.claimErrorCrrForm.get(this.ctrlMapping.contact+idx).value;
      if(contactType){
        this.inputValue[idx].commNumberQual = contactType.value;
      }else{
        this.inputValue[idx].commNumberQual = "";
        this.inputValue[idx].commNumber = "";
      }

      if(telFaxArr.indexOf(this.inputValue[idx].commNumberQual) !== -1){
        const tel1 = this.claimErrorCrrForm.get(this.ctrlMapping.telFax1+idx).value;
        const tel2 = this.claimErrorCrrForm.get(this.ctrlMapping.telFax2+idx).value;
        const tel3 = this.claimErrorCrrForm.get(this.ctrlMapping.telFax3+idx).value;
        this.inputValue[idx].commNumber = tel1+tel2+tel3;
        }else if(this.inputValue[idx].commNumberQual === contactCode.mail){
          this.inputValue[idx].commNumber = this.claimErrorCrrForm.get(this.ctrlMapping.email+idx).value;
        }else if(this.inputValue[idx].commNumberQual === contactCode.telExt){
          this.inputValue[idx].commNumber = this.claimErrorCrrForm.get(this.ctrlMapping.telExt+idx).value;
        }
    }
  }

  updateNameField(event) {
    this.inputValue.nameLastOrgName = this.claimErrorCrrForm.get(this.ctrlMapping.lName).value;
    this.inputValue.nameFirst = this.claimErrorCrrForm.get(this.ctrlMapping.fName).value;
    this.inputValue.nameMiddle = this.claimErrorCrrForm.get(this.ctrlMapping.mName).value;
    this.inputValue.nameSuffix = this.claimErrorCrrForm.get(this.ctrlMapping.suffix).value;
    if(this.isEntityTypeReq){
    this.inputValue.entityTypeQualifier = (this.claimErrorCrrForm.controls[this.ctrlMapping.entityCode].value).value;
    }
  }

  updateAddressField(event){
     this.updateClmAttribute(this.inputValue, this.jsonKey.addinfo1, (this.claimErrorCrrForm.get('address1').value));
      this.updateClmAttribute(this.inputValue, this.jsonKey.addinfo2, (this.claimErrorCrrForm.get('address2').value));
      this.updateClmAttribute(this.inputValue, this.jsonKey.city, (this.claimErrorCrrForm.get('city').value));
      this.updateClmAttribute(this.inputValue, this.jsonKey.stateorProvCode, ((this.claimErrorCrrForm.get('state').value).value));
      const zipCode = (this.claimErrorCrrForm.get('zipcode').value + this.claimErrorCrrForm.get('zipcodeoptional').value);
      this.updateClmAttribute(this.inputValue, this.jsonKey.postalCode, zipCode);
    
    event.value = this.inputValue;
  }
  updateDollarField(event){
    const cent = this.claimErrorCrrForm.get('cent').value;
    event.value = this.claimErrorCrrForm.get('dollar').value + (cent === "" ? "" : "." + cent);
  }
  updateCodeInfoTextBox(event) {
    const codeSecond = this.claimErrorCrrForm.get('code2').value;
    const codeThitrd = this.claimErrorCrrForm.get('code3').value;
    const codeFour = this.claimErrorCrrForm.get('code4').value;
    event.value = this.claimErrorCrrForm.get('code1').value 
    + (codeSecond === "" ? "" : "," + codeSecond) 
    + (codeThitrd === "" ? "" : "," + codeThitrd) 
    + (codeFour === "" ? "" : "," + codeFour);

  }
  updateFromThroughDate(event){
    const throughDate = this.encodeDateFormat(this.claimErrorCrrForm.get('throughField').value);
        event.value = this.encodeDateFormat(this.claimErrorCrrForm.get('fromField').value) 
        + (throughDate === "" ? "" : "-" + throughDate);
  }
  encodeDateFormat(date) {
    let encodedDate = "";
    const parseDate = new Date(date);
    if (!isNaN(parseDate.getTime())) {
      encodedDate = this.datePipe.transform(new Date(parseDate), "yyyyMMdd");
    }
    return encodedDate;
  }

  getSubmissionYear(){
    const claimDOSViewByID = 1;
   this.summaryService.getTimePeriod(claimDOSViewByID).subscribe((data) =>{
    this.submissionYear = data &&  data[0] && data[0].value;
     if(this.submissionYear){
      this.dateConfig.minYear =this.submissionYear;
    }
   })
  }

  isDateRangeInvalid() {
    if (this.inputType === inputTypeConfig.fromThrough  ) {
      const fromDate = new Date(this.claimErrorCrrForm.get('fromField').value).getTime();
      const throughDate = new Date(this.claimErrorCrrForm.get('throughField').value).getTime();
      if(throughDate){
        return isNaN(throughDate) || isNaN(fromDate) || throughDate < fromDate;
      }
    }
  }

}
