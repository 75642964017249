<uitk-dialog showModal="true" uitkId='termsConditionsPopup' tkShowHeader="false" tkOwnContent="true">
    <uitk-dialog-content>
        <div class="popup-wrap" cdkTrapFocus="true">
            <div class="header-title">Terms of Use</div>
            <div class="header-sub-title">
                To use this application, you must read the Privacy Policy and agree to the Terms of Use
            </div>
            <div class="terms-content">
                <app-privacy-policy></app-privacy-policy>
            </div>
            <div class="terms-btns">
                <div class="checkbox-wrap">
                        <uitk-form-field>
                            <input
                                id="my-basic-checkbox"
                                name="myBasicCheckbox"
                                type="checkbox"
                                uitkCheckbox
                                #myBasicCheckbox="ngModel"
                                [(ngModel)]="isAgreeButtonChecked"
                            />
                            <label uitkCheckboxLabel for="my-basic-checkbox">
                                    <span>I have read the </span>&nbsp;
                                    <a routerLink="/privacy" target="_blank" class="link">Privacy Policy</a>&nbsp;
                                    <span> and agree to </span>&nbsp;
                                    <a routerLink="/terms" target="_blank" class="link">Terms of Use</a>.
                            </label>
                        </uitk-form-field>
                   
                </div>
                <div class="action-btn-wrap">
                    <button class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="onAgreeBtnClick()"
                        [disabled]="!isAgreeButtonChecked">Agree</button>
                    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="onCancelBtnClick()">Cancel</button>
                </div>
            </div>
        </div>
    </uitk-dialog-content>
</uitk-dialog>