import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { DatePipe, CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRouting } from './app.routing.module';

import { HomeComponent } from './home/home.component';
import { AppMainComponent } from './app-main/app-main.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { AfterIfDirective } from './directives/after-if.directive';
import { LogoutComponent } from './logout/logout.component';
import { OAuthInterceptor } from './services/auth/oauth.interceptor';
import { OAuthGuard } from './services/auth/oauth.guard';
import { ErrorInterceptor } from './services/error-intreceptor';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LineChartComponent} from './charts/lineCharts/lineCharts.component';
import {PieChartComponent} from './charts/pieCharts/pieCharts.component';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { CustomLinerChartService } from './services/custom.line.chart.service';
import { BarChartsComponent } from './charts/bar-charts/bar-charts.component';
import { SummaryComponent } from './home/tabs/summary/summary.component';
import { ErrorsComponent } from './home/tabs/errors/errors.component';
import { HomeTabsComponent } from './home/tabs/home.tabs.component';
import { StackedBarChartsComponent } from './charts/stacked-bar-charts/stacked-bar-charts.component';

import { CmsAcceptedComponent } from './home/tabs/cmsaccepted/cmsaccepted.component';
import { DuplicatesComponent } from './home/tabs/duplicates/duplicates.component';
import { ClosedComponent } from './home/tabs/closed/closed.component';
import { InprogressComponent } from './home/tabs/inprogress/inprogress.component';

import { GraphSwitchFrequencyComponent } from './home/tabs/graph-switch-frequency/graph-switch-frequency.component';

import { TermsOfUseComponent } from './terms-privacy-policy/terms-of-use/terms-of-use.component';
import {ReportsModule} from './reports/reports.module';
import {TermsConditionsPopupModule} from './terms-privacy-policy/terms-conditions-popup/terms-conditions-popup.module'
import { UserNotificationMsgComponent } from './user-notification-msg/user-notification-msg.component';
import { UnauthorizedUserComponent } from './logout/unauthorized-user/unauthorized-user.component';
import { ClosedCrrRecordsComponent } from './crr-dashboard/closed-crr-records/closed-crr-records.component';
import { ClaimCreationCrrRecordsComponent } from './crr-dashboard/claim-creation-crr-records/claim-creation-crr-records.component';
import { CrrSummaryComponent } from './crr-dashboard/crr-summary/crr-summary.component';
import { CrrRecordErrorsComponent } from './crr-dashboard/crr-record-errors/crr-record-errors.component';
import { CrrInprogressComponent } from './crr-dashboard/crr-inprogress/crr-inprogress.component';
import { CrrClaimInprogressComponent } from './crr-dashboard/crr-claim-inprogress/crr-claim-inprogress.component';
import { CrrClaimErrorsComponent } from './crr-dashboard/crr-claim-errors/crr-claim-errors.component';
import { CrrCmsAcceptedComponent } from './crr-dashboard/crr-cms-accepted/crr-cms-accepted.component';

//---------- UI Toolkit import 
import { UITKDropdownModule, UITKFormFieldService, UITKDatePickerModule,UITKNavigationModule, UITKExpandRowService, UITKDialogModule, UITKSelectModule, UITKFooterModule, UITKTooltipModule } from '@uitk/angular';
import { ClaimErrorsModule } from './claim-errors/claim-errors.module';
import { SharedModule } from './shared.module';
import { SessionTimeoutModule} from "./logout/session-timeout/session-timeout.module"
import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [
        AppComponent,
        AppHeaderComponent,
        AppFooterComponent,
        HomeComponent,
        AppMainComponent,
        AfterIfDirective,
        LogoutComponent,
        LineChartComponent,
        PieChartComponent,
        BarChartsComponent,
        SummaryComponent,
        ErrorsComponent,
        HomeTabsComponent,
        StackedBarChartsComponent,
        CmsAcceptedComponent,
        ClosedComponent,
        DuplicatesComponent,
        InprogressComponent,
        GraphSwitchFrequencyComponent,
        TermsOfUseComponent,
        UserNotificationMsgComponent,
        UnauthorizedUserComponent,
        CrrSummaryComponent,
        CrrRecordErrorsComponent,
        CrrInprogressComponent,
        CrrClaimInprogressComponent,
        CrrClaimErrorsComponent,
        CrrCmsAcceptedComponent,
        ClaimCreationCrrRecordsComponent,
        ClosedCrrRecordsComponent
    ],
    imports: [
        ReactiveFormsModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        AppRouting,
        NgxChartsModule,
        StorageServiceModule,
        ReportsModule,
        TermsConditionsPopupModule,
        UITKDropdownModule,
        UITKDatePickerModule,
        UITKNavigationModule,
        UITKDialogModule,
        UITKFooterModule,
        ClaimErrorsModule,
        SharedModule,
        SessionTimeoutModule,
        RouterModule,
        UITKSelectModule,
        UITKTooltipModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: OAuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        DatePipe,
        OAuthGuard,
        CustomLinerChartService,
        UITKFormFieldService
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }