import { Component, OnInit, Inject, Input } from '@angular/core';
import { SummaryService } from '../../services/tabs/summary.service';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { Constants } from '../../data-models/constants.enum';
import { BaseChartsComponent } from '../base-charts/base-charts.component';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';
@Component({
  selector: 'app-bar-charts',
  templateUrl: './bar-charts.component.html',
  styleUrls: ['./bar-charts.component.css']
})
export class BarChartsComponent extends BaseChartsComponent implements OnInit {
  @Input()
  private readonly isHidden: boolean;
  @Input()
  private readonly scopeData: any;
  multi: any[];
  view: any;

  yAxisLabel = 'Number of Files';
  trimXAxisTicks = false;
  barPadding:number;
  colorScheme = {
    domain: ['#e87722']
  };

  constructor(private readonly summaryService: SummaryService,
    @Inject(SESSION_STORAGE) private readonly sessionStorage: WebStorageService) {
    super();
  }

  ngOnInit() {
    const graphSeriesId: number[] = [SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.graphSeriesId)];
    this.yAxisLabel = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.yAxisLabel);
    !this.isHidden && this.populateDataBasedOnTimePeriod(graphSeriesId);
  }

  populateDataBasedOnTimePeriod(graphSeriesId): any {
    const prtySk = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.clientName)?.value;
    const isChildDataIncluded = this.sessionStorage.get(Constants.includeChildData);
    const clmDtCriteriaId = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.viewingBy)?.id;
    const timeCriteriaId = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod)?.id;
    const yearVal = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod)?.value;
    const startDate = this.sessionStorage.get(Constants.fromDate);
    const endDate = this.sessionStorage.get(Constants.toDate);
    const tabindx = this.sessionStorage.get(Constants.tabsSelectedIndex);
    const customDateObj = {
      duration: this.sessionStorage.get(Constants.duration),
      graphViewByType: this.sessionStorage.get(Constants.graphViewByType)
    }
    this.multi = null;
    if (tabindx === 0) {
      this.summaryService.getSummaryGraphStats(prtySk, isChildDataIncluded, clmDtCriteriaId, timeCriteriaId, startDate, endDate, Constants.claimsRecieved, customDateObj).subscribe(data => {
        const clmData = {
          name: Constants.claimsRecieved,
          series: data
        }
        this.updateBarGraphData(clmData);
      })
    }
    else {
      this.summaryService.getChartStats(prtySk, isChildDataIncluded, clmDtCriteriaId, timeCriteriaId,yearVal, startDate, endDate, graphSeriesId, customDateObj).subscribe(data => {
        this.updateBarGraphData(data);
      });
    }
  }

  updateBarGraphData(data) {
    this.view = this.calculateChartView(data.series);
    const chartDataInfo = new Array<any>();
    const chartData = data["series"][0];
    chartData.label = data["name"];
    data["name"] = chartData.name;
    data.series = this.modifyData(data.series);
    chartDataInfo.push(data);
    if(data["series"].length <= 8){
      this.barPadding = this.barPaddingConfig[data["series"].length.toString()];
    }else{
      this.barPadding = 8;
    }
    this.multi = chartDataInfo;
  }

  reloadGraph(graphSeriesId) {
    this.populateDataBasedOnTimePeriod(graphSeriesId);
  }
}
