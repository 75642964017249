import { Constants } from "../../data-models/constants.enum"
import { DateValidity } from '../../file-load/file-load.model'

export type ErrorPageConfigModel = {
  title?:string,
  titlePostfix?: string;
  breadcrumbMapping: {
      name: string;
      url: string;
  }[];
  breadcrumb?: {
      name: string;
      url: string;
  }[];
  apiInput: {
      data?: {
          isInbndClaimErrFlag?: boolean;
          isMDEFlg?: boolean;
          isCMSClaimErrFlag?: boolean;
          isClaimsInErrFlag?:boolean;
      };
      sessionKeys?: Array<string>;
  };
  titleSessionKeys?: Array<string>;
  disabledFields?: string[];
  pageType: string;
  errorCountSummary?: { name: string; value: string; }[],
  fileName?:string, 
  inboundFileName?:string,
  exportScreenName?:string, 
  prePopulateScope?:boolean
}

export const scopeInitData = {
  selectedClient: { value: "" },
  clientNames: [],
  viewByDates: [],
  viewByDate: { value: "" },
  timePeriod: {
    period: { value: "" },
    fromDate: "",
    throughDate: "",
    validity: new DateValidity(),
    isInvalid: false
  },
  timePeriods: [],
  sortbylist: [],
  sortOrder: null,
  mdeFilters: [],
  mdeFilter: null,
  canEdits: [],
  canEdit: null,
  claimTypes: [],
  claimType: null,
  errorTypes: [],
  errorType: null,
  errorCategories: [],
  errorCategory: null,
  dateOfServices: [],
  dateOfService: {
    period: null,
    fromDate: "",
    throughDate: "",
    validity: new DateValidity(),
    isInvalid: false
  },
  claimId: "",
  errorCode: "",
  loop: "",
  segment: "",
  memberFirstName: "",
  memberLastName:"",
  mbi: "",
  providerFirstName: "",
  providerLastName:"",
  npi: "",
  isChildDataIncluded:null,
}
export const ErrorPageConfig: {[key:string]:ErrorPageConfigModel} = {
  "error": {
    title: Constants.errorsInClaims,
    breadcrumbMapping: [{ name: "Errors By Type", url: "/error/type" }, { name: Constants.errorsInClaims, url: "/" }],
    apiInput: {
      data:{ isClaimsInErrFlag: true }
    },
    pageType: Constants.errorDetailsScreenType,
    prePopulateScope:true,
    exportScreenName:Constants.errorDetailsScreenType
  },
  "mde": {
    title: "Claims Eligible For Minimum Data Element (MDE) Submission",
    breadcrumbMapping: [{ name: "Claims Eligible For MDE Submission", url: "/breadcrumb/example" }],
    apiInput: {
      data:{ isMDEFlg: true },
    },
    pageType: Constants.mdeScreenType,
    disabledFields: ["mdeFilter"],
    prePopulateScope:true,
    exportScreenName:Constants.mdeScreenType
  },
  "inbound": {
    titlePostfix: "Inbound Claim Errors",
    breadcrumbMapping: [{ name: "File Inbound Claim Errors", url: "/" }],
    apiInput: {
      data: { isInbndClaimErrFlag: true },
      sessionKeys: [Constants.fileKey,Constants.fileName,Constants.fileReceivedDateKey,Constants.inboundFileName]
    },
    titleSessionKeys: [Constants.fileName,Constants.inboundFileName],
    disabledFields: ["clientNames", "viewByDate", "timePeriods"],
    pageType: Constants.inboundErrorScreenType,
    exportScreenName:Constants.inboundErrorScreenType
  },
  "cms": {
    titlePostfix: "CMS Errors",
    breadcrumbMapping: [{ name: "File CMS Processed Claim Errors", url: "/" }],
    apiInput: {
      data: { isCMSClaimErrFlag: true },
      sessionKeys: [Constants.fileKey,Constants.fileName,Constants.fileReceivedDateKey,Constants.inboundFileName]
      
    },
    titleSessionKeys: [Constants.fileName,Constants.inboundFileName],
    disabledFields: ["clientNames", "viewByDate", "timePeriods"],
    pageType: Constants.cmsErrorScreenType,
    exportScreenName:Constants.cmsErrorScreenType
  }
}

