import { Component, OnInit, ViewChild, Inject, Input, ElementRef } from '@angular/core';
import { LineChartComponent } from '../../../charts/lineCharts/lineCharts.component';
import { BarChartsComponent } from '../../../charts/bar-charts/bar-charts.component';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { Constants } from 'src/app/data-models/constants.enum';
import { SummaryService } from 'src/app/services/tabs/summary.service';
import { Tab } from 'src/app/data-models/tab.stats';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';


@Component({
  selector: 'app-duplicates',
  templateUrl: './duplicates.component.html',
  styleUrls: ['./duplicates.component.css']
})
export class DuplicatesComponent implements OnInit {
  @Input()
  public data: any;
 
  @ViewChild(LineChartComponent)
  public lineChart: LineChartComponent;

  @ViewChild(BarChartsComponent)
  public barChart: BarChartsComponent;

  public graphSeriesId : number[];

  tabs: Tab[] = [];

  claimNumber:string;

  constructor( @Inject(SESSION_STORAGE) private readonly sessionStorage: WebStorageService,private readonly summaryService: SummaryService,) { }

  ngOnInit() {
    const prtySk =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.clientName)?.value;
    const isChildDataIncluded = this.sessionStorage.get(Constants.includeChildData);
    const clmDtCriteriaId =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.viewingBy)?.id;
    const timeCriteriaId =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod)?.id;
    const startDate = this.sessionStorage.get(Constants.fromDate);
    const endDate = this.sessionStorage.get(Constants.toDate);
    this.sessionStorage.set(Constants.seriesName, JSON.stringify("Inbound Duplicate Claims"));
    this.sessionStorage.set(Constants.yAxisLabel, JSON.stringify("Number of Claims"));
    this.graphSeriesId = [7];
    this.sessionStorage.set(Constants.tabsSelectedIndex, 3);
    const tabId = 4;
    this.sessionStorage.set(Constants.graphSeriesId, this.graphSeriesId);
    this.summaryService.getDuplicateSummaryStats(prtySk, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,tabId).subscribe(data => {
    this.tabs = data;
    this.data["frequencyChange"] = this.reloadGraph.bind(this);
    })
  }
    
  /* This fuction is used to show line and bar graph on the basis of graph Series id */

  isBarChartVissible(){
    const freqType = this.sessionStorage.get(Constants.graphViewByType);
    return this.data.timePeriod === Constants.today || 
    (this.data.timePeriod === Constants.customDateRange && 
    ((freqType === Constants.monthly && this.data[Constants.isSingleMonthDateRange]) 
    || this.data.duration === 1));
  }

  reloadGraph(seriesName) {
    this.sessionStorage.set(Constants.seriesName, JSON.stringify(seriesName));
    if(Constants.inboundDupClaim===seriesName){
      this.graphSeriesId = [7];
    }else if(Constants.cmsDupClaim===seriesName){
      this.graphSeriesId = [8];
    }
    if(this.isBarChartVissible()){
      this.barChart.reloadGraph(this.graphSeriesId);
    }else{
      this.lineChart.reloadGraph(this.graphSeriesId);
    }
  }
}
