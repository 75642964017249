import { Component, OnInit, Input, ViewChild, AbstractType, Inject } from '@angular/core';
import { LineChartComponent } from '../../../charts/lineCharts/lineCharts.component';
import { BarChartsComponent } from '../../../charts/bar-charts/bar-charts.component';
import { SummaryService } from 'src/app/services/tabs/summary.service';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { Constants } from '../../../data-models/constants.enum';
import { mockClaimData } from './summary-mock-claim-count-data';
import { StackedBarChartsComponent } from '../../../charts/stacked-bar-charts/stacked-bar-charts.component';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';

@Component({
  selector: 'app-summary-tab',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {

  @Input()
  public data: any;
 
  @ViewChild(LineChartComponent)
  public lineChart: LineChartComponent;

  @ViewChild(StackedBarChartsComponent)
  public stackedBarChart: StackedBarChartsComponent;

  @ViewChild(BarChartsComponent)
  public barChart: BarChartsComponent;

  private aggData:any;

  clmDtCriteriaIdClone :any;

  constructor(private readonly summaryService: SummaryService,
    @Inject(SESSION_STORAGE) private readonly sessionStorage: WebStorageService) { }

  ngOnInit() {
    const prtySk =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.clientName)?.value;
    const isChildDataIncluded = this.sessionStorage.get(Constants.includeChildData);
    const clmDtCriteriaId =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.viewingBy)?.id;
    const timeCriteriaId =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod)?.id;
    const startDate = this.sessionStorage.get(Constants.fromDate);
    const endDate = this.sessionStorage.get(Constants.toDate);
    this.clmDtCriteriaIdClone=clmDtCriteriaId;
    // set Y-Axis label to Number of Claims when 'Claim Date of Service' is selected from dropdown
    if(this.clmDtCriteriaIdClone==1){
      this.sessionStorage.set(Constants.yAxisLabel,JSON.stringify("Number of Claims"));
    }
     this.summaryService.getCompletedFilesCount(prtySk, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate,'1').subscribe(data => {
       this.aggData = data;
     });
   
    this.sessionStorage.set(Constants.tabsSelectedIndex, 0);
    this.data["frequencyChange"] = this.reloadGraph.bind(this);
  }
  

  reloadGraph(seriesName) {
    if(this.clmDtCriteriaIdClone == 0){
      this.stackedBarChart.reloadGraph(Constants.filesRecieved);
    }else if(this.isBarChartVissible()){
      this.barChart.reloadGraph(seriesName);
    }else{
      this.lineChart.reloadGraph(seriesName);
    }
  }

  isBarChartVissible(){
    const freqType = this.sessionStorage.get(Constants.graphViewByType);
    return this.data.timePeriod === Constants.customDateRange && 
    ((freqType === Constants.monthly && this.data[Constants.isSingleMonthDateRange]) 
    || this.data.duration === 1);
  }
}
