<div class="breadcrumb-wrapper" *ngIf="pageConfig.breadcrumb">
    <uitk-breadcrumb>
        <uitk-breadcrumb-item [routerLink]="breadcrumbItem.url" *ngFor="let breadcrumbItem of pageConfig.breadcrumb">
            <span>{{breadcrumbItem.name}}</span>
        </uitk-breadcrumb-item>
    </uitk-breadcrumb>
</div>

<h3>{{pageConfig.title}} </h3>

<div *ngIf="isFileLvlErrors" class="error-count-summary-wrapper">
    <div *ngFor="let errorCount of pageConfig.errorCountSummary" class="error-count-summary">
        <span class="error-name ax_default"> {{errorCount.displayName}} </span>
        <span class="error-count ax_default"> {{errorCount.value}} </span>
    </div>
</div>
<app-filter-selection #filterSelection [filterExpandedView] ="filterExpandedView" [config]="filterConfig" [scopeData]="scopeData" [pageConfig]="pageConfig" [selectionDetails]="filterSelectionDetails" [confirmPopupConfig]="confirmPopupConfig" (scopeDataChanged)="filterSelectionEvent($event)"></app-filter-selection>
<app-filter-confirm [filterExpandedView] ="filterExpandedView" (emitClosePopup)="filterSelectionEvent($event)" [selectionDetails]="filterSelectionDetails" [config]="confirmPopupConfig"> </app-filter-confirm>
