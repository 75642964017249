import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterSelectionComponent } from '../claim-errors/filter-selection/filter-selection.component';
import { AppliedFilterBubbleComponent } from '../applied-filter-bubble/applied-filter-bubble.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterConfirmComponent } from '../filter-confirm/filter-confirm.component';
import { ShowHidePopUpComponent} from '../show-hide-pop-up/show-hide-pop-up.component';
import { LoadMoreRecordsComponent } from '../load-more-records/load-more-records.component';
import { PageloaderComponent } from '../pageloader/pageloader.component';
import { UITKLoadingIndicatorModule, UITKDropdownModule,UITKCheckboxModule, UITKDatePickerModule,UITKInputModule,UITKBreadcrumbModule, UITKDialogModule,UITKFormFieldService, UITKInputService, UITKTableModule, UITKSelectModule, UITKAlertModule } from '@uitk/angular';
import { ErrorCrrResubmitComponent } from '../claim-errors/error-crr-resubmit/error-crr-resubmit.component';
import { DownloadComponent } from '../download/download.component';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [AppliedFilterBubbleComponent, FilterSelectionComponent,FilterConfirmComponent,ShowHidePopUpComponent,LoadMoreRecordsComponent,
  PageloaderComponent,
  ErrorCrrResubmitComponent,DownloadComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UITKLoadingIndicatorModule,
    UITKDropdownModule,
    UITKDatePickerModule,
    UITKInputModule,
    UITKBreadcrumbModule,
    UITKDialogModule,
    UITKCheckboxModule,
    UITKTableModule,
    UITKSelectModule,
    UITKAlertModule
    ],
  exports: [FilterSelectionComponent,FilterConfirmComponent,ShowHidePopUpComponent,LoadMoreRecordsComponent,PageloaderComponent,
    ErrorCrrResubmitComponent,DownloadComponent],
  providers:[UITKFormFieldService, UITKInputService]
  
})
export class ReportsModule { }
