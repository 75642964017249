<div *ngIf="getUnmatchErrorHighlight(section)" class="info-unmatch-wrapper">
    <app-error-highlight
        [errorHighlightList]="getUnmatchErrorHighlight(section)">
    </app-error-highlight>
    <!-- not containing uitk component  -->
</div>
<div class="claim-info-wrapper">
    <div class="info-item error-highlight-section">
        <app-error-highlight
            [errorHighlightList]="getMatchErrorHighlight(section,labelMapping.dateOfService)">
        </app-error-highlight>
        <!-- not containing uitk component  -->
    </div>
    <div class="info-item error-highlight-section" *ngIf="isInstitutional">
        <app-error-highlight
            [errorHighlightList]="getMatchErrorHighlight(section,labelMappingI.revenueCode)">
        </app-error-highlight>
        <!-- not containing uitk component  -->
    </div>
    <div class="info-item error-highlight-section" *ngIf="isProfessional">
        <app-error-highlight
            [errorHighlightList]="getMatchErrorHighlight(section,labelMappingP.placeOfService)">
        </app-error-highlight>
        <!-- not containing uitk component  -->
    </div>
    <div class="info-item error-highlight-section">
        <app-error-highlight
            [errorHighlightList]="getMatchErrorHighlight(section,labelMapping.procedureCodeQual)">
        </app-error-highlight>
        <!-- not containing uitk component  -->
    </div>
    <div class="info-item error-highlight-section">
        <app-error-highlight
            [errorHighlightList]="getMatchErrorHighlight(section,labelMapping.procedureCode)">
        </app-error-highlight>
        <!-- not containing uitk component  -->
    </div>
    <div class="info-item" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(section,labelMapping.dateOfService)}">
        <ng-container *ngIf="isDateOfServiceValid()">
            <div *ngIf="invalidFieldStatus[index+'_'+'dateOfService']" class="invalid-field-msg"
            [innerHTML]="getErrorHighlightMsg(labelMapping.dateOfService+' is invalid')"></div>
            <label [ngClass]="{'invalid-field': invalidFieldStatus[index+'_'+'dateOfService']}" class="info-label-item">{{labelMapping.dateOfService}}</label>
            <div class="icon-value-wrapper">
                <i *ngIf="!showHideConfig[index+'_'+'dateOfService']"
                    (click)="expandEditor(index+'_'+'dateOfService',showHideConfig)" class="uitk-icon uitk-icon-edit md-icon"
                    hiddenText="edit">
            </i>
                <span class="data-wrap" *ngIf="!showHideConfig[index+'_'+'dateOfService']">{{getDateOfService()}}
                </span>
            </div>
        </ng-container>
        <app-error-crr-editor *ngIf="showHideConfig[index+'_'+'dateOfService']" [inputType]="inputTypeConfig.fromThrough"
            [inputValue]="getDateOfService()" instruction="mm/dd/yyyy" [inputLabel]="index+'_'+'dateOfService'"
            jsonKey="dateOfService" [changeBtnCls]="changeBtnCls" (collapseFields)="collapseEditor($event)">
        </app-error-crr-editor>
        <!--  containing uitk component Done -->
    </div>
    <div class="info-item" *ngIf="isInstitutional" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(section,labelMappingI.revenueCode)}">
        <ng-container *ngIf="isAttrApplicable(clmInfoData,serviceLineObj.revenueCode)">
            <div *ngIf="invalidFieldStatus[index+'_'+'revenueCode']" class="invalid-field-msg"
            [innerHTML]="getErrorHighlightMsg(labelMappingI.revenueCode+' is invalid')"></div>
            <label [ngClass]="{'invalid-field': invalidFieldStatus[index+'_'+'revenueCode']}" class="info-label-item">{{labelMappingI.revenueCode}}</label>

            <div class="icon-value-wrapper text-ellipses">
                <i *ngIf="!showHideConfig[index+'_'+'revenueCode']"
                    (click)="expandEditor(index+'_'+'revenueCode',showHideConfig)" class="uitk-icon uitk-icon-edit md-icon"
                    hiddenText="edit">
            </i>
                <span class="data-wrap"
                    *ngIf="!showHideConfig[index+'_'+'revenueCode']" [title]="getFieldValue(clmInfoData,serviceLineObj.revenueCode)">{{getFieldValue(clmInfoData,serviceLineObj.revenueCode)}}</span>
            </div>
        </ng-container>
        <app-error-crr-editor *ngIf="showHideConfig[index+'_'+'revenueCode']" [inputType]="inputTypeConfig.input"
            [inputValue]="getFieldValue(clmInfoData,serviceLineObj.revenueCode)" instruction="Max length 48 characters"
            [inputLabel]="index+'_'+'revenueCode'" [jsonKey]="serviceLineObj.revenueCode" pattern='^[a-zA-Z0-9]+$' maxLength='48' 
            (collapseFields)="collapseEditor($event)" [changeBtnCls]="changeBtnCls">
        </app-error-crr-editor>
         <!--  containing uitk component Done -->
    </div>

    <div class="info-item" *ngIf="isProfessional" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(section,labelMappingP.placeOfService)}">
        <ng-container *ngIf="isAttrApplicable(clmInfoData,serviceLineObj.facilityCode)">
            <div *ngIf="invalidFieldStatus['facilityCode'+'_'+index]" class="invalid-field-msg"
            [innerHTML]="getErrorHighlightMsg(labelMappingP.placeOfService+' is invalid')"></div>
            <label [ngClass]="{'invalid-field': invalidFieldStatus['facilityCode'+'_'+index]}" class="info-label-item">{{labelMappingP.placeOfService}}</label>
            <div class="icon-value-wrapper">
                <i *ngIf="!showHideConfig['facilityCode'+'_'+index]"
                    (click)="expandEditor('facilityCode'+'_'+index,showHideConfig)" class="uitk-icon uitk-icon-edit md-icon"
                    hiddenText="edit">
            </i>
                <span class="data-wrap"
                    *ngIf="!showHideConfig['facilityCode'+'_'+index]">{{getFieldLabel('facilityCode',clmInfoData.sv1Loop2400ServiceLine.facilityCode)}}</span>
            </div>
        </ng-container>
        <app-error-crr-editor *ngIf="showHideConfig['facilityCode'+'_'+index]" [inputType]="inputTypeConfig.select"
            [inputValue]="getFieldValue(clmInfoData,serviceLineObj.facilityCode)" instruction="Select one option"
            [inputLabel]="'facilityCode'+'_'+index" [jsonKey]="serviceLineObj.facilityCode" [changeBtnCls]="changeBtnCls"
            [listData]="listConfig" (collapseFields)="collapseEditor($event)">
        </app-error-crr-editor>
         <!--  containing uitk component Done  -->
    </div>

    <div class="info-item" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(section,labelMapping.procedureCodeQual)}">
        <ng-container *ngIf="isAttrApplicable(clmInfoData,serviceLineObj.productQualifier)">
            <div *ngIf="invalidFieldStatus['productQualifier'+'_'+index]" class="invalid-field-msg"
            [innerHTML]="getErrorHighlightMsg('Procedure Code Qualifier is invalid')"></div>
            <label [ngClass]="{'invalid-field': invalidFieldStatus['productQualifier'+'_'+index]}" class="info-label-item">Procedure Code Qualifier</label>
            <div class="icon-value-wrapper">
                <i *ngIf="!showHideConfig['productQualifier'+'_'+index]"
                    (click)="expandEditor('productQualifier'+'_'+index,showHideConfig,index)" class="uitk-icon uitk-icon-edit md-icon"
                    hiddenText="edit">
            </i>
                <span class="data-wrap" *ngIf="!showHideConfig['productQualifier'+'_'+index]">
                    {{ getFieldLabel('productQualifier',getFieldValue(clmInfoData,serviceLineObj.productQualifier))}}
                </span>
            </div>
        </ng-container>
        <app-error-crr-editor *ngIf="showHideConfig['productQualifier'+'_'+index]" [inputType]="inputTypeConfig.select"
            [inputValue]="getFieldValue(clmInfoData,serviceLineObj.productQualifier)"
            instruction="Select one option" [inputLabel]="'productQualifier'+'_'+index"
            [jsonKey]="serviceLineObj.productQualifier" pattern='^[a-zA-Z0-9]+$' maxLength='2' minLength='2'
            (collapseFields)="collapseEditor($event)" [listData]="listConfig"  [changeBtnCls]="changeBtnCls">
        </app-error-crr-editor>
        <!--  containing uitk component Done  -->
    </div>

    <div class="info-item" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(section,labelMapping.procedureCode)}">
        <ng-container *ngIf="isAttrApplicable(clmInfoData,serviceLineObj.procedureCode)">
            <div *ngIf="invalidFieldStatus[index+'_'+'procedureCode']" class="invalid-field-msg"
            [innerHTML]="getErrorHighlightMsg('Procedure Code is invalid')"></div>
            <label [ngClass]="{'invalid-field': invalidFieldStatus[index+'_'+'procedureCode']}" class="info-label-item">Procedure
                Code</label>
            <div class="icon-value-wrapper text-ellipses">
                <i *ngIf="!showHideConfig[index+'_'+'procedureCode']"
                    (click)="expandEditor(index+'_'+'procedureCode',showHideConfig)" class="uitk-icon uitk-icon-edit md-icon"
                    hiddenText="edit"></i>
                <span class="data-wrap"
                    *ngIf="!showHideConfig[index+'_'+'procedureCode']" [title]="getFieldValue(clmInfoData,serviceLineObj.procedureCode)">{{getFieldValue(clmInfoData,serviceLineObj.procedureCode)}}</span>
            </div>
        </ng-container>
        <app-error-crr-editor *ngIf="showHideConfig[index+'_'+'procedureCode']" [inputType]="inputTypeConfig.input"
            [inputValue]="getFieldValue(clmInfoData,serviceLineObj.procedureCode)"
            instruction="Max length 48 characters" [inputLabel]="index+'_'+'procedureCode'"
            [jsonKey]="serviceLineObj.procedureCode" pattern='^[a-zA-Z0-9]+$' maxLength='48'
            (collapseFields)="collapseEditor($event)" [changeBtnCls]="changeBtnCls">
        </app-error-crr-editor>
         <!--  containing uitk component Done  -->
    </div>

    <div class="info-item error-highlight-section">
        <app-error-highlight
            [errorHighlightList]="getMatchErrorHighlight(section,labelMapping.procedureCodeMod)">
        </app-error-highlight>
          <!-- not containing uitk component  -->
    </div>
    <div class="info-item error-highlight-section">
        <app-error-highlight
            [errorHighlightList]="getMatchErrorHighlight(section,labelMapping.serviceLineQty)">
        </app-error-highlight>
          <!-- not containing uitk component  -->
    </div>
    <div class="info-item error-highlight-section">
        <app-error-highlight
            [errorHighlightList]="getMatchErrorHighlight(section,labelMapping.ndcCode)">
        </app-error-highlight>
          <!-- not containing uitk component  -->
    </div>
    <div class="info-item error-highlight-section">
        <app-error-highlight
            [errorHighlightList]="getMatchErrorHighlight(section,labelMapping.ndcQty)">
        </app-error-highlight>
          <!-- not containing uitk component  -->
    </div>

    <div class="info-item" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(section,labelMapping.procedureCodeMod)}">
        <ng-container *ngIf="procedureModifier(true)">
            <div *ngIf="invalidFieldStatus[index+'_'+'procedureModifier']" class="invalid-field-msg"
            [innerHTML]="getErrorHighlightMsg('Procedure Code Modifier is invalid')"></div>
            <label [ngClass]="{'invalid-field': invalidFieldStatus[index+'_'+'procedureModifier']}" class="info-label-item">Procedure Code Modifier</label>
            <div class="icon-value-wrapper">
                <i *ngIf="!showHideConfig[index+'_'+'procedureModifier']"
                    (click)="expandEditor(index+'_'+'procedureModifier',showHideConfig)" class="uitk-icon uitk-icon-edit md-icon"
                    hiddenText="edit"></i>
                <span class="data-wrap" *ngIf="!showHideConfig[index+'_'+'procedureModifier']">
                    {{procedureModifier()}}
                </span>
            </div>
        </ng-container>
        <app-error-crr-editor *ngIf="showHideConfig[index+'_'+'procedureModifier']" [inputType]="inputTypeConfig.codeInfo"
            [inputValue]="procedureModifier()" instruction="Max length 2 characters each" [inputLabel]="index+'_'+'procedureModifier'"
            jsonKey="procedureModifier" pattern='^[a-zA-Z0-9][a-zA-Z0-9](,[a-zA-Z0-9][a-zA-Z0-9])*$' maxLength='2'
            (collapseFields)="collapseEditor($event)" [changeBtnCls]="changeBtnCls">
        </app-error-crr-editor>
         <!--  containing uitk component Done  -->
    </div>

    <div class="info-item" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(section,labelMapping.serviceLineQty)}">
        <ng-container *ngIf="isAttrApplicable(clmInfoData,serviceLineObj.quantity)">
            <div *ngIf="invalidFieldStatus[index+'_'+'quantity']" class="invalid-field-msg"
            [innerHTML]="getErrorHighlightMsg('Service Line Quantity is invalid')"></div>
            <label [ngClass]="{'invalid-field': invalidFieldStatus[index+'_'+'quantity']}" class="info-label-item">Service Line Quantity</label>
            <div class="icon-value-wrapper">
                <i *ngIf="!showHideConfig[index+'_'+'quantity']" (click)="expandEditor(index+'_'+'quantity',showHideConfig)"
                class="uitk-icon uitk-icon-edit md-icon"  hiddenText="edit"></i>
                <span class="data-wrap"
                    *ngIf="!showHideConfig[index+'_'+'quantity']">{{getQtyUnitFieldData(serviceLineObj.quantity,serviceLineObj.unitBassisMeasCode)}}</span>
            </div>
        </ng-container>
        <app-error-crr-editor *ngIf="showHideConfig[index+'_'+'quantity']" [inputType]="inputTypeConfig.unit"
            [inputValue]="getQtyUnitFieldData(serviceLineObj.quantity,serviceLineObj.unitBassisMeasCode)"
            instruction="Max length 7 characters" [inputLabel]="index+'_'+'quantity'" jsonKey="unitBassisMeasCode" pattern = "^\d{1,6}(\.\d{1,1})?$"
            [quantity]="quantity"  (collapseFields)="collapseEditor($event)" [isConditional]="isProfessional" [quantityPattern]="quantityPattern"
            [changeBtnCls]="changeBtnCls" [listData]="listConfig">
        </app-error-crr-editor>
         <!--  containing uitk component  Done -->
    </div> 

    <div class="info-item" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(section,labelMapping.ndcCode)}">
        <ng-container *ngIf="isAttrApplicable(clmInfoData,serviceLineObj.drugIdentification)">
            <div *ngIf="invalidFieldStatus[index+'_'+'drugIdentification']" class="invalid-field-msg"
            [innerHTML]="getErrorHighlightMsg('NDC Code is invalid')"></div>
            <label [ngClass]="{'invalid-field': invalidFieldStatus[index+'_'+'drugIdentification']}" class="info-label-item">NDC Code</label>
            <div class="icon-value-wrapper">
                <i *ngIf="!showHideConfig[index+'_'+'drugIdentification']"
                    (click)="expandEditor(index+'_'+'drugIdentification',showHideConfig)"  class="uitk-icon uitk-icon-edit md-icon"
                    hiddenText="edit"></i>
                <span class="data-wrap"
                    *ngIf="!showHideConfig[index+'_'+'drugIdentification']">{{getFieldValue(clmInfoData,serviceLineObj.drugIdentification)}}</span>
            </div>
        </ng-container>
        <app-error-crr-editor *ngIf="showHideConfig[index+'_'+'drugIdentification']" [inputType]="inputTypeConfig.input"
            [inputValue]="getFieldValue(clmInfoData,serviceLineObj.drugIdentification)"
            instruction="Max length 11 characters" [inputLabel]="index+'_'+'drugIdentification'" optional='true'
            [jsonKey]="serviceLineObj.drugIdentification"  pattern='^[a-zA-Z0-9]+$' maxLength='11' minLength='11'
            (collapseFields)="collapseEditor($event)" [changeBtnCls]="changeBtnCls">
        </app-error-crr-editor>
        <!--  containing uitk component Done  -->
    </div>

    <div class="info-item" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(section,labelMapping.ndcQty)}">
        <ng-container *ngIf="isAttrApplicable(clmInfoData,serviceLineObj.drugQuantity)">
            <div *ngIf="invalidFieldStatus[index+'_'+'drugQuantity']" class="invalid-field-msg"
            [innerHTML]="getErrorHighlightMsg('NDC Quantity is invalid')"></div>
            <label [ngClass]="{'invalid-field': invalidFieldStatus[index+'_'+'drugQuantity']}" class="info-label-item">NDC Quantity</label>
            <div class="icon-value-wrapper">
                <i *ngIf="!showHideConfig[index+'_'+'drugQuantity']"
                    (click)="expandEditor(index+'_'+'drugQuantity',showHideConfig)"  class="uitk-icon uitk-icon-edit md-icon"
                    hiddenText="edit"></i>
                <span class="data-wrap"
                    *ngIf="!showHideConfig[index+'_'+'drugQuantity']">{{getQtyUnitFieldData(serviceLineObj.drugQuantity,serviceLineObj.ndcUnit)}}</span>
            </div>
            
        </ng-container>
        <app-error-crr-editor *ngIf="showHideConfig[index+'_'+'drugQuantity']" [inputType]="inputTypeConfig.unit"
            [inputValue]="getQtyUnitFieldData(serviceLineObj.drugQuantity,serviceLineObj.ndcUnit)"
            instruction="Max length 10 characters" [inputLabel]="index+'_'+'drugQuantity'" jsonKey="ndcUnits"
            pattern='^\s*(?=.*[1-9])\d{1,7}(?:\.\d{1,3})?\s*$' maxLength='10' (collapseFields)="collapseEditor($event)"
            [changeBtnCls]="changeBtnCls" [listData]="listConfig" [quantity]="ndcQuantity">
        </app-error-crr-editor>
        <!--  containing uitk component Done  -->
    </div>

    <div class="info-item error-highlight-section" *ngIf="isProfessional">
        <app-error-highlight
            [errorHighlightList]="getMatchErrorHighlight(section,labelMappingP.diagCodePointer)">
        </app-error-highlight>
    </div>
    <div class="info-item error-highlight-section">
        <app-error-highlight
            [errorHighlightList]="getMatchErrorHighlight(section,labelMapping.serviceChgAmt)">
        </app-error-highlight>
    </div>
    <div class="info-item error-highlight-section">
        <app-error-highlight
            [errorHighlightList]="getMatchErrorHighlight(section,labelMapping.paidAmt)">
        </app-error-highlight>
    </div>
    <div class="info-item error-highlight-section">
        <app-error-highlight
            [errorHighlightList]="getMatchErrorHighlight(section,labelMapping.paidDate)">
        </app-error-highlight>
    </div>
    <div class="info-item error-highlight-section" *ngIf="isInstitutional"></div>

    <div class="info-item" *ngIf="isProfessional" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(section,labelMappingP.diagCodePointer)}">
        <ng-container *ngIf="isAttrApplicable(clmInfoData,serviceLineObj.diagnosisCodePointer)">
            <div *ngIf="invalidFieldStatus[index+'_'+'diagnosisCodePointer']" class="invalid-field-msg"
            [innerHTML]="getErrorHighlightMsg('Diagnosis Code Pointer is invalid')">
            </div>
            <label [ngClass]="{'invalid-field': invalidFieldStatus[index+'_'+'diagnosisCodePointer']}"
                class="info-label-item">Diagnosis Code Pointer</label>
            <div class="icon-value-wrapper">
                <i *ngIf="!showHideConfig[index+'_'+'diagnosisCodePointer']"
                    (click)="expandEditor(index+'_'+'diagnosisCodePointer',showHideConfig)" class="uitk-icon uitk-icon-edit md-icon" hiddenText="edit">
                </i>
                <span class="data-wrap"
                    *ngIf="!showHideConfig[index+'_'+'diagnosisCodePointer']">
                    {{getDiagnosisCodePointer(clmInfoData,serviceLineObj.diagnosisCodePointer)}}
                </span>
            </div>
        </ng-container>
        <app-error-crr-editor *ngIf="showHideConfig[index+'_'+'diagnosisCodePointer']" [inputType]="inputTypeConfig.codeInfo"
            [inputValue]="getFieldValue(clmInfoData,serviceLineObj.diagnosisCodePointer)"
            instruction="Max length 2 characters each" [inputLabel]="index+'_'+'diagnosisCodePointer'" pattern='^([1-9]|1[012])(,([1-9]|1[012])){0,3}$'
            [jsonKey]="serviceLineObj.diagnosisCodePointer" [changeBtnCls]="changeBtnCls" maxLength='2'
            (collapseFields)="collapseEditor($event)">
        </app-error-crr-editor>
        <!--  containing uitk component remaining  -->
    </div>

    <div class="info-item" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(section,labelMapping.serviceChgAmt)}">
        <ng-container *ngIf="isAttrApplicable(clmInfoData,serviceLineObj.monetaryAmount)">
            <div *ngIf="invalidFieldStatus[index+'_'+'monetaryAmount']" class="invalid-field-msg"
            [innerHTML]="getErrorHighlightMsg('Service Charge Amount is invalid')"></div>
            <label [ngClass]="{'invalid-field': invalidFieldStatus[index+'_'+'monetaryAmount']}" class="info-label-item">Service Charge Amount</label>
            <div class="icon-value-wrapper">
                <i *ngIf="!showHideConfig[index+'_'+'monetaryAmount']"
                    (click)="expandEditor(index+'_'+'monetaryAmount',showHideConfig)" class="uitk-icon uitk-icon-edit md-icon"
                    hiddenText="edit"></i>
                <span class="data-wrap"
                    *ngIf="!showHideConfig[index+'_'+'monetaryAmount']">{{getFieldValue(clmInfoData,serviceLineObj.monetaryAmount) | currency
                    }}</span>
            </div>
        </ng-container>
        <app-error-crr-editor *ngIf="showHideConfig[index+'_'+'monetaryAmount']" [inputType]="inputTypeConfig.dollar"
            [inputValue]="getFieldValue(clmInfoData,serviceLineObj.monetaryAmount)" instruction="Do not include commas"
            [inputLabel]="index+'_'+'monetaryAmount'" pattern='[0-9]+' [maxLength]="isProfessional ? 5 : 8"
            [jsonKey]="serviceLineObj.monetaryAmount" [changeBtnCls]="changeBtnCls"
            (collapseFields)="collapseEditor($event)">
        </app-error-crr-editor>
         <!--  containing uitk component Done  -->
    </div>

    <div class="info-item" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(section,labelMapping.paidAmt)}">
        <ng-container *ngIf="isAttrApplicable(clmInfoData,serviceLineObj.paidAmount)">
            <div *ngIf="invalidFieldStatus[index+'_'+'paidAmount']" class="invalid-field-msg"
            [innerHTML]="getErrorHighlightMsg('Paid Amount is invalid')"></div>
            <label [ngClass]="{'invalid-field': invalidFieldStatus[index+'_'+'paidAmount']}" class="info-label-item">Paid Amount</label>
            <div class="icon-value-wrapper">
                <i *ngIf="!showHideConfig[index+'_'+'paidAmount']"
                    (click)="expandEditor(index+'_'+'paidAmount',showHideConfig)" class="uitk-icon uitk-icon-edit md-icon"
                    hiddenText="edit"></i>
                <span class="data-wrap"
                    *ngIf="!showHideConfig[index+'_'+'paidAmount']">{{getFieldValue(clmInfoData,serviceLineObj.paidAmount) | currency }}</span>
            </div>
        </ng-container>
        <app-error-crr-editor *ngIf="showHideConfig[index+'_'+'paidAmount']" [inputType]="inputTypeConfig.dollar"
            [inputValue]="getFieldValue(clmInfoData,serviceLineObj.paidAmount)" instruction="Do not include commas"
            [inputLabel]="index+'_'+'paidAmount'" pattern='[0-9]+' [maxLength]="isProfessional ? 5 : 8"
            [jsonKey]="serviceLineObj.paidAmount" [changeBtnCls]="changeBtnCls"
            (collapseFields)="collapseEditor($event)">
        </app-error-crr-editor>
        <!--  containing uitk component Done  -->
    </div>

    <div class="info-item" [ngClass]="{'error-highlight-field':getMatchErrorHighlight(section,labelMapping.paidDate)}">
        <ng-container *ngIf="isAttrApplicable(clmInfoData,serviceLineObj.paidDate)">
            <div *ngIf="invalidFieldStatus[index+'_'+'paidDate']" class="invalid-field-msg" 
            [innerHTML]="getErrorHighlightMsg('Paid Date is invalid')"></div>
            <label [ngClass]="{'invalid-field': invalidFieldStatus[index+'_'+'paidDate']}" class="info-label-item">Paid Date</label>
            <div class="icon-value-wrapper">
                <i *ngIf="!showHideConfig[index+'_'+'paidDate']" (click)="expandEditor(index+'_'+'paidDate',showHideConfig)"
                class="uitk-icon uitk-icon-edit md-icon" hiddenText="edit"></i>
                <span class="data-wrap"
                    *ngIf="!showHideConfig[index+'_'+'paidDate']">{{getPaidDate(clmInfoData,serviceLineObj.paidDate)}}</span>
            </div>
        </ng-container>
        <app-error-crr-editor *ngIf="showHideConfig[index+'_'+'paidDate']" [inputType]="inputTypeConfig.date"
            [inputValue]="getPaidDate(clmInfoData,serviceLineObj.paidDate)" instruction="mm/dd/yyyy"
            [inputLabel]="index+'_'+'paidDate'" [jsonKey]="serviceLineObj.paidDate" [changeBtnCls]="changeBtnCls"
            (collapseFields)="collapseEditor($event)">
        </app-error-crr-editor>
        <!--  containing uitk component Done  -->
    </div>

</div>
