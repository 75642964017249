import { Component, Inject, Input, OnInit } from '@angular/core';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';
import { Constants } from '../../../data-models/constants.enum';

@Component({
  selector: 'app-graph-switch-frequency',
  templateUrl: './graph-switch-frequency.component.html',
  styleUrls: ['./graph-switch-frequency.component.css']
})
export class GraphSwitchFrequencyComponent implements OnInit {
  @Input() scopeProps:any;
  freqType:string 
  readonly daily = Constants.daily;
  readonly monthly = Constants.monthly;
  readonly customDate = Constants.customDateRange;

  constructor( @Inject(SESSION_STORAGE) readonly sessionStorage: WebStorageService,) { }

  ngOnInit() {
    this.freqType = this.scopeProps[Constants.graphViewByType];
  }

  onFrequencyTabClick(freqType){
    this.freqType = freqType;
    this.sessionStorage.set(Constants.graphViewByType,freqType);
    const seriesName =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.seriesName);
    this.scopeProps.frequencyChange(seriesName);
  }

  checkSelectedTab(freqType){
    return this.freqType === freqType;
  }

}
