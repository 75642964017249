<div class="err-crr-header-expand-wrapper">
    <div *ngIf="dataLoading" [ngStyle]="{ 'height': '370px', 'position': 'relative' }">
        <uitk-loading-indicator
        uitkId="errCrrHeaderCatgIndicator"
        [centerSpinner]="true"
        visibleText="Please wait..."
        spinnerSize="medium"
        [displayOverlay]="false"
      >
      </uitk-loading-indicator>
    </div>
    <ng-container *ngIf="!dataLoading">
        <div class="info-wapper">
            <div class="flex-container">
                <span *ngIf="open" class="collapse-btn" (click)="toggleOpen()">Collapse All</span>
                <span *ngIf="!open" class="collapse-btn" (click)="toggleOpen()">Expand All</span>
                <a class="close-icon" (click)="cancelHeader()" >  <i class="uitk-icon uitk-icon-close lg-icon"></i> </a>
            </div>
            <div class="err-crr-header-dtls-wrapper">
                <div class="err-crr-header-dtls">
                    
                    <uitk-panel class="claim-info-accordian" uitkId="claimInfo" [collapsible]="collapsiblePanel" [open]="open">
                            <uitk-panel-header-title [ngClass]="{'error-highlight-acc': getErrorHighlightCount(claimInfoMapping.section)}">
                                    <strong>{{ claimInfoMapping.section }}
                                    <span *ngIf="getErrorHighlightCount(claimInfoMapping.section)" class="panel-header-detail">
                                        <span class="error-highlight-count">{{getErrorHighlightCount(claimInfoMapping.section)}}</span>
                                    </span>
                                </strong>
                            </uitk-panel-header-title>
                        <uitk-panel-content panelId="1"  
                            headerClass="tk-panl-with-underline" [ngClass]="{'error-highlight-acc': getErrorHighlightCount(claimInfoMapping.section)}">
                            <div *ngIf="getUnmatchErrorHighlight(claimInfoMapping.section)" class="info-unmatch-wrapper">
                                <app-error-highlight
                                    [errorHighlightList]="getUnmatchErrorHighlight(claimInfoMapping.section)">
                                </app-error-highlight>
                            </div>
                           
                            <div class="claim-info-wrapper">
                                <div class="info-item error-highlight-section" *ngIf="isInstitutional">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.facilityBillType)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section" *ngIf="isProfessional">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.placeOfService)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.frequencyCodeType)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.payerClmCtrlNo)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.claimTotalCharges)">
                                    </app-error-highlight>
                                </div>

                                <div class="info-item" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.facilityBillType)}"  *ngIf="isInstitutional">
                                    <ng-container
                                        *ngIf="isAttrApplicable(clmDta,'clmLoop2300ClaimInformation.healthCareServLoc.facilityCodeValue')">
                                        <div *ngIf="invalidFieldStatus.facilityCodeValue" class="invalid-field-msg" 
                                         [innerHTML]="getErrorHighlightMsg('Facility/Bill Type is invalid')">
                                        </div>
                                        <label class="info-label-item" [ngClass]="{'invalid-field': invalidFieldStatus.facilityCodeValue}">
                                            Facility/Bill Type
                                        </label>
                                        <div class="icon-value-wrapper">
                                            <i  class="uitk-icon uitk-icon-edit md-icon"*ngIf="!showHideConfig['facilityCodeValue']"
                                                (click)="expandEditor('facilityCodeValue',showHideConfig)"  
                                                icon="uimf-icon-edit" hiddenText="edit"></i>
                                            <span class="data-wrap"
                                                *ngIf="!showHideConfig['facilityCodeValue']">{{getFieldLabel("facilityCodeValue",clmDta.clmLoop2300ClaimInformation.healthCareServLoc.facilityCodeValue)}}</span>
                                            <app-error-crr-editor *ngIf="showHideConfig['facilityCodeValue']"
                                                [inputType]="inputTypeConfig.select"
                                                [inputValue]="clmDta.clmLoop2300ClaimInformation.healthCareServLoc.facilityCodeValue"
                                                instruction="Select one option" inputLabel="facilityCodeValue"
                                                jsonKey="clmLoop2300ClaimInformation.healthCareServLoc.facilityCodeValue"
                                                [changeBtnCls]="changeBtnCls"
                                                [listData]="listConfig" (collapseFields)="collapseEditor($event)">
                                            </app-error-crr-editor>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="info-item" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.placeOfService)}"   *ngIf="isProfessional">
                                    <ng-container
                                        *ngIf="isAttrApplicable(clmDta,'clmLoop2300ClaimInformation.healthCareServLoc.facilityCodeValue')">
                                        <div *ngIf="invalidFieldStatus.facilityCodeValue" class="invalid-field-msg" 
                                         [innerHTML]="getErrorHighlightMsg('Place of Service is invalid')">
                                        </div>
                                        <label class="info-label-item" [ngClass]="{'invalid-field': invalidFieldStatus.facilityCodeValue}">
                                        Place of Service</label>
                                        <div class="icon-value-wrapper">
                                            <i  class="uitk-icon uitk-icon-edit md-icon"*ngIf="!showHideConfig['facilityCodeValue']"
                                                (click)="expandEditor('facilityCodeValue',showHideConfig)" icon="uimf-icon-edit"
                                                hiddenText="edit"  ></i>
                                            <span class="data-wrap"
                                                *ngIf="!showHideConfig['facilityCodeValue']">{{getFieldLabel("facilityCodeValue",clmDta.clmLoop2300ClaimInformation.healthCareServLoc.facilityCodeValue)}}</span>
                                            <app-error-crr-editor *ngIf="showHideConfig['facilityCodeValue']"
                                                [inputType]="inputTypeConfig.select"
                                                [inputValue]="clmDta.clmLoop2300ClaimInformation.healthCareServLoc.facilityCodeValue"
                                                instruction="Select one option" inputLabel="facilityCodeValue"
                                                jsonKey="clmLoop2300ClaimInformation.healthCareServLoc.facilityCodeValue"
                                                [changeBtnCls]="changeBtnCls"
                                                [listData]="listConfig" (collapseFields)="collapseEditor($event)">
                                            </app-error-crr-editor>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="info-item" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.frequencyCodeType)}" >
                                    <ng-container
                                        *ngIf="isAttrApplicable(clmDta,'clmLoop2300ClaimInformation.healthCareServLoc.claimFrequencyTypeCode')">
                                        <div *ngIf="invalidFieldStatus.claimFrequencyTypeCode" class="invalid-field-msg" [innerHTML]="getErrorHighlightMsg('Frequency Type Code is invalid')">
                                        </div>
                                        <label class="info-label-item" [ngClass]="{'invalid-field': invalidFieldStatus.claimFrequencyTypeCode}">
                                        Frequency Type Code</label>
                                        <div class="icon-value-wrapper">
                                            <i  class="uitk-icon uitk-icon-edit md-icon"*ngIf="!showHideConfig['claimFrequencyTypeCode']"
                                                (click)="expandEditor('claimFrequencyTypeCode',showHideConfig)" icon="uimf-icon-edit"
                                                hiddenText="edit"  ></i>
                                            <span class="data-wrap"
                                                *ngIf="!showHideConfig['claimFrequencyTypeCode']">{{getFieldLabel("claimFrequencyTypeCode",clmDta.clmLoop2300ClaimInformation.healthCareServLoc.claimFrequencyTypeCode)}}</span>
                                            <app-error-crr-editor *ngIf="showHideConfig['claimFrequencyTypeCode']"
                                                [inputType]="inputTypeConfig.select"
                                                [inputValue]="clmDta.clmLoop2300ClaimInformation.healthCareServLoc.claimFrequencyTypeCode"
                                                instruction="Select one option" inputLabel="claimFrequencyTypeCode"
                                                jsonKey="clmLoop2300ClaimInformation.healthCareServLoc.claimFrequencyTypeCode"
                                                [listData]="listConfig" (collapseFields)="collapseEditor($event)"
                                                [changeBtnCls]="changeBtnCls">
                                            </app-error-crr-editor>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="info-item" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.payerClmCtrlNo)}">
                                    <ng-container *ngIf="isPayerCtrlNoApplicable()">
                                        <div *ngIf="invalidFieldStatus.payerControlNumber" class="invalid-field-msg data-wrap text-ellipses" 
                                         [innerHTML]="getErrorHighlightMsg('Payer Claim Control Number is invalid')">
                                        </div>
                                        <label [ngClass]="{'invalid-field': invalidFieldStatus.payerControlNumber}"
                                            class="info-label-item">Payer Claim Control Number</label>
                                        <div class="icon-value-wrapper">
                                            <i  class="uitk-icon uitk-icon-edit md-icon"*ngIf="!showHideConfig['payerControlNumber']"
                                                (click)="expandEditor('payerControlNumber',showHideConfig)" icon="uimf-icon-edit"
                                                hiddenText="edit"  ></i>
                                            <span class="data-wrap text-ellipses"
                                                *ngIf="!showHideConfig['payerControlNumber']">{{getPayerControlNumber()}}</span>
                                            <app-error-crr-editor *ngIf="showHideConfig['payerControlNumber']"
                                                [inputType]="inputTypeConfig.input" [inputValue]="getPayerControlNumber()"
                                                instruction="Max length 50 characters" inputLabel="payerControlNumber"
                                                jsonKey="payerControlNumber" pattern='^[a-zA-Z0-9]+$' maxLength='50'
                                                (collapseFields)="collapseEditor($event)"  [optional]="true"
                                                [changeBtnCls]="changeBtnCls">
                                            </app-error-crr-editor>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="info-item" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.claimTotalCharges)}">
                                    <ng-container
                                        *ngIf="isAttrApplicable(clmDta,'clmLoop2300ClaimInformation.monetaryAmount')">
                                        <div *ngIf="invalidFieldStatus.monetaryAmount" class="invalid-field-msg" [innerHTML]="getErrorHighlightMsg('Claim Total Charges is invalid')">    
                                        </div>
                                        <label [ngClass]="{'invalid-field': invalidFieldStatus.monetaryAmount}"
                                            class="info-label-item">Claim Total Charges</label>
                                        <div class="icon-value-wrapper">
                                            <i  class="uitk-icon uitk-icon-edit md-icon"*ngIf="!showHideConfig['monetaryAmount']"
                                                (click)="expandEditor('monetaryAmount',showHideConfig)" icon="uimf-icon-edit"
                                                hiddenText="edit"  ></i>
                                            <span class="data-wrap"
                                                *ngIf="!showHideConfig['monetaryAmount']">{{clmDta.clmLoop2300ClaimInformation.monetaryAmount
                                                | currency}}</span>
                                            <app-error-crr-editor *ngIf="showHideConfig['monetaryAmount']"
                                                [inputType]="inputTypeConfig.dollar"
                                                [inputValue]="clmDta.clmLoop2300ClaimInformation.monetaryAmount"
                                                instruction="Do not include commas" inputLabel="monetaryAmount"
                                                pattern='[0-9]+' [maxLength]="isProfessional ? 5 : 8"
                                                jsonKey="clmLoop2300ClaimInformation.monetaryAmount"
                                                [changeBtnCls]="changeBtnCls"
                                                (collapseFields)="collapseEditor($event)">
                                            </app-error-crr-editor>
                                        </div>
                                    </ng-container>
                                </div>

                                <div class="info-item error-highlight-section" *ngIf="isInstitutional">
                                        <app-error-highlight class="data-wrap" [errorHighlightList]="getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.admissionTypeCode)"></app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section" *ngIf="isInstitutional">
                                        <app-error-highlight class="data-wrap" [errorHighlightList]="getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.admissionSourceCode)"></app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section" *ngIf="isInstitutional">
                                        <app-error-highlight class="data-wrap" [errorHighlightList]="getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.dischargeStatus)"></app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.paidAmount)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section" *ngIf="isProfessional">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.adjDate)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section" *ngIf="isProfessional">
                                </div>
                                <div class="info-item error-highlight-section" *ngIf="isProfessional">
                                </div>
                            
                                <div class="info-item" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.admissionTypeCode)}" *ngIf="isInstitutional">
                                    <ng-container
                                        *ngIf="isAttrApplicable(clmDta,'cl1Loop2300InstitutionalClaimCode.admissionTypeCode')">
                                        <label class="info-label-item">Admission Type Code</label>
                                        <div class="icon-value-wrapper">
                                            <i  class="uitk-icon uitk-icon-edit md-icon"*ngIf="!showHideConfig['admissionTypeCode']"
                                                (click)="expandEditor('admissionTypeCode',showHideConfig)" icon="uimf-icon-edit"
                                                hiddenText="edit"  ></i>
                                            <span class="data-wrap"
                                                *ngIf="!showHideConfig['admissionTypeCode']">{{getFieldLabel("admissionTypeCode",clmDta.cl1Loop2300InstitutionalClaimCode.admissionTypeCode)}}</span>
                                            <app-error-crr-editor *ngIf="showHideConfig['admissionTypeCode']"
                                                [inputType]="inputTypeConfig.select"
                                                [inputValue]="clmDta.cl1Loop2300InstitutionalClaimCode.admissionTypeCode"
                                                instruction="Select one option" inputLabel="admissionTypeCode"
                                                jsonKey="cl1Loop2300InstitutionalClaimCode.admissionTypeCode"
                                                [changeBtnCls]="changeBtnCls"
                                                [optional]="true"
                                                [listData]="listConfig" (collapseFields)="collapseEditor($event)">
                                            </app-error-crr-editor>
                                        </div>
                                    </ng-container>
                                </div>
                              
                                <div class="info-item" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.admissionSourceCode)}" *ngIf="isInstitutional">
                                    <ng-container
                                        *ngIf="isAttrApplicable(clmDta,'cl1Loop2300InstitutionalClaimCode.admissionSourceCode')">
                                        <label class="info-label-item">Admission Source Code</label>
                                        <div class="icon-value-wrapper">
                                            <i  class="uitk-icon uitk-icon-edit md-icon"*ngIf="!showHideConfig['admissionSourceCode']"
                                                (click)="expandEditor('admissionSourceCode',showHideConfig)" icon="uimf-icon-edit"
                                                hiddenText="edit"  ></i>
                                            <span class="data-wrap"
                                                *ngIf="!showHideConfig['admissionSourceCode']">{{getFieldLabel("admissionSourceCode",clmDta.cl1Loop2300InstitutionalClaimCode.admissionSourceCode)}}</span>
                                            <app-error-crr-editor *ngIf="showHideConfig['admissionSourceCode']"
                                                [inputType]="inputTypeConfig.select"
                                                [inputValue]="clmDta.cl1Loop2300InstitutionalClaimCode.admissionSourceCode"
                                                instruction="Select one option" inputLabel="admissionSourceCode"
                                                jsonKey="cl1Loop2300InstitutionalClaimCode.admissionSourceCode"
                                                [changeBtnCls]="changeBtnCls"
                                                [optional]="true"
                                                [listData]="listConfig" (collapseFields)="collapseEditor($event)">
                                            </app-error-crr-editor>
                                        </div>
                                    </ng-container>
                                </div>
                                
                                <div class="info-item" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.dischargeStatus)}" *ngIf="isInstitutional">
                                    <ng-container
                                        *ngIf="isAttrApplicable(clmDta,'cl1Loop2300InstitutionalClaimCode.patientStatusCode')">
                                        <div *ngIf="invalidFieldStatus.patientStatusCode" class="invalid-field-msg" [innerHTML]="getErrorHighlightMsg('Discharge Status is invalid')">    
                                        </div>
                                        <label class="info-label-item" [ngClass]="{'invalid-field': invalidFieldStatus.patientStatusCode}">Discharge Status</label>
                                        <div class="icon-value-wrapper">
                                            <i  class="uitk-icon uitk-icon-edit md-icon"*ngIf="!showHideConfig['patientStatusCode']"
                                                (click)="expandEditor('patientStatusCode',showHideConfig)" icon="uimf-icon-edit"
                                                hiddenText="edit"  ></i>
                                            <span class="data-wrap"
                                                *ngIf="!showHideConfig['patientStatusCode']">{{getFieldLabel("patientStatusCode",clmDta.cl1Loop2300InstitutionalClaimCode.patientStatusCode)}}</span>
                                            <app-error-crr-editor *ngIf="showHideConfig['patientStatusCode']"
                                                [inputType]="inputTypeConfig.select"
                                                [inputValue]="clmDta.cl1Loop2300InstitutionalClaimCode.patientStatusCode"
                                                instruction="Select one option" inputLabel="patientStatusCode"
                                                jsonKey="cl1Loop2300InstitutionalClaimCode.patientStatusCode"
                                                [changeBtnCls]="changeBtnCls"
                                                [listData]="listConfig" (collapseFields)="collapseEditor($event)">
                                            </app-error-crr-editor>
                                        </div>
                                    </ng-container>
                                </div>
                                    <div class="info-item" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.paidAmount)}" >
                                        <ng-container *ngIf="isPaidAmountApplicable()">
                                            <div *ngIf="invalidFieldStatus.paidAmount" class="invalid-field-msg" [innerHTML]="getErrorHighlightMsg('Paid Amount is invalid')">    
                                            </div>
                                            <label [ngClass]="{'invalid-field': invalidFieldStatus.paidAmount}"
                                                class="info-label-item">Paid Amount</label>
                                            <div class="icon-value-wrapper">
                                                <i  class="uitk-icon uitk-icon-edit md-icon"*ngIf="!showHideConfig['paidAmount']"
                                                    (click)="expandEditor('paidAmount',showHideConfig)"   icon="uimf-icon-edit"
                                                    hiddenText="edit"></i>
                                                <span class="data-wrap" *ngIf="!showHideConfig['paidAmount']">{{getPaidAmount()|
                                                    currency}}</span>
                                                <app-error-crr-editor *ngIf="showHideConfig['paidAmount']"
                                                    [inputType]="inputTypeConfig.dollar" [inputValue]="getPaidAmount()"
                                                    instruction="Do not include commas" inputLabel="paidAmount" pattern='[0-9]+'
                                                    [maxLength]="isProfessional ? 5 : 8" jsonKey="paidAmount"
                                                    [changeBtnCls]="changeBtnCls"
                                                    (collapseFields)="collapseEditor($event)">
                                                </app-error-crr-editor>
                                            </div>
                                        </ng-container>
                                </div>

                                <ng-container *ngIf="isProfessional">
                                    <ng-container *ngTemplateOutlet="adjDate"></ng-container>
                                </ng-container>
                                
                                <div class="info-item" *ngIf="isProfessional"></div>
                                <div class="info-item" *ngIf="isProfessional"></div>


                                <div class="info-item error-highlight-section" *ngIf="isProfessional">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.AmbulancePickupAddr)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section" *ngIf="isProfessional">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.AmbulanceDropoffpAddr)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section" *ngIf="isInstitutional">
                                    <app-error-highlight class="data-wrap"
                                        [errorHighlightList]="getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.adjDate)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section"></div>
                                <div class="info-item error-highlight-section"></div>
                                <div class="info-item error-highlight-section" *ngIf="isInstitutional"></div>

                                <ng-container *ngIf="isInstitutional">
                                    <ng-container *ngTemplateOutlet="adjDate"></ng-container>
                                </ng-container>

                                <div class="info-item" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.AmbulancePickupAdd)}" *ngIf="isProfessional">
                                    <ng-container *ngIf="isAttrApplicable(clmDta,'loop2310EAmbulancePickupProvider')">
                                        <div *ngIf="invalidFieldStatus.loop2310EAmbulancePickupProvider" class="invalid-field-msg" [innerHTML]="getErrorHighlightMsg('Ambulance Pick-up Address is invalid')">    
                                        </div>
                                        <label
                                            [ngClass]="{'invalid-field': invalidFieldStatus.loop2310EAmbulancePickupProvider}"
                                            class="info-label-item">Ambulance Pick-up Address</label>
                                        <div class="icon-value-wrapper">
                                            <i  class="uitk-icon uitk-icon-edit md-icon"*ngIf="!showHideConfig['loop2310EAmbulancePickupProvider']"
                                                (click)="expandEditor('loop2310EAmbulancePickupProvider',showHideConfig)"
                                                icon="uimf-icon-edit" hiddenText="edit"  ></i>
                                            <span class="data-wrap"
                                                *ngIf="!showHideConfig['loop2310EAmbulancePickupProvider']">{{getAmbulanceAddr(clmDta.loop2310EAmbulancePickupProvider)}}</span>
                                            <app-error-crr-editor *ngIf="showHideConfig['loop2310EAmbulancePickupProvider']"
                                                [inputType]="inputTypeConfig.address"
                                                [inputValue]="clmDta.loop2310EAmbulancePickupProvider"
                                                instruction="Select one option"
                                                inputLabel="loop2310EAmbulancePickupProvider"
                                                [jsonKey]="addressMapping"
                                                pattern='^[a-zA-Z0-9\s.\-]+$' 
                                                [changeBtnCls]="changeBtnCls"
                                                maxLength='55' [listData]="listConfig"
                                                (collapseFields)="collapseEditor($event)">
                                            </app-error-crr-editor>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="info-item" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.AmbulanceDropoffpAddr)}" *ngIf="isProfessional">
                                    <ng-container *ngIf="isAttrApplicable(clmDta,'loop2310FAmbulanceDropOffProvider')">
                                        <div *ngIf="invalidFieldStatus.loop2310FAmbulanceDropOffProvider" class="invalid-field-msg" [innerHTML]="getErrorHighlightMsg('Ambulance Drop-off Address is invalid')">    
                                        </div>
                                        <label
                                            [ngClass]="{'invalid-field': invalidFieldStatus.loop2310FAmbulanceDropOffProvider}"
                                            class="info-label-item">Ambulance Drop-off Address</label>
                                        <div class="icon-value-wrapper">
                                            <i  class="uitk-icon uitk-icon-edit md-icon"*ngIf="!showHideConfig['loop2310FAmbulanceDropOffProvider']"
                                                (click)="expandEditor('loop2310FAmbulanceDropOffProvider',showHideConfig)"
                                                icon="uimf-icon-edit" hiddenText="edit"  ></i>
                                            <span class="data-wrap"
                                                *ngIf="!showHideConfig['loop2310FAmbulanceDropOffProvider']">{{getAmbulanceAddr(clmDta.loop2310FAmbulanceDropOffProvider)}}</span>
                                            <app-error-crr-editor
                                                *ngIf="showHideConfig['loop2310FAmbulanceDropOffProvider']"
                                                [inputType]="inputTypeConfig.address"
                                                [inputValue]="clmDta.loop2310FAmbulanceDropOffProvider"
                                                instruction="Select one option"
                                                inputLabel="loop2310FAmbulanceDropOffProvider"
                                                [jsonKey]="addressMapping"
                                                pattern='^[a-zA-Z0-9\s.\-]+$' 
                                                [listData]="listConfig"  maxLength='55'
                                                [changeBtnCls]="changeBtnCls"
                                                (collapseFields)="collapseEditor($event)">
                                            </app-error-crr-editor>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </uitk-panel-content>
                    </uitk-panel>
                    <uitk-panel uitkId="claimAdjustmentsInfo" [collapsible]="collapsiblePanel" [open]="open">
                        <uitk-panel-header-title headerClass="tk-panl-with-underline" [ngClass]="{'error-highlight-acc': getErrorHighlightCount(claimInfoMapping.adjustmentSec)}">
                            <strong>
                            Claim Adjustments
                            <span *ngIf="getErrorHighlightCount(claimInfoMapping.adjustmentSec)" class="panel-header-detail">
                                <span class="error-highlight-count">{{getErrorHighlightCount(claimInfoMapping.adjustmentSec)}}</span>
                            </span>
                            </strong>
                        </uitk-panel-header-title>
                        <uitk-panel-content>
                            
                            <div *ngIf="getUnmatchErrorHighlight(claimInfoMapping.adjustmentSec)">
                                <app-error-highlight
                                    [errorHighlightList]="getUnmatchErrorHighlight(claimInfoMapping.adjustmentSec)">
                                </app-error-highlight>
                            </div>
                            <div class="clm-adjst-wrapper hdr-btm-cls">
                                <div class="grp-code clm-adjst-label ">
                                    <div *ngIf="isColumnInvalid(invalidClaimAdjFieldStatus,'groupCode')" class="invalid-clm-adj-field-msg" [innerHTML]="getErrorHighlightMsg('Group Code is invalid')">    
                                    </div>
                                    <span class="field-label"  [ngClass]="{'invalid-field': isColumnInvalid(invalidClaimAdjFieldStatus,'groupCode')}">Group Code</span>
                                </div>
                                <div class="res-code-col clm-adjst-label">
                                    <div *ngIf="isColumnInvalid(invalidClaimAdjFieldStatus,'reasonCode')" class="invalid-clm-adj-field-msg"
                                        [innerHTML]="getErrorHighlightMsg('Reason Code is invalid')">
                                    </div>
                                    <span class="field-label"  [ngClass]="{'invalid-field': isColumnInvalid(invalidClaimAdjFieldStatus,'reasonCode')}">Reason Code</span>
                                </div>
                    
                                <div class="clm-adjst-label">
                                    <div *ngIf="isColumnInvalid(invalidClaimAdjFieldStatus,'amount')" class="invalid-clm-adj-field-msg"
                                        [innerHTML]="getErrorHighlightMsg('Amount is invalid')">
                                    </div>
                                    <span class="field-label" [ngClass]="{'invalid-field': isColumnInvalid(invalidClaimAdjFieldStatus,'amount')}">Amount</span>
                                </div>
                                <div class="clm-adjst-label">
                                    <div *ngIf="isColumnInvalid(invalidClaimAdjFieldStatus,'quantity')" class="invalid-clm-adj-field-msg"
                                        [innerHTML]="getErrorHighlightMsg('Quantity is invalid')">
                                    </div>
                                    <span class="field-label" [ngClass]="{'invalid-field': isColumnInvalid(invalidClaimAdjFieldStatus,'quantity')}">Quantity</span>
                                </div>
                            </div>
                    
                            <div *ngFor="let claimAdjGroup of clmDta.casLoop2320ClaimLevelAdjustments; let claimAdjGroupCode = index">
                                    <div *ngFor="let clmField of claimInfoMapping.adjustmentFields">
                                        <app-error-highlight
                                            [errorHighlightList]="getMatchErrorHighlight(claimInfoMapping.adjustmentSec,clmField,claimAdjGroup.claimAdjGroupCode,claimAdjGroup.rptnPosNum)">
                                        </app-error-highlight>
                                    </div>
                                
                                <ng-container *ngIf="!showHideclaimAdjConfig[claimAdjGroupCode]">
                                    <div class="clm-adjst-wrapper hdr-data-cls">
                                        <div  class="group-code clm-adjst-item" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.adjustmentSec,claimInfoMapping.adjustmentFields[0],claimAdjGroup.claimAdjGroupCode,claimAdjGroup.rptnPosNum)}">
                                            <ng-container>
                                                <span class="icon-clm-edit">
                                                    <i  class="uitk-icon uitk-icon-edit md-icon"icon="uimf-icon-edit" hiddenText="edit"
                                                        (click)="expandEditor(claimAdjGroupCode,showHideclaimAdjConfig)"></i>
                                                </span>{{getFieldLabel('clmAdjGroupCode',claimAdjGroup.claimAdjGroupCode)}}
                                            </ng-container>
                                        </div>
                                        <div class="res-code-col clm-adjst-item" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.adjustmentSec,claimInfoMapping.adjustmentFields[1],claimAdjGroup.claimAdjGroupCode,claimAdjGroup.rptnPosNum)}">{{claimAdjGroup.claimAdjReasonCode}}</div>
                                        <div class="clm-adjst-item"  [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.adjustmentSec,claimInfoMapping.adjustmentFields[2],claimAdjGroup.claimAdjGroupCode,claimAdjGroup.rptnPosNum)}">{{claimAdjGroup.monetaryAmount | currency}}</div>
                                        <div class="clm-adjst-item"  [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.adjustmentSec,claimInfoMapping.adjustmentFields[3],claimAdjGroup.claimAdjGroupCode,claimAdjGroup.rptnPosNum)}">{{claimAdjGroup.quantity}}</div>
                                    </div>
                                </ng-container>
                                <app-error-crr-claim-adjustments *ngIf="showHideclaimAdjConfig[claimAdjGroupCode]"
                                    [inputLabel]="claimAdjGroupCode" [inputValue]="claimAdjGroup" [listData]="listConfig"
                                    [changeBtnCls]="changeBtnCls"
                                    (collapseClaimAdjFields)="collapseClaimAdjEditor($event)"  [isInstitutional]="isInstitutional">
                                </app-error-crr-claim-adjustments>
                            </div>
                        </uitk-panel-content>
                    </uitk-panel>
                    <uitk-panel uitkId="diagnosisCodes" [open]="open"  [collapsible]="collapsiblePanel">
                        <uitk-panel-header-title [ngClass]="{'error-highlight-acc': getErrorHighlightCount(claimInfoMapping.diagnosisCodeSec)}">
                            <strong> Diagnosis Codes
                            <span *ngIf="getErrorHighlightCount(claimInfoMapping.diagnosisCodeSec)" class="panel-header-detail">
                                <span class="error-highlight-count">{{getErrorHighlightCount(claimInfoMapping.diagnosisCodeSec)}}</span>
                            </span>
                            </strong>
                        </uitk-panel-header-title>
                        <uitk-panel-content 
                            headerClass="tk-panl-with-underline" [ngClass]="{'error-highlight-acc': getErrorHighlightCount(claimInfoMapping.diagnosisCodeSec)}">
                           
                            <div *ngIf="getUnmatchErrorHighlight(claimInfoMapping.diagnosisCodeSec)">
                                <app-error-highlight
                                    [errorHighlightList]="getUnmatchErrorHighlight(claimInfoMapping.diagnosisCodeSec)">
                                </app-error-highlight>
                            </div>
                            <div class="claim-info-wrapper hdr-btm-cls">
                                <div class="diag-label qualf-col">
                                    <div *ngIf="isColumnInvalid(invalidDiagnosisCodeFieldStatus,'qualifier')" class="invalid-diagnosis-field-msg"
                                        [innerHTML]="getErrorHighlightMsg('Qualifier is invalid')">
                                    </div>
                                    <span [ngClass]="{'invalid-field': isColumnInvalid(invalidDiagnosisCodeFieldStatus,'qualifier')}">Qualifier</span>
                                </div>    
                                <div class="diag-label diag-code">
                                    <div *ngIf="isColumnInvalid(invalidDiagnosisCodeFieldStatus,'diagnosisCode')" class="invalid-diagnosis-field-msg"
                                        [innerHTML]="getErrorHighlightMsg('Diagnosis Code is invalid')">
                                    </div>
                                    <span [ngClass]="{'invalid-field': isColumnInvalid(invalidDiagnosisCodeFieldStatus,'diagnosisCode')}">Diagnosis Code </span>
                                </div>
                                <div class="diag-label desc-col">Description</div>
                                <div class="diag-label poa-col">POA</div>
                            </div>
                            <div
                                *ngFor="let clm of clmDta?.hiLoop2300HealthcareDiagnosisCode; let diagnosisCode = index">
                                <div *ngFor="let diagnosisField of claimInfoMapping.diagnosisCodeFields">
                                    <app-error-highlight
                                        [errorHighlightList]="getMatchErrorHighlight(claimInfoMapping.diagnosisCodeSec,diagnosisField,clm.codeListQualifierCode,diagnosisCode)">
                                    </app-error-highlight>
                                </div>
                                <ng-container *ngIf="!showHideDiagnosisCodeConfig[diagnosisCode]">
                                    <div class="claim-info-wrapper diag-code-data hdr-data-cls">
                                        <div class="diag-item qualf-col text-ellipses"
                                            [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.diagnosisCodeSec,claimInfoMapping.diagnosisCodeFields[0],clm.codeListQualifierCode,diagnosisCode)}">
                                            <span class="icon-clm-edit">
                                                <i  class="uitk-icon uitk-icon-edit md-icon"(click)="expandEditor(diagnosisCode,showHideDiagnosisCodeConfig)" icon="uimf-icon-edit"
                                                    hiddenText="edit"></i>
                                            </span>
                                            <span
                                                [title]="diagnosisQualMapping[clm.codeListQualifierCode] ? clm.codeListQualifierCode + '-' + diagnosisQualMapping[clm.codeListQualifierCode]: clm.codeListQualifierCode">
                                                {{clm.codeListQualifierCode}}{{ diagnosisQualMapping[clm.codeListQualifierCode] ? '-' +
                                                diagnosisQualMapping[clm.codeListQualifierCode]:''}}
                                            </span>
                                        </div>
                                        <div class="diag-item diag-code text-ellipses"
                                            [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.diagnosisCodeSec,claimInfoMapping.diagnosisCodeFields[1],clm.codeListQualifierCode,diagnosisCode)}">
                                            <span [title]="clm.industryCode">{{clm.industryCode}}</span>
                                        </div>
                                        <div class="diag-item desc-col text-ellipses"
                                            [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.diagnosisCodeSec,claimInfoMapping.diagnosisCodeFields[2],clm.codeListQualifierCode,diagnosisCode)}">
                                            <span [title]="diagnosisDesMapping[clm.industryCode] || ''">{{diagnosisDesMapping[clm.industryCode] ||
                                                ''}}</span>
                                        </div>
                                        <div class="diag-item poa-col text-ellipses"
                                            [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.diagnosisCodeSec,claimInfoMapping.diagnosisCodeFields[3],clm.codeListQualifierCode,diagnosisCode)}">
                                            <span *ngIf="isInstitutional" [title]="poaDropdown[clm.responseCode] || ''">
                                                {{poaDropdown[clm.responseCode] || "" }}
                                            </span>
                                        </div>
                                    </div>
                                </ng-container>
                            <app-error-crr-diagnosis-code class="component-wrapper" *ngIf="showHideDiagnosisCodeConfig[diagnosisCode]"
                                    [inputLabel]="diagnosisCode" [inputValue]="clm" [listData]="listConfig" 
                                    [diagnosisQualMapping]="diagnosisQualMapping" [diagnosisDesMapping]="diagnosisDesMapping"
                                    [changeBtnCls]="changeBtnCls"
                                    [isInstitutional]="isInstitutional"
                                    (collapsediagnosisCodeFields)="collapseDiagnosisCodeEditor($event)">
                                </app-error-crr-diagnosis-code>
                            </div>
                        
                        </uitk-panel-content>
                    </uitk-panel>
                    <uitk-panel class="additional-hltcre-accordion" uitkId="additionalHealthCareInfoCodes" [open]="open"  [collapsible]="collapsiblePanel">
                        <uitk-panel-header-title class="tk-panl-with-underline"  [ngClass]="{'error-highlight-acc': getErrorHighlightCount(claimInfoMapping.addHealthSec)}">
                            <strong> Additional Health Care Information Codes
                            <span *ngIf="getErrorHighlightCount(claimInfoMapping.addHealthSec)" class="panel-header-detail">
                                <span class="error-highlight-count">{{getErrorHighlightCount(claimInfoMapping.addHealthSec)}}</span>
                            </span>
                            </strong>
                        </uitk-panel-header-title>
                        <uitk-panel-content panelId="4"  headerClass="tk-panl-with-underline"  [ngClass]="{'error-highlight-acc': getErrorHighlightCount(claimInfoMapping.addHealthSec)}">
                           
                            <div *ngIf="getUnmatchErrorHighlight(claimInfoMapping.addHealthSec)">
                                <app-error-highlight
                                    [errorHighlightList]="getUnmatchErrorHighlight(claimInfoMapping.addHealthSec)">
                                </app-error-highlight>
                            </div>
                            <div class="claim-info-wrapper hdr-btm-cls">
                                <div class="add-info-label add-info-qualf-col">Qualifier</div>
                                <div class="add-info-label add-info-code-col ">
                                    <div *ngIf="isColumnInvalid(invalidadditionalHltcreFieldStatus,'industryCode')" class="invalid-diagnosis-field-msg"
                                        [innerHTML]="getErrorHighlightMsg('Code is invalid')">
                                    </div>
                                    <span [ngClass]="{'invalid-field': isColumnInvalid(invalidadditionalHltcreFieldStatus,'industryCode')}">Code</span>
                                </div>
                                <div class="add-info-label add-info-code-col">
                                    <div *ngIf="isColumnInvalid(invalidadditionalHltcreFieldStatus,'codeFromDate')" class="invalid-diagnosis-field-msg"
                                        [innerHTML]="getErrorHighlightMsg('Code From is invalid')">
                                    </div>
                                   <span [ngClass]="{'invalid-field': isColumnInvalid(invalidadditionalHltcreFieldStatus,'codeFromDate')}">Code From</span>
                                </div>
                                <div class="add-info-label add-info-code-col">
                                    <div *ngIf="isColumnInvalid(invalidadditionalHltcreFieldStatus,'codeThroughDate')" class="invalid-diagnosis-field-msg"
                                        [innerHTML]="getErrorHighlightMsg('Code Through is invalid')">
                                    </div>
                                    <span [ngClass]="{'invalid-field': isColumnInvalid(invalidadditionalHltcreFieldStatus,'codeThroughDate')}">Code Through</span>
                                </div>
                                <div class="add-info-label amt-col">
                                    <div *ngIf="isColumnInvalid(invalidadditionalHltcreFieldStatus,'amount')" class="invalid-diagnosis-field-msg"
                                        [innerHTML]="getErrorHighlightMsg('Amount is invalid')">
                                    </div>
                                    <span [ngClass]="{'invalid-field': isColumnInvalid(invalidadditionalHltcreFieldStatus,'amount')}">Amount</span> </div>
                            </div>
                            <div *ngFor="let clm of clmDta?.addnlHiLoop2300HealthcareDiagnosisCode; let additionalHltcre = index">
                                <div *ngFor="let addHltCareField of claimInfoMapping.addHltCareInfoFields">
                                    <app-error-highlight [errorHighlightList]="getMatchErrorHighlight(claimInfoMapping.addHealthSec,addHltCareField,clm.codeListQualifierCode,additionalHltcre)">
                                    </app-error-highlight>
                                </div>
                                <ng-container *ngIf="!showHideAdditionalHltCareConfig[additionalHltcre]">
                                    <div class="claim-info-wrapper add-info-data hdr-data-cls">
                                        <div class="add-info-item add-info-qualf-col text-ellipses"
                                            [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.addHealthSec,claimInfoMapping.addHltCareInfoFields[0],clm.codeListQualifierCode,additionalHltcre)}">
                                            <span class="icon-clm-edit">
                                                <i  class="uitk-icon uitk-icon-edit md-icon"icon="uimf-icon-edit" hiddenText="edit"
                                                    (click)="expandEditor(additionalHltcre,showHideAdditionalHltCareConfig)"></i>
                                            </span>
                                            <span
                                                [title]="additionalHltCareDesMapping[clm.codeListQualifierCode] ? clm.codeListQualifierCode + '-' + additionalHltCareDesMapping[clm.codeListQualifierCode]:clm.codeListQualifierCode">
                                                {{clm.codeListQualifierCode}}{{additionalHltCareDesMapping[clm.codeListQualifierCode] ? '-' +
                                                additionalHltCareDesMapping[clm.codeListQualifierCode]:''}}
                                            </span>
                                        </div>
                                        <div class="add-info-item add-info-code-col text-ellipses"
                                            [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.addHealthSec,claimInfoMapping.addHltCareInfoFields[1],clm.codeListQualifierCode,additionalHltcre)}">
                                            <span [title]="clm.industryCode">
                                                {{clm.industryCode}}
                                            </span>
                                        </div>
                                        <div class="add-info-item add-info-code-col"
                                            [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.addHealthSec,claimInfoMapping.addHltCareInfoFields[2],clm.codeListQualifierCode,additionalHltcre)}">
                                            {{getAddHealthDate(clm)}}</div>
                                        <div class="add-info-item add-info-code-col"
                                            [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.addHealthSec,claimInfoMapping.addHltCareInfoFields[2],clm.codeListQualifierCode,additionalHltcre)}">
                                            {{getAddHealthDate(clm,true)}}</div>
                                        <div class="add-info-item amt-col"
                                            [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.addHealthSec,claimInfoMapping.addHltCareInfoFields[3],clm.codeListQualifierCode,additionalHltcre)}">
                                            {{clm.monetaryAmount | currency}}</div>
                                    </div>
                                </ng-container>
                                <app-error-crr-additional-hltcre class="component-wrapper" *ngIf="showHideAdditionalHltCareConfig[additionalHltcre]"
                                    [inputLabel]="additionalHltcre" [inputValue]="clm" [additionalHltCareDesMapping]="additionalHltCareDesMapping"
                                    [changeBtnCls]="changeBtnCls" [getAddHealthDate]="getAddHealthDateProps" [encodeDateFormat]="encodeDateFormat"
                                    (collapseAdditionalHltcreFields)="collapseAdditionalHltcreEditor($event)">
                                </app-error-crr-additional-hltcre>
                            </div>
                        </uitk-panel-content>
                    </uitk-panel>
                    <uitk-panel uitkId="claimReferenceInfo" [collapsible]="collapsiblePanel" [open]="open">
                        <uitk-panel-header-title>
                            <strong>
                            Claim Reference Information
                            <span *ngIf="getErrorHighlightCount(claimInfoMapping.clmRefInfoSec)" class="panel-header-detail">
                                <span class="error-highlight-count">{{getErrorHighlightCount(claimInfoMapping.clmRefInfoSec)}}</span>
                            </span>
                            </strong>
                        </uitk-panel-header-title>
                        <uitk-panel-content panelId="5"
                            headerClass="tk-panl-with-underline" [ngClass]="{'error-highlight-acc': getErrorHighlightCount(claimInfoMapping.clmRefInfoSec)}">
                           
                            <div *ngIf="getUnmatchErrorHighlight(claimInfoMapping.clmRefInfoSec)">
                                <app-error-highlight
                                    [errorHighlightList]="getUnmatchErrorHighlight(claimInfoMapping.clmRefInfoSec)">
                                </app-error-highlight>
                            </div>
                            
                            <div class="claim-info-wrapper hdr-btm-cls">
                                <div class="claim-ref-label qualf-col">Qualifier</div>
                                <div class="claim-ref-label ref-col">
                                    <div *ngIf="isColumnInvalid(invalidClaimRefInfoFieldStatus,'refIdentification')"
                                        class="invalid-clm-adj-field-msg error-msg"
                                        [innerHTML]="getErrorHighlightMsg('Reference Identification is invalid')">
                                    </div>
                                    <span [ngClass]="{'invalid-field': isColumnInvalid(invalidClaimRefInfoFieldStatus,'refIdentification')}"> Reference Identification</span>
                                </div>
                            </div>
                            <div *ngFor="let clm of clmDta?.refLoop2300Reference; let claimRefInfo = index" >
                                
                                <ng-container *ngIf="clm.referenceIdentQual !== 'F8'">
                                <div *ngFor="let clmRefInfo of claimInfoMapping.clmRefInfoFields">
                                    <app-error-highlight
                                        [errorHighlightList]="getMatchErrorHighlight(claimInfoMapping.clmRefInfoSec,clmRefInfo,clm.referenceIdentQual,claimRefInfo)">
                                    </app-error-highlight>
                                </div>
                                <ng-container *ngIf="!showHideClaimRefInfoConfig[claimRefInfo]">
                                   <div class="claim-info-wrapper hdr-data-cls">
                                    <div class="claim-ref-item text-ellipses"
                                    [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.clmRefInfoSec,claimInfoMapping.clmRefInfoFields[0],clm.referenceIdentQual,claimRefInfo)}">
                                    <span class="icon-clm-edit">
                                        <i  class="uitk-icon uitk-icon-edit md-icon"icon="uimf-icon-edit" hiddenText="edit"
                                            (click)="expandEditor(claimRefInfo,showHideClaimRefInfoConfig)"></i>
                                    </span>
                                    <span
                                        [title]="claimReferenceDesMapping[clm.referenceIdentQual] ? clm.referenceIdentQual + '-' + claimReferenceDesMapping[clm.referenceIdentQual]:clm.referenceIdentQual">{{clm.referenceIdentQual}}{{
                                        claimReferenceDesMapping[clm.referenceIdentQual] ? '-' +
                                        claimReferenceDesMapping[clm.referenceIdentQual]:''}}</span>
                                </div>
                                <div class="claim-ref-item ref-col text-ellipses"
                                    [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.clmRefInfoSec,claimInfoMapping.clmRefInfoFields[1],clm.referenceIdentQual,claimRefInfo)}">
                                    <span [title]="clm.referenceIdent">{{clm.referenceIdent}}</span>
                                </div>
                                   </div>
                                </ng-container>
                                <app-error-crr-claim-ref class="component-wrapper" *ngIf="showHideClaimRefInfoConfig[claimRefInfo]"
                                    [inputLabel]="claimRefInfo" [inputValue]="clm" [claimReferenceDesMapping]="claimReferenceDesMapping"
                                    [changeBtnCls]="changeBtnCls" (collapseClaimRefInfoFields)="collapseClaimRefInfoEditor($event)" [listData]="listConfig" >
                                </app-error-crr-claim-ref>
                                </ng-container>
                            </div>
                        </uitk-panel-content>
                    </uitk-panel>
                    <uitk-panel class="addition-clm-dts" uitkId="additionalClaimDates" [collapsible]="collapsiblePanel" [open]="open">
                        <uitk-panel-header-title>
                            <strong>
                            Additional Claim Dates
                            <span *ngIf="getErrorHighlightCount(claimInfoMapping.AddClmDatesSec)" class="panel-header-detail">
                                <span class="error-highlight-count">{{getErrorHighlightCount(claimInfoMapping.AddClmDatesSec)}}</span>
                            </span>
                            </strong>
                        </uitk-panel-header-title>
                        <uitk-panel-content panelId="6"
                            headerClass="tk-panl-with-underline" [ngClass]="{'error-highlight-acc': getErrorHighlightCount(claimInfoMapping.AddClmDatesSec)}">
                            
                            <div *ngIf="getUnmatchErrorHighlight(claimInfoMapping.AddClmDatesSec)">
                                <app-error-highlight
                                    [errorHighlightList]="getUnmatchErrorHighlight(claimInfoMapping.AddClmDatesSec)">
                                </app-error-highlight>
                            </div>
                            <div class="claim-info-wrapper hdr-btm-cls">
                                <div class="clm-dts-label qualf-col">Date/Time Qualifier</div>
                                <div class="clm-dts-label add-dt-frm-col add-dttm-fmt-col">
                                <div *ngIf="isColumnInvalid(invalidAddClaimDatesFieldStatus,'dateTimeFormatQual')" class="invalid-clm-adj-field-msg error-msg"
                                    [innerHTML]="getErrorHighlightMsg('Date/Time Format Qualifier is invalid')">
                                </div>
                                <span [ngClass]="{'invalid-field': isColumnInvalid(invalidAddClaimDatesFieldStatus,'dateTimeFormatQual')}">Date/Time Format Qualifier</span>
                                </div>
                                <div class="clm-dts-label add-dt-frm-col clm-add-date-col">
                                <div *ngIf="isColumnInvalid(invalidAddClaimDatesFieldStatus,'dateFrom')" class="invalid-clm-adj-field-msg error-msg"
                                    [innerHTML]="getErrorHighlightMsg('Date From is invalid')">
                                </div>
                                <span [ngClass]="{'invalid-field': isColumnInvalid(invalidAddClaimDatesFieldStatus,'dateFrom')}">Date From</span>
                                </div>

                                <div class="clm-dts-label add-dt-frm-col clm-add-date-col">
                                <div *ngIf="isColumnInvalid(invalidAddClaimDatesFieldStatus,'dateThrough')" class="invalid-clm-adj-field-msg"
                                    [innerHTML]="getErrorHighlightMsg('Date Through is invalid')">
                                </div>
                                <span [ngClass]="{'invalid-field': isColumnInvalid(invalidAddClaimDatesFieldStatus,'dateThrough')}">Date Through</span>
                                </div>
                                <div class="clm-dts-label add-dt-frm-col">
                                    <div *ngIf="isColumnInvalid(invalidAddClaimDatesFieldStatus,'time')" class="invalid-clm-adj-field-msg error-msg"
                                        [innerHTML]="getErrorHighlightMsg('Invalid Time')">
                                    </div>
                                    <span [ngClass]="{'invalid-field': isColumnInvalid(invalidAddClaimDatesFieldStatus,'time')}">Time</span>
                                </div>
                            </div>
                            <div *ngFor="let clm of clmDta?.dtpLoop2300DateTimePeriod; let addClaimDates = index">
                                <div *ngFor="let addClmDates of claimInfoMapping.AddClmDatesFields">
                                    <app-error-highlight
                                        [errorHighlightList]="getMatchErrorHighlight(claimInfoMapping.AddClmDatesSec,addClmDates,clm.dateTimeQualifier,addClaimDates)">
                                    </app-error-highlight>
                                </div>
                                <ng-container *ngIf="!showHideAddClaimDatesConfig[addClaimDates]">
                                    <div class="claim-info-wrapper hdr-data-cls">
                                    <div class="clm-dts-item qualf-col text-ellipses" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.AddClmDatesSec,claimInfoMapping.AddClmDatesFields[0],clm.dateTimeQualifier,addClaimDates)}">
                                        <span class="icon-clm-edit">
                                            <i  class="uitk-icon uitk-icon-edit md-icon"icon="uimf-icon-edit" hiddenText="edit" (click)="expandEditor(addClaimDates,showHideAddClaimDatesConfig)"></i>
                                        </span>
                                        <span
                                            [title]="additionalClaimDateDesMapping[clm.dateTimeQualifier] ? clm.dateTimeQualifier +'-' + additionalClaimDateDesMapping[clm.dateTimeQualifier]:clm.dateTimeQualifier">

                                            {{clm.dateTimeQualifier}}{{additionalClaimDateDesMapping[clm.dateTimeQualifier]
                                            ? '-' + additionalClaimDateDesMapping
                                            [clm.dateTimeQualifier]:''}}
                                        </span>
                                    </div>

                                    <div class="clm-dts-item add-dt-frm-col add-dttm-fmt-col" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.AddClmDatesSec,claimInfoMapping.AddClmDatesFields[1],clm.dateTimeQualifier,addClaimDates)}">
                                            <span
                                            [title]="additionalClaimDateTimeData[clm.dateTimeFormatQual] ? clm.dateTimeFormatQual +'-' + additionalClaimDateTimeData[clm.dateTimeFormatQual]:clm.dateTimeFormatQual">

                                            {{clm.dateTimeFormatQual}}{{additionalClaimDateTimeData[clm.dateTimeFormatQual]
                                            ? '-' + additionalClaimDateTimeData
                                            [clm.dateTimeFormatQual]:''}}
                                        </span>
                                    </div>

                                    <div class="clm-dts-item add-dt-frm-col clm-add-date-col" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.AddClmDatesSec,claimInfoMapping.AddClmDatesFields[2],clm.dateTimeQualifier,addClaimDates)}">{{getFromDate(clm) }}</div>
                                    <div class="clm-dts-item add-dt-thr-col clm-add-date-col" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.AddClmDatesSec,claimInfoMapping.AddClmDatesFields[3],clm.dateTimeQualifier,addClaimDates)}">{{getThroughDate(clm) }}</div>
                                    <div class="clm-dts-item add-dt-tm-col" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.AddClmDatesSec,claimInfoMapping.AddClmDatesFields[4],clm.dateTimeQualifier,addClaimDates)}">{{getTime(clm) }}</div>
                                </div>
                                </ng-container>
                                <app-error-crr-add-claim-dates class="component-wrapper" *ngIf="showHideAddClaimDatesConfig[addClaimDates]"
                                    [inputLabel]="addClaimDates" 
                                    [inputValue]="clm"
                                    [additionalClaimDateDesMapping]="additionalClaimDateDesMapping"
                                    [additionalClaimDateTimeData]="additionalClaimDateTimeData"
                                    [changeBtnCls]="changeBtnCls"
                                    [getAddClaimDate] = "getAddClaimDateProps"
                                    [encodeDateFormat]="encodeDateFormat"
                                    [isInstitutional]="isInstitutional"
                                    [listData]="listConfig"
                                    (collapseAddClaimDatesEditor)="collapseAddClaimDatesEditor($event)">
                                </app-error-crr-add-claim-dates>
                            </div>
                        </uitk-panel-content>
                    </uitk-panel>
                </div>
            </div>
            <div class="row">
                <div class="col accrdn-btm">
                </div>
            </div>
    
        </div>
        <div class="btns-btm">
            <div>
                <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="cancelHeader()">Cancel</button>
            </div>
            <div>
                <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="saveHeaderClaim()">Save</button>
            </div>
            <div>
                <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="saveHeaderClaim(true)">Resubmit</button>
            </div>
        </div>
    </ng-container>
</div>
<uitk-dialog [(showModal)]="showInvalidWarning"   uitkId="myDialog-1"
[closeButtonText]="'close'" class="warning-popup"
triggerElementId='myDialog_openBtn4' (onModalHidden)="closeHeaderPopUp()">
<uitk-dialog-header>
    <div *ngIf="warningFlow !=='cancel'" >
        <i  class="uitk-icon uitk-icon-warning_filled md-icon" icon="uimf-icon-warning_filled" hiddenText="warning"></i>
        <strong class="warning-msg-heading">Warning</strong>
        
    </div>
</uitk-dialog-header>
    <uitk-dialog-content>
        <div cdkTrapFocus="true">
            <div class="confirm-data" [ngClass]="{'cancel':warningFlow ==='cancel'}">
                <div class="warning-msg-wrapper">
                    <div class="warning-msg-content" >{{warningMsg}}</div> 
                    <div class="warning-btns-btm" *ngIf="warningFlow==='cancel'">
                        <button class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="collapseHeaderPanel()">Continue</button>
                        <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="showInvalidWarning = false">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </uitk-dialog-content>
</uitk-dialog>
<app-error-crr-resubmit [resubmitConfig]="resubmitClmConfig" [selectedRow]="selectedRow" [errorCategoryDetails]="errorCategoryDtls" (collapsePopUp)="colpseHdrResubmitPopUp($event)" ></app-error-crr-resubmit>

<ng-template #adjDate>
    <div class="info-item" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(claimInfoMapping.section,claimInfoMapping.adjDate)}">
        <div *ngIf="invalidFieldStatus.adjPaymentDt" class="invalid-field-msg" [innerHTML]="getErrorHighlightMsg('Adjudication Date is invalid')">    
        </div>
        <label [ngClass]="{'invalid-field': invalidFieldStatus.adjPaymentDt}"
            class="info-label-item">Adjudication Date</label>
        <div class="icon-value-wrapper">
            <i  class="uitk-icon uitk-icon-edit md-icon"*ngIf="!showHideConfig['adjPaymentDt']"
                (click)="expandEditor('adjPaymentDt',showHideConfig)" icon="uimf-icon-edit" hiddenText="edit"
                 ></i>
            <span class="data-wrap"
                *ngIf="!showHideConfig['adjPaymentDt'] && clmDta?.loop2330BOtherPayerName?.dtpLoop2330BAdjDt?.adjPaymentDt">{{decodeDateFormat(clmDta?.loop2330BOtherPayerName?.dtpLoop2330BAdjDt?.adjPaymentDt)}}</span>
            <app-error-crr-editor *ngIf="showHideConfig['adjPaymentDt']"
                [inputType]="inputTypeConfig.date"
                [inputValue]="decodeDateFormat(clmDta?.loop2330BOtherPayerName?.dtpLoop2330BAdjDt?.adjPaymentDt)"
                instruction="mm-dd-yyyy" inputLabel="adjPaymentDt"
                jsonKey="loop2330BOtherPayerName.dtpLoop2330BAdjDt.adjPaymentDt"
                [changeBtnCls]="changeBtnCls"
                (collapseFields)="collapseEditor($event)">
            </app-error-crr-editor>
        </div>
    </div>
</ng-template>
