export const crrServiceIData = {
    'billingProvider':
        [
            {
                'subscriber': [{
                    "claim":[{
                        "loop2400ServiceLine": [
                            {
                                "loop2420Name": [

                                    {

                                        "loop2420NM1": {

                                            "idCode": "285613992",

                                            "nameFirst": "PFNAME",

                                            "nameMiddle": "",

                                            "nameOrgName": "ServNAME71",

                                            "entityIDCode": "72",

                                            "entityTypeQualifier": "1"

                                        }

                                    },

                                    {

                                        "loop2420NM1": {

                                            "idCode": "639174246",

                                            "nameFirst": "PFNAME",

                                            "nameMiddle": "",

                                            "nameOrgName": "ServNAMEZZ",

                                            "entityIDCode": "ZZ",

                                            "entityTypeQualifier": "1"

                                        }

                                    },

                                    {

                                        "loop2420NM1": {

                                            "idCode": "111111112",

                                            "nameFirst": "PFNAME",

                                            "nameMiddle": "",

                                            "nameOrgName": "ServNAME82",

                                            "entityIDCode": "82",

                                            "entityTypeQualifier": "1"

                                        }

                                    },

                                    {

                                        "loop2420NM1": {

                                            "idCode": "3104807882",

                                            "nameFirst": "ServFNAMEDN",

                                            "nameMiddle": "",

                                            "nameOrgName": "ServNAMEDN",

                                            "entityIDCode": "DN",

                                            "entityTypeQualifier": "1"

                                        }

                                    }

                                ],
                                "dtpLoop2400DTP": [
                                    {
                                        "dateTimePeriod": "20220124",
                                        "dateTimeQualifier": "472",
                                        "dateTimeFormatQual": "D8"
                                    }
                                ],
                                "refLoop2400REF": [

                                ],
                                "sv2Loop2400ServiceLine": {
                                    "quantity": 2,
                                    "facilityCode": "18",
                                    "monetaryAmount": 146.42,
                                    "compMedProcedID": {
                                        "productQualifier": "HC",
                                        "productServiceId1": "A5500",
                                        "procedureModifier1": "LT",
                                        "procedureModifier2": "RT"
                                    },
                                    "compDiagCodePoint": "11",
                                    "unitBassisMeasCode": "UN",
                                    "productorServiceID": "0634",
                                    "productQualifier": "HC",
                                    "serviceTypeCode": "S5161"
                                },
                                "ctpLoop2410DrugQuantity": {
                                    "compositeUnitofMea": "UN",
                                    "loop2410DrugQuantity": 2
                                },
                                "loop2400ServiceLineNumber": [
                                    {
                                        "assignedNumber": "1"
                                    }
                                ],
                                "loop2430CASLineAdjustment": [
                                    {
                                        "casLineAdjustment": [{
                                            "claimAdjReasonCode": 11,
                                            "monetaryAmount": 71.8,
                                            "quantity": "11"
                                        },
                                        {
                                            "claimAdjReasonCode": 11,
                                            "monetaryAmount": 71.8,
                                            "quantity": "11"
                                        }
                                        ],
                                        "claimAdjGroupCode": "CO"
                                    },
                                    {
                                        "casLineAdjustment": [{
                                            "claimAdjReasonCode": 11,
                                            "monetaryAmount": 11,
                                            "quantity": "11"
                                        },
                                        {
                                            "claimAdjReasonCode": 1,
                                            "monetaryAmount": 11,
                                            "quantity": "11"
                                        }
                                        ],
                                        "claimAdjGroupCode": "OA"
                                    },
                                    {
                                        "casLineAdjustment": [{
                                            "claimAdjReasonCode": 11,
                                            "monetaryAmount": 11,
                                            "quantity": "11"
                                        },
                                        {
                                            "claimAdjReasonCode": 11,
                                            "monetaryAmount": 11,
                                            "quantity": "11"
                                        }
                                        ],
                                        "claimAdjGroupCode": "PI"
                                    }
                                ],
                                "linLoop2410DrugIdentification": {
                                    "loop2410DrugIdentification": "01234567891"
                                },
                                "loop2430LineAdjudicationInformation":[{"dtpLoop2430DTP": {"dateTimePeriod": "20141203", "dateTimeQualifier": "573", "dateTimeFormatQual": "D8"},
                                 "loop2430CASLineAdjustment": [{"quantity": 0.0, "rptnPosNum": 11, "monetaryAmount": 45.0, "claimAdjGroupCode": "CR", "claimAdjReasonCode": "A1"}], 
                                 "svdLoop2430LineAdjudication": {"rptnPosNum": 1, "monetaryAmount": 65.0}}]
                            },
                            {
                                "loop2420Name": [

                                    {

                                        "loop2420NM1": {

                                            "idCode": "285613992",

                                            "nameFirst": "PFNAME",

                                            "nameMiddle": "",

                                            "nameOrgName": "ServNAME71",

                                            "entityIDCode": "72",

                                            "entityTypeQualifier": "1"

                                        }

                                    },

                                    {

                                        "loop2420NM1": {

                                            "idCode": "639174246",

                                            "nameFirst": "PFNAME",

                                            "nameMiddle": "",

                                            "nameOrgName": "ServNAMEZZ",

                                            "entityIDCode": "ZZ",

                                            "entityTypeQualifier": "1"

                                        }

                                    },

                                    {

                                        "loop2420NM1": {

                                            "idCode": "111111112",

                                            "nameFirst": "PFNAME",

                                            "nameMiddle": "",

                                            "nameOrgName": "ServNAME82",

                                            "entityIDCode": "82",

                                            "entityTypeQualifier": "1"

                                        }

                                    },

                                    {

                                        "loop2420NM1": {

                                            "idCode": "3104807882",

                                            "nameFirst": "ServFNAMEDN",

                                            "nameMiddle": "",

                                            "nameOrgName": "ServNAMEDN",

                                            "entityIDCode": "DN",

                                            "entityTypeQualifier": "1"

                                        }

                                    }

                                ],
                                "dtpLoop2400DTP": [
                                    {
                                        "dateTimePeriod": "20220124-20220124",
                                        "dateTimeQualifier": "472",
                                        "dateTimeFormatQual": "D8"
                                    }
                                ],
                                "refLoop2400REF": [

                                ],
                                "sv1Loop2400ServiceLine": {
                                    "quantity": 2,
                                    "facilityCode": "18",
                                    "monetaryAmount": 146.42,
                                    "compMedProcedID": {
                                        "productQualifier": "HC",
                                        "productServiceId1": "A5500",
                                        "procedureModifier1": "LT",
                                        "procedureModifier2": "RT"
                                    },
                                    "compDiagCodePoint": "11,12",
                                    "unitBassisMeasCode": "UN"
                                },
                                "ctpLoop2410DrugQuantity": {
                                    "compositeUnitofMea": "UN",
                                    "loop2410DrugQuantity": 2
                                },
                                "loop2400ServiceLineNumber": [
                                    {
                                        "assignedNumber": "1"
                                    }
                                ],
                                "loop2430CASLineAdjustment": [
                                    {
                                        "casLineAdjustment": [{
                                            "claimAdjReasonCode": 11,
                                            "monetaryAmount": 71.8,
                                            "quantity": "11"
                                        },
                                        {
                                            "claimAdjReasonCode": 11,
                                            "monetaryAmount": 71.8,
                                            "quantity": "11"
                                        }
                                        ],
                                        "claimAdjGroupCode": "CO"
                                    },
                                    {
                                        "casLineAdjustment": [{
                                            "claimAdjReasonCode": 11,
                                            "monetaryAmount": 11,
                                            "quantity": "11"
                                        },
                                        {
                                            "claimAdjReasonCode": 11,
                                            "monetaryAmount": 11,
                                            "quantity": "11"
                                        }
                                        ],
                                        "claimAdjGroupCode": "OA"
                                    },
                                    {
                                        "casLineAdjustment": [{
                                            "claimAdjReasonCode": 11,
                                            "monetaryAmount": 11,
                                            "quantity": "11"
                                        },
                                        {
                                            "claimAdjReasonCode": 11,
                                            "monetaryAmount": 11,
                                            "quantity": "11"
                                        }
                                        ],
                                        "claimAdjGroupCode": "PI"
                                    }
                                ],
                                "linLoop2410DrugIdentification": {
                                    "loop2410DrugIdentification": "01234567891"
                                },
                                "loop2430LineAdjudicationInformation": [{"dtpLoop2430DTP": {"dateTimePeriod": "20141203", "dateTimeQualifier": "573", "dateTimeFormatQual": "D8"},
                                "loop2430CASLineAdjustment": [{"quantity": 0.0, "rptnPosNum": 11, "monetaryAmount": 45.0, "claimAdjGroupCode": "CR", "claimAdjReasonCode": "A1"}], 
                                "svdLoop2430LineAdjudication": {"rptnPosNum": 1, "monetaryAmount": 65.0}}]
                            },
                        ]
                    }]
                }]

            }
        ]
}

export const errServicegroupCodeDta = [
    { "label": "CO - Contractual Obligations", "value": "CO" },
    { "label": "OA - Other Adjustments", "value": "OA" },
    { "label": "PI - Payor Initiated Reductions", "value": "PI" },
    { "label": "PR - Patient Responsibility", "value": "PR" }]
