<div [formGroup]="addClaimDateForm" class="editor-wrapper hdr-data-cls"
[ngClass]="{'service-catg':isServiceLineCategory}">
    <div class="clm-dts-item qualf-col text-ellipses">
        <span
            [title]="additionalClaimDateDesMapping[inputValue.dateTimeQualifier] ? inputValue.dateTimeQualifier +'-' + additionalClaimDateDesMapping[inputValue.dateTimeQualifier]:inputValue.dateTimeQualifier">{{inputValue.dateTimeQualifier}}{{additionalClaimDateDesMapping[inputValue.dateTimeQualifier]
            ? '-' + additionalClaimDateDesMapping[inputValue.dateTimeQualifier]:''}}</span>
    </div>
    <div class="clm-dts-item add-dt-frm-col add-dttm-fmt-col">
            <span *ngIf="!isDTTMFormatQualEditable()"
            [title]="additionalClaimDateTimeData[inputValue.dateTimeFormatQual] ? inputValue.dateTimeFormatQual +'-' + additionalClaimDateTimeData[inputValue.dateTimeFormatQual]:inputValue.dateTimeFormatQual">{{inputValue.dateTimeFormatQual}}{{additionalClaimDateTimeData[inputValue.dateTimeFormatQual]
            ? '-' + additionalClaimDateTimeData[inputValue.dateTimeFormatQual]:''}}</span>
            <uitk-form-field>
            <uitk-select id="dateTimeFormatQual"  *ngIf="isDTTMFormatQualEditable()" class="select-field-wrapper" [itemList]="listData['dateTimeFormatCode']" 
            formControlName="dateTimeFormatQual"
            (onDropdownChange)="validate()" [defaultLabelFlag]=false [showError]="getFormControlValidity('dateTimeFormatQual')" (onDropdownChange)='onDropdownChangeCallbck($event)'>
            </uitk-select>
        </uitk-form-field>
        </div>

    <div  class="clm-dts-item add-dt-frm-col clm-add-date-col"
        [ngClass]="{'tk-form-element-container--error': displayCodeFrom() && getFormControlValidity('dateFromField')}">
        <uitk-form-field *ngIf="displayCodeFrom()" class="code-from-field date-field" >
            
            <uitk-date-picker
                uitkId="'dateFromField'"
                formControlName="dateFromField"
                (ngModelChange)="validate()"
            ></uitk-date-picker>
            <uitk-form-field-error
                *ngIf="
                displayCodeFrom() && getFormControlValidity('dateFromField')
                "
            >
            
            </uitk-form-field-error>
        </uitk-form-field>
    </div>
    <div class="clm-dts-item add-dt-thr-col clm-add-date-col"
        [ngClass]="{'tk-form-element-container--error': isDateRangeInvalid() || getFormControlValidity('dateThroughField')}">
        <uitk-form-field *ngIf="displayCodeThroughDate()" class="date-through-field" >
            
            <uitk-date-picker
            uitkId="dateFromField"
                formControlName="dateThroughField"
                (ngModelChange)="validate()"
            ></uitk-date-picker>
            <uitk-form-field-error
                *ngIf="
                addClaimDateForm?.controls?.dateThroughField?.errors?.invalidFormat &&
                addClaimDateForm?.controls?.dateFrodateThroughFieldmField?.touched
                "
            >
                
            </uitk-form-field-error>
        </uitk-form-field>
    </div>

    <div class="editor-wrapper clm-dts-item add-dt-tme-col">
        <ng-container *ngIf="displayInputField()">
            <uitk-form-field>  
            <input uitkInput  formControlName="hoursField" class="hours-field" id="hoursField"
                (blur)="validate()"  placeholder="hh"
                [appInputRestrict]="addClaimDateForm" formCtrl="hoursField" maxLength="2"/>
            </uitk-form-field>
            <uitk-form-field-error
            *ngIf="getFormControlValidity('hoursField') || isInvalidInput()"
        >   
        </uitk-form-field-error>
            <span class="separator">-</span>
            <uitk-form-field>
            
            <input uitkInput  formControlName="minutesField" class="minutes-field" id="minutesField"
                (blur)="validate()" [appInputRestrict]="addClaimDateForm" formCtrl="minutesField" maxLength="2"
                 placeholder="mm"/>
                <uitk-form-field-error
                                *ngIf="getFormControlValidity('minutesField') || isInvalidInput()
                                "
                            >   
                            </uitk-form-field-error>
            
        </uitk-form-field>
        </ng-container>
    
</div>
    <div class="action-btn-wrapper">
        <button [class]="'uitk-c-button uitk-c-button--default uitk-u-margin-right--sm   uitk-u-margin-left--xs '+changeBtnCls" (mousedown)="mousedownEvent($event)"
            (click)="changeClick()">Apply</button>
        <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--sm" (click)="cancelClick()">Cancel</button>
    </div>
</div>