import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ClientService } from '../services/client.service';
import { FileService } from '../services/file.service';
import { SelectItem } from '../data-models/select.item';
import { Router } from '@angular/router';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { Constants } from '../data-models/constants.enum';
import { UserPrincipal } from '../services/auth/userPrincipal.model';
import { UntypedFormBuilder } from '@angular/forms';
import { SummaryComponent } from './tabs/summary/summary.component';
import { ErrorsComponent } from './tabs/errors/errors.component';
import { CmsAcceptedComponent } from './tabs/cmsaccepted/cmsaccepted.component';
import { ClosedComponent } from './tabs/closed/closed.component';
import { SummaryService } from '../services/tabs/summary.service';
import { HomeTabsComponent } from './tabs/home.tabs.component';
import { DuplicatesComponent } from './tabs/duplicates/duplicates.component';
import { InprogressComponent } from './tabs/inprogress/inprogress.component';
import { forkJoin } from 'rxjs';
import { ClosedCrrRecordsComponent } from '../crr-dashboard/closed-crr-records/closed-crr-records.component';
import { ClaimCreationCrrRecordsComponent } from '../crr-dashboard/claim-creation-crr-records/claim-creation-crr-records.component';
import { CrrSummaryComponent } from '../crr-dashboard/crr-summary/crr-summary.component';
import { CrrRecordErrorsComponent } from '../crr-dashboard/crr-record-errors/crr-record-errors.component';
import { CrrInprogressComponent } from '../crr-dashboard/crr-inprogress/crr-inprogress.component';
import { CrrClaimInprogressComponent } from '../crr-dashboard/crr-claim-inprogress/crr-claim-inprogress.component';
import { CrrClaimErrorsComponent } from '../crr-dashboard/crr-claim-errors/crr-claim-errors.component';
import { CrrCmsAcceptedComponent } from '../crr-dashboard/crr-cms-accepted/crr-cms-accepted.component';
import { SessionStorageUtility } from '../reports/utility/sessionstorage.utility';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  submissionYear: any;
  customFromDate: any;
  customThroughDate:any;
  graphViewByType:any;
  duration:any;
  public readonly dateConfig: any = {
    enableValidation: false,
    iconCalendar: true
  };
  public customDateInvalid: boolean = false;
  public isApplyClicked: boolean = false;
  public fromDateInvalid: boolean = false;
  public throughDateInvalid: boolean = false;
  private readonly clientNames: any[] = [];
  userDtls: UserPrincipal;
  clientName: any;
  private timePeriods: any[] =[];
  timePeriod: any;
  private readonly viewByDates: any[] = [];
  viewByDate: any;
  private readonly recordTypes: any[] = [];
  recordType: any;
  private readonly timePeriodNew: any;
  public pageloaderConfig = {
    show: true
  };
 
  numDays: any;
  private dataRefreshTime: any;

  private readonly view: any[] = [190, 100];
  // options
  private readonly startDate :any;
  private readonly endDate: any;
  submittedStartDate :any;
  throughDate: any;
  private todayDate: any;
  private today: any;
  spoltUrl: any;
  includeChildData: any;
  asmEnabled: String = "N";
  
  private selectedIndex : any;
  @ViewChild('homeTabs', { read: HomeTabsComponent }) homeTabs: HomeTabsComponent;
 
  public userInactiveDialogModal = {
    show: false,
  };
  tabsData: any[] = [

];

  tabs = [ ];

  constructor(private readonly clientService: ClientService, private readonly fileService: FileService,private readonly summaryService: SummaryService,
    private readonly formBuilder: UntypedFormBuilder,private readonly router: Router, @Inject(SESSION_STORAGE) private readonly sessionStorage: WebStorageService,
    private datePipe:DatePipe) {

    this.viewByDates = [{ id: 0, label:Constants.fileReceivedDate, selected:true, value:Constants.fileReceivedDate },
      { id: 1, label:Constants.claimDosDate, value:Constants.claimDosDate }
    ];
    this.recordTypes = [{ id: 0, label:Constants.ClaimRecordType, value:Constants.ClaimRecordType },
      { id: 1, label:Constants.ChartReviewRecordType, value:Constants.ChartReviewRecordType }
    ];
    this.viewByDate =  SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.viewingBy);
     this.recordType = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.recordType);
    
    if(this.viewByDate==null){
    this.viewByDate = this.viewByDates[0];
  }
  this.recordType === null && (this.recordType = this.recordTypes[0]);
    

  }
  
  delay = ms => new Promise(res => setTimeout(res, ms));
  async ngOnInit() {
    this.getSubmissionYear();
   this.userDtls = JSON.parse(sessionStorage.getItem('userPrincipal'));
   
   console.log("**** Home user principal after wait tim****", this.userDtls);
   if (this.userDtls != null && this.userDtls.active) {
      this.asmEnabled = this.userDtls.asmEnabled;
      forkJoin(
        {
          client:this.clientService.getClients(this.userDtls.preferred_username),
          timePeriod :this.summaryService.getTimePeriod(this.viewByDate.id)
        }
      ).subscribe(data =>{
        for (let index = 0; index < data.client.length; index++) {
          const element = data.client[index];
          const selectItem: SelectItem = new SelectItem();
          selectItem.id = element["dClntSk"];
          selectItem.label = element["clntNm"];
          selectItem.value = element["prtySk"];
          selectItem.spoltUrl = element["spoltUrl"];
          selectItem.includeChildData = element["includeChildData"];
          if(index === 0){
            selectItem.selected = true;
          }
          this.clientNames.push(selectItem);
        }
        if (this.clientNames.length > 0) {
          this.clientName = this.clientNames[0];
          this.includeChildData =  this.clientNames[0].includeChildData;
          this.updateSearchCriteriaFromSession();
          this.prepareTimePeriodCallBack(data.timePeriod,this.viewByDate.id);
          this.getNumDays(this.clientName.value);
          this.getDataRefreshTime(this.clientName.value);
          this.spoltUrl = this.clientNames[0].spoltUrl;
          } else {
          this.pageloaderConfig.show = false;
        }
      },error =>{
        console.log(error);
      });
    } else {
      this.pageloaderConfig.show= false;
      this.userInactiveDialogModal.show = true;
    }
    this.todayDate = new Date().toJSON().split('T')[0];
    this.today="Today";
    this.selectedIndex =  SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.tabsSelectedIndex);
  }

   prepareTabsComponents(prtySk, isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate) {
    this.summaryService.getSummaryStats(prtySk,isChildDataIncluded, clmDtCriteriaId,timeCriteriaId,startDate,endDate).subscribe(data => {
      this.tabs = data;
      this.pageloaderConfig.show = false;
      this.homeTabs && this.homeTabs.ngAfterViewInit();
    });
    const isSingleMonthDateRange = this.checkDateRangeInOneMonth();
    this.tabsData = [
      {component: SummaryComponent, 
        data: {
        timePeriod: this.timePeriod.value,
        submittedStartDate: startDate,
        throughDate: endDate,
        [Constants.graphViewByType]: this.graphViewByType,
        [Constants.duration]:this.duration,
        isSingleMonthDateRange
      }},
      {component:CmsAcceptedComponent, data: {
        timePeriod: this.timePeriod.value,
        submittedStartDate: startDate,
        throughDate: endDate,
        [Constants.graphViewByType]: this.graphViewByType,
        [Constants.duration]:this.duration,
        isSingleMonthDateRange
        
      }},
      {component:ErrorsComponent,data: {
        timePeriod: this.timePeriod.value,
        submittedStartDate: startDate,
        throughDate: endDate,
        [Constants.graphViewByType]: this.graphViewByType,
        [Constants.duration]:this.duration,
        isSingleMonthDateRange
      }},
      {component:DuplicatesComponent, data:{
        timePeriod: this.timePeriod.value,
        submittedStartDate: startDate,
        throughDate: endDate,
        [Constants.graphViewByType]: this.graphViewByType,
        [Constants.duration]:this.duration,
        isSingleMonthDateRange
      }},
      {component:InprogressComponent,data: {
        timePeriod: this.timePeriod.value,
        submittedStartDate: startDate,
        throughDate: endDate,
        [Constants.graphViewByType]: this.graphViewByType,
        [Constants.duration]:this.duration
      }},
      {component:ClosedComponent, data:{
        timePeriod: this.timePeriod.value,
        submittedStartDate: startDate,
        throughDate: endDate,
        [Constants.graphViewByType]: this.graphViewByType,
        [Constants.duration]:this.duration,
        isSingleMonthDateRange
      }}
    ];
  }

  prepareCRRTabsComponents(prtySk, isChildDataIncluded,year_val,startDate,endDate) {
    this.summaryService.getCRRSummaryStats(prtySk, isChildDataIncluded,year_val,startDate,endDate).subscribe(data => {
      this.tabs = data;
      this.pageloaderConfig.show = false;
      this.homeTabs && this.homeTabs.ngAfterViewInit();
    });
    
    this.tabsData = [
      {component: CrrSummaryComponent, data:this.getCRRTabData()},
      {component: ClosedCrrRecordsComponent, data:this.getCRRTabData()},
      {component: CrrRecordErrorsComponent, data:this.getCRRTabData()},
      {component: CrrInprogressComponent, data:this.getCRRTabData()},
      {component: ClaimCreationCrrRecordsComponent, data:this.getCRRTabData()},
      {component: CrrClaimInprogressComponent, data:this.getCRRTabData()},
      {component: CrrClaimErrorsComponent, data:this.getCRRTabData()},
      {component: CrrCmsAcceptedComponent, data:this.getCRRTabData()}
    ];
  }
  
  getCRRTabData(){
    const isSingleMonthDateRange = this.checkDateRangeInOneMonth();
    return {
      timePeriod: this.timePeriod.value,
      submittedStartDate: this.customFromDate,
      throughDate: this.customThroughDate,
      [Constants.graphViewByType]: this.graphViewByType,
      [Constants.duration]:this.duration,
      isSingleMonthDateRange
    }
  }

  checkDateRangeInOneMonth(){
    const fromDate =  new Date(this.customFromDate);
    const throughDate =  new Date(this.customThroughDate);
    return this.isCustomDateRangeSelection() && 
    fromDate.getFullYear() === throughDate.getFullYear() && 
    fromDate.getMonth() === throughDate.getMonth();
  }

  updateSearchCriteriaFromSession(){
    this.clientName = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.clientName);
    if(this.clientName==null){
      this.clientName=this.clientNames[0];
    }
    this.timePeriod = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod);
    if(this.timePeriod==null && this.timePeriods.length>0){
      this.timePeriod=this.timePeriods[1];
    }
    this.viewByDate = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.viewingBy);
    if(this.viewByDate==null){
      this.viewByDate=this.viewByDates[0];
    }
    this.recordType = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.recordType);
    if(this.recordType==null){
      this.recordType=this.recordTypes[0];
    }
    this.includeChildData = this.clientNames[0].includeChildData;
    this.spoltUrl=this.clientNames[0].spoltUrl;
  }

  updateSearchCriteriaIntoSession(){
    this.syncDropdownSelection();
    this.sessionStorage.set(Constants.clientName, JSON.stringify(this.clientName));
    this.sessionStorage.set(Constants.timePeriod, JSON.stringify(this.timePeriod));
    this.sessionStorage.set(Constants.viewingBy, JSON.stringify(this.viewByDate));
    this.sessionStorage.set(Constants.recordType, JSON.stringify(this.recordType));
    this.sessionStorage.set(Constants.fromDate, this.transformDate(this.customFromDate));
    this.sessionStorage.set(Constants.toDate, this.transformDate(this.customThroughDate));
    this.sessionStorage.set(Constants.duration, this.duration);
    this.sessionStorage.set(Constants.graphViewByType, this.graphViewByType);
    this.sessionStorage.set(Constants.spoltUrl, this.clientName.spoltUrl);
    this.sessionStorage.set(Constants.includeChildData, this.clientName.includeChildData);
  }
  syncDropdownSelection(){
    this.clientNames.forEach((item:SelectItem) => {
      item.selected = (item.value === this.clientName.value && item.includeChildData === this.clientName.includeChildData)
    })
    this.timePeriods.forEach((item:SelectItem) => {
      item.selected = (item.id === this.timePeriod.id);
    })
    this.viewByDates.forEach((item:SelectItem) => {
      item.selected = (item.id === this.viewByDate.id )
    })
    this.recordTypes.forEach((item:SelectItem) => {
      item.selected = (item.id === this.recordType.id )
    })
  }


  changeClient(model) {
    if (!this.isCustomDateRangeSelection()) {
      this.pageloaderConfig.show = true;
      // this.clientName = model;
      this.getNumDays(this.clientName.value);
      if(this.recordType.value == Constants.ClaimRecordType){
        this.getDataRefreshTime(this.clientName.value);
      }
      else if(this.recordType.value == Constants.ChartReviewRecordType){
        this.getDataRefreshTimeCRR(this.clientName.value);
      }
      this.populateStats();
    }
  }

  changeTimePeriod(model) {
    this.pageloaderConfig.show = true;
    // this.timePeriod = model;
    if (this.isCustomDateRangeSelection()) {
      this.pageloaderConfig.show = false;
    }
    else {
      this.customFromDate = null;
      this.customThroughDate = null;
      this.resetCustomDateFlag();
      this.populateStats();
    }
  }
  // method to populate Time Period dropdown on change of Viewing By dropdown
  changeViewingBy(model){
    this.pageloaderConfig.show= true;
    // this.viewByDate = model;
    this.customFromDate = null;
    this.customThroughDate = null;
    this.populateTimePeriod(); 
    this.resetCustomDateFlag(); 
  }

  changeRecordType(model){
    this.viewByDate = this.viewByDates[1]
    this.changeViewingBy(this.viewByDates[1]);
    if(this.recordType.value == Constants.ClaimRecordType){
      this.getDataRefreshTime(this.clientName.value);
    }
    else if(this.recordType.value == Constants.ChartReviewRecordType){
      this.getDataRefreshTimeCRR(this.clientName.value);
    }
  }

  populateTimePeriod(){
    this.prepareTimePeriod(this.viewByDate.id);  
  }

  // Service call to get Time Period data by clmDtCriteriaId
  prepareTimePeriod(clmDtCriteriaId){
    this.summaryService.getTimePeriod(clmDtCriteriaId).subscribe( data => {
        this.prepareTimePeriodCallBack(data,clmDtCriteriaId) ;
    });
  }
  prepareTimePeriodCallBack(data,clmDtCriteriaId){
    this.timePeriods = data;
    if(clmDtCriteriaId==0){
      this.timePeriod = this.timePeriods[1];
      }else if(clmDtCriteriaId==1){
        this.timePeriod = this.timePeriods[this.timePeriods.length -2];
      }
      this.populateStats(); 
    }

  populateStats() {
    this.updateSearchCriteriaIntoSession();
    const isChildDataIncluded = this.sessionStorage.get(Constants.includeChildData);
    if(this.recordType.id === 0){
      this.prepareTabsComponents(this.clientName.value, isChildDataIncluded, this.viewByDate.id ,this.timePeriod.id,this.transformDate(this.customFromDate),this.transformDate(this.customThroughDate));
    }else{
      this.prepareCRRTabsComponents(this.clientName.value, isChildDataIncluded,this.timePeriod.value,this.transformDate(this.customFromDate),this.transformDate(this.customThroughDate));
    }
  }
  
  getvalidationFlag(validateFlag) {
    console.log(validateFlag);
  }

  onSelect(event) {
    console.log(event);
  }

  gotoDetails() {
    this.router.navigateByUrl('/reports/summary');
  }

  gotoErrorSummary() {
    this.router.navigateByUrl('/reports/errorSummary');
  }

  isInvalidValidClient() {
    return this.clientNames.length === 0;
  }


  getColor(){
    if(this.numDays<4){
      return "rgba(98, 125, 50, 1)"
    }
    if(this.numDays<8){
      return "rgba(242, 180, 17, 1)"
    }
    if(this.numDays<15){
      return "rgba(232, 119, 34, 1)";
    }
    else{
      return "rgba(209, 63, 68, 1)"
    }
  }

  getNumDays(prtySk){     
    this.fileService.getLastFileReceivedDays(prtySk).subscribe(data => {
    this.numDays=data;
    });
  }
  
  getDataRefreshTime(prtySk){
    this.fileService.getLastRefreshTime(prtySk).subscribe(data => {
    this.dataRefreshTime = data.refreshTime;
    this.fileService.setDataReresh(this.dataRefreshTime);
    });
  }

  getDataRefreshTimeCRR(prtySk){
    this.fileService.getLastRefreshTimeCRR(prtySk).subscribe(data => {
    this.dataRefreshTime = data.refreshTime;
    this.fileService.setDataReresh(this.dataRefreshTime);
    });
  }
  
  onSwitchTab(event) {
    console.log("Tab switched");
  }

  onApplyBtnClick(){
    this.isApplyClicked = true;
    this.validateDate(this.customFromDate,this.customThroughDate);
    if(!this.customDateInvalid){
      this.graphViewByType = this.calculateDays(this.customFromDate,this.customThroughDate);
      this.populateStats();
      this.resetCustomDateFlag();
    }
  }

  resetCustomDateFlag(){
    this.customDateInvalid = false;
    this.fromDateInvalid = false;
    this.throughDateInvalid = false;
    this.isApplyClicked = false;
  }

 validateDate(customFromDate,customThroughDate){
    const fromDate = new Date(customFromDate).getTime();
    const throughDate = new Date(customThroughDate).getTime();
    this.fromDateInvalid = isNaN(fromDate) || new Date(customFromDate).getFullYear() < this.submissionYear ;
    this.throughDateInvalid = isNaN(throughDate) || new Date(customThroughDate).getFullYear() < this.submissionYear || throughDate < fromDate;
   
    if (!this.fromDateInvalid && !this.throughDateInvalid && this.getIs13MonthsRange(fromDate, throughDate)) {
      this.fromDateInvalid = true;
      this.throughDateInvalid = true;
    }
    this.customDateInvalid = this.fromDateInvalid || this.throughDateInvalid;
    
  }

  onDateChanged(customFromDate,customThroughDate){
    this.customDateInvalid = false;
    this.validateDate(customFromDate,customThroughDate);
  }

  getIs13MonthsRange(fromDate,throughDate) {
    const date = new Date(fromDate);
    const d = date.getDate();
    date.setMonth(date.getMonth() + 13);
    if (date.getDate() !== d) {
      date.setDate(0);
    }
    return date.getTime() < throughDate;
  }


  calculateDays(fromDate,throughDate){
    const from = new Date(fromDate);
    const through = new Date(throughDate);
    const time_difference = through.getTime() - from.getTime();
    this.duration = time_difference / (1000 * 60 * 60 * 24) + 1;
    if (this.duration <= 31) {
      return Constants.daily;
    } else {
      return Constants.monthly;
    }
  }
  getSubmissionYear(){
    const claimDOSViewByID = 1;
   this.summaryService.getTimePeriod(claimDOSViewByID).subscribe((data) =>{
    this.submissionYear = data &&  data[0] && data[0].value;
     if(this.submissionYear){
      this.dateConfig.minYear =this.submissionYear;
    }
   })
  }

  isCustomDateRangeSelection() {
   return this.timePeriod.value === Constants.customDateRange;
  }  

  transformDate(datValue){
    const dateFormat= "MM-dd-yyyy";
    if(datValue){
      return this.datePipe.transform(datValue, dateFormat );
    }
  }
  
}
