<div *ngIf="componentLoading" [ngStyle]="{ 'height': '500px', 'position': 'relative' }">
    <uitk-component-load-indicator tkId="centeredLoadIndicator" [centerSpinner]="true" 
        visibleText="Please wait..." [displayOverlay]="false">
    </uitk-component-load-indicator>
</div>
<div *ngIf="!componentLoading" class="conainer-fluid reports-container">
    <div class="row">
        <div class="col">
            <uitk-breadcrumb>
                <uitk-breadcrumb-item routerLink="/home">
                    <span>Home</span>
                </uitk-breadcrumb-item>
                <uitk-breadcrumb-item routerLink="/reports/errorSummary">
                    <span>Error Summary Screen</span>
                </uitk-breadcrumb-item>
            </uitk-breadcrumb>
        </div>
    </div>


<div class=" home-container col-md">
        <div class="conainer-fluid">
            <div class=" row">
                <div class="form-layout">
                    <label for="clientNames">Client</label>
                    <uitk-select [disabled]="isInvalidValidClient()" 
                        customClassName="dropdown-css" [id]="'clientNames'" [itemList]="clientNames"
                        [required]='false' [(ngModel)]='clientName' name="clientName" [errorMessage]="'Value is required'" 
                        defaultLabel='Select Client Name' [defaultLabelFlag]=false (tkInvalidChange)='getvalidationFlag($event)'>
                    </uitk-select>
                </div>
                <div class="col-md-2">
                        <uitk-radio-select
                        [itemList]="dateCriteriaItems"
                        [id]="'dateCriteriaItems'"
                        [tkLabelledby]="'header_radio'"
                        (onModelChange)="onDtCriteriaChange($event)"
                        [(ngModel)]='dateCriteria'
                        [required]='true'>
                    </uitk-radio-select>
                </div>
                <div class="form-layout">
                    <label for="timePeriods">Time Period</label>
                    <uitk-select [disabled]="isInvalidValidClient()" customClassName="dropdown-css" 
                        [id]="'timePeriods'" [itemList]="timePeriods" [required]='false' [(ngModel)]='timePeriod' 
                        name="timePeriod" [errorMessage]="'Value is required'" defaultLabel='Select Time Period'
                        [defaultLabelFlag]=false (tkInvalidChange)='getvalidationFlag($event)' (onDropdownChange)='changeTimePeriod($event)'>
                    </uitk-select>
                </div>
                <div class="col-md-4">
                            <label for="startMonthCriteria">Mon</label>
                        <uitk-select [disabled]="isDateRangeDisabled()" customClassName="month-dropdown-css" 
                            [id]="'startMonthCriteria'" [itemList]="monthCriteriaItems" [required]='false' [(ngModel)]='startMonthCriteria' 
                            name="startMonthCriteria" [errorMessage]="'Value is required'" defaultLabel='Select Time Period'
                            [defaultLabelFlag]=false ></uitk-select>
                </div>
                <div class="col-md-3">
                        <label for="startYearCriteria">Year</label>
                        <uitk-select [disabled]="isDateRangeDisabled()" customClassName="year-dropdown-css" 
                        [id]="'startYearCriteria'" [itemList]="yearCriteriaItems" [required]='false' [(ngModel)]='startYearCriteria' 
                        name="startYearCriteria" [errorMessage]="'Value is required'" defaultLabel='Select Time Period'
                        [defaultLabelFlag]=false ></uitk-select>
                </div>
                <div class="col-md-5">
                    <uitk-icon-font icon="uimf-icon-subtract" hiddenText="subtract" iconSize="md-icon"></uitk-icon-font>
                </div>
                <div class="col-md-4">
                        <label for="endMonthCriteria">Mon</label>
                        <uitk-select [disabled]="isDateRangeDisabled()" customClassName="month-dropdown-css" 
                        [id]="'endMonthCriteria'" [itemList]="monthCriteriaItems" [required]='false' [(ngModel)]='endMonthCriteria' 
                        name="endMonthCriteria" [errorMessage]="'Value is required'" defaultLabel='Select Time Period'
                        [defaultLabelFlag]=false ></uitk-select>
                </div>
                <div class="col-md-3">
                        <label for="endYearCriteria">Year</label>
                        <uitk-select [disabled]="isDateRangeDisabled()" customClassName="year-dropdown-css" 
                        [id]="'endYearCriteria'" [itemList]="yearCriteriaItems" [required]='false' [(ngModel)]='endYearCriteria' 
                        name="endYearCriteria" [errorMessage]="'Value is required'" defaultLabel='Select Time Period'
                        [defaultLabelFlag]=false ></uitk-select> 
                </div>
                <div></div>
                <div>
                    <button (click)="gotoDetails();" type="button" class="button-normal" [disabled]="isApplyButtonDisabled()">Apply</button>
                </div>
            </div>
        </div>
            
             <div class="container-fluid">
                    <div class="row">
                            <div class="col">
                                    <p class="pie-chart-label ax_default">Claim Counts By Error Type</p>
                            </div>
                    </div>
             </div>
             <div class="row" *ngIf="!isDataFound">
                    <div class="form-layout">
                    <label class="dataNotFoundLabel" for="isDataFound">No errors available for the given time range</label>
                    </div>
            </div>
            <div class="row" *ngIf="isDataFound">
                <div class="col">
                    <ngx-charts-pie-chart
                    [view]="view"
                    [scheme]="colorScheme"
                    [results]="single"
                    [gradient]="gradient"
                    [legend]="showLegend"
                    [legendPosition]="legendPosition"
                    [labels]="showLabels"
                    [doughnut]="isDoughnut"
                    (select)="onSelect($event)"
                    (activate)="onActivate($event)"
                    (deactivate)="onDeactivate($event)"
                    >
                  </ngx-charts-pie-chart>
        
                </div>
            </div>
    </div>