import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MdeUtility } from '../../reports/utility/mde.utility';
import { Constants } from '../../data-models/constants.enum';
import { ConfirmModelConfig } from '../../filter-confirm/confirm.mdelconfig';
import { FilterSelectionComponent } from '../filter-selection/filter-selection.component';
import { ErrorPageConfigModel } from '../claim-errors-details/claim-errors-details.model';

@Component({
  selector: 'app-claim-errors-header',
  templateUrl: './claim-errors-header.component.html',
  styleUrls: ['./claim-errors-header.component.css']
})
export class ClaimErrorsHeaderComponent implements OnInit {
  
  @Input() isFileLvlErrors: boolean;
  @Input() pageConfig: ErrorPageConfigModel;
  @Input() scopeData: any;
  @Input() filterSelectionDetails: any;
  @Output() public triggerEvent = new EventEmitter<any>();
  confirmPopupConfig : ConfirmModelConfig = new ConfirmModelConfig(); 
  @ViewChild('filterSelection', {read: FilterSelectionComponent, static: true}) filterSelection : FilterSelectionComponent;
  filterConfig:any;
  filterExpandedView = {
    show: false,
    isApplyClicked:false,
  }
  
  constructor() {
    // ClaimErrorsHeaderComponent constructor
  }

  ngOnInit() {
    this.filterConfig = MdeUtility.getFilterConfig(this.isFileLvlErrors);
    this.prepareBreadcrumbData();
  }

  private prepareBreadcrumbData() {
    const breadcrumbData =  this.pageConfig && this.pageConfig.breadcrumbMapping ;
    if (this.isFileLvlErrors) {
      this.pageConfig.breadcrumb = [
        { name: "Home", url: "/" }, 
        { name: "File Load Activity", url: "/reports/fileload" }
      ]
      this.pageConfig["title"] = this.pageConfig[Constants.inboundFileName] + 
      " - " + this.pageConfig["titlePostfix"];
    } else {
      this.pageConfig.breadcrumb = [{ name: "Home", url: "/" }]
    }
    for(const breadcrumbItem of breadcrumbData){
      this.pageConfig.breadcrumb.push(breadcrumbItem);
    }
  }

  onTimePeriodSelect(model){
    const event = {
      type:Constants.timePeriod,
      model: model
    }
    this.triggerEvent.emit(event);
  }

  onViewByDatesSelect(model){
    const event = {
      type: Constants.viewingBy,
      model: model
    }
    this.triggerEvent.emit(event);
  }

  onClientNameSelect(model){
    const event = {
      type: Constants.clientName,
      model: model
    }
    this.triggerEvent.emit(event);
  }

  filterSelectionEvent({action}){
    this.triggerEvent.emit({action});
  }
}
