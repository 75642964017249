import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ModelMapper } from '../modelmapper';
import { UserPrincipal } from './userPrincipal.model';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { OauthResponse } from './oauthResponse.model';
import { Constants } from 'src/app/data-models/constants.enum';

@Injectable({
    providedIn: 'root'
})
export class OAuthService {
    private readonly currentUserSubject: BehaviorSubject<UserPrincipal>;
    public currentUser: Observable<UserPrincipal>;
    private userPrincipal: UserPrincipal = new UserPrincipal();
    private readonly oauthResponse: OauthResponse = new OauthResponse();
    private baseUrl = "";
    private readonly pingfederateRedirectUrl = environment.pingfederateRedirectUrl;
    private readonly pingfederateLoginUrl = environment.pingfederateLoginUrl + this.pingfederateRedirectUrl;
    private readonly pingLogoutUrl = environment.logoutUrl;
    private refreshTokenSubscription;
    
    private readonly contentTypeJson = 'application/json';

    constructor(private readonly http: HttpClient, private readonly router: Router, @Inject(SESSION_STORAGE) private readonly sessionStorage: WebStorageService) {
        console.log('auth service constructor is called');
        this.currentUserSubject = new BehaviorSubject<UserPrincipal>(this.userPrincipal);
        this.currentUser = this.currentUserSubject.asObservable();
    }


    redirectToPingfederateLogin() {
        window.location.href = this.pingfederateLoginUrl;
    }

    getUserAuthentication<T>(authCode: string){
        console.log('auth code from optumID is ----> ' + authCode);
        const authRequest = {
            oauthCode: authCode,
            redirectUri: this.pingfederateRedirectUrl,
        }        
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': this.contentTypeJson
                })
        };
        const oauthURL = environment.accessTokenServerUrl + environment.oauthEndpoint;
        return this.http.post<OauthResponse>(oauthURL, authRequest, httpOptions).toPromise();           
    }


    getUserPrincipalAppLvl() {
        const resp : OauthResponse = JSON.parse(sessionStorage.getItem("oAuthResponse"));
        let headers = new HttpHeaders(); // create header object         
        console.log('stargate enabled flag in oauth reponse is '+resp.stargateEnabled);
        headers = headers.append('Authorization','Bearer '+resp.access_token);
        if(resp.stargateEnabled){
            this.baseUrl = environment.starGateUrl+Constants.reportingServiceBaseUrlSuffix;
        } else {
            this.baseUrl = environment.backedBaseUrl+Constants.reportingServiceBaseUrlSuffix; 
        }
        const userEndPointURL = this.baseUrl + environment.userEndPoint;
        return this.http.get(userEndPointURL,  { headers: headers }).toPromise();;
    }
	
	async processAuth(authCode: string){
        await this.getUserAuthentication(authCode).then(response => {            
            this.setOAuthResponse(response);
        }, error => this.redirectToPingfederateLogin());
        const resp : OauthResponse = JSON.parse(sessionStorage.getItem("oAuthResponse"));
        console.log('*** auth response', resp);
		await this.getUserPrincipalAppLvl().then(response => {
			console.log('*** app response',response);
			this.userPrincipal = new ModelMapper(UserPrincipal).map(response);
			this.userPrincipal.expires_in=resp.expires_in;
            this.userPrincipal.refreshToken=resp.refresh_token;
            this.userPrincipal.accessToken=resp.access_token;
            this.userPrincipal.tokenType=resp.token_type;
			this.setUserDataStorage(this.userPrincipal);
        }, error =>  console.log('error while fetching user info', error)
        );        
		return true;
	}

    setUserDataStorage(userPrincipal: UserPrincipal): boolean {
        sessionStorage.setItem('userPrincipal', JSON.stringify(userPrincipal));
        sessionStorage.setItem('expDate', this.calculateTokenExpireDate().toString());        
        localStorage.setItem('userPrincipal', JSON.stringify(userPrincipal));
        this.currentUserSubject.next(userPrincipal);
        return true;
    }

    setOAuthResponse(response): boolean{
        sessionStorage.setItem('oAuthResponse',JSON.stringify(response));
        return true;
    }

    clearUserDataStorage() {
        sessionStorage.removeItem("userPrincipal");
        sessionStorage.removeItem("expDate");        
        localStorage.removeItem("userPrincipal");
        this.userPrincipal = null;
        this.currentUserSubject.next(null);
    }

    sessionTimeoutRedirect(){
        this.auditSecureEvent("5");
        this.clearUserDataStorage();
    }

    logoutUser() {
       this.auditSecureEvent("2");
       this.clearUserDataStorage();
    }

    logoutUserRedirectSignIn() {
        const resp : OauthResponse = JSON.parse(sessionStorage.getItem("oAuthResponse"));
        const logoutEndPoint : string = this.pingLogoutUrl + resp.id_token + '&post_logout_redirect_uri=' + this.pingfederateRedirectUrl + '&state=userLogggedOut';
        window.location.href = logoutEndPoint;
     }

    private  baseServerUrl = environment.baseServerUrl;
    getBaseServerUrl(){
        const resp : OauthResponse = JSON.parse(sessionStorage.getItem("oAuthResponse"));
        if(resp.stargateEnabled){
            this.baseServerUrl = environment.starGateUrl+Constants.reportingServiceBaseUrlSuffix;
        }
        else{
            this.baseServerUrl = environment.backedBaseUrl+Constants.reportingServiceBaseUrlSuffix;
        }
      }

    auditSecureEvent(eventId:string){
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': this.contentTypeJson,
            })
        };
        const userData:UserPrincipal = JSON.parse(sessionStorage.getItem("userPrincipal"));
        const sessionIdGenerated:string = userData.sessionIdGenerated;
        const userId:string = userData.preferred_username;
        const client = JSON.parse(JSON.parse(sessionStorage.getItem(Constants.clientName)));
        const prtySk = client && client.value;
        if(eventId == "2")
        {
            this.getBaseServerUrl();
            this.http.post<any[]>(`${this.baseServerUrl}/v1/logout` , {eventId,sessionIdGenerated,userId,prtySk} ,httpOptions).subscribe(response => {});
        }
        else
        {
            this.http.post<any[]>(`${this.baseUrl}/auditSecurityEvent` , {eventId,sessionIdGenerated,userId} ,httpOptions).subscribe(response => {});
        }
    }

    getUserPrincipal(): UserPrincipal {
        const userPrincipal: string = sessionStorage.getItem('userPrincipal');
        return (userPrincipal === null || userPrincipal === '' || userPrincipal === undefined) ? null : JSON.parse(userPrincipal);
    }

    getOauthResponse(): OauthResponse {
        const resp: string = sessionStorage.getItem('oAuthResponse');
        return (resp === null || resp === '' || resp === undefined) ? null : JSON.parse(resp);
    }
    isRefreshTokenTriggered(){
       return this.refreshTokenSubscription;
    }

    checkAndRefreshAccessToken() {
        const currentTime: number = (new Date()).getTime();
        const expireTime: number = (new Date(sessionStorage.getItem("expDate"))).getTime();
        const timeremaining: number = Math.abs((expireTime - currentTime) / 60000);
        console.log('****timeremaining',timeremaining,expireTime,currentTime);
        if (timeremaining < 20 &&  !this.refreshTokenSubscription) {
            this.refreshTokenSubscription=this.getRefreshedAccessToken()
                .subscribe((response) => {
                    console.log('refresh response ', response);
                    this.userPrincipal = this.getUserPrincipal();
                    this.userPrincipal.expires_in=response.expires_in;
                    this.userPrincipal.refreshToken=response.refresh_token;
                    this.userPrincipal.accessToken=response.access_token;
                    this.userPrincipal.tokenType=response.token_type;
                    this.setUserDataStorage(this.userPrincipal);            
                    const oAuthResponse = this.getOauthResponse();
                    oAuthResponse.access_token = this.userPrincipal.accessToken;
                    oAuthResponse.refresh_token = this.userPrincipal.refreshToken;
                    oAuthResponse.expires_in = this.userPrincipal.expires_in;            
                    oAuthResponse.id_token = response.id_token;
                    this.setOAuthResponse(oAuthResponse);
                    sessionStorage.setItem('expDate', this.calculateTokenExpireDate().toString());
                    this.refreshTokenSubscription=null;                       
                }, error => {
                    console.log('refresh error response', error);
                    this.refreshTokenSubscription=null; 
                });
        }
    }

    getRefreshedAccessToken() {
        const userPrincipal: UserPrincipal = this.getUserPrincipal();
        const refreshRequest = {
            refresh_token: userPrincipal.refreshToken
        }
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': this.contentTypeJson
            })
        };
        let refreshUrl = environment.backedBaseUrl+Constants.reportingServiceBaseUrlSuffix;
        if(this.getOauthResponse().stargateEnabled){
            refreshUrl = environment.starGateUrl+Constants.reportingServiceBaseUrlSuffix;
        }         
        refreshUrl += environment.userEndPoint + 'refreshAccessToken';
        return this.http.post<OauthResponse>(refreshUrl, refreshRequest, httpOptions);
    }

    calculateTokenExpireDate(): Date {
        const expireDate = new Date();
        // set expired date to be 60 seconds before the real expired time to account for network issue etc
        const expiresIn = parseInt(this.userPrincipal.expires_in, 10);
        expireDate.setUTCSeconds(expireDate.getUTCSeconds() + (expiresIn - 60));
        return expireDate;
    }

    isUserSessionValid() {
        const userPrincipal: UserPrincipal = JSON.parse(sessionStorage.getItem('userPrincipal'));        
        const expDate: Date = sessionStorage.getItem('expDate') == null ? null : new Date(sessionStorage.getItem('expDate'));
        const currentDate: Date = new Date();       
        setInterval(this.checkAndRefreshAccessToken.bind(this), 10000);
        if (userPrincipal && userPrincipal.accessToken != null && userPrincipal.refreshToken != null && expDate != null && expDate.getTime() >= currentDate.getTime()) {  
            const currentUsrPrnc = this.currentUserSubject.getValue();
            if (currentUsrPrnc && !currentUsrPrnc.email) {
                this.currentUserSubject.next(userPrincipal);
            } 
            return true;
        } else {
            return false;
        }
    }

    isTermsAccepeted(){
        const userPrincipal: UserPrincipal = JSON.parse(sessionStorage.getItem('userPrincipal'));        
        return userPrincipal && userPrincipal.isTNCAccepted === "Y";
    }

    getSupportEmail(emailType){
        const resp : OauthResponse = JSON.parse(sessionStorage.getItem("oAuthResponse"));
        let baseUrl = environment.backedBaseUrl+Constants.maintenanceServiceBaseUrlSuffix; 
        if(resp.stargateEnabled){
            baseUrl = environment.starGateUrl+Constants.maintenanceServiceBaseUrlSuffix;
        }
        return this.http.post(`${baseUrl}/presentation/authorization/supportEmail-details`,{typeOfMailId: emailType});
      }
    
}
