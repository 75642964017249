import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OAuthService } from './oauth.service';
import { Router } from '@angular/router';
import { UserPrincipal } from './userPrincipal.model';
import { OauthResponse } from './oauthResponse.model';
import { Constants } from 'src/app/data-models/constants.enum';

@Injectable(
    { providedIn: 'root' }
)
export class OAuthInterceptor implements HttpInterceptor {
    constructor(private readonly oauthService: OAuthService, private readonly router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const userPrincipal: UserPrincipal = this.oauthService.getUserPrincipal();
        const authResponse : OauthResponse = this.oauthService.getOauthResponse();
        console.log('----- In Interceptor------');
       
        if(userPrincipal){
           if(authResponse.stargateEnabled){
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + (authResponse.access_token),
                   'Access-Control-Request-Method': request.method
                }
            });   
        }
        else{
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + btoa(userPrincipal.preferred_username + ':' + userPrincipal.email)  // username/password is user/user@123 
                }
            });   
        }
       }        
        console.log('Intercepted request URL is: ' + request.url);
        return next.handle(request);
    }
}