<div class="err-crr-member-expand-wrapper">
    <div *ngIf="dataLoading" [ngStyle]="{ 'height': '370px', 'position': 'relative' }">
        <uitk-loading-indicator
        uitkId="crrMemberCatg"
        [centerSpinner]="true"
        visibleText="Please wait..."
        spinnerSize="medium"
        [displayOverlay]="false"
      >
      </uitk-loading-indicator>
    </div>
    <ng-container *ngIf="!dataLoading">
        <div class="info-wapper">
            <div class="flex-container">
                <span *ngIf="open" class="collapse-btn" (click)="toggleOpen()">Collapse All</span>
                <span *ngIf="!open" class="collapse-btn" (click)="toggleOpen()">Expand All</span>
                <a class="close-icon" (click)="cancelMember()" > <i class="uitk-icon uitk-icon-close lg-icon"></i></a>
            </div>
            <div class="err-crr-member-dtls-wrapper">
                <div class="err-crr-member-dtls">
                    <uitk-panel class="claim-info-accordian" uitkId="claimInfo" [open]="open" [collapsible]="collapsiblePanel1">
                        <uitk-panel-header-title  class="tk-panl-with-underline" [ngClass]="{'error-highlight-acc': getErrorHighlightCount(section)}">
                            <strong>
                            {{section}}
                            <span *ngIf="getErrorHighlightCount(section)" class="panel-header-detail">
                                <span class="error-highlight-count">{{getErrorHighlightCount(section)}}</span>
                            </span>
                            </strong>
                        </uitk-panel-header-title>
                        <uitk-panel-content panelId="1" 
                            headerClass="tk-panl-with-underline" [ngClass]="{'error-highlight-acc': getErrorHighlightCount(section)}">
                            
                            <div *ngIf="getUnmatchErrorHighlight(section)" class="info-unmatch-wrapper">
                                <app-error-highlight
                                    [errorHighlightList]="getUnmatchErrorHighlight(section)">
                                </app-error-highlight>
                            </div>
                            <div class="claim-info-wrapper">
                                <div class="info-item member-info-item error-highlight-section">
                                    <app-error-highlight
                                        [errorHighlightList]="getMatchErrorHighlight(section,memberInfoMapping.memberName)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section">
                                    <app-error-highlight
                                        [errorHighlightList]="getMatchErrorHighlight(section,memberInfoMapping.dob)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section">
                                    <app-error-highlight
                                        [errorHighlightList]="getMatchErrorHighlight(section,memberInfoMapping.gender)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item member-info-item" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(section,memberInfoMapping.memberName)}">
                                    <ng-container *ngIf="isAttrApplicable(memberClmInfoObj,'loop2010BNM1')">
                                        <div *ngIf="invalidFieldStatus.memberName" class="invalid-field-msg" 
                                         [innerHTML]="getErrorHighlightMsg('Member Name is invalid')">
                                        </div>
                                        <label class="info-label-item"  [ngClass]="{'invalid-field': invalidFieldStatus.memberName}">
                                                Member Name
                                        </label>
                                        <div class="icon-value-wrapper">
                                            <i class="uitk-icon uitk-icon-edit md-icon"  *ngIf="!showHideConfig['memberName']" (click)="expandEditor('memberName',showHideConfig)" 
                                                  icon="uimf-icon-edit" hiddenText="edit"></i>
                                            <span class="data-wrap" *ngIf="!showHideConfig['memberName']">{{getMemberName()}}</span>
                                            <app-error-crr-editor 
                                            *ngIf="showHideConfig['memberName']" 
                                            [inputType]="inputTypeConfig.entityName"
                                                [inputValue]="memberClmInfoObj.loop2010BNM1" instruction="Max length 48 characters"
                                                inputLabel="memberName" jsonKey="loop2010BNM1" 
                                                [isEntityTypeReq]="false"
                                                (collapseFields)="collapseEditor($event)" [changeBtnCls]="changeBtnCls">
                                            </app-error-crr-editor>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="info-item" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(section,memberInfoMapping.dob)}">
                                    <ng-container *ngIf="isAttrApplicable(memberClmInfoObj,'loop2010BDemographicInformation.dateTimePeriod')">
                                        <div *ngIf="invalidFieldStatus.dob" class="invalid-field-msg" 
                                         [innerHTML]="getErrorHighlightMsg('Date of Birth is invalid')">
                                        </div>
                                        <label class="info-label-item" [ngClass]="{'invalid-field': invalidFieldStatus.dob}">Date of Birth</label>
                                        <div class="icon-value-wrapper">
                                            <i class="uitk-icon uitk-icon-edit md-icon"   *ngIf="!showHideConfig['dob']" (click)="expandEditor('dob',showHideConfig)" icon="uimf-icon-edit" hiddenText="edit"
                                                 ></i>
                                            <span class="data-wrap"
                                            *ngIf="!showHideConfig['dob']">{{getDateOfBirth()}}</span>
                                            <app-error-crr-editor *ngIf="showHideConfig['dob']"
                                                [inputType]="inputTypeConfig.date" [inputValue]="getDateOfBirth()"
                                                inputLabel="dob" instruction="mm-dd-yyyy"
                                                jsonKey="loop2010BDemographicInformation.dateTimePeriod" 
                                                (collapseFields)="collapseEditor($event)" 
                                                [changeBtnCls]="changeBtnCls">
                                            </app-error-crr-editor>
                                        </div>
                                    </ng-container> 
                                </div>
                                <div class="info-item" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(section,memberInfoMapping.gender)}">
                                    <ng-container *ngIf="isAttrApplicable(memberClmInfoObj,'loop2010BDemographicInformation.genderCode')">
                                        <div *ngIf="invalidFieldStatus.gender" class="invalid-field-msg" 
                                         [innerHTML]="getErrorHighlightMsg('Gender is invalid')">
                                        </div>
                                        <label class="info-label-item" [ngClass]="{'invalid-field': invalidFieldStatus.gender}">Gender</label>
                                        <div class="icon-value-wrapper">
                                            <i class="uitk-icon uitk-icon-edit md-icon"  *ngIf="!showHideConfig['gender']" (click)="expandEditor('gender',showHideConfig)"  icon="uimf-icon-edit" hiddenText="edit"
                                                 ></i>
                                            <span class="data-wrap" *ngIf="!showHideConfig['gender']">{{getFieldLabel("gender",getGenderCode())}}</span>
                                            <app-error-crr-editor *ngIf="showHideConfig['gender']"
                                                [inputType]="inputTypeConfig.select" [inputValue]="getGenderCode()"
                                                inputLabel="gender"
                                                instruction="Select one option"
                                                jsonKey="loop2010BDemographicInformation.genderCode"
                                                [listData]="listConfig" 
                                                [optional] = "false"
                                                (collapseFields)="collapseEditor($event)" 
                                                [changeBtnCls]="changeBtnCls">
                                            </app-error-crr-editor>
                                        </div>
                                    </ng-container> 
                                </div>

                                <div class="info-item member-addr error-highlight-section">
                                    <app-error-highlight
                                        [errorHighlightList]="getMatchErrorHighlight(section,memberInfoMapping.memberAddress)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item cms-ctrct-no-field error-highlight-section">
                                    <app-error-highlight
                                        [errorHighlightList]="getMatchErrorHighlight(section,memberInfoMapping.CMSContractNo)">
                                    </app-error-highlight>
                                </div>
                                <div class="info-item error-highlight-section"></div>
                                <div class="info-item error-highlight-section"></div>
                                <div class="info-item member-addr" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(section,memberInfoMapping.memberAddress)}">
                                    <ng-container *ngIf="isAttrApplicable(memberClmInfoObj,'loop2010BAddress') && isAttrApplicable(memberClmInfoObj,'loop2010BCityStateZipCode')">
                                        <div *ngIf="invalidFieldStatus.memberAddress" class="invalid-field-msg" 
                                        [innerHTML]="getErrorHighlightMsg('Member Address is invalid')">
                                       </div>
                                        <label class="info-label-item" [ngClass]="{'invalid-field': invalidFieldStatus.memberAddress}">Member Address</label>
                                        <div class="icon-value-wrapper">
                                            <i class="uitk-icon uitk-icon-edit md-icon"  *ngIf="!showHideConfig['memberAddress']"
                                            (click)="expandEditor('memberAddress',showHideConfig)" icon="uimf-icon-edit" hiddenText="edit" 
                                                 ></i>
                                            <span class="data-wrap" *ngIf="!showHideConfig['memberAddress']">{{getMemberAddr()}}</span>
                                            <app-error-crr-editor *ngIf="showHideConfig['memberAddress']"
                                                [inputType]="inputTypeConfig.address"
                                                [inputValue]="memberClmInfoObj"
                                                instruction="Select one option"
                                                inputLabel="memberAddress"
                                                [jsonKey]="addressKeyMapping"
                                                pattern='^[a-zA-Z0-9\s.\-]+$' 
                                                [changeBtnCls]="changeBtnCls"
                                                maxLength='55' [listData]="listConfig"
                                                (collapseFields)="collapseEditor($event)">
                                            </app-error-crr-editor>
                                        </div>
                                    </ng-container>                           
                                </div>
                                <div class="info-item cms-ctrct-no-field" [ngClass]="{'error-highlight-field': getMatchErrorHighlight(section,memberInfoMapping.CMSContractNo)}">
                                    <ng-container *ngIf="getCmsContrcNo(true,cmsContrctNoDtls)">
                                        <div *ngIf="invalidFieldStatus.CmsContractNumber" class="invalid-field-msg" 
                                        [innerHTML]="getErrorHighlightMsg('CMS Contract Number is invalid')">
                                       </div>
                                        <label class="info-label-item" [ngClass]="{'invalid-field': invalidFieldStatus.CmsContractNumber}"> CMS Contract Number</label>
                                        <div class="icon-value-wrapper">
                                            <i class="uitk-icon uitk-icon-edit md-icon"  *ngIf="!showHideConfig['CmsContractNumber']"
                                            (click)="expandEditor('CmsContractNumber',showHideConfig)"  icon="uimf-icon-edit" hiddenText="edit" 
                                                 ></i>
                                            <span class="data-wrap" *ngIf="!showHideConfig['CmsContractNumber']">{{cmsContrctNoDtls['loop2010BReference'][0].referenceIdent}}</span>
                                            <app-error-crr-editor *ngIf="showHideConfig['CmsContractNumber']"
                                                [inputType]="inputTypeConfig.input" [inputValue]="cmsContrctNoDtls['loop2010BReference'][0].referenceIdent"
                                                instruction="Max length 50 characters" inputLabel="CmsContractNumber"
                                                jsonKey="referenceIdent"  pattern='^[a-zA-Z0-9]+$' maxLength='50'
                                                (collapseFields)="collapseEditor($event)"
                                                [optional] = "false"
                                                [changeBtnCls]="changeBtnCls">
                                            </app-error-crr-editor>
                                        </div>
                                    </ng-container> 
                                </div>
                            </div>
                        </uitk-panel-content>
                    </uitk-panel>
                </div>
            </div>
            <div class="row">
            </div>
        </div>
        <div class="btns-btm accrdn-btm">
            <div>
                <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="cancelMember()">Cancel</button>
            </div>
            <div>
                <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="saveMemberClaim()">Save</button>
            </div>
            <div>
                <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="saveMemberClaim(true)">Resubmit</button>
            </div>
        </div>
    </ng-container>
</div>
<uitk-dialog [(showModal)]="showInvalidWarning"  uitkId='myDialog-4' class="warning-popup"
triggerElementId='myDialog_openBtn4' (onModalHidden)="closeMemberPopUp()">
<uitk-dialog-header>
    <div class="warning-msg-heading-wrap" *ngIf="warningFlow !=='cancel'" >
        <i class="uitk-icon uitk-icon-warning_filled md-icon" hiddenText="warning"></i>
        <div class="warning-msg-heading">Warning</div>
    </div>
</uitk-dialog-header>
    <uitk-dialog-content>
        <div class="confirm-data" [ngClass]="{'cancel':warningFlow ==='cancel'}">
            <div class="warning-msg-wrapper">
                <div class="warning-msg-content">{{warningMsg}}</div> 
                <div class="warning-btns-btm" *ngIf="warningFlow==='cancel'">
                    <button class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="collapseMemberPanel()">Continue</button>
                    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="showInvalidWarning = false">Cancel</button>

                </div>
            </div>
        </div>
        
    </uitk-dialog-content>
</uitk-dialog>
<app-error-crr-resubmit [resubmitConfig]="resubmitClmConfig" [selectedRow]="selectedRow" [errorCategoryDetails]="errorCategoryDtls" (collapsePopUp)="colpseMemberResubmitPopUp($event)" ></app-error-crr-resubmit>
