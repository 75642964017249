import { Component, OnInit } from '@angular/core';
import { OAuthService } from '../../services/auth/oauth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.css']
})
export class SessionTimeoutComponent {
  private readonly pingfederateLoginUrl = environment.pingfederateLoginUrl + environment.pingfederateRedirectUrl;
  constructor(private readonly authenticationService: OAuthService) {
      console.log('SessionTimeoutComponent is constructed');
      this.authenticationService.logoutUser();
  }
  onSignIn(){
    window.location.href = this.pingfederateLoginUrl;
  }
}
