import { Component, OnInit } from '@angular/core';
import { MaintenanceService } from '../services/maintenance.service';

@Component({
  selector: 'app-user-notification-msg',
  templateUrl: './user-notification-msg.component.html',
  styleUrls: ['./user-notification-msg.component.css']
})
export class UserNotificationMsgComponent implements OnInit {

  notificationMsg;

  constructor(private readonly maintenanceService:MaintenanceService) { }

  ngOnInit() {
    this.maintenanceService.getNotificationData().subscribe((res)=>{
      this.refreshNotificationMsg(res);
    })
  }

  closeNotification(msgObj){
    const hiddenList =  JSON.parse(sessionStorage.getItem("notificationHideList"))||  [];
    hiddenList.push(msgObj.changeReqNumber);
    sessionStorage.setItem("notificationHideList",JSON.stringify(hiddenList));
    this.refreshNotificationMsg(this.notificationMsg);
  }

  refreshNotificationMsg(notificationMsgs){
    const hiddenList =  JSON.parse(sessionStorage.getItem("notificationHideList"))||  [];
    this.notificationMsg = notificationMsgs.filter(msgObj=>hiddenList.indexOf(msgObj.changeReqNumber)===-1);
  }

}
